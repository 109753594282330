import { Box, Button, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

const RegisterSuccessModal = ({ handleFiitPdpaOpen }: any) => {
  return (
    <Box display="flex" flexDirection="column" p="32px" gap="16px">
      <Typography align="center" variant="h4" color="black.main">
        <FormattedMessage id="register.success" />
      </Typography>

      <Button
        color="green"
        variant="contained"
        component={RouterLink}
        to="/login"
      >
        <Typography variant="subtitle2" color="white">
          <FormattedMessage id="signin" />
        </Typography>
      </Button>

      <Button
        color="green"
        variant="contained"
        startIcon={
          <Box component="img" src="/icons/fiit.png" width={16} height={14} />
        }
        onClick={handleFiitPdpaOpen}
      >
        <Typography variant="subtitle2" color="white">
          <FormattedMessage id="fiit.cennect" />
        </Typography>
      </Button>

      <Typography align="center" variant="body1" color="black.main">
        <FormattedMessage id="fiit.tokenDesc" />
      </Typography>

      <Box component="img" src="/images/fiit/fiit-token.png" width={1} />
    </Box>
  );
};

export default RegisterSuccessModal;
