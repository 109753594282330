import { Box } from "@mui/system";
import BannerSlider from "../../components/user/home/BannerSlider";
import ResultExercise from "../../components/user/home/ResultExercise";
import Rewards from "../../components/user/home/Rewards";
import StatusInfo from "../../components/user/home/StatusInfo";
import HomeDashboard from "../../components/user/home/HomeDashboard";
import NewsSlider from "../../components/user/home/NewsSlider";
import CourseDpac from "../../components/user/home/CourseDpac";
import HealthLiteracy from "../../components/user/home/HealthLiteracy";
import RewardSponcer from "../../components/user/home/RewardSponcer";
import Sponcers from "../../components/user/home/Sponcers";
import ExerciseResults from "../../components/user/home/ExerciseResults";
import HealthPlace from "../../components/user/home/HealthPlace";

const HomePage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { xs: "24px", sm: "56px" },
        py: "100px",
      }}
    >
      <StatusInfo />

      <BannerSlider />

      <ExerciseResults />

      <HealthPlace />

      <ResultExercise />

      <Rewards />

      <RewardSponcer />

      <HomeDashboard />

      <CourseDpac />

      <NewsSlider />

      <HealthLiteracy />

      <Sponcers />
    </Box>
  );
};

export default HomePage;
