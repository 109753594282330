import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Resizer from "react-image-file-resizer";
import { FormattedMessage } from "react-intl";

import { exerciseActivityTypeService } from "../../services/master-data.service";
import { createActivityExerciseService } from "../../services/exercise.service";
import {
  setHealthPoint,
  getHealthPoint,
  setRank,
  setExperienceLevel,
  setCurrentSeasonExperiencePoint,
} from "../../features/userStore/libs/profileSlice";
import { useAppDispatch, useAppSelector } from "../../features/userStore/hook";
import { imageUploadService } from "../../services/upload.service";

const regex = /(^\d{0,3})+(\.\d{0,2})?$/;
const AddResultExercise = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef = useRef<HTMLInputElement>(null);

  // gobal states
  const dispatch = useAppDispatch();
  const healthPoint = useAppSelector(getHealthPoint);
  // end gobal states

  // states
  const [openSuccess, setSuccessOpen] = useState(false);
  const [openWarnning, setWarnningOpen] = useState(false);
  const [openError, setErrorOpen] = useState(false);
  const [activityType, setActivityType] = useState<any>(null);
  const [exerciseActivityTypes, setExerciseActivityTypes] = useState([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [activityTypeId, setActivityTypeId] = useState<number>(0);
  const [file, setFile] = useState<any>();
  const [base64, setBase64] = useState<any>();
  const [distance, setDistance] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  // end states

  // form
  const {
    register,
    unregister,
    formState,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      activity_type_id: null,
      distances: 0,
      used_hours: 0,
      used_minutes: 0,
      intense_level: null,
    },
  });

  const watchLastActivityTypeId = watch("activity_type_id");
  const usedHours: any = watch("used_hours");
  const usedMinutes: any = watch("used_minutes");

  const { isValid, errors } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);
  // end form

  // handler
  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => setSuccessOpen(false);
  const handleWarnningOpen = () => setWarnningOpen(true);
  const handleWarnningClose = () => {
    setErrorMessage("");
    setWarnningOpen(false);
  };
  // const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };

  const handleUploadClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];

    if (file) {
      Resizer.imageFileResizer(
        file,
        640,
        480,
        "JPEG",
        50,
        0,
        (file: any) => {
          setFile(file);
        },
        "file"
      );

      Resizer.imageFileResizer(
        file,
        640,
        480,
        "PNG",
        80,
        0,
        (file: any) => {
          setBase64(file);
        },
        "base64"
      );
    }
  };

  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  const handleDistancesChange = (event: any) => {
    let result = event.target.value;

    if (regex.test(result) || result === "") {
      setDistance(result);
      setValue("distances", result);

      const distanceLimit = activityType?.distance_limit;
      if (result < 0 || result > distanceLimit) {
        setError("distances", {
          type: "custom",
          message: `จำกัด 0-${distanceLimit} กิโลเมตร.`,
        });
      } else {
        clearErrors("distances");
      }
    }
  };

  const handleHoursChange = (event: any) => {
    let result = event.target.value;
    const timePeriodLimit =
      (activityType?.time_period_limit - usedMinutes) / 60;
    if (result < 0 || result > timePeriodLimit) {
      setError("used_hours", {
        type: "custom",
        message:
          timePeriodLimit <= 0
            ? `ชั่วโมงเกินจำกัด`
            : `จำกัด 0 - ${timePeriodLimit.toFixed(0)} ชม.`,
      });
    } else {
      clearErrors("used_hours");
      clearErrors("used_minutes");
    }
  };

  const handleMinutesChange = (event: any) => {
    let result = event.target.value;
    const timePeriodLimit = activityType?.time_period_limit - usedHours * 60;
    if (result < 0 || result > timePeriodLimit) {
      setError("used_minutes", {
        type: "custom",
        message:
          timePeriodLimit <= 0
            ? `นาทีเกินจำกัด`
            : `จำกัด 0 - ${timePeriodLimit.toFixed(0)} น.`,
      });
    } else {
      clearErrors("used_hours");
      clearErrors("used_minutes");
    }
  };
  // end handler

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();
      let filename = "";
      if (file) {
        const formdata = new FormData();
        formdata.append("file", file);
        const response = await imageUploadService(formdata);
        filename = response.data.filename;
      }

      const activityType: any = exerciseActivityTypes.find(
        (ea: any) => ea.id === values.activity_type_id
      );
      let body: any = {};

      if (
        activityType.name === "เดิน-วิ่ง" ||
        activityType.name === "ปั่นจักรยาน"
      ) {
        body = {
          activity_type_id: values.activity_type_id,
          distances: parseFloat(values.distances),
          used_hours: parseInt(values.used_hours),
          used_minutes: parseInt(values.used_minutes),
          temp_image_filename: filename,
        };
      } else {
        body = {
          activity_type_id: values.activity_type_id,
          used_hours: parseInt(values.used_hours),
          used_minutes: parseInt(values.used_minutes),
          intense_level: parseInt(values.intense_level),
          temp_image_filename: filename,
        };
      }

      const activityExercise = await createActivityExerciseService(body);

      // plus point
      dispatch(setHealthPoint(healthPoint + activityExercise.data.point));
      dispatch(setRank(activityExercise.data.rank));
      dispatch(setExperienceLevel(activityExercise.data.experience_level));
      dispatch(setCurrentSeasonExperiencePoint(activityExercise.data.current_season_experience_point));

      // set state
      handleSuccessOpen();
      setTimeout(() => {
        navigate("/result-exercise", {
          state: activityExercise.data,
          replace: true,
        });
        handleSuccessClose();
      }, 1000);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };
  // end handler

  // func
  const getExerciseActivityType = async () => {
    const response = await exerciseActivityTypeService();
    setExerciseActivityTypes(response.data);
  };
  // end func

  // func core
  useEffect(() => {
    if (watchLastActivityTypeId) {
      const activityType: any = exerciseActivityTypes.find(
        (item: any) => item.id === watchLastActivityTypeId
      );

      if (activityType?.distance_limit) {
        register("distances");
        unregister("intense_level");
      } else {
        register("intense_level");
        unregister("distances");
        setDistance("");
      }

      setValue("used_hours", 0);
      setValue("used_minutes", 0);
      clearErrors("used_hours");
      clearErrors("used_minutes");

      if (activityType) setActivityTypeId(activityType.id);
      setActivityType(activityType);
    }
  }, [exerciseActivityTypes, register, unregister, watchLastActivityTypeId]);

  useEffect(() => {
    if (location.state) {
      const { activity_type_id } = location.state;
      setValue("activity_type_id", activity_type_id);
      setActivityTypeId(activity_type_id);
    }

    getExerciseActivityType();
  }, [location, setValue]);

  useEffect(() => {
    if (errorMessage) handleWarnningOpen();
  }, [errorMessage]);
  // end func core

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "30px",
        pt: "50px",
        pb: "100px",
      }}
    >
      <Typography
        align="center"
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="ktgGreen.main"
        zIndex={100}
      >
        <FormattedMessage id="exercise.addResult" />
      </Typography>

      <Container
        component="main"
        sx={{ zIndex: 1000, maxWidth: 600 }}
        maxWidth={false}
      >
        <Box
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            display: "flex",
            flexDirection: "column",
            padding: { xs: "40px 32px", sm: "24px 56px" },
            borderRadius: "10px",
            background: "#FFFFFF",
            gap: "32px",
          }}
        >
          <Typography align="center" variant="h4" color="common.black">
            <FormattedMessage
              id={
                activityType?.distance_limit || !activityType
                  ? "exercise.addDistanceTime"
                  : "exercise.addTimeFatigue"
              }
            />
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="subtitle2" color="common.black">
                  <FormattedMessage id="exercise.chooseActivityType" />
                </Typography>
              </FormLabel>
              <Select
                value={activityTypeId}
                sx={{
                  "& .MuiSelect-select .notranslate::after": "กรุณาเลือกกีฬา"
                    ? {
                        content: `"กรุณาเลือกกีฬา"`,
                        opacity: 0.42,
                      }
                    : {},
                }}
                {...register("activity_type_id", { required: true })}
                MenuProps={{
                  PaperProps: {
                    style: {
                      height: 250,
                    },
                  },
                }}
              >
                {exerciseActivityTypes.map((item: any, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {activityType && !activityType?.distance_limit && (
              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="subtitle2" color="common.black">
                    <FormattedMessage id="exercise.fatigueLevel" />
                  </Typography>
                </FormLabel>
                <Select
                  sx={{
                    "& .MuiSelect-select .notranslate::after":
                      "กรุณาเลือกระดับความเหนื่อย"
                        ? {
                            content: `"กรุณาเลือกระดับความเหนื่อย"`,
                            opacity: 0.42,
                          }
                        : {},
                  }}
                  {...register("intense_level", { required: true })}
                >
                  <MenuItem value={1} key={1}>
                    เหนื่อยนิดหน่อย
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    เหนื่อยปานกลาง
                  </MenuItem>
                  <MenuItem value={3} key={3}>
                    เหนื่อยมาก
                  </MenuItem>
                </Select>
              </FormControl>
            )}

            {activityType?.distance_limit && (
              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="subtitle2" color="common.black">
                    <FormattedMessage id="exercise.distance" />
                  </Typography>
                </FormLabel>
                <TextField
                  type="text"
                  color="lightGrey"
                  value={distance}
                  {...register("distances", {
                    required: true,
                    min: 0,
                    max: activityType?.distance_limit,
                    onChange: handleDistancesChange,
                  })}
                  error={!!errors["distances"]}
                  helperText={
                    errors["distances"] ? errors["distances"].message : ""
                  }
                />
              </FormControl>
            )}

            <Box sx={{ display: "flex", gap: "20px" }}>
              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="subtitle2" color="common.black">
                    <FormattedMessage id="exercise.hours" />
                  </Typography>
                </FormLabel>
                <TextField
                  type="number"
                  color="lightGrey"
                  error={!!errors["used_hours"]}
                  helperText={
                    errors["used_hours"] ? errors["used_hours"].message : ""
                  }
                  {...register("used_hours", {
                    required: true,
                    min: 0,
                    max: activityType?.time_period_limit - usedMinutes / 60,
                    onChange: handleHoursChange,
                  })}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 2);
                  }}
                />
              </FormControl>

              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="subtitle2" color="common.black">
                    <FormattedMessage id="exercise.minutes" />
                  </Typography>
                </FormLabel>
                <TextField
                  type="number"
                  color="lightGrey"
                  error={!!errors["used_minutes"]}
                  helperText={
                    errors["used_minutes"] ? errors["used_minutes"].message : ""
                  }
                  {...register("used_minutes", {
                    required: true,
                    min: 0,
                    max: activityType?.time_period_limit - usedHours * 60,
                    onChange: handleMinutesChange,
                  })}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 2);
                  }}
                />
              </FormControl>
            </Box>

            <Button
              color="blue"
              variant="contained"
              startIcon={<InsertPhotoOutlinedIcon />}
              onClick={handleUploadClick}
            >
              <input
                hidden
                accept="image/*"
                type="file"
                ref={inputRef}
                onChange={handleFileChange}
              />
              <Typography variant="subtitle2" color="white">
                <FormattedMessage id="common.uploadImage" />
              </Typography>
            </Button>

            {base64 && (
              <Box
                component="img"
                src={base64}
                sx={{ boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)" }}
              ></Box>
            )}

            <Box sx={{ display: "flex", gap: "20px" }}>
              <Button
                color="blue"
                variant="outlined"
                component={RouterLink}
                to="/home"
                fullWidth
              >
                <Typography variant="subtitle2">ยกเลิก</Typography>
              </Button>

              <Button
                color="green"
                variant="contained"
                fullWidth
                onClick={handleSubmit(onSubmitHandler, onError)}
                disabled={
                  !isValid ||
                  (activityType && activityType?.distance_limit && !file) ||
                  loading
                }
              >
                <Typography variant="subtitle2" color="white">
                  <FormattedMessage id="common.save" />
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>

      {/* Modal */}
      <Modal keepMounted open={openSuccess}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340,
            bgcolor: "background.paper",
            borderRadius: "10px",
            p: 4,
          }}
        >
          <Typography align="center">
            <CheckCircleOutlineIcon sx={{ fontSize: 60 }} color="ktgGreen" />
          </Typography>

          <Typography align="center" variant="subtitle2" color="common.black">
            <FormattedMessage id="common.saveSuccess" />
          </Typography>
        </Box>
      </Modal>

      <Modal keepMounted open={openWarnning}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340,
            bgcolor: "background.paper",
            borderRadius: "10px",
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography align="center">
            <ErrorOutlineIcon sx={{ fontSize: 50 }} color="secYellow" />
          </Typography>

          <Box>
            <Typography align="center" variant="subtitle2" color="common.black">
              {errorMessage}
            </Typography>
          </Box>

          <Button
            color="blue"
            variant="contained"
            onClick={handleWarnningClose}
          >
            <Typography variant="subtitle2" color="white">
              <FormattedMessage id="common.close" />
            </Typography>
          </Button>
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340,
            bgcolor: "background.paper",
            borderRadius: "10px",
            p: 4,
          }}
        >
          <Typography align="center">
            <HighlightOffIcon sx={{ fontSize: 50, color: "#EB698F" }} />
          </Typography>

          <Typography align="center" variant="h4" color="#EB698F" mt="15px">
            {errorMessage}
          </Typography>
        </Box>
      </Modal>
      {/* Modal */}
    </Box>
  );
};

export default AddResultExercise;
