import { Box, Button, Typography } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

const ConfirmCancleConnectFiitModal = ({
  handlePostFiitDisConnect,
  handleConfirmCancleConnectFiitClose,
}: any) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      p="32px"
      gap="16px"
    >
      <HelpOutlineOutlinedIcon
        sx={{
          color: "#FFB100",
          width: 50,
          height: 50,
        }}
      />

      <Box display="flex" flexDirection="column" gap="8px">
        <Typography align="center" typography="h4" color="black.main">
          คุณต้องการยกเลิก
        </Typography>

        <Typography align="center" typography="h4" color="black.main">
          การเชื่อมต่อใช่หรือไม่
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column">
        <Typography typography="body1" fontWeight={600} color="black.main">
          สิทธิประโยชน์ที่คุณจะเสียไป
        </Typography>

        <Box component="ol" paddingLeft="16px" my="8px">
          <Box component="li">
            <Typography typography="body1" color="black.main">
              ไม่สามารถส่งผลการออกกำลังกาย ไปยังระบบก้าวท้าใจได้
            </Typography>
          </Box>

          <Box component="li">
            <Typography typography="body1" color="black.main">
              หากต้องการส่งผลอีกครั้ง ต้องทำการเชื่อมระบบใหม่
            </Typography>
          </Box>

          <Box component="li">
            <Typography typography="body1" color="black.main">
              ข้อมูลที่สะสมใน FIIT TOKEN ยังจัดเก็บ ตามจำนวนโควต้าของ FIIT TOKEN
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" gap="8px" width={1}>
        <Button
          color="tealishBlue"
          sx={{ boxShadow: "none" }}
          variant="contained"
          fullWidth
          onClick={handlePostFiitDisConnect}
        >
          <Typography variant="subtitle2" color="black.main">
            ยืนยัน
          </Typography>
        </Button>

        <Button
          color="primary"
          variant="outlined"
          fullWidth
          onClick={handleConfirmCancleConnectFiitClose}
        >
          <Typography variant="subtitle2" color="primary">
            ปิด
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmCancleConnectFiitModal;
