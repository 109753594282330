import {
  Box,
  Button,
  Container,
  Modal,
  Pagination,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  redemptionHistoryService,
  redeemService,
} from "../../../services/reward.service";
import QRCode from "react-qr-code";
import Barcode from "react-barcode";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Link as RouterLink } from "react-router-dom";

const RedemptionHistory = () => {
  const [openRedeem, setOpenRedeem] = useState<boolean>(false);
  const [openSuccess, setSuccesOpen] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [redeem, setRedeem] = useState<any>(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [redemptionHistories, setRedemptionHistories] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const getRedemptionHistory = () => {
    redemptionHistoryService({ page, size }).then((res) => {
      setRedemptionHistories(res.data.items);
      setCount(res.data.total_page);
    });
  };

  const confirmRedemption = async () => {
    try {
      setLoading(true);
      await redeemService({ redemption_id: redeem.redemption_id });

      handleSuccessOpen();
      handleRedeemClose();
      getRedemptionHistory();
      setLoading(false);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      setLoading(false);
    }
  };

  const handleSuccessOpen = () => setSuccesOpen(true);
  const handleSuccessClose = () => setSuccesOpen(false);

  const handleRedeemOpen = (redeem: any) => {
    setRedeem(redeem);
    setOpenRedeem(true);
  };

  const handleRedeemClose = () => {
    setRedeem(null);
    setOpenRedeem(false);
  };

  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };

  useEffect(getRedemptionHistory, [page]);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      gap="24px"
      pt="50px"
      pb="100px"
    >
      <Typography
        align="center"
        typography={{ xs: "h3", sm: "h1" }}
        color="ktgGreen.main"
        zIndex={1}
      >
        ประวัติการแลกของรางวัล
      </Typography>

      <Container
        component="main"
        sx={{
          zIndex: 1,
          maxWidth: 600,
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
        maxWidth={false}
      >
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="10px"
          alignItems="center"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.855) 0%, rgba(255, 255, 255, 0.76) 100%)",
          }}
          padding={{ xs: "24px 8px", sm: "24px" }}
          gap="16px"
        >
          {redemptionHistories.map((rh: any, index: number) => (
            <Box
              key={index}
              width={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                background: "#FFFFFF",
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                borderRadius: "10px",
              }}
              padding={{ xs: "8px", sm: "16px" }}
              gap="16px"
            >
              <Box display="flex" flexDirection="column" gap="4px">
                <Typography
                  typography={{ xs: "body2", sm: "body1" }}
                  color="cgrey.main"
                >
                  {dayjs(rh.reserve_date).locale("th").format("D MMMM BBBB")}
                </Typography>

                <Typography
                  fontWeight={600}
                  fontSize={{ xs: "16px", sm: "18px" }}
                  color="common.black"
                >
                  {rh.reward_title}
                </Typography>

                <Typography
                  fontWeight={600}
                  fontSize={{ xs: "16px", sm: "18px" }}
                  color="secPink.main"
                >
                  ใช้ {rh.point} แต้มสุขภาพ
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                minWidth={80}
              >
                <Button
                  color={rh.status === 1 ? "green" : "primary"}
                  variant="contained"
                  sx={{ height: "30px", minWidth: "60px", boxShadow: "none" }}
                  disabled={rh.status === 2}
                  onClick={() => handleRedeemOpen(rh)}
                >
                  <Typography
                    typography={{ sx: "body2", sm: "body1" }}
                    color="white"
                  >
                    {rh.status === 1 ? "ใช้สิทธิ์" : "ใช้สิทธิ์แล้ว"}
                  </Typography>
                </Button>
              </Box>
            </Box>
          ))}
          <Pagination
            count={count}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            color="blue"
          />
        </Box>

        <Button
          color="blue"
          variant="contained"
          component={RouterLink}
          to="/reward"
        >
          <Typography variant="subtitle2" color="white">
            กลับหน้าแลกรางวัล
          </Typography>
        </Button>
      </Container>

      <Modal keepMounted open={openRedeem}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340,
            bgcolor: "background.paper",
            borderRadius: "10px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            padding="20px"
            alignItems="center"
            gap="16px"
          >
            <Box alignSelf="end" component="a" onClick={handleRedeemClose}>
              <HighlightOffOutlinedIcon
                sx={{ color: "#89939E", cursor: "pointer" }}
              />
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              gap="4px"
              alignItems="center"
            >
              {redeem && (
                <QRCode
                  size={256}
                  style={{ width: 140, height: 140 }}
                  value={redeem?.code}
                />
              )}

              <Typography variant="body1" color="common.black">
                {redeem?.code}
              </Typography>
            </Box>

            <Barcode
              value={redeem?.code}
              fontSize={16}
              width={1.4}
              height={100}
            />

            <Typography variant="body2" color="#EF1717">
              กดปุ่ม “ยืนยันใช้สิทธิ์” เมื่อใช้สิทธิ์แล้ว
            </Typography>

            <Button
              color="green"
              variant="contained"
              fullWidth
              onClick={confirmRedemption}
              disabled={loading}
            >
              <Typography typography="subtitle2" color="white">
                ยืนยันใช้สิทธิ์
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal keepMounted open={openSuccess} onClose={handleSuccessClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340,
            bgcolor: "background.paper",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ textAlign: "end", p: 1 }}>
            <Typography
              onClick={handleSuccessClose}
              component="span"
              sx={{ cursor: "pointer" }}
              color="cgrey.main"
            >
              <CloseIcon sx={{ fontSize: 20 }} />
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            sx={{ px: 4, pb: 4 }}
            gap="16px"
          >
            <Typography align="center">
              <CheckCircleOutlineIcon sx={{ fontSize: 60 }} color="ktgGreen" />
            </Typography>

            <Typography align="center" variant="h4" color="ktgGreen.main">
              ยืนยันใช้สิทธิ์ สำเร็จ
            </Typography>
          </Box>
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340,
            bgcolor: "background.paper",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ textAlign: "end", p: 1 }}>
            <Typography
              onClick={handleErrorClose}
              component="span"
              sx={{ cursor: "pointer" }}
              color="cgrey.main"
            >
              <CloseIcon sx={{ fontSize: 20 }} />
            </Typography>
          </Box>

          <Box sx={{ px: 4, pb: 4 }}>
            <Typography align="center">
              <HighlightOffIcon sx={{ fontSize: 60, color: "#EB698F" }} />
            </Typography>
            <Typography align="center" variant="h4" color="#EB698F" mt="15px">
              {errorMessage}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default RedemptionHistory;
