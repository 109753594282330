import { Box, Button, Container, Pagination, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Link as RouterLink } from "react-router-dom";
import { historyPointService } from "../../../services/reward.service";

const RewardPoint = () => {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [historyHealthPoint, setHistoryHealthPoint] = useState<any>([]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const getRewardPublish = async () => {
    const response = await historyPointService({ page, size });
    setHistoryHealthPoint(response.data.items);
    setCount(response.data.total_page);
  };

  useEffect(() => {
    getRewardPublish();
  }, [page]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      gap="24px"
      pt="50px"
      pb="100px"
    >
      <Typography
        align="center"
        typography={{ xs: "h3", sm: "h1" }}
        color="ktgGreen.main"
        zIndex={1}
      >
        แต้มสุขภาพ
      </Typography>

      <Container
        component="main"
        sx={{
          zIndex: 1,
          maxWidth: 600,
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
        maxWidth={false}
      >
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="10px"
          alignItems="center"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background: "#0892F5",
            textDecoration: "none",
          }}
          padding="8px"
          gap="8px"
          component={RouterLink}
          to="/point-conditions"
        >
          <Typography
            align="center"
            typography={{ xs: "h4", sm: "h3" }}
            color="white"
            zIndex={1}
          >
            แต้มสุขภาพ
          </Typography>

          <Typography
            align="center"
            typography={{ xs: "body1", sm: "subtitle2" }}
            color="white"
            zIndex={1}
          >
            ได้รับอย่างไร ?
          </Typography>
        </Box>
      </Container>

      <Container
        component="main"
        sx={{
          zIndex: 1,
          maxWidth: 600,
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
        maxWidth={false}
      >
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="10px"
          alignItems="center"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.855) 0%, rgba(255, 255, 255, 0.76) 100%)",
          }}
          padding="16px"
          gap="16px"
        >
          <Typography
            align="center"
            typography={{ xs: "h4", sm: "h3" }}
            color="common.black"
          >
            ประวัติการสะสมแต้มสุขภาพ
          </Typography>

          {historyHealthPoint.map((hp: any, index: number) => (
            <Box
              key={index}
              width={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                background: "#FFFFFF",
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                borderRadius: "10px",
              }}
              padding={{ xs: "8px", sm: "16px" }}
            >
              <Box display="flex" flexDirection="column" gap="4px">
                <Typography
                  typography={{ xs: "body2", sm: "body1" }}
                  color="cgrey.main"
                >
                  {dayjs(hp.activity_date).locale("th").format("D MMMM BBBB")}
                </Typography>

                <Typography
                  fontWeight={600}
                  fontSize={{ xs: "16px", sm: "18px" }}
                  color="common.black"
                >
                  {hp.activity_type_name}
                </Typography>
                {hp.activity_type_id < 1000 && (
                  <Box display="flex" gap="24px">
                    <Box display="flex" flexDirection="column">
                      <Typography
                        align="center"
                        typography={{ xs: "body2", sm: "h4" }}
                        color="green.main"
                      >
                        {hp.used_hours * 60 + hp.used_minutes}
                      </Typography>

                      <Typography
                        align="center"
                        fontWeight={600}
                        fontSize={{ xs: "12px", sm: "14px" }}
                        color="cgrey.main"
                      >
                        นาที
                      </Typography>
                    </Box>

                    <Box display="flex" flexDirection="column">
                      <Typography
                        align="center"
                        typography={{ xs: "body2", sm: "h4" }}
                        color="green.main"
                      >
                        {hp.distances || 0}
                      </Typography>

                      <Typography
                        align="center"
                        fontWeight={600}
                        fontSize={{ xs: "12px", sm: "14px" }}
                        color="cgrey.main"
                      >
                        กิโลเมตร
                      </Typography>
                    </Box>

                    <Box display="flex" flexDirection="column">
                      <Typography
                        align="center"
                        typography={{ xs: "body2", sm: "h4" }}
                        color="green.main"
                      >
                        {hp.calories || 0}
                      </Typography>

                      <Typography
                        align="center"
                        fontWeight={600}
                        fontSize={{ xs: "12px", sm: "14px" }}
                        color="cgrey.main"
                      >
                        แคลอรี
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                sx={{ background: "#EB698F" }}
                borderRadius="10px"
                padding="4px 0"
                width={{ xs: 80, sm: 85 }}
                height={{ xs: 80, sm: 85 }}
              >
                <Typography
                  align="center"
                  fontWeight={600}
                  fontSize={{ xs: "12px", sm: "14px" }}
                  color="white"
                >
                  ได้รับ
                </Typography>

                <Typography align="center" typography="h4" color="white">
                  {hp.point}
                </Typography>

                <Typography
                  align="center"
                  fontWeight={600}
                  fontSize={{ xs: "12px", sm: "14px" }}
                  color="white"
                >
                  แต้ม
                </Typography>
              </Box>
            </Box>
          ))}

          <Pagination
            count={count}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            color="blue"
          />
        </Box>

        <Button
          color="blue"
          variant="contained"
          component={RouterLink}
          to="/reward"
        >
          <Typography variant="subtitle2" color="white">
            กลับหน้าแลกรางวัล
          </Typography>
        </Button>
      </Container>
    </Box>
  );
};

export default RewardPoint;
