import { Box, Button, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { FormattedMessage } from "react-intl";
import dayjs from "dayjs";
import { Link as RouterLink } from "react-router-dom";

import { activityExerciseSummaryService } from "../../../services/exercise.service";

const COLORS = ["#58DB08", "#D9E2EB"];

const DashboardExerciseAll = () => {
  const [pieData, setPieData] = useState<any>([]);
  const [targetPersent, setTargetPersent] = useState<any>([]);
  const [areaData, setAreaData] = useState<any>([]);
  const [activityExerciseSummary, setActivityExerciseSummary] = useState<any>(
    {}
  );

  const getActivityExerciseSummary = async () => {
    const response = await activityExerciseSummaryService();
    setActivityExerciseSummary(response.data);
    setPieData([
      { name: "used", value: response.data.weekly_used_minutes || 0 },
      { name: "remind", value: response.data.weekly_remain_minutes },
    ]);
    setTargetPersent(
      response.data.weekly_used_minutes
        ? (
            (response.data.weekly_used_minutes * 100) /
            response.data.weekly_target_minutes
          ).toFixed(0)
        : 0
    );
    setAreaData(response.data.activities);
  };

  // func core
  useEffect(() => {
    getActivityExerciseSummary();
  }, []);
  // end func core

  return (
    <Box
      display="flex"
      flexDirection="column"
      py={{ xs: "32px", sm: "24px" }}
      px={{ xs: "24px", sm: "128px", md: "192px" }}
      gap="32px"
    >
      <Box display="flex" flexDirection="column" gap="16px">
        <Typography
          align="center"
          typography={{ xs: "h5", sm: "h4" }}
          color="common.black"
        >
          <FormattedMessage id="exercise.allResult" />
        </Typography>

        <Box display="flex" justifyContent="space-between">
          <Box display="flex" gap="8px">
            <Box
              component="img"
              src="/icons/sneaker_all.png"
              width="22px"
              height="22px"
              alignSelf="center"
            />
            <Box display="flex" flexDirection="column" gap="8px">
              <Typography variant="body2" color="softBlack.main">
                <FormattedMessage id="exercise.distanceUsed" />
              </Typography>

              <Box display="flex" flexDirection="row" gap="8px">
                <Typography
                  variant="h5"
                  color="common.black"
                  display="flex"
                  flexDirection="column"
                  justifyContent="end"
                >
                  {activityExerciseSummary?.total_distances?.toLocaleString() ||
                    0}
                </Typography>

                <Typography
                  variant="body2"
                  color="lightGrey.main"
                  display="flex"
                  flexDirection="column"
                  justifyContent="end"
                >
                  <FormattedMessage id="exercise.distanceUnit" />
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box display="flex" gap="8px">
            <Box
              component="img"
              src="/icons/clock_all.png"
              width="22px"
              height="22px"
              alignSelf="center"
            />
            <Box display="flex" flexDirection="column" gap="8px">
              <Typography variant="body2" color="softBlack.main">
                <FormattedMessage id="exercise.timeUsed" />
              </Typography>

              <Box display="flex" flexDirection="row" gap="8px">
                <Typography
                  variant="h5"
                  color="common.black"
                  display="flex"
                  flexDirection="column"
                  justifyContent="end"
                >
                  {activityExerciseSummary?.total_minutes?.toLocaleString() ||
                    0}
                </Typography>

                <Typography
                  variant="body2"
                  color="lightGrey.main"
                  display="flex"
                  flexDirection="column"
                  justifyContent="end"
                >
                  <FormattedMessage id="exercise.timeUnit" />
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap="8px">
          <Typography
            align="center"
            typography={{ xs: "body2", sm: "body1" }}
            color="softBlack.main"
          >
            <FormattedMessage id="exercise.amountCalories" />
          </Typography>

          <Box display="flex" justifyContent="center" gap="8px">
            <Box
              component="img"
              src="/icons/fire_all.png"
              width="22px"
              height="22px"
              alignSelf="center"
            />
            <Typography align="center" variant="h2" color="secPink.main">
              {activityExerciseSummary?.total_calories?.toLocaleString() || 0}
            </Typography>
          </Box>

          <Typography
            align="center"
            typography={{ xs: "body2", sm: "body1" }}
            color="lightGrey.main"
          >
            <FormattedMessage id="exercise.calories" />
          </Typography>
        </Box>
      </Box>

      <Divider sx={{ color: "#89939E" }} />

      <Typography
        align="center"
        typography={{ xs: "h5", sm: "h4" }}
        color="ktjBlue.main"
      >
        <FormattedMessage id="exercise.weeklyTarget" />
      </Typography>

      <Box display="flex" flexDirection="column" gap="16px">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" gap="8px">
            <Box
              component="img"
              src="/icons/clock_all.png"
              width="22px"
              height="22px"
              alignSelf="center"
            />
            <Box display="flex" flexDirection="row" gap="8px">
              <Typography
                variant="h5"
                color="common.black"
                alignSelf="end"
                lineHeight="22px"
              >
                {activityExerciseSummary?.weekly_target_minutes?.toLocaleString() ||
                  0}
              </Typography>

              <Typography
                variant="body2"
                color="lightGrey.main"
                alignSelf="end"
              >
                <FormattedMessage id="exercise.timeUnit" />
              </Typography>
            </Box>
          </Box>

          <Box display="flex" gap="8px">
            <Typography variant="body2" color="lightGrey.main" alignSelf="end">
              <FormattedMessage id="exercise.remainMinutes" />
            </Typography>

            <Typography
              variant="h5"
              color="common.black"
              alignSelf="end"
              lineHeight="22px"
            >
              {activityExerciseSummary?.weekly_remain_minutes?.toLocaleString() ||
                0}
            </Typography>

            <Typography variant="body2" color="lightGrey.main" alignSelf="end">
              <FormattedMessage id="exercise.timeUnit" />
            </Typography>
          </Box>
        </Box>

        <Box display="flex" justifyContent="center">
          <PieChart width={150} height={150}>
            <Pie
              data={pieData}
              nameKey="name"
              dataKey="value"
              innerRadius={60}
              outerRadius={70}
              startAngle={90}
              endAngle={-270}
              fill="#8884d8"
              paddingAngle={0}
            >
              <Label
                width={30}
                position="center"
                content={<CustomLabel value={targetPersent} />}
              ></Label>
              {pieData.map((entry: any, index: any) => (
                <Cell fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography
              typography={{ xs: "body2", sm: "body1" }}
              color="softBlack.main"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <FormattedMessage id="exercise.weeklyUsedMinutes" />
            </Typography>

            <Box display="flex" gap="8px" justifyContent="center">
              <Box
                component="img"
                src="/icons/clock_all.png"
                width="22px"
                height="22px"
                alignSelf="center"
              />

              <Typography
                variant="h5"
                color="common.black"
                alignSelf="end"
                lineHeight="22px"
              >
                {activityExerciseSummary?.weekly_used_minutes?.toLocaleString() ||
                  0}
              </Typography>

              <Typography
                variant="body2"
                color="lightGrey.main"
                alignSelf="end"
              >
                <FormattedMessage id="exercise.timeUnit" />
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap="8px">
            <Typography
              typography={{ xs: "body2", sm: "body1" }}
              color="softBlack.main"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <FormattedMessage id="exercise.weeklyCalories" />
            </Typography>

            <Box display="flex" gap="8px" justifyContent="center">
              <Box
                component="img"
                src="/icons/fire_all.png"
                width="22px"
                height="22px"
                alignSelf="center"
              />

              <Typography
                variant="h5"
                color="common.black"
                alignSelf="end"
                lineHeight="22px"
              >
                {activityExerciseSummary?.weekly_calories?.toLocaleString() ||
                  0}
              </Typography>

              <Typography
                variant="body2"
                color="lightGrey.main"
                alignSelf="end"
              >
                <FormattedMessage id="exercise.calories" />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap="8px">
        <Typography
          align="center"
          typography={{ xs: "subtitle2", sm: "h5" }}
          color="ktjBlue.main"
        >
          <FormattedMessage id="exercise.average" />
        </Typography>

        <Box display="flex" justifyContent="center" gap="8px">
          <Typography typography={{ xs: "h5", sm: "h4" }} color="secPink.main">
            {activityExerciseSummary?.weekly_average_calories?.toLocaleString() ||
              0}
          </Typography>

          <Typography
            typography={{ xs: "body2", sm: "body1" }}
            color="lightGrey.main"
            alignSelf="end"
          >
            <FormattedMessage id="exercise.calories" />
          </Typography>
        </Box>
      </Box>

      <Typography
        align="center"
        typography={{ xs: "body1", sm: "subtitle2" }}
        color="common.black"
      >
        {`${dayjs(areaData[0]?.activity_date).format("D")} - ${dayjs(
          areaData[6]?.activity_date
        ).format("D")} ${dayjs().locale("th").format("MMMM BBBB")}`}
      </Typography>

      <Box display="flex" justifyContent="center">
        <ResponsiveContainer width={"99%"} height={320}>
          <AreaChart
            width={430}
            height={320}
            data={areaData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="activity_date"
              interval={0}
              tick={<CustomizedAxisTick />}
            />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="sum_minutes"
              stroke="#EB698F"
              fill="#EB698F"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>

      <Box display="flex" justifyContent="center" gap="8px">
        <Box
          component="img"
          src="/icons/average_all.png"
          width="22px"
          height="22px"
          alignSelf="center"
        />

        <Typography
          align="center"
          typography={{ xs: "body1", sm: "subtitle1" }}
          color="ktjBlue.main"
        >
          <FormattedMessage id="exercise.average" />
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center">
        <Button
          color="green"
          fullWidth
          variant="contained"
          type="button"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            width: "295px",
          }}
          component={RouterLink}
          to="/history-exercise"
        >
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 600 }}
            color="white"
          >
            <FormattedMessage id="exercise.exerciseHistory" />
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default DashboardExerciseAll;

const CustomLabel = ({ viewBox, value }: any) => {
  const { cx, cy } = viewBox;
  return (
    <text x={cx} y={cy} fill="#58DB08" textAnchor="middle">
      <tspan alignmentBaseline="middle" fontSize="24" fontWeight="600">
        {value} %
      </tspan>
    </text>
  );
};

const CustomizedAxisTick = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
        {dayjs(payload.value).locale("th").format("dd")}
      </text>
    </g>
  );
};
