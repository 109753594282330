import {
  Box,
  Typography,
  Container,
  styled,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  Grid,
  Fab,
  Button,
  Modal,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import ReactPlayer from "react-player";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link as RouterLink } from "react-router-dom";
import { tableCellClasses } from "@mui/material/TableCell";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  boxStyle,
  containerStyle,
  modalStyle,
  pageStyle,
} from "../../../consts/styles";
import { postLearningTimeService } from "../../../services/course.service";
import dayjs from "dayjs";
import WarningModal from "../../../components/modals/WarningModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.ktjBlue.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const LessonPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = async (event: any) => {
      event.preventDefault();
      event.returnValue = "";

      // Make your API call here
      try {
        await postLearningTimeService(
          courseIdRef.current,
          lessonIdRef.current,
          {
            learned_time: countRef.current - location.state.lesson.learned_time,
          }
        );
      } catch (error) {
        console.error(error);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location.state.lesson.learned_time]);

  const [lesson, setLesson] = useState<any>(null);
  const [counter, setCounter] = useState(0);
  const countRef = useRef<any>();
  const courseIdRef = useRef<any>();
  const lessonIdRef = useRef<any>();
  const [openWarnning, setWarnningOpen] = useState<boolean>(false);
  const [messageWarnning, setMessageWarnning] = useState<string>("");

  const handleWarnningOpen = () => setWarnningOpen(true);
  const handleWarnningClose = () => {
    setMessageWarnning("");
    setWarnningOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // update the ref if the counter changes
  useEffect(() => {
    countRef.current = counter;
  }, [counter]);

  useEffect(() => {
    if (!location.state) {
      navigate("/home");
      return;
    }
    setLesson(location.state.lesson);
    lessonIdRef.current = location.state.lesson.lesson_id;
    courseIdRef.current = location.state.courseId;
    setCounter(location.state.lesson.learned_time || 0);
  }, [location, navigate]);

  const onClickNextSession = () => {
    if (lesson?.is_passed) return;

    if (lesson?.passed_criteria > 0) {
      const date1 = dayjs();
      const date2 = dayjs(lesson.tested_date).add(lesson.gap_time, "hour");

      if (!lesson.is_passed && lesson.tested_date && date1.isBefore(date2)) {
        const duration = dayjs.duration(date2.diff(date1));

        const hours = Math.floor(duration.asHours());
        const minutes = Math.floor(duration.asMinutes() % 60);

        setMessageWarnning(
          `เหลืออีก ${hours}:${minutes} ช.ม. จึงจะสามารถทำแบบทดสอบได้อีกครั้ง`
        );
        return;
      }

      navigate("/course/lesson-quiz", {
        state: {
          courseId: courseIdRef.current,
          lessonId: lessonIdRef.current,
          lesson,
        },
        replace: true,
      });
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    return () => {
      postLearningTimeService(courseIdRef.current, lessonIdRef.current, {
        learned_time:
          countRef.current - (location.state.lesson.learned_time || 0),
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (messageWarnning) handleWarnningOpen();
  }, [messageWarnning]);

  return (
    <Box sx={pageStyle}>
      <Box maxWidth={{ xs: 450, sm: 600 }} width={1}>
        <Grid container spacing={2} padding="8px">
          <Grid item xs={4}>
            <Fab
              sx={{
                background: "#0892F5",
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                color: "white",
                "&:hover": { background: "#0892F5" },
              }}
              component={RouterLink}
              to="/course/lesson-overview"
              state={{ courseId: courseIdRef.current }}
            >
              <ArrowBackIcon />
            </Fab>
          </Grid>
          <Grid item xs={4} alignSelf="center">
            <Typography
              align="center"
              sx={{ typography: { xs: "h3", sm: "h1" } }}
              color="ktgGreen.main"
              zIndex={1}
            >
              {lesson?.lesson_order === 0
                ? "บทนำ"
                : `บทที่ ${lesson?.lesson_order}`}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Container
        component="main"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        maxWidth={false}
      >
        <Box sx={{ ...boxStyle, padding: "16px", gap: "16px" }}>
          <ReactPlayer width={"100%"} controls={true} url={lesson?.clip_url} />

          <Typography
            variant="body1"
            color="black.main"
            dangerouslySetInnerHTML={{
              __html: lesson?.detail,
            }}
          />

          <Box display="flex" gap="8px" alignItems="center">
            <Typography variant="body1" color="black.main">
              ระยะเวลาการเรียน {Math.floor(counter / 60)} นาที {counter % 60}{" "}
              วินาที
            </Typography>

            {counter >= lesson?.minimum_learning_time && (
              <CheckCircleIcon color="green" />
            )}
          </Box>

          <Box display="flex" flexDirection="column" gap="8px">
            <Typography align="center" typography="h4" color="black.main">
              รายการเอกสารดาวน์โหลด
            </Typography>

            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
                borderRadius: 0,
                borderTopRightRadius: "5px",
                borderTopLeftRadius: "5px",
              }}
            >
              <Table
                sx={{ minWidth: 300 }}
                aria-labelledby="tableTitle"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={{
                        typography: "body1",
                        fontWeight: 600,
                      }}
                    >
                      ลำดับ
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        typography: "body1",
                        fontWeight: 600,
                      }}
                    >
                      ชื่อไฟล์
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        typography: "body1",
                        fontWeight: 600,
                      }}
                    >
                      ดาวน์โหลด
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      1
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {lesson?.lesson_order === 0
                        ? "บทนำ"
                        : `บทที่ ${lesson?.lesson_order}`}
                      {".pdf"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {lesson && (
                        <Box
                          component={RouterLink}
                          to={lesson?.lesson_url}
                          target="_blank"
                          download
                        >
                          <DownloadIcon sx={{ color: "black.main" }} />
                        </Box>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box display="flex" gap="8px">
            <Button
              fullWidth
              color="blue"
              variant="outlined"
              component={RouterLink}
              to="/course/lesson-overview"
              state={{ courseId: courseIdRef.current }}
            >
              <Typography typography="subtitle2" color="blue.main">
                กลับ
              </Typography>
            </Button>
            {lesson?.passed_criteria > 0 && (
              <Button
                fullWidth
                color="green"
                variant="contained"
                onClick={onClickNextSession}
                disabled={counter <= lesson?.minimum_learning_time}
              >
                <Typography typography="subtitle2" color="white">
                  {lesson?.is_passed ? "เรียนสำเร็จ" : "แบบทดสอบ"}
                </Typography>
              </Button>
            )}
          </Box>
        </Box>
      </Container>

      <Modal keepMounted open={openWarnning} onClose={handleWarnningClose}>
        <Box sx={modalStyle} width="340px">
          <WarningModal
            message={messageWarnning}
            handleClose={handleWarnningClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default LessonPage;
