import { Box, Typography, Button } from "@mui/material";

const CancleConnectFiitModal = ({
  handleConfirmCancleConnectFiitOpen,
  handleCancleConnectFiitClose,
}: any) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      p="32px"
      gap="16px"
    >
      <Typography align="center" variant="h4" color="green.main">
        ข้อมูลเชื่อมต่ออยู่แล้ว
      </Typography>

      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        width={1}
      >
        <Box
          component="img"
          src="/images/logo.png"
          alt="logo"
          width={75}
          height={100}
        />

        <Box
          component="img"
          src="/images/fiit/x.png"
          alt="logo"
          width={15}
          height={15}
        />

        <Box
          component="img"
          src="/images/fiit/logo-main.png"
          alt="logo"
          width={80}
          height={80}
        />
      </Box>

      <Box>
        <Typography
          align="center"
          variant="h4"
          typography="subtitle2"
          color="black.main"
          fontWeight={600}
        >
          คุณได้ทำการเชื่อมต่อ
        </Typography>

        <Typography
          align="center"
          typography="subtitle2"
          color="black.main"
          fontWeight={600}
        >
          “ก้าวท้าใจ” กับ ”FIIT TOKEN” แล้ว
        </Typography>
      </Box>

      <Box display="flex" gap="8px" width={1}>
        <Button
          color="tealishBlue"
          sx={{ boxShadow: "none" }}
          variant="contained"
          fullWidth
          onClick={handleConfirmCancleConnectFiitOpen}
        >
          <Typography variant="subtitle2" color="black.main">
            <Box>ยกเลิก</Box>
            <Box>การเชื่อมต่อ</Box>
          </Typography>
        </Button>

        <Button
          color="primary"
          variant="outlined"
          fullWidth
          onClick={handleCancleConnectFiitClose}
        >
          <Typography variant="subtitle2" color="primary">
            ปิด
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default CancleConnectFiitModal;
