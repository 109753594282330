import { Box, Typography, Button } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const SendAssessmentSuccessModal = ({ handleClose }: any) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="32px"
      alignItems="center"
      gap="16px"
    >
      <Typography align="center" typography="h4" color="blue.main">
        ส่งคำตอบเรียบร้อย
      </Typography>

      <Typography align="center">
        <CheckCircleOutlineIcon sx={{ fontSize: 60, color: "#1DA64A" }} />
      </Typography>

      <Typography align="center" typography="subtitle2" color="black.main">
        เราจะตรวจคำตอบให้ท่าน ภายใน 15 วันค่ะ
      </Typography>

      <Button fullWidth color="blue" variant="contained" onClick={handleClose}>
        <Typography typography="subtitle2" color="white">
          ตกลง
        </Typography>
      </Button>
    </Box>
  );
};

export default SendAssessmentSuccessModal;
