import { Box, Typography, Button } from "@mui/material";
import dayjs from "dayjs";

const WaitingForAnswerModal = ({ course, handleClose }: any) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="32px"
      gap="16px"
    >
      <Box
        component="img"
        src="/course/waitforanswer.png"
        width={50}
        height={40}
      />

      <Box>
        <Typography
          align="center"
          typography="subtitle2"
          fontWeight={600}
          color="black.main"
        >
          กำลังรอตรวจคำตอบ
        </Typography>

        <Typography align="center" typography="subtitle2" color="black.main">
          ส่งคำตอบวันที่ {dayjs(course?.tested_date).format("D MMMM BBBB")}
        </Typography>

        {/* <Typography align="center" typography="subtitle2" color="black.main">
          เวลา {dayjs(course?.evaluated_date).format("HH:mm:ss")} น.
        </Typography> */}
      </Box>

      <Button fullWidth color="blue" variant="contained" onClick={handleClose}>
        <Typography typography="subtitle2" color="white">
          ตกลง
        </Typography>
      </Button>
    </Box>
  );
};

export default WaitingForAnswerModal;
