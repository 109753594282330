import axiosService from "../api/axios";

export interface AreaSummaryFilter {
  zone?: string;
  province_id?: string;
  district_id?: string | undefined;
}

export interface KpiFilter {
  zone?: string;
  province_id?: string;
  district_id?: string | undefined;
}

type AreaSummary = "zone" | "province_id" | "district_id";

export const memberSummary = (queryType: string = "all") => {
  let url =
    queryType === "all"
      ? "/dashboard/member-summary"
      : "/dashboard/season-member-summary";
  return axiosService.get(url);
};

export const memberAreaSummary = (
  params: AreaSummaryFilter,
  queryType: string = "all"
) => {
  let url =
    queryType === "all"
      ? "/dashboard/member-area-summary"
      : "/dashboard/season-member-area-summary";

  for (const key in params) {
    if (
      params[key as AreaSummary] === null ||
      params[key as AreaSummary] === "" ||
      params[key as AreaSummary] === "all"
    ) {
      delete params[key as AreaSummary];
    }
  }
  return axiosService.get(
    `${url}?` + new URLSearchParams(JSON.parse(JSON.stringify(params)))
  );
};

export const exerciseSummary = (queryType: string = "all") => {
  let url =
    queryType === "all"
      ? "/dashboard/exercise-summary"
      : "/dashboard/season-exercise-summary";
  return axiosService.get(url);
};

export const exerciseAreaSummary = (
  params: AreaSummaryFilter,
  queryType: string = "all"
) => {
  let url =
    queryType === "all"
      ? "/dashboard/exercise-area-summary"
      : "/dashboard/season-exercise-area-summary";
  for (const key in params) {
    if (
      params[key as AreaSummary] === null ||
      params[key as AreaSummary] === "" ||
      params[key as AreaSummary] === "all"
    ) {
      delete params[key as AreaSummary];
    }
  }
  return axiosService.get(`${url}?` + new URLSearchParams({ ...params }));
};

export const getTopRankService = () => {
  return axiosService.get("/dashboard/top-rank");
};

export const getTopProvinceRankService = () => {
  return axiosService.get("/dashboard/top-province-rank");
};

export const getCommunitySummaryService = (queryType: string = "all") => {
  let url =
    queryType === "all"
      ? "/dashboard/community-summary"
      : "/dashboard/season-community-summary";
  return axiosService.get(url);
};

export const getHomeSummaryService = () => {
  return axiosService.get("/dashboard/home-summary");
};

export const getKpiService = (params: KpiFilter) => {
  return axiosService.get(
    "/dashboard/kpi?" + new URLSearchParams({ ...params })
  );
};
