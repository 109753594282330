import { Box, Button, Typography, Divider } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Link as RouterLink } from "react-router-dom";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        background:
          "linear-gradient(94.1deg, rgba(4, 205, 133, 0.9) -20.26%, rgba(28, 185, 243, 0.9) 149.71%)",
      }}
      padding={{ xs: "32px 16px", md: "56px 120px 24px" }}
      display="flex"
      flexDirection="column"
      gap="32px"
    >
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        gap={{ xs: "32px", sm: 0 }}
      >
        <Box
          display="flex"
          gap="16px"
          justifyContent={{ xs: "center", md: "start" }}
        >
          <Box
            component="img"
            src="/images/logo.png"
            width={{ xs: 60, md: 105 }}
            height={{ xs: 80, md: 140 }}
          />

          <Box
            display="flex"
            flexDirection="column"
            gap="16px"
            justifyContent="center"
          >
            <Typography variant="h3" color="white">
              กรมอนามัย
            </Typography>

            <Box display="flex" gap="8px">
              <Box
                component="img"
                src="/icons/marker.png"
                width={16}
                height={20}
              />

              <Box display="flex" flexDirection="column">
                <Typography variant="body1" color="white">
                  88/22 ม.4 ถ.ติวานนท์ ต.ตลาดขวัญ
                </Typography>
                <Typography variant="body1" color="white">
                  อ.เมือง จ.นนทบุรี 11000
                </Typography>
              </Box>
            </Box>

            <Box display="flex" gap="8px">
              {/* <LocalPhoneIcon sx={{ fontSize: "20px", color: "white" }} />

              <Box display="flex" flexDirection="column">
                <Typography variant="body1" color="white">
                  081 836 8821
                </Typography>
                <Typography variant="body1" color="white">
                  081 268 6721
                </Typography>
              </Box> */}
            </Box>
          </Box>
        </Box>

        <Box
          display={{ xs: "none", md: "flex" }}
          flexDirection="column"
          justifyContent="center"
          gap="4px"
        >
          <Typography
            variant="subtitle1"
            color="white"
            component={RouterLink}
            sx={{ textDecoration: "none" }}
            to="/dashboard-overview"
          >
            รายงาน
          </Typography>
          <Typography
            variant="subtitle1"
            color="white"
            component={RouterLink}
            sx={{ textDecoration: "none" }}
            to="/news"
          >
            ข่าวสารกรมอนามัย
          </Typography>
          <Typography
            variant="subtitle1"
            color="white"
            component={RouterLink}
            sx={{ textDecoration: "none" }}
            to="/health-literacy"
          >
            รอบรู้สุขภาพ
          </Typography>
          <Typography variant="subtitle1" color="white">
            แบบสอบถาม
          </Typography>
          <Typography
            variant="subtitle1"
            color="white"
            component={RouterLink}
            sx={{ textDecoration: "none" }}
            to="/faq"
          >
            FAQ
          </Typography>
        </Box>

        <Box alignSelf="center">
          <Button
            color="cwhite"
            variant="contained"
            sx={{
              width: "279px",
            }}
            startIcon={<ErrorOutlineIcon />}
          >
            <Typography variant="subtitle2" color="black.main">
              แจ้งปัญหาการใช้งาน
            </Typography>
          </Button>
        </Box>
      </Box>

      <Divider sx={{ borderColor: "#FFFFFF", borderRightWidth: 1 }} />

      <Box display="flex" gap="8px" justifyContent="center">
        <Box
          alignSelf="center"
          component="img"
          src="/icons/copyright.png"
          width={20}
          height={20}
        ></Box>
        <Typography variant="subtitle2" color="white">
          2022 กระทรวงสาธารณะสุข
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
