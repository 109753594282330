import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Modal,
} from "@mui/material";
import {
  boxStyle,
  containerStyle,
  modalStyle,
  pageStyle,
} from "../../../consts/styles";
import { FormattedMessage } from "react-intl";
import { communityTypes } from "../../../consts/app";
import { useForm } from "react-hook-form";
import { useEffect, useState, ChangeEvent } from "react";
import {
  districtService,
  provinceService,
  subdistrictService,
} from "../../../services/master-data.service";
import {
  getCommunityService,
  postInviteMemberService,
} from "../../../services/community.service";
import SuccessModal from "../../../components/modals/SuccessModal";
import ErrorModal from "../../../components/modals/ErrorModal";

const CommunitySearch = () => {
  const [provinces, setProvinces] = useState([]);
  const [districs, setDistrics] = useState([]);
  const [subdistrict, setSubdistricts] = useState([]);

  const [openSuccess, setSuccessOpen] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [communities, setCommunities] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [size] = useState(4);

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
    onSearchHandler();
  };

  const { register, setValue, getValues } = useForm({
    defaultValues: {
      community_name: "",
      community_code: "",
      type_id: "",
      province_id: "",
      district_id: "",
      subdistrict_id: "",
    },
  });

  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => setSuccessOpen(false);
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setValue("district_id", "");
    setValue("subdistrict_id", "");

    setDistrics([]);
    setSubdistricts([]);
    getDistricts(event.target.value);
  };

  const handleDistrictChange = (event: SelectChangeEvent) => {
    setValue("subdistrict_id", "");

    setSubdistricts([]);
    getSubdistricts(event.target.value);
  };

  const handleJoinGroup = async (community_id: any) => {
    try {
      await postInviteMemberService(community_id, { invite_type: 2 });
      handleSuccessOpen();
      onSearchHandler();
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
    }
  };

  const getProvinces = () => {
    provinceService().then((resp) => {
      setProvinces(resp.data);
    });
  };

  const getDistricts = async (provinceId: string) => {
    const response = await districtService(provinceId);
    setDistrics(response.data);
  };

  const getSubdistricts = async (districtId: any) => {
    const response = await subdistrictService(districtId);
    setSubdistricts(response.data);
  };

  const onSearchHandler = async () => {
    const response = await getCommunityService({ ...getValues(), page, size });
    setCommunities(response.data.items);
    setCount(response.data.total_page);
  };

  useEffect(getProvinces, []);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  return (
    <Box sx={pageStyle}>
      <Typography
        align="center"
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="ktgGreen.main"
        zIndex={100}
      >
        <FormattedMessage id="community" />
      </Typography>

      <Container component="main" sx={containerStyle} maxWidth={false}>
        <Box sx={{ ...boxStyle, padding: "24px" }}>
          <Typography align="center" typography="h5" color="common.black">
            <FormattedMessage id="community.searchGroup" />
          </Typography>

          <FormControl fullWidth>
            <FormLabel>
              <Typography variant="body1" color="common.black">
                <FormattedMessage id="community.types" />
              </Typography>
            </FormLabel>
            <Select
              sx={{
                "& .MuiSelect-select .notranslate::after":
                  "กรุณาเลือกประเภทกลุ่ม"
                    ? {
                        content: `"กรุณาเลือกประเภทกลุ่ม"`,
                        opacity: 0.42,
                      }
                    : {},
              }}
              {...register("type_id", { required: true })}
            >
              <MenuItem value={""}>ทั้งหมด</MenuItem>
              {communityTypes.map((str: string, index: number) => (
                <MenuItem key={index} value={index + 1}>
                  {str}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            gap="16px"
          >
            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  <FormattedMessage id="community.name" />
                </Typography>
              </FormLabel>

              <TextField
                placeholder="กรอก ..."
                {...register("community_name", { required: true })}
              />
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  <FormattedMessage id="community.code" />
                </Typography>
              </FormLabel>
              <TextField
                placeholder="กรอก ..."
                {...register("community_code", { required: true })}
              />
            </FormControl>
          </Box>

          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            gap="16px"
          >
            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  <FormattedMessage id="community.province" />
                </Typography>
              </FormLabel>
              <Select
                {...register("province_id", { required: true })}
                sx={{
                  "& .MuiSelect-select .notranslate::after": "กรุณาเลือกจังหวัด"
                    ? {
                        content: `"กรุณาเลือกจังหวัด"`,
                        opacity: 0.42,
                      }
                    : {},
                }}
                onChange={handleProvinceChange}
              >
                <MenuItem value={""}>ทั้งหมด</MenuItem>
                {provinces.map((p: { id: number; name: string }) => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  <FormattedMessage id="community.district" />
                </Typography>
              </FormLabel>
              <Select
                {...register("district_id", { required: true })}
                sx={{
                  "& .MuiSelect-select .notranslate::after": "กรุณาเลือกอำเภอ"
                    ? {
                        content: `"กรุณาเลือกอำเภอ"`,
                        opacity: 0.42,
                      }
                    : {},
                }}
                onChange={handleDistrictChange}
              >
                <MenuItem value={""}>ทั้งหมด</MenuItem>
                {districs.map((p: { id: number; name: string }) => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="body1" color="common.black">
                    <FormattedMessage id="community.subdistrict" />
                  </Typography>
                </FormLabel>
                <Select
                  {...register("subdistrict_id", { required: true })}
                  sx={{
                    "& .MuiSelect-select .notranslate::after": "กรุณาเลือกตำบล"
                      ? {
                          content: `"กรุณาเลือกตำบล"`,
                          opacity: 0.42,
                        }
                      : {},
                  }}
                >
                  <MenuItem value={""}>ทั้งหมด</MenuItem>
                  {subdistrict.map((p: { id: number; name: string }) => (
                    <MenuItem key={p.id} value={p.id}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Button
            fullWidth
            color="blue"
            variant="contained"
            onClick={onSearchHandler}
          >
            <Typography variant="subtitle2" color="white">
              <FormattedMessage id="common.search" />
            </Typography>
          </Button>
        </Box>

        <Box display="flex" flexDirection="column" gap="16px" marginTop="16px">
          {communities?.map((community, index) => (
            <Box key={index} sx={boxStyle}>
              <Box display="flex" gap="16px">
                <Box
                  component="img"
                  borderRadius={100}
                  boxShadow="0px 0px 10px rgba(0, 0, 0, 0.15)"
                  width={100}
                  height={100}
                  src={community.image_url || "/images/community-default.png"}
                />

                <Box display="flex" flexDirection="column" gap="8px">
                  <Typography typography="h3" color="black.main">
                    {community.community_name}
                  </Typography>

                  <Box display="flex" gap="8px">
                    <Box
                      component="img"
                      width={16}
                      height={21}
                      src="/icons/member-in-group.png"
                    />

                    <Typography typography="body1" color="secYellow.main">
                      สมาชิก {community?.total?.toLocaleString() || 0} คน
                    </Typography>
                  </Box>

                  <Box display="flex" gap="8px">
                    <Typography typography="h4" color="secPink.main">
                      {community?.calories?.toLocaleString() || 0}
                    </Typography>

                    <Typography
                      alignSelf="center"
                      typography="body1"
                      color="lightGrey.main"
                    >
                      แคลอรี
                    </Typography>
                  </Box>

                  <Box>
                    <Button
                      color="green"
                      variant="contained"
                      sx={{ height: 40 }}
                      onClick={() => handleJoinGroup(community.community_id)}
                      disabled={
                        community.can_join === 1 || community.can_join === 2
                      }
                    >
                      <Typography variant="subtitle2" color="white">
                        {community.can_join === 0 && (
                          <FormattedMessage id="community.join" />
                        )}
                        {community.can_join === 1 && (
                          <FormattedMessage id="community.waitforconfirm" />
                        )}
                        {community.can_join === 2 && (
                          <FormattedMessage id="community.joined" />
                        )}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}

          <Pagination
            sx={{ alignSelf: "center" }}
            count={count}
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            color="blue"
          />
        </Box>
      </Container>

      <Modal keepMounted open={openSuccess}>
        <Box sx={modalStyle} width="340px">
          <SuccessModal
            message={"ส่งคำขอสำเร็จ"}
            handleSuccessClose={handleSuccessClose}
            showButton={true}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle} width="340px">
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default CommunitySearch;
