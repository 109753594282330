import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { IntlProvider } from "react-intl";
// import ReactGA from "react-ga";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { App } from "./App";
import { store } from "./features/userStore/store";
import Thailand from "./lang/th.json";
import "./index.css";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import timezone from "dayjs/plugin/timezone";
import localeData from "dayjs/plugin/localeData";
import { configEnv } from "./configs/config-env";
require("dayjs/locale/th");
dayjs.extend(buddhistEra);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.locale("th");

// ReactGA.initialize("G-8P3FCMTG5Q");
// ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <HelmetProvider>
    <Helmet>
      <meta http-equiv="X-Frame-Options" content="DENY" />
      <meta
        http-equiv="Content-Security-Policy"
        content={configEnv.content_security_policy}
      />
    </Helmet>
    <Provider store={store}>
      <IntlProvider locale={"th"} messages={Thailand}>
        <Router>
          <App />
        </Router>
      </IntlProvider>
    </Provider>
  </HelmetProvider>
);
