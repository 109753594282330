import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const analytics = getAnalytics();
    logEvent(analytics, "screen_view", {
      firebase_screen: pathname, // <- In my case I do not want to include search params, so 'location.pathname' is just what I want
      firebase_screen_class: "kaojai-routes-analytics", // <- This name is up to you
    });
  }, [pathname]);

  return null;
};

export default ScrollToTop;
