import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

interface ProfileState {
  member_id: number;
  title: string;
  first_name: string;
  username: string;
  last_name: string;
  identification_number: string;
  date_of_birth: string;
  gender: string;
  marriage_status: string;
  height: number;
  weight: number;
  rank: number;
  phone_no: string;
  email: string;
  current_address: string;
  current_moo: string;
  current_subdistrict_id: number;
  current_district_id: number;
  current_province_id: number;
  current_postcode: number;
  avatar_url: string;
  health_point: number;
  experience_point: number;
  experience_level: number;
  current_season_experience_point: number;
  fiit_registered_date: string;
}

export const profileSlice = createSlice({
  name: "profile",
  initialState: {} as ProfileState,
  reducers: {
    setAvatarUrl: (state, action) => {
      state.avatar_url = action.payload;
    },
    setHealthPoint: (state, action) => {
      state.health_point = action.payload;
    },
    setRank: (state, action) => {
      state.rank = action.payload;
    },
    setProfile: (state, { payload }) => {
      return { ...state, ...payload };
    },
    setExperienceLevel: (state, action) => {
      state.experience_level = action.payload;
    },
    setCurrentSeasonExperiencePoint: (state, action) => {
      state.current_season_experience_point = action.payload;
    },
    setFiitRegisteredDate: (state, action) => {
      state.fiit_registered_date = action.payload;
    },
  },
});

export const getAvatar = (state: any) =>
  state.profile?.avatar_url
    ? state.profile?.avatar_url + "?tm=" + dayjs().unix()
    : "";
export const getHealthPoint = (state: any) => state.profile?.health_point;
export const getProfile = (state: any) => state.profile;
export const getExperienceLevel = (state: any) =>
  state.profile?.experience_level;
export const getCurrentSeasonExperiencePoint = (state: any) =>
  state.profile?.current_season_experience_point;
export const {
  setAvatarUrl,
  setHealthPoint,
  setProfile,
  setRank,
  setExperienceLevel,
  setCurrentSeasonExperiencePoint,
  setFiitRegisteredDate,
} = profileSlice.actions;

export default profileSlice.reducer;
