import { Box, Container, Grid } from "@mui/material";

const Sponcers = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Container
        component="main"
        maxWidth={false}
        sx={{
          zIndex: 1,
          maxWidth: "1120px",
        }}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={6} sm={3}>
            <Sponcer img="/sponcer/nestle.png" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const Sponcer = ({ img }: any) => {
  return (
    <Box
      width={1}
      sx={{
        background: "#FFFFFF",
        borderRadius: "30px",
      }}
      component="img"
      src={img}
    />
  );
};

export default Sponcers;
