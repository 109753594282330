import { Box, Button, Container, Pagination, Typography } from "@mui/material";
import { boxStyle, containerStyle, pageStyle } from "../../../consts/styles";
import { useEffect, useState } from "react";
import {
  getNotificationUnreadService,
  getNotificationsService,
  postNotificationAcceptService,
  postNotificationRejectService,
} from "../../../services/notification.service";
import dayjs from "dayjs";
import { useAppDispatch } from "../../../features/userStore/hook";
import { setProfile } from "../../../features/userStore/libs/profileSlice";

const Notifications = () => {
  const dispatch = useAppDispatch();

  const [notifications, setNotifications] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [size] = useState(10);
  const [decrease, setDecrease] = useState(0);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const getNotifications = () => {
    getNotificationsService({ page, size }).then((resp) => {
      setNotifications(resp.data.items);
      setCount(resp.data.total_page);
    });
  };

  const getNotificationUnread = () => {

    if (notifications.length) {
      const unread = notifications.filter(
        (notification) =>
          (notification.message_type === 2 ||
            notification.message_type === 3) &&
          notification.status === 0
      ).length;

      if (unread)
        getNotificationUnreadService({
          notification_unread_count: unread - decrease,
        }).then((resp) => {
          dispatch(setProfile(resp.data));
        });
    }
  };

  const postAcceptNotification = (id: any) => {
    postNotificationAcceptService(id).then((resp) => {
      if (!decrease) setDecrease(1);
      getNotifications();
    });
  };

  const postRejectNotification = (id: any) => {
    postNotificationRejectService(id).then((resp) => {
      if (!decrease) setDecrease(1);
      getNotifications();
    });
  };

  useEffect(getNotifications, [page, size]);

  useEffect(getNotificationUnread, [decrease, dispatch, notifications]);

  return (
    <Box sx={pageStyle}>
      <Container component="main" sx={containerStyle} maxWidth={false}>
        <Box sx={boxStyle}>
          <Typography align="center" typography="h4">
            การแจ้งเตือน
          </Typography>

          {notifications.map((item: any, index: number) => (
            <Box key={index} sx={{ display: "flex", gap: "16px" }}>
              <Box>
                {item.message_type === 1 ||
                  item.message_type === 4 ||
                  (item.message_type === 5 && (
                    <Box
                      width={40}
                      height={40}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ backgroundColor: "#F5F7FA", borderRadius: 100 }}
                    >
                      <Box
                        component="img"
                        src={
                          item.message_type === 1
                            ? "/notification/message.png"
                            : "/notification/course.png"
                        }
                        width={24}
                        height={24}
                      />
                    </Box>
                  ))}

                {item.message_type === 2 ||
                  (item.message_type === 3 && (
                    <Box
                      component="img"
                      width={40}
                      height={40}
                      src={
                        item.icon_url
                          ? item.icon_url
                          : "/notification/default_group.png"
                      }
                      sx={{ borderRadius: 100 }}
                    />
                  ))}
              </Box>
              <Box display="flex" flexDirection="column" gap="4px">
                <Typography typography="body1" color="black.main">
                  {item.title}
                </Typography>

                <Typography
                  typography="body1"
                  color="black.main"
                  fontWeight={600}
                >
                  {item.subtitle}
                </Typography>

                {item.message_type === 2 ||
                  (item.message_type === 3 && (
                    <Box display="flex" gap="8px">
                      <Box
                        width={24}
                        height={24}
                        component="img"
                        src="/notification/ic_round-directions-run.png"
                      />

                      <Typography
                        alignSelf="center"
                        typography="body2"
                        color="primary"
                      >
                        สมาชิก {item.total} คน
                      </Typography>

                      <Box display="flex" gap="8px">
                        <Typography
                          alignSelf="center"
                          typography="body1"
                          color="secPink.main"
                        >
                          {item.calories.toLocaleString()}
                        </Typography>

                        <Typography
                          alignSelf="center"
                          typography="body2"
                          color="primary"
                        >
                          แคลอรี
                        </Typography>
                      </Box>
                    </Box>
                  ))}

                <Box display="flex" gap="8px">
                  <Typography
                    alignSelf="center"
                    fontSize={12}
                    color="black.main"
                  >
                    {dayjs(item.message_date).format("DD/MM/BB")}
                  </Typography>

                  {(item.message_type === 2 || item.message_type === 3) &&
                    item.status === 0 && (
                      <Box display="flex" gap="8px">
                        <Button
                          fullWidth
                          color="green"
                          variant="contained"
                          sx={{ height: 32, boxShadow: "none" }}
                          onClick={() =>
                            postAcceptNotification(item.notification_id)
                          }
                        >
                          <Typography variant="body1" color="white">
                            ยืนยัน
                          </Typography>
                        </Button>

                        <Button
                          fullWidth
                          color="silver"
                          variant="contained"
                          sx={{ height: 32, boxShadow: "none" }}
                          onClick={() =>
                            postRejectNotification(item.notification_id)
                          }
                        >
                          <Typography variant="body1" color="black.main">
                            ปฏิเสธ
                          </Typography>
                        </Button>
                      </Box>
                    )}
                </Box>
              </Box>
            </Box>
          ))}

          <Box display="flex" justifyContent="center">
            <Pagination
              count={count}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
              color="blue"
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Notifications;
