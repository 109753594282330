import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { FormattedMessage } from "react-intl";
import debounce from "lodash.debounce";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  districtService,
  provinceService,
} from "../../services/master-data.service";
import { verifyForget } from "../../services/auth.service";

const ForgotPassword = () => {
  const navigate = useNavigate();

  // state
  const [openError, setErrorOpen] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [districs, setDistrics] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  // end state

  // handle
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);
  // end handle

  // form
  const {
    register,
    formState: { isValid, isDirty, errors },
    setValue,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm({
    defaultValues: {
      username: "",
      identification_number: "",
      province_id: null,
      district_id: null,
    },
  });
  // end form

  // func
  const getProvinces = async () => {
    const response = await provinceService();
    setProvinces(response.data);
  };

  const getDistricts = async (provinceId: string) => {
    const response = await districtService(provinceId);
    setDistrics(response.data);
  };

  const validateCitizenId = async (event: any) => {
    const value = event.target.value;
    if (value.substring(0, 1) === 0 || value.length !== 13) {
      setError("identification_number", {
        type: "custom",
        message: "เลขบัตรผิด",
      });
      return;
    } else {
      let sum = 0;
      for (var i = 0; i < value.length - 1; i++) {
        sum += parseFloat(value.charAt(i)) * (13 - i);
      }

      if ((11 - (sum % 11)) % 10 !== parseFloat(value.charAt(12))) {
        setError("identification_number", {
          type: "custom",
          message: "เลขบัตรผิด",
        });
        return;
      }
    }
    clearErrors("identification_number");
  };

  // handler
  const handleProvinceChange = (event: SelectChangeEvent) => {
    setValue("district_id", null);

    setDistrics([]);
    getDistricts(event.target.value);
  };

  const debouncedIdNumberHandler = useMemo(
    () => debounce(validateCitizenId, 1000),
    []
  );

  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorOpen(false);
    setErrorMessage("");
  };
  // end handler

  // func
  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();
      const response = await verifyForget(values);

      if (!response.data.is_correct) throw new Error("incorrect");

      setTimeout(() => {
        navigate("/reset-password", {
          state: { ...values, username: response.data.username },
        });
      }, 1000);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };
  // end func

  // core func
  useEffect(() => {
    getProvinces();
  }, []);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);
  // end core func

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingBottom: "100px",
        paddingTop: "36px",
      }}
    >
      <Box display="flex" justifyContent="center" mb="16px">
        <Box
          component="img"
          src="/images/logo.png"
          width="75px"
          height="100px"
        />
      </Box>

      <Container
        component="main"
        sx={{ maxWidth: "840px", zIndex: 1 }}
        maxWidth={false}
      >
        <Box
          boxShadow="0px 0px 6px rgba(0, 0, 0, 0.15)"
          display="flex"
          flexDirection="column"
          gap="24px"
          borderRadius="10px"
          sx={{
            background: "#FFFFFF",
          }}
          padding={{ xs: "40px 32px", md: "40px 149px" }}
        >
          <Typography align="center" variant="h3" color="ktgGreen.main">
            <FormattedMessage id="forgot.authenticate" />
          </Typography>

          <Typography align="center" variant="body1" color="common.black">
            <FormattedMessage id="forgot.hint" />
          </Typography>

          {/* <FormControl fullWidth>
            <FormLabel>
              <Typography variant="body1" color="common.black">
                <FormattedMessage id="forgot.username" />
              </Typography>
            </FormLabel>
            <TextField
              color="lightGrey"
              fullWidth
              placeholder="ชื่อผู้ใช้ สมาชิกซีซั่น 1-4 ให้กรอกเบอร์โทร)"
              {...register("username", { required: true })}
            />
          </FormControl> */}

          <FormControl fullWidth>
            <FormLabel>
              <Typography variant="body1" color="common.black">
                <FormattedMessage id="forgot.idNumber" />
              </Typography>
            </FormLabel>
            <TextField
              color="lightGrey"
              fullWidth
              placeholder="เลขบัตรประชาชน 13 หลัก"
              error={!!errors["identification_number"]}
              helperText={
                errors["identification_number"]
                  ? errors["identification_number"].message
                  : ""
              }
              {...register("identification_number", {
                required: true,
                onChange: debouncedIdNumberHandler,
              })}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel>
              <Typography variant="body1" color="common.black">
                <FormattedMessage id="forgot.province" />
              </Typography>
            </FormLabel>
            <Select
              {...register("province_id", { required: true })}
              sx={{
                "& .MuiSelect-select .notranslate::after": "กรุณาเลือกจังหวัด"
                  ? {
                      content: `"กรุณาเลือกจังหวัด"`,
                      opacity: 0.42,
                    }
                  : {},
              }}
              onChange={handleProvinceChange}
            >
              {provinces.map((p: { id: number; name: string }) => (
                <MenuItem key={p.id} value={p.id}>
                  {p.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <FormLabel>
              <Typography variant="body1" color="common.black">
                <FormattedMessage id="forgot.district" />
              </Typography>
            </FormLabel>
            <Select
              sx={{
                "& .MuiSelect-select .notranslate::after": "กรุณาเลือกอำเภอ"
                  ? {
                      content: `"กรุณาเลือกอำเภอ"`,
                      opacity: 0.42,
                    }
                  : {},
              }}
              error={!!errors["district_id"]}
              {...register("district_id", { required: true })}
            >
              {districs.map((d: { id: number; name: string }) => (
                <MenuItem key={d.id} value={d.id}>
                  {d.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box display="flex" justifyContent="center" gap="8px">
            <Button
              color="blue"
              variant="outlined"
              type="button"
              component={RouterLink}
              to="/login"
              sx={{ maxWidth: "230px", width: 1 }}
            >
              <Typography variant="subtitle2">
                <FormattedMessage id="common.back" />
              </Typography>
            </Button>

            <Button
              color="green"
              variant="contained"
              type="button"
              disabled={!isDirty || !isValid || loading}
              onClick={handleSubmit(onSubmitHandler)}
              sx={{ maxWidth: "230px", width: 1 }}
            >
              <Typography variant="subtitle2" color="white">
                <FormattedMessage id="common.confirm" />
              </Typography>
            </Button>
          </Box>
        </Box>
      </Container>

      {/* modal */}
      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 343,
            bgcolor: "background.paper",
            borderRadius: "10px",
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Typography align="center">
            <ErrorOutlineOutlinedIcon sx={{ fontSize: 60, color: "#FFCC4C" }} />
          </Typography>

          <Typography align="center" variant="subtitle2" color="common.black">
            <FormattedMessage id="verifyForgot.fail" />
          </Typography>

          <Button
            color="blue"
            variant="contained"
            fullWidth
            onClick={handleErrorClose}
          >
            <Typography variant="h5" color="white">
              <FormattedMessage id="common.tryAgain" />
            </Typography>
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ForgotPassword;
