import { Box, Button, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

const ConnectFiitModal = ({ handleFiitPdpaOpen }: any) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      p="32px"
      gap="16px"
    >
      <Box
        component="img"
        src="/images/fiit/logo.png"
        width={110}
        height={50}
      />

      <Typography align="center" variant="body1" color="black.main">
        <FormattedMessage id="fiit.tokenDesc" />
      </Typography>

      <Box component="img" src="/images/fiit/fiit-token.png" width={1} />

      <Button
        fullWidth
        color="green"
        variant="contained"
        startIcon={
          <Box component="img" src="/icons/fiit.png" width={16} height={14} />
        }
        onClick={handleFiitPdpaOpen}
      >
        <Typography variant="subtitle2" color="white">
          <FormattedMessage id="fiit.cennect" />
        </Typography>
      </Button>
    </Box>
  );
};

export default ConnectFiitModal;
