import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { configEnv } from "../../configs/config-env";
import { postThaidVerify } from "../../services/auth.service";
import { getItem } from "../../services/localStorage.service";

const ThaiDCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const state = getItem("state");
    if (state === searchParams.get("state")) {
      postThaidVerify({
        authorization_code: searchParams.get("code"),
        state: searchParams.get("state"),
        callback_uri: configEnv.thaid_callback_url as string,
      }).then((resp) => {
        navigate("/thaid-verify-sccess", {
          state: { ...resp.data, state: searchParams.get("state") },
        });
      });
    } else {
      window.close();
    }
  }, [searchParams]);

  return <div></div>;
};

export default ThaiDCallback;
