const TermsAndConditions = () => {
  return (
    <div>
      <h3>Terms of Use</h3>
      <ol>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris quis
      condimentum libero, ut pulvinar mauris. Nulla dapibus purus mauris, sed
      finibus lorem scelerisque sed. Praesent et ultricies tellus.</li>
        <li>Praesent et ultricies tellus. Maecenas
      tristique enim laoreet tincidunt finibus. Nulla sit amet quam ex. In hac
      habitasse platea dictumst. Etiam rutrum mi ac lacus porta posuere. Etiam
      et eros nec dolor condimentum interdum eu feugiat justo. Pellentesque id
      ullamcorper nibh, a tempus felis.</li>
      <li>Aliquam placerat leo eget dolor
      vestibulum, ac volutpat orci volutpat. Pellentesque consectetur tellus
      consequat justo pulvinar vehicula. Donec imperdiet dapibus velit, non
      placerat dui rhoncus in. Morbi facilisis, massa ut accumsan ultricies,
      dolor enim convallis quam, a sagittis sapien ipsum pellentesque orci.
      Nullam commodo neque eget sagittis auctor.</li>
      <li>Suspendisse potenti. Sed varius
      auctor ipsum non venenatis. Nullam sed commodo nulla. Class aptent taciti
      sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
      Praesent aliquet elit vel lorem congue semper efficitur id nunc. Fusce
      bibendum dolor arcu, sed hendrerit velit placerat et. Donec eget mi
      ultrices, facilisis felis a, pellentesque massa. Donec finibus sodales
      purus ultricies condimentum. Fusce risus mi, faucibus at sem dapibus,
      auctor tristique mi. Mauris eu cursus nisi. Donec mattis, diam eget
      pellentesque ultricies, magna arcu euismod risus, sed blandit ipsum elit a
      erat. Etiam at quam eget leo dictum commodo quis non eros. Mauris libero
      arcu, fermentum sagittis malesuada et, dictum et massa. In elementum, nibh
      sit amet consectetur varius, arcu lacus tincidunt augue, eu semper mi arcu
      ut nisl. Ut rhoncus scelerisque tincidunt. Nulla sed facilisis diam, non
      malesuada nunc. Integer dictum maximus lacus sit amet placerat.</li>
      <li>Aliquam vestibulum nisl at tellus sodales, nec
      dignissim diam auctor. Vivamus tempor semper eros, id semper dolor
      fermentum sed. Nulla non sem sit amet ante consequat aliquet. Nunc iaculis
      egestas urna, vitae sagittis sapien tempor eu. Nullam sit amet tristique
      velit, nec varius tellus. Proin eget felis vel erat accumsan maximus ut at
      sem. Suspendisse fermentum nunc nec orci laoreet facilisis. Nulla
      pellentesque condimentum metus in efficitur. Morbi vulputate bibendum
      nulla, quis vulputate enim vestibulum in. Class aptent taciti sociosqu ad
      litora torquent per conubia nostra, per inceptos himenaeos. Nulla a augue
      congue, sagittis risus at, pellentesque neque. Duis eu tellus ex.
      Suspendisse blandit vehicula bibendum. Curabitur lacus augue, facilisis
      sed mi sit amet, sodales venenatis eros. Nunc porta ipsum a vulputate
      placerat. Proin porttitor quam quis lorem pellentesque, nec aliquam magna
      convallis. Aliquam vestibulum, elit quis venenatis suscipit, magna odio
      bibendum arcu, non maximus justo nulla posuere ligula. Aliquam blandit leo
      elit, vel rhoncus metus tristique eleifend.</li>
      <li>Nunc nibh ipsum, aliquam eu
      felis at, molestie maximus justo. In et diam ipsum. Proin id venenatis
      nibh. Donec faucibus venenatis elit eu imperdiet. Cras commodo varius
      porta. Vivamus feugiat ullamcorper convallis. Maecenas vitae libero quam.
      Phasellus tellus tortor, rutrum eu scelerisque sed, sagittis ac augue.
      Aenean et efficitur ligula. Quisque rhoncus imperdiet augue vitae
      consectetur. Nunc tellus neque, finibus sit amet semper non, molestie at
      erat. Quisque convallis massa sed eros iaculis, a hendrerit lorem finibus.
      Donec consequat purus odio, non egestas sapien congue non. Suspendisse
      potenti. Ut et consectetur metus. Phasellus tincidunt imperdiet magna.
      Phasellus ut vulputate ex, vel pretium urna. Nulla rutrum erat vestibulum
      lectus posuere, ut tempus nisl luctus. Quisque bibendum ac lorem sit amet
      pellentesque. Cras nec lorem et felis lobortis dictum sit amet non eros.
      Pellentesque habitant morbi tristique senectus et netus et malesuada fames
      ac turpis egestas. Aliquam erat volutpat. Fusce et diam diam. Nulla
      facilisi. Mauris pulvinar maximus arcu id elementum. Nulla non laoreet
      ante. In posuere nibh at lobortis hendrerit. Morbi non tempor augue, vitae
      elementum elit. Nulla vel nunc ante. Mauris ultrices vel dolor at
      vehicula. Cras non quam id purus lacinia luctus.</li>
      <li>Aenean bibendum ornare nunc, quis fringilla metus. Quisque diam massa,
      efficitur nec ipsum ut, tempus consectetur ante. Etiam tempor eu justo id
      dapibus. In augue mi, tempor sollicitudin bibendum sed, finibus vel
      libero. Aenean interdum orci ac porta congue. Nam in quam nec sapien
      rhoncus iaculis. Vivamus eget dui dolor. Morbi dignissim eget tortor in
      gravida. Vestibulum non lobortis elit. Aenean tristique, nisi sed
      ultricies ultricies, tellus purus eleifend elit, in pulvinar erat sem non
      tortor. Mauris hendrerit aliquet quam eget scelerisque. Donec in accumsan
      erat, vitae sagittis massa. In venenatis nisl dolor, nec placerat ipsum
      dictum eget. Aenean sit amet ligula pharetra, pulvinar orci sit amet,
      sollicitudin neque. Aliquam ut tincidunt lacus, sit amet hendrerit nunc.</li>
      <li>Orci varius natoque penatibus et magnis dis parturient montes, nascetur
      ridiculus mus. Phasellus id nisl magna. Sed congue libero ac nulla
      tristique dignissim. Pellentesque vestibulum arcu eget velit laoreet, quis
      cursus libero tempor. Proin feugiat sodales dui ut mollis. Etiam sed justo
      vel nisi semper blandit a eget metus. Nam ullamcorper nibh ac sem molestie
      facilisis. Mauris vitae consequat est. Morbi ac lorem id odio imperdiet
      interdum. Vivamus a nisi interdum, hendrerit enim a, ullamcorper sem.</li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
