import {
  Box,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  TextField,
  Typography,
  Button,
  Modal,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import { patchCommunityService } from "../../../services/community.service";
import { modalStyle } from "../../../consts/styles";
import SuccessModal from "../SuccessModal";
import ErrorModal from "../ErrorModal";
import dayjs from "dayjs";

const CreateNewsModal = ({
  communityId,
  handleCreateNewsClose,
  handleCreatedNewsClose,
}: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openSuccess, setSuccessOpen] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);

  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => {
    setSuccessOpen(false);
    handleCreatedNewsClose();
  };
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  const { register, formState, handleSubmit } = useForm({
    defaultValues: {
      announcement_date: dayjs().format("YYYY-MM-DD"),
      announcement_info: "",
    },
  });

  const { isValid, isDirty } = formState;

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();
      await patchCommunityService(communityId, values);
      handleSuccessOpen();
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  return (
    <>
      <DialogContent sx={{ width: { xs: 300, sm: 600 } }}>
        <Box display="flex" flexDirection="column">
          <Box component="a" onClick={handleCreateNewsClose} alignSelf="end">
            <CancelOutlinedIcon
              sx={{ cursor: "pointer", color: "lightGrey.main" }}
            />
          </Box>

          <Typography align="center" typography="h3" color="green.main">
            สร้างข่าวสาร
          </Typography>

          <Box display="flex" flexDirection="column" gap="16px">
            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  เนื้อหาข่าว
                </Typography>
              </FormLabel>

              <TextField
                placeholder="กรอกเนื้อหาข่าว..."
                multiline
                rows={4}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px !important",
                  },
                }}
                {...register("announcement_info", { required: true })}
              />
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "0px 20px 24px",
        }}
      >
        <Box display="flex" gap="8px" width={1}>
          <Button
            fullWidth
            color="blue"
            variant="outlined"
            onClick={handleCreateNewsClose}
          >
            <Typography variant="subtitle2">ยกเลิก</Typography>
          </Button>

          <Button
            fullWidth
            color="green"
            variant="contained"
            onClick={handleSubmit(onSubmitHandler)}
            disabled={!isValid || !isDirty || loading}
          >
            <Typography variant="subtitle2" color="white">
              <FormattedMessage id="common.confirm" />
            </Typography>
          </Button>
        </Box>
      </DialogActions>

      <Modal keepMounted open={openSuccess}>
        <Box sx={modalStyle} width="340px">
          <SuccessModal
            message={"สร้างข่าวสารสำเร็จ"}
            handleSuccessClose={handleSuccessClose}
            showButton={true}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle} width="340px">
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>
    </>
  );
};

export default CreateNewsModal;
