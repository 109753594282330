import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Container,
  Button,
} from "@mui/material";
import { TableComponents, TableVirtuoso } from "react-virtuoso";
import React from "react";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { memberSummary } from "../../services/dashboard.service";

interface Data {
  code: string;
  total: number;
}

interface ColumnData {
  dataKey: keyof Data;
  label: string;
  numeric?: boolean;
  width: number;
}

const columns: ColumnData[] = [
  {
    width: 100,
    label: "ช่วงวันที่",
    dataKey: "code",
  },
  {
    width: 100,
    label: "จำนวนลงทะเบียน",
    dataKey: "total",
  },
];

const MemberBySeason = () => {
  const rowContent = (_index: number, row: Data) => {
    return (
      <React.Fragment>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            align="center"
            sx={{
              borderBottom: "none",
              background: _index % 2 !== 0 ? "#F5F7FA" : "",
            }}
          >
            {column.dataKey === "code" && (
              <Typography variant="subtitle2" color="black.main">
                {dayjs(row[column.dataKey]).locale("th").format("DD MMM")} -{" "}
                {(weeklyView
                  ? summary?.weekly_summaries.length - 1
                  : summary?.monthly_summaries.length - 1) !== _index
                  ? dayjs(row[column.dataKey])
                      .add(
                        weeklyView
                          ? 6
                          : dayjs(row[column.dataKey]).daysInMonth() - 1,
                        "day"
                      )
                      .locale("th")
                      .format("DD MMM BBBB")
                  : "ปัจจุบัน"}
              </Typography>
            )}
            {column.dataKey === "total" && (
              <Typography
                variant="subtitle2"
                fontWeight={600}
                color="secGreen.main"
              >
                {row[column.dataKey]?.toLocaleString()}
              </Typography>
            )}
          </TableCell>
        ))}
      </React.Fragment>
    );
  };

  const [summary, setSummary] = useState<any>();
  const [weeklyView, setWeeklyView] = useState<boolean>(true);

  const handlWeeklyView = () => setWeeklyView(true);
  const handleMonthlyView = () => setWeeklyView(false);

  // services
  const getMemberSummary = async () => {
    const response = await memberSummary();
    setSummary(response.data);
  };

  useEffect(() => {
    getMemberSummary();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "32px",
        py: "50px",
      }}
    >
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap="8px">
        <Typography
          align="center"
          sx={{ typography: { xs: "h3", sm: "h1" } }}
          color="ktgGreen.main"
          zIndex={100}
        >
          จำนวนผู้สมัคร ก้าวท้าใจ
        </Typography>

        <Typography
          align="center"
          sx={{ typography: { xs: "h3", sm: "h1" } }}
          color="ktgGreen.main"
          zIndex={100}
        >
          Season 5
        </Typography>
      </Box>

      <Box display="flex" gap="16px">
        <Button
          fullWidth
          color={weeklyView ? "blue" : "cwhite"}
          variant="contained"
          type="button"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            minWidth: { xs: "160px", sm: "270px" },
          }}
          onClick={handlWeeklyView}
        >
          <Typography
            variant="subtitle2"
            color={weeklyView ? "white" : "grey"}
            sx={{ fontWeight: 600 }}
          >
            ข้อมูลรายสัปดาห์
          </Typography>
        </Button>

        <Button
          fullWidth
          color={!weeklyView ? "blue" : "cwhite"}
          variant="contained"
          type="button"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            minWidth: { xs: "160px", sm: "270px" },
          }}
          onClick={handleMonthlyView}
        >
          <Typography
            variant="subtitle2"
            color={!weeklyView ? "white" : "grey"}
            sx={{ fontWeight: 600 }}
          >
            ข้อมูลรายเดือน
          </Typography>
        </Button>
      </Box>

      <Container
        component="main"
        sx={{ zIndex: 1000, maxWidth: 930 }}
        maxWidth={false}
      >
        <Box
          sx={{
            height: 400,
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            display: "flex",
            flexDirection: "column",
            padding: "16px",
            borderRadius: "10px",
            background: "#FFFFFF",
            gap: "32px",
          }}
        >
          <TableVirtuoso
            data={
              weeklyView
                ? summary?.weekly_summaries
                : summary?.monthly_summaries
            }
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
          />
        </Box>
      </Container>
    </Box>
  );
};

const fixedHeaderContent = () => {
  return (
    <TableRow>
      {columns.map((column: any) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align="center"
          style={{ width: column.width }}
          sx={{
            padding: "10px 0",
            backgroundColor: "#EB698F",
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 600 }} color="white">
            {column.label}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  );
};

const VirtuosoTableComponents: TableComponents<Data> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer
      sx={{ boxShadow: "none", borderRadius: 0 }}
      {...props}
      ref={ref}
    />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

export default MemberBySeason;
