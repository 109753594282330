import axiosService from "../api/axios";

export interface ActivityExercise {
  activity_type_id: number;
  distances?: number;
  used_hours: number;
  used_minutes: number;
  intense_level?: number;
  temp_image_filename: string;
}

export interface ActivityExerciseHistory {
  year: string;
  month: string;
  activity_type_id?: string | undefined;
}

export const createActivityExerciseService = (body: ActivityExercise) => {
  return axiosService.post("/activity/exercise", body);
};

export const activityExerciseSummaryService = () => {
  return axiosService.get("/activity/exercise-summary");
};

export const activityExerciseHistoryService = (
  params: ActivityExerciseHistory
) => {
  return axiosService.get(
    "/activity/exercise-history?" + new URLSearchParams({ ...params })
  );
};
