import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  Modal,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from "@mui/icons-material/Close";
// Import Swiper styles
import {
  reserveRewardService,
  rewardPublishService,
} from "../../../services/reward.service";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../features/userStore/hook";
import {
  getHealthPoint,
  setHealthPoint,
  getProfile,
} from "../../../features/userStore/libs/profileSlice";
import "../../../styles/swipper-cs.css";
import "swiper/css";
import { configEnv } from "../../../configs/config-env";
import SuccessModal from "../../../components/modals/SuccessModal";
import { modalStyle } from "../../../consts/styles";
import VerifiedRedeemModal from "../../modals/profile/VerifiedRedeemModal";

const Rewards = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const healthPoint = useAppSelector(getHealthPoint);
  const profile = useAppSelector(getProfile);

  const [openSuccess, setSuccesOpen] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [openVerified, setVerifiedOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [page] = useState(1);
  const [size] = useState(100);
  const [rewards, setRewards] = useState<any>([]);
  const [reward, setReward] = useState<any>();
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const handleSuccessOpen = () => setSuccesOpen(true);
  const handleSuccessClose = () => {
    setSuccessMessage("");
    setSuccesOpen(false);
  };
  const handleVerifiedOpen = () => setVerifiedOpen(true);
  const handleVerifiedClose = (updated = false) => {
    if (updated) {
      setSuccessMessage("ยืนยันตัวตนสำเร็จแล้ว");
      setTimeout(() => {
        handleSuccessClose();
      }, configEnv.modal_close);
    }
    setVerifiedOpen(false);
  };

  const handleAlertOpen = (reward: any) => {
    if (!profile.self_verification) {
      handleVerifiedOpen();
    } else {
      setReward(reward);
      setOpenAlert(true);
    }
  };
  const handleAlertClose = () => {
    setReward(null);
    setOpenAlert(false);
  };

  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };

  const getRewardPublish = async () => {
    const response = await rewardPublishService({ page, size });
    setRewards(response.data.items);
  };

  const postReserveReward = async () => {
    try {
      setLoading(true);
      const response = await reserveRewardService({
        reward_id: reward.reward_id,
      });
      dispatch(setHealthPoint(response.data.remain_health_point));
      handleAlertClose();
      setSuccessMessage("ยืนยันแลกสิทธิ์ของรางวัล สำเร็จ");
      setTimeout(() => {
        handleSuccessClose();
      }, configEnv.modal_close);
      getRewardPublish();
      setLoading(false);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      handleAlertClose();
      setErrorMessage(resMessage);
      setLoading(false);
    }
  };

  const navigateToRewardDescription = (reward: any) => {
    navigate("/reward/description", {
      state: {
        reward,
      },
      replace: true,
    });
  };

  useEffect(() => {
    getRewardPublish();
  }, []);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) handleSuccessOpen();
  }, [successMessage]);

  return (
    <Box display="flex" flexDirection="column">
      <Container
        component="main"
        maxWidth={false}
        sx={{
          zIndex: 1,
          maxWidth: "1120px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box display="flex" gap={{ xs: "8px", sm: "16px" }}>
          <Box
            alignSelf="center"
            component="img"
            height={{ xs: 24, sm: 48 }}
            width={{ xs: 24, sm: 48 }}
            src="/icons/akar_icons_gift.png"
          />

          <Typography
            typography="rewardTitle"
            fontSize={{ xs: "24px", sm: "48px" }}
          >
            รางวัลสำหรับคุณ
          </Typography>
        </Box>

        <Box>
          <Swiper
            className="swiper-container"
            spaceBetween={24}
            slidesPerView={md ? 1.5 : 4}
            modules={[Autoplay, Pagination, Navigation]}
            style={{ display: "grid" }}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
          >
            <Box>
              {rewards.map((reward: any, index: number) => (
                <SwiperSlide key={index}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    sx={{
                      background: "#FFFFFF",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    padding={{ xs: "12px 8px", sm: "20px 16px" }}
                    gap={{ xs: "4px", sm: "8px" }}
                    height={1}
                  >
                    <Box
                      component="img"
                      src={reward.image_url}
                      width={1}
                      onClick={() => navigateToRewardDescription(reward)}
                    />

                    <Box
                      display="flex"
                      sx={{
                        background: "#F5F7FA",
                        borderRadius: "10px",
                      }}
                      padding={{ xs: "4px", sm: "8px" }}
                      justifyContent="space-around"
                      flexDirection="column"
                      gap="4px"
                    >
                      <Box
                        display="flex"
                        gap={{ xs: "2px", sm: "8px" }}
                        alignSelf="center"
                      >
                        <Box
                          component="img"
                          sx={{
                            width: { xs: 16, sm: 24 },
                            height: { xs: 16, sm: 24 },
                          }}
                          alt="star icon"
                          src="/icons/hp_coin.png"
                          alignSelf="center"
                        />

                        <Typography
                          alignSelf="center"
                          sx={{
                            fontSize: { xs: "14px", sm: "20px" },
                            fontWeight: "600",
                          }}
                          color="red"
                        >
                          {reward.used_point.toLocaleString()}
                        </Typography>
                      </Box>

                      <Box
                        display="flex"
                        gap={{ xs: "4px", sm: "8px" }}
                        justifyContent="center"
                      >
                        <Box display="flex" gap="4px">
                          <Typography
                            alignSelf="center"
                            sx={{ fontSize: { xs: "12px", sm: "20px" } }}
                            color="red"
                          >
                            {reward.remain_qty.toLocaleString()} /
                          </Typography>

                          <Typography
                            alignSelf="center"
                            sx={{ fontSize: { xs: "10px", sm: "18px" } }}
                            color="red"
                          >
                            {reward.quantity.toLocaleString()}
                          </Typography>

                          <Typography
                            alignSelf="center"
                            sx={{ fontSize: { xs: "10px", sm: "18px" } }}
                            color="primary"
                          >
                            สิทธิ์
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Typography
                      typography={{ sx: "body1", sm: "h4" }}
                      fontWeight={600}
                      color="common.black"
                    >
                      {reward.title}
                    </Typography>

                    <Button
                      color="green"
                      variant="contained"
                      disabled={
                        healthPoint < reward.used_point ||
                        reward.remain_qty <= 0 ||
                        reward.is_full_reserved
                      }
                      onClick={() => handleAlertOpen(reward)}
                    >
                      <Typography
                        typography={{ sx: "body2", sm: "subtitle2" }}
                        color="white"
                      >
                        แลกรางวัล
                      </Typography>
                    </Button>
                  </Box>
                </SwiperSlide>
              ))}
            </Box>
          </Swiper>
        </Box>

        <Box alignSelf="center">
          <Button
            color="blue"
            variant="contained"
            component={RouterLink}
            to="/reward"
          >
            <Typography variant="subtitle2">ดูเพิ่มเติม</Typography>
          </Button>
        </Box>
      </Container>

      <Modal keepMounted open={openAlert} onClose={handleAlertClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340,
            bgcolor: "background.paper",
            borderRadius: "10px",
          }}
          display="flex"
          flexDirection="column"
          gap="8px"
        >
          <Box sx={{ textAlign: "end", p: 1 }}>
            <Typography
              onClick={handleAlertClose}
              component="span"
              sx={{ cursor: "pointer" }}
              color="cgrey.main"
            >
              <CloseIcon sx={{ fontSize: 20 }} />
            </Typography>
          </Box>

          <Box
            sx={{ px: 4, pb: 4 }}
            display="flex"
            flexDirection="column"
            gap="8px"
          >
            <Typography align="center" variant="h4" color="green.main">
              {`ยืนยันแลกสิทธิ์ของรางวัล ${reward?.title} โดยใช้แต้ม ${reward?.used_point} ?`}
            </Typography>

            <Button
              color="green"
              variant="contained"
              type="submit"
              fullWidth
              onClick={postReserveReward}
              disabled={loading}
            >
              <Typography variant="subtitle2" color="white">
                ยืนยัน
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal keepMounted open={openSuccess} onClose={handleSuccessClose}>
        <Box sx={modalStyle} width={340}>
          <SuccessModal
            message={successMessage}
            handleSuccessClose={handleSuccessClose}
            showButton={false}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340,
            bgcolor: "background.paper",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ textAlign: "end", p: 1 }}>
            <Typography
              onClick={handleErrorClose}
              component="span"
              sx={{ cursor: "pointer" }}
              color="cgrey.main"
            >
              <CloseIcon sx={{ fontSize: 20 }} />
            </Typography>
          </Box>

          <Box sx={{ px: 4, pb: 4 }}>
            <Typography align="center">
              <HighlightOffIcon sx={{ fontSize: 60, color: "#EB698F" }} />
            </Typography>
            <Typography align="center" variant="h4" color="#EB698F" mt="15px">
              {errorMessage}
            </Typography>
          </Box>
        </Box>
      </Modal>

      <Dialog
        keepMounted
        open={openVerified}
        onClose={() => handleVerifiedClose(false)}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        scroll="body"
      >
        <Box
          display="flex"
          width={1}
          onClick={() => handleVerifiedClose(false)}
          justifyContent="end"
          padding="8px 8px 0 8px"
        >
          <CancelOutlinedIcon
            sx={{ cursor: "pointer", color: "lightGrey.main" }}
          />
        </Box>
        <DialogContent sx={{ width: 300 }}>
          {openVerified && (
            <VerifiedRedeemModal handleClose={handleVerifiedClose} />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Rewards;
