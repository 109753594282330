import { Box, Typography, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const AssessmentFailModal = ({ course, handleClose }: any) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="32px"
      alignItems="center"
      gap="16px"
    >
      <Typography align="center" typography="h4" color="blue.main">
        คุณยังไม่ผ่านการประเมิน
      </Typography>

      <Box
        component="img"
        src="/course/not-pass.png"
        width={150}
        height={150}
      />

      <Typography align="center" typography="h5" color="black.main">
        คุณยังไม่ผ่านการประเมิน
      </Typography>

      <Typography align="center" typography="body1" color="cgrey.main">
        {!(course?.evaluated_count % 2)
          ? "* กรุณาเรียนทบทวนใหม่อีกครั้งค่ะ"
          : "* คุณสามารถทำแบบทดสอบได้อีกครั้ง เมื่อผ่านไป 7 วัน"}
      </Typography>

      <Box display="flex" gap="8px" width={1}>
        <Button fullWidth color="blue" variant="outlined" onClick={handleClose}>
          <Typography typography="subtitle2" color="blue.main">
            กลับ
          </Typography>
        </Button>
        <Button
          fullWidth
          color="blue"
          variant="contained"
          component={RouterLink}
          to="/course/lesson-overview"
          state={{ courseId: course?.course_id }}
        >
          <Typography typography="subtitle2" color="white">
            บทเรียน
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default AssessmentFailModal;
