import { Outlet } from "react-router-dom";
import {
  Box,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { userTheme } from "../themes/userTheme";

const UserLayout = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <ThemeProvider theme={userTheme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: "100vh",
          background:
            "linear-gradient(180deg, #F5F7FB 32.6%, #5DBEF2 75%, #18D9CD 100%)",
        }}
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: matches ? 630 : 400,
              height: matches ? 630 : 254,
              backgroundImage:
                "radial-gradient(136.86% 174.03% at 131.53% -2.96%, #00A651 0%, #00AEEF 100%)",
              opacity: 0.8,
              left: matches ? "-530px" : "-298px",
              top: matches ? "-40px" : "70px",
              transform: "rotate(-30deg)",
            }}
          ></Box>

          <Box
            sx={{
              position: "absolute",
              width: matches ? 500 : 400,
              height: matches ? 500 : 254,
              backgroundImage:
                "radial-gradient(136.86% 174.03% at 131.53% -2.96%, #1CB9F3 0%, #32C0F7 100%)",
              opacity: 0.8,
              left: matches ? "-359px" : "-320px",
              top: matches ? "-122.65px" : "-40px",
              transform: "rotate(-30deg)",
            }}
          ></Box>

          <Box
            sx={{
              position: "absolute",
              width: matches ? 590 : 400,
              height: matches ? 590 : 254,
              backgroundImage:
                "radial-gradient(89.34% 113.6% at 4.47% -12.21%, #32C0F7 0%, #04CD85 100%)",
              opacity: 0.8,
              right: matches ? "-460px" : "-328px",
              top: matches ? "0" : "-23px",
              transform: "rotate(-30deg)",
            }}
          ></Box>

          <Box
            sx={{
              position: "absolute",
              width: matches ? 628 : 400,
              height: matches ? 628 : 254,
              backgroundImage: matches
                ? "radial-gradient(136.86% 174.03% at 131.53% -2.96%, #00AEEF 0%, #04CD85 100%)"
                : "radial-gradient(89.34% 113.6% at 4.47% -12.21%, #00AEEF 0%, #04CD85 100%)",
              opacity: 0.8,
              right: matches ? "-478px" : "-325px",
              top: matches ? "-248px" : "-130px",
              transform: "rotate(-30deg)",
            }}
          ></Box>
        </Box>

        <Outlet />
      </Box>
    </ThemeProvider>
  );
};

export default UserLayout;
