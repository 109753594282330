import {
  Box,
  Container,
  LinearProgress,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import { activityExerciseSummaryService } from "../../../services/exercise.service";
import { useEffect, useState } from "react";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 100,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E8EBEF",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 100,
    backgroundColor: "#0DAC87",
  },
}));

const ExerciseResults = () => {
  const [activity, setActivity] = useState<any>(null);
  const [percentTarget, setPercentTarget] = useState<number>(0);

  const activityExerciseSummary = () => {
    activityExerciseSummaryService().then((resp) => setActivity(resp.data));
  };

  useEffect(activityExerciseSummary, []);

  useEffect(
    () =>
      setPercentTarget(
        (activity?.weekly_used_minutes / activity?.weekly_target_minutes) * 100
      ),
    [activity]
  );

  return (
    <Container
      component="main"
      maxWidth={false}
      sx={{ zIndex: 1000, maxWidth: "1120px" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          borderRadius: "8px",
          background: "#FFFFFF",
          padding: { xs: "16px", md: "56px" },
          paddingBottom: { xs: "48px !important" },
        }}
      >
        <Box
          sx={{
            borderRadius: "100px",
            padding: "8px 0px",
            background: "#0DAC87",
          }}
        >
          <Typography
            typography={{ xs: "h4", md: "h2" }}
            color="white"
            align="center"
          >
            ผลการออกกำลังกาย
          </Typography>

          <Typography
            typography={{ xs: "h5", md: "h3" }}
            color="white"
            align="center"
          >
            ประจำสัปดาห์
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            gap: { xs: "78px", lg: "16px" },
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={{ xs: "8px", md: "24px" }}
            width={{ xs: 1, md: 0.45 }}
          >
            <Box display="flex" gap="16px">
              <Typography
                typography={{ xs: "body1", lg: "h4" }}
                sx={{ fontWeight: "700 !important" }}
              >
                สถานะ
              </Typography>

              <Typography
                typography={{ xs: "body1", lg: "h4" }}
                sx={{ fontWeight: "400 !important", color: "#00A3FE" }}
              >
                {activity?.weekly_status}
              </Typography>
            </Box>

            <Box display="flex" gap="16px">
              <Typography
                typography={{ xs: "body1", lg: "h4" }}
                sx={{ fontWeight: "700 !important" }}
              >
                สะสมแล้ว
              </Typography>

              <Typography
                typography={{ xs: "body1", lg: "h4" }}
                sx={{ fontWeight: "400 !important", color: "#FF6EB9" }}
              >
                {activity?.weekly_calories} แคลอรี
              </Typography>
            </Box>

            <Box display="flex" gap="16px">
              <Typography
                typography={{ xs: "body1", lg: "h4" }}
                sx={{ fontWeight: "700 !important" }}
              >
                ท่านส่งผลติดต่อกัน
              </Typography>

              <Typography
                typography={{ xs: "body1", lg: "h4" }}
                sx={{ fontWeight: "400 !important", color: "#E67B4C" }}
              >
                {activity?.weekly_exercise_day_count} วัน
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              backgroundImage: "url('/images/bg-results-exercise.png')",
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
            position="relative"
            width={1}
            height={{ xs: "45px", md: "auto" }}
          >
            <Box
              position="absolute"
              bottom={{ xs: "-30%", md: "-5%" }}
              zIndex={1}
              left="95%"
              width={1}
            >
              <Box
                component="img"
                width={{ xs: "40px", md: "60px" }}
                src="/images/finish.png"
              ></Box>
            </Box>

            <Box position="absolute" top={{ xs: "82%", md: "90%" }} width={1}>
              <BorderLinearProgress
                variant="determinate"
                value={percentTarget}
              />
            </Box>

            <Box
              position="absolute"
              bottom={{ xs: "0px" }}
              left={`${percentTarget - 8}%`}
              width={1}
            >
              <Box
                component="img"
                width={{ xs: "65px", md: "80px" }}
                src="/images/walk.png"
              ></Box>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              position="absolute"
              width={1}
              top={{ xs: "120%", md: "110%" }}
            >
              <Typography typography="subtitle2">0 นาที</Typography>
              <Typography typography="subtitle2">75 นาที</Typography>
              <Typography typography="subtitle2">150 นาที</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ExerciseResults;
