import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

const RewardSponcer = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Container
        component="main"
        maxWidth={false}
        sx={{
          zIndex: 1,
          maxWidth: "1120px",
          display: "flex",
          gap: "16px",
          justifyContent: "center",
        }}
      >
        <Swiper
          className="swiper-container"
          centeredSlides={true}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {/* <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
            <Sponcer img="/sponcer/nestle.png" />
          </SwiperSlide> */}

          <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
            <Sponcer img="/sponcer/sabuy-digital.png" />
          </SwiperSlide>
        </Swiper>
      </Container>
    </Box>
  );
};

const Sponcer = ({ img }: any) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      width={sm ? 1 : 400}
      sx={{
        background: "#FFFFFF",
        borderRadius: "30px",
      }}
      component="img"
      src={img}
    />
  );
};

export default RewardSponcer;
