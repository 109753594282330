import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  Skeleton,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  boxStyle,
  containerStyle,
  modalStyle,
  pageStyle,
} from "../../../consts/styles";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import SuccessModal from "../../../components/modals/course/SuccessModal";
import WarningModal from "../../../components/modals/course/WarningModal";
import ErrorModal from "../../../components/modals/ErrorModal";
import {
  getLessonQuizService,
  postLessonQuizService,
} from "../../../services/course.service";
import dayjs from "dayjs";

const QuizPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [courseId, setCourseId] = useState<boolean>(false);
  const [lessonId, setLessonId] = useState<boolean>(false);
  const [lesson, setLesson] = useState<any>(null);
  const [quiz, setQuiz] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openSuccess, setSuccessOpen] = useState<boolean>(false);
  const [openWarning, setWarningOpen] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [needLearnAgain, setNeedLearnAgain] = useState<boolean>(false);

  const { handleSubmit, formState, control } = useForm();

  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);
  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleGoToLessonOverview = () => {
    setSuccessOpen(false);
    navigate("/course/lesson-overview", { state: { courseId } });
  };
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };
  const handleWarningOpen = () => setWarningOpen(true);
  const handleGoToLesson = () => {
    setSuccessOpen(false);
    navigate("/course/lesson", {
      state: {
        lesson: !needLearnAgain
          ? { ...lesson, tested_date: dayjs().toISOString() }
          : { ...lesson, learned_time: 0 },
        courseId,
      },
    });
  };

  const getLessonQuiz = () => {
    if (courseId && lessonId)
      setTimeout(() => {
        getLessonQuizService(courseId, lessonId).then((resp) => {
          setQuiz(resp.data);
        });
      }, 500);
  };

  const { isDirty, isValid } = formState;

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();
      const body = [];
      for (const [key, value] of Object.entries(values)) {
        body.push({
          quiz_id: key,
          selected_answer: value,
        });
      }

      const resp = await postLessonQuizService(courseId, lessonId, body);

      if (!resp.data.is_passed) {
        if (resp.data.need_learn_again) {
          setNeedLearnAgain(resp.data.need_learn_again);
        } else {
          setNeedLearnAgain(false);
        }
        handleWarningOpen();
        return;
      }

      handleSuccessOpen();
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  useEffect(() => {
    if (!location.state) {
      navigate("/home");
      return;
    }
    setLesson(location.state.lesson);
    setCourseId(location.state.courseId);
    setLessonId(location.state.lessonId);
  }, [location, navigate]);

  useEffect(getLessonQuiz, [courseId, lessonId]);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  return (
    <Box sx={pageStyle}>
      <Container
        component="main"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        maxWidth={false}
      >
        <Typography
          align="center"
          sx={{ typography: { xs: "h3", sm: "h1" } }}
          color="ktgGreen.main"
          zIndex={1}
        >
          {quiz?.title}
        </Typography>

        <Box sx={boxStyle} component="form">
          <Typography align="center" typography="h4" color="black.main">
            โปรดเลือกข้อที่ถูกที่สุด
          </Typography>

          {!quiz?.questions.length && (
            <Box display="flex" flexDirection="column" gap="16px">
              <Skeleton variant="rounded" width={"100%"} height={200} />
              <Skeleton variant="rounded" width={"100%"} height={200} />
              <Skeleton variant="rounded" width={"100%"} height={200} />
            </Box>
          )}

          {quiz?.questions.map((question: any, index: number) => (
            <Controller
              key={index}
              name={`${question.quiz_id}`}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormControl key={index} component="fieldset">
                  <FormLabel id={`question-${index}`}>
                    <Box display="flex" gap="8px">
                      <Typography typography="body1" color="black.main">
                        {question.seq_no}.
                      </Typography>

                      <Typography typography="body1" color="black.main">
                        {question.question}
                      </Typography>
                    </Box>
                  </FormLabel>

                  <RadioGroup
                    sx={{
                      marginLeft: "24px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                    aria-labelledby={`question-${index}`}
                    name={`answers-${index}`}
                    value={value}
                    onChange={onChange}
                  >
                    {question.answer.map((ans: any, i: number) => (
                      <FormControlLabel
                        key={`answer-${i}`}
                        value={ans.answer_order}
                        control={<Radio color="success" />}
                        label={
                          <Typography typography="body1" color="black.main">
                            {ans.answer}
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            />
          ))}

          <Box display="flex" gap="8px">
            <Button
              fullWidth
              color="blue"
              variant="outlined"
              component={RouterLink}
              to="/course/lesson-overview"
              state={{ courseId }}
            >
              <Typography typography="subtitle2" color="blue.main">
                ยกเลิก
              </Typography>
            </Button>
            <Button
              fullWidth
              color="green"
              variant="contained"
              onClick={handleSubmit(onSubmitHandler)}
              disabled={!isValid || !isDirty || loading}
            >
              <Typography typography="subtitle2" color="white">
                ส่งคำตอบ
              </Typography>
            </Button>
          </Box>
        </Box>
      </Container>

      <Modal keepMounted open={openSuccess}>
        <Box sx={modalStyle} width={340}>
          {lesson && (
            <SuccessModal
              lesson={lesson}
              handleClose={handleGoToLessonOverview}
            />
          )}
        </Box>
      </Modal>

      <Modal keepMounted open={openWarning}>
        <Box sx={modalStyle} width={340}>
          {lesson && (
            <WarningModal
              needLearnAgain={needLearnAgain}
              lesson={lesson}
              handleGoBack={handleGoToLessonOverview}
              handleGoToLesson={handleGoToLesson}
            />
          )}
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle} width={340}>
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default QuizPage;
