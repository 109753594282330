import {
  Typography,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
// import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import InsertChartOutlinedTwoToneIcon from "@mui/icons-material/InsertChartOutlinedTwoTone";
// import ArtTrackSharpIcon from "@mui/icons-material/ArtTrackSharp";

const Menu = () => {
  const [value, setValue] = useState(0);

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
      }}
      elevation={2}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          component={RouterLink}
          to="/home"
          label={<Typography sx={{ fontSize: "10px" }}>หน้าแรก</Typography>}
          icon={<HomeOutlinedIcon />}
        />
        <BottomNavigationAction
          component={RouterLink}
          to="/add-result-exercise"
          label={<Typography sx={{ fontSize: "10px" }}>ส่งผล</Typography>}
          icon={<PostAddOutlinedIcon />}
        />
        <BottomNavigationAction
          component={RouterLink}
          to="/dashboard-exercise"
          label={<Typography sx={{ fontSize: "10px" }}>ดูผล</Typography>}
          icon={<AssignmentOutlinedIcon />}
        />
        <BottomNavigationAction
          component={RouterLink}
          to="/dashboard-overview"
          label={<Typography sx={{ fontSize: "10px" }}>รายงาน</Typography>}
          icon={<InsertChartOutlinedTwoToneIcon />}
        />
        {/* <BottomNavigationAction
          label={<Typography sx={{ fontSize: "10px" }}>แลกรางวัล</Typography>}
          icon={<CardGiftcardOutlinedIcon />}
        /> */}
      </BottomNavigation>
    </Paper>
  );
};

export default Menu;
