import { Box, Button, Container, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import DashboardExerciseAll from "../../components/user/exercise/DashboardExerciseAll";
import DashboardExerciseSeason from "../../components/user/exercise/DashboardExerciseSeason";

const DashboardExercise = () => {
  const [allView, setAllView] = useState<boolean>(false);

  const handleAllView = () => setAllView(true);
  const handleSeason = () => setAllView(false);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "32px",
        py: "50px",
      }}
    >
      <Typography
        zIndex={1}
        align="center"
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="ktgGreen.main"
      >
        <FormattedMessage id="exercise.result" />
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: 2,
        }}
      >
        <Button
          fullWidth
          color={allView ? "blue" : "cwhite"}
          variant="contained"
          type="button"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            minWidth: { xs: "160px", sm: "270px" },
          }}
          onClick={handleAllView}
        >
          <Typography
            variant="subtitle2"
            color={allView ? "white" : "grey"}
            sx={{ fontWeight: 600 }}
          >
            <FormattedMessage id="exercise.typeAll" />
          </Typography>
        </Button>

        <Button
          fullWidth
          color={!allView ? "blue" : "cwhite"}
          variant="contained"
          type="button"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            minWidth: { xs: "160px", sm: "270px" },
          }}
          onClick={handleSeason}
        >
          <Typography
            variant="subtitle2"
            color={!allView ? "white" : "grey"}
            sx={{ fontWeight: 600 }}
          >
            <FormattedMessage id="exercise.typeSeason" />
          </Typography>
        </Button>
      </Box>

      <Container
        component="main"
        sx={{
          maxWidth: "940px",
          gap: "15px",
          display: "flex",
          flexDirection: "column",
          zIndex: 1,
        }}
        maxWidth={false}
      >
        <Box
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            borderRadius: "10px",
            background: "#FFFFFF",
          }}
        >
          {allView && <DashboardExerciseAll />}
          {!allView && <DashboardExerciseSeason />}
        </Box>
      </Container>
    </Box>
  );
};

export default DashboardExercise;
