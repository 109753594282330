import { Box, Typography } from "@mui/material";

const MemberSection = ({ summary }: any) => {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      gap="16px"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        boxShadow="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15)"
        borderRadius="10px"
        sx={{ background: "#2FD25D" }}
        gap="16px"
        justifyContent="center"
        width={1}
        paddingY="16px"
      >
        <Box
          alignSelf="center"
          component="img"
          src="/dashboard/member.png"
          sx={{
            height: { xs: "37px", sm: "45px" },
            width: { xs: "40px", sm: "50px" },
          }}
        ></Box>
        <Box display="flex" flexDirection="column">
          <Typography
            typography={{ xs: "body1", sm: "subtitle2" }}
            color="white"
          >
            จำนวนผู้ลงทะเบียนทั้งหมด
          </Typography>

          <Box display="flex" gap="8px">
            <Typography typography={{ xs: "h4", sm: "h2" }} color="white">
              {summary
                ? (
                    summary?.all_summaries[0].total_male +
                    summary?.all_summaries[0].total_female
                  ).toLocaleString()
                : ""}
            </Typography>

            <Typography variant="body1" color="white" alignSelf="end">
              คน
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        boxShadow="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15)"
        borderRadius="10px"
        sx={{ background: "#2CADF2" }}
        gap="16px"
        justifyContent="center"
        width={1}
        paddingY="16px"
      >
        <Box
          alignSelf="center"
          component="img"
          src="/dashboard/male.png"
          sx={{
            height: { xs: "40px", sm: "50px" },
            width: { xs: "15px", sm: "18px" },
          }}
        ></Box>
        <Box display="flex" flexDirection="column">
          <Typography
            typography={{ xs: "body1", sm: "subtitle2" }}
            color="white"
          >
            จำนวนเพศชายที่ลงทะเบียน
          </Typography>

          <Box display="flex" gap="8px">
            <Typography typography={{ xs: "h4", sm: "h2" }} color="white">
              {summary?.all_summaries[0].total_male.toLocaleString()}
            </Typography>

            <Typography variant="body1" color="white" alignSelf="end">
              คน
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        boxShadow="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15)"
        borderRadius="10px"
        sx={{ background: "#EB698F" }}
        gap="16px"
        justifyContent="center"
        width={1}
        paddingY="16px"
      >
        <Box
          alignSelf="center"
          component="img"
          src="/dashboard/female.png"
          sx={{
            height: { xs: "40px", sm: "50px" },
            width: { xs: "18px", sm: "22px" },
          }}
        ></Box>
        <Box display="flex" flexDirection="column">
          <Typography
            typography={{ xs: "body1", sm: "subtitle2" }}
            color="white"
          >
            จำนวนเพศหญิงที่ลงทะเบียน
          </Typography>

          <Box display="flex" gap="8px">
            <Typography typography={{ xs: "h4", sm: "h2" }} color="white">
              {summary?.all_summaries[0].total_female.toLocaleString()}
            </Typography>

            <Typography variant="body1" color="white" alignSelf="end">
              คน
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MemberSection;
