import {
  Box,
  Typography,
  Grid,
  styled,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  Pagination,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { tableCellClasses } from "@mui/material/TableCell";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getEventByIdService } from "../../../services/community.service";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.ktjBlue.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const EventModal = ({ event, handleClose }: any) => {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [size] = useState(4);
  const [members, setMembers] = useState<any>([]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const getEventById = () => {
    getEventByIdService(event.event_id, { page, size }).then((resp) => {
      setMembers(resp.data.items);
      setCount(resp.data.total_page);
    });
  };

  useEffect(getEventById, [event.event_id, page, size]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      padding="16px"
      gap="8px"
    >
      <Box display="flex" width={1} onClick={handleClose} justifyContent="end">
        <CancelOutlinedIcon
          sx={{ cursor: "pointer", color: "lightGrey.main" }}
        />
      </Box>

      <Typography align="center" typography="h5" color="ktgGreen.main">
        {event.event}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography typography="body1" fontWeight={600} color="black.main">
            ระยะเวลา:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography typography="body1" color="black.main">
            {dayjs(event.start_date).format("D MMM BBBB")} -{" "}
            {dayjs(event.end_date).format("D MMM BBBB")}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography typography="body1" fontWeight={600} color="black.main">
            ประเภทกีฬา:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          {event.activity_type_name}
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          borderRadius: 0,
          borderTopRightRadius: "10px",
          borderTopLeftRadius: "10px",
        }}
      >
        <Table sx={{ minWidth: 300 }} aria-labelledby="tableTitle" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell
                align="center"
                sx={{
                  typography: "body1",
                  fontWeight: 600,
                  padding: "8px 2px",
                }}
              >
                ลำดับ
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  typography: "body1",
                  fontWeight: 600,
                  padding: "8px 2px",
                }}
              >
                e-BIB
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  typography: "body1",
                  fontWeight: 600,
                  padding: "8px 2px",
                }}
              >
                ชื่อ - สกุล
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  typography: "body1",
                  fontWeight: 600,
                  padding: "8px 2px",
                }}
              >
                {event.result_count_type === 1 && <>ระยะทาง</>}
                {event.result_count_type === 2 && <>ระยะเวลา</>}
                {event.result_count_type === 3 && <>แคลอรี</>}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((row: any, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {row.seq_no}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row.member_id}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row.first_name} {row.last_name}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {event.result_count_type === 1 && (
                    <>{row.distances.toLocaleString()}</>
                  )}
                  {event.result_count_type === 2 && (
                    <>{row.minutes.toLocaleString()}</>
                  )}
                  {event.result_count_type === 3 && (
                    <>{row.calories.toLocaleString()}</>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {!members.length && (
              <StyledTableRow>
                <StyledTableCell
                  align="center"
                  component="th"
                  scope="row"
                  colSpan={4}
                >
                  ไม่มีข้อมูล
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {members.length > size && (
        <Box display="flex" justifyContent="center">
          <Pagination
            count={count}
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
            color="blue"
          />
        </Box>
      )}
    </Box>
  );
};

export default EventModal;
