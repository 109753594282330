import { Box, Typography, Button } from "@mui/material";

const SuccessModal = ({ lesson, handleClose }: any) => {
  return (
    <Box display="flex" flexDirection="column" padding="32px">
      <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
        <Typography align="center" typography="h4" color="blue.main">
          ผลการทดสอบ
        </Typography>

        <Box
          component="img"
          src="/course/passed.png"
          width={150}
          height={150}
        />

        <Box display="flex" flexDirection="column" gap="8px">
          <Typography align="center" typography="h5" color="black.main">
            เก่งมาก !
          </Typography>

          <Typography align="center" typography="h5" color="black.main">
            คุณผ่านแบบทดสอบ บทที่ {lesson.lesson_order}
          </Typography>
        </Box>

        <Button
          fullWidth
          color="blue"
          variant="outlined"
          onClick={handleClose}
        >
          <Typography typography="subtitle2" color="blue.main">
            กลับ
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default SuccessModal;
