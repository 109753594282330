import { Alert, Box, Typography } from "@mui/material";
import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FormattedMessage } from "react-intl";
import { Button, Snackbar } from "@mui/material";

import { getProfile } from "../../../features/userStore/libs/profileSlice";
import { useAppSelector } from "../../../features/userStore/hook";
import { configEnv } from "../../../configs/config-env";
import { useState } from "react";

const QrcodeInviteModal = () => {
  const profile = useAppSelector(getProfile);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
  };

  const handleCloseSnackbar = () => {
    setIsCopied(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      alignItems="center"
      padding="16px"
    >
      <Typography align="center" typography="h3">
        <FormattedMessage id="statusInfo.invite" />
      </Typography>

      <QRCode
        size={256}
        style={{ width: 200, height: 200 }}
        value={`${configEnv.app_domain}/register?inviteBy=${profile.member_id}`}
      />

      <Snackbar
        open={isCopied}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Link copied to clipboard!
        </Alert>
      </Snackbar>

      <CopyToClipboard
        text={`${configEnv.app_domain}/register?inviteBy=${profile.member_id}`}
      >
        <Button onClick={handleCopy}>
          <Typography align="center" typography="body1" color="ktgGreen.main">
            Copy Link
          </Typography>
        </Button>
      </CopyToClipboard>
    </Box>
  );
};

export default QrcodeInviteModal;
