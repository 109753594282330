/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Stepper,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Slider,
  FormLabel,
  TextField,
  Modal,
  Grid,
  Fab,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Controller, useForm } from "react-hook-form";
import {
  boxStyle,
  containerStyle,
  modalStyle,
  pageStyle,
} from "../../../consts/styles";
import {
  getQuestionsByIdService,
  postQuestionByIdService,
} from "../../../services/questionaire.service";
import SuccessModal from "../../../components/modals/SuccessModal";
import ErrorModal from "../../../components/modals/ErrorModal";
import { configEnv } from "../../../configs/config-env";

const Questionaire = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [questionaireId, setQuestionaireId] = useState<string>("");
  const [questionaire, setQuestionaire] = useState<any>(null);
  const [started, setStarted] = useState<boolean>(false);
  const [questions, setQuestions] = useState<any>([]);
  const [sections, setSections] = useState<any[]>([]);
  const [suggestQuestions, setSuggestQuestions] = useState<any[]>([]);
  const [skipQuestions, setSkipQuestions] = useState<any>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [openSuccess, setSuccessOpen] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleStarted = () => setStarted(true);

  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => {
    navigate("/questionaires");
    setSuccessOpen(false);
  };
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  const { control, getValues } = useForm();

  const handleQuestionNext = async () => {
    try {
      const values = getValues();

      const value =
        values[`question-${suggestQuestions[activeQuestion].question_id}`];

      if (!value) return;

      if (activeQuestion === suggestQuestions.length - 1) {
        handleLoading();
        const answers = [];

        for (const property in values) {
          const value = values[property];
          const questionId = parseInt(property.replace("question-", ""));
          let answer;

          switch (typeof values[property]) {
            case "object":
              answer = (value[0] * 60 + value[1]).toString();
              break;

            case "number":
              answer = value.toString();
              break;

            default:
              answer = value;
              break;
          }

          const question = suggestQuestions.find(
            (t) => t.question_id === questionId
          );

          if (question)
            answers.push({
              question_id: questionId,
              answer,
            });
        }

        await postQuestionByIdService(questionaireId, answers);

        handleSuccessOpen();

        setTimeout(() => {
          handleSuccessClose();
        }, configEnv.modal_close);

        return;
      }
      setActiveQuestion((prevActiveStep) => prevActiveStep + 1);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  const handleQuestionBack = () => {
    if (!activeQuestion) {
      return;
    }
    setActiveQuestion((prevActiveStep) => prevActiveStep - 1);
  };

  const filterQuestions = (answer: any) => {
    const skips = skipQuestions;
    if (answer.next_question) {
      setSkipQuestions(
        [
          ...skipQuestions,
          {
            question_id: suggestQuestions[activeQuestion].question_id,
            next_question: answer.next_question,
          },
        ].sort((a: any, b: any) => a.next_question - b.next_question)
      );
    } else {
      const data = skips.filter(
        (skip: any) =>
          skip.question_id !== suggestQuestions[activeQuestion].question_id
      );
      setSkipQuestions(data);
    }
  };

  const suggestQuestion = () => {
    const suggest = [...questions];
    const removepos: any[] = [];
    for (const value of skipQuestions) {
      const curr = suggest.findIndex(
        (t: any) => t.question_id === value.question_id
      );
      const end = value.next_question - 1;
      Array.from({ length: end - 1 - curr }).forEach((_, index) => {
        removepos.push(curr + (index + 1));
      });
    }
    removepos.sort((a, b) => b - a);
    removepos.forEach((position) => {
      suggest.splice(position, 1);
    });

    setSuggestQuestions(suggest);
  };

  const getContentForStep = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        marginTop="16px"
        gap="16px"
      >
        <Typography
          align="center"
          variant="subtitle2"
          color="black.main"
          fontWeight={600}
          dangerouslySetInnerHTML={{
            __html: sections[activeStep]?.question,
          }}
        />

        <Typography
          variant="body1"
          color="black.main"
          dangerouslySetInnerHTML={{
            __html: suggestQuestions[activeQuestion]?.question,
          }}
        />

        <Box display="flex">
          {suggestQuestions[activeQuestion] &&
            getQuestionContent(suggestQuestions[activeQuestion])}
        </Box>

        <Box display="flex" gap="8px">
          <Button
            fullWidth
            color="blue"
            variant="outlined"
            onClick={handleQuestionBack}
          >
            <Typography typography="subtitle2" color="blue.main">
              กลับ
            </Typography>
          </Button>
          <Button
            fullWidth
            color="green"
            variant="contained"
            onClick={handleQuestionNext}
            disabled={loading}
          >
            <Typography typography="subtitle2" color="white">
              ต่อไป
            </Typography>
          </Button>
        </Box>
      </Box>
    );
  };

  const getQuestionContent = (question: any) => {
    switch (question.question_type) {
      case 1:
        return (
          <Controller
            key={question.question_id}
            name={`question-${question.question_id}`}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                multiline
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "10px !important",
                  },
                }}
                value={value}
                onChange={onChange}
                rows={4}
                label="กรอกคำตอบของท่านที่นี่..."
                variant="outlined"
              />
            )}
          />
        );
      case 2:
        return (
          <Controller
            key={question.question_id}
            name={`question-${question.question_id}`}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                value={value}
                onChange={onChange}
                type="number"
                variant="outlined"
              />
            )}
          />
        );
      case 3:
        return (
          <Box marginLeft="24px" width={"50%"}>
            <FormLabel>ระบุจำนวน</FormLabel>

            <Controller
              key={question.question_id}
              name={`question-${question.question_id}`}
              control={control}
              rules={{ required: true }}
              defaultValue={0}
              render={({ field: { onChange, value } }) => (
                <Box display="flex" gap="32px">
                  <Slider
                    value={value}
                    onChange={onChange}
                    min={question?.answer_options.minimum}
                    max={question?.answer_options.maximum}
                    valueLabelDisplay="auto"
                  />

                  <Typography alignSelf="center">{value}</Typography>
                </Box>
              )}
            />
          </Box>
        );
      case 4:
        return (
          <Controller
            key={question.question_id}
            name={`question-${question.question_id}`}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <FormControl component="fieldset">
                <RadioGroup
                  sx={{
                    marginLeft: "24px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                    const answer = question?.answer_options?.answers.find(
                      (option: any) => option.answer === e.target.value
                    );
                    filterQuestions(answer);
                  }}
                >
                  {question?.answer_options?.answers.map(
                    (option: any, index: number) => (
                      <FormControlLabel
                        key={`answer-${index}`}
                        value={option.answer}
                        control={<Radio color="success" />}
                        label={
                          <Typography typography="body1" color="black.main">
                            {option.answer}
                          </Typography>
                        }
                      />
                    )
                  )}
                </RadioGroup>
              </FormControl>
            )}
          />
        );
      case 5:
        return (
          <Box
            display="flex"
            flexDirection="column"
            gap="8"
            marginLeft="24px"
            width={"50%"}
          >
            <FormLabel>ระบุจำนวนชั่วโมง</FormLabel>

            <Controller
              key={`${question.question_id}-0`}
              name={`question-${question.question_id}[0]`}
              control={control}
              defaultValue={0}
              render={({ field: { onChange, value } }) => (
                <Box display="flex" gap="32px">
                  <Slider
                    value={value}
                    onChange={onChange}
                    min={0}
                    max={24}
                    valueLabelDisplay="auto"
                  />

                  <Typography alignSelf="center">{value}</Typography>
                </Box>
              )}
            />

            <FormLabel>ระบุจำนวนนาที</FormLabel>

            <Controller
              key={`${question.question_id}-1`}
              name={`question-${question.question_id}[1]`}
              control={control}
              defaultValue={0}
              render={({ field: { onChange, value } }) => (
                <Box display="flex" gap="32px">
                  <Slider
                    value={value}
                    onChange={onChange}
                    min={0}
                    max={59}
                    valueLabelDisplay="auto"
                  />

                  <Typography alignSelf="center">{value}</Typography>
                </Box>
              )}
            />
          </Box>
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (!location.state) {
      navigate("/home");
      return;
    }
    setQuestionaireId(location.state.question.questionaire_id);
    setQuestionaire(location.state.question);
  }, [location, navigate]);

  const getQuestionsById = () => {
    if (questionaireId)
      getQuestionsByIdService(questionaireId).then((resp: any) => {
        const sectionsData = resp.data.filter(
          (data: any) => !data.question_type
        );
        setSections(sectionsData);
        const questions = resp.data.filter((data: any) => data.question_type);
        setQuestions(questions);
        setSuggestQuestions(questions);
      });
  };

  const suggestSection = () => {
    if (activeQuestion) {
      const index = sections.findIndex(
        (t: any, index: number) =>
          (t.question_order < suggestQuestions[activeQuestion].question_order &&
            sections[index + 1] &&
            sections[index + 1].question_order >
              suggestQuestions[activeQuestion].question_order) ||
          (!sections[index + 1] &&
            t.question_order < suggestQuestions[activeQuestion].question_order)
      );

      if (index !== -1) setActiveStep(index);
    }
  };

  useEffect(getQuestionsById, [questionaireId]);

  useEffect(suggestQuestion, [skipQuestions]);

  useEffect(suggestSection, [activeQuestion]);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  return (
    <Box sx={pageStyle}>
      <Box maxWidth={{ xs: 450, sm: 600 }} width={1} zIndex={1}>
        <Grid container spacing={2} padding="8px">
          <Grid item xs={1}>
            <Fab
              sx={{
                background: "#0892F5",
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                color: "white",
                "&:hover": { background: "#0892F5" },
              }}
              component={RouterLink}
              to="/questionaires"
            >
              <ArrowBackIcon />
            </Fab>
          </Grid>
          <Grid item xs={10} alignSelf="center">
            <Typography
              align="center"
              sx={{ typography: { xs: "h3", sm: "h1" } }}
              color="ktgGreen.main"
              zIndex={1}
            >
              แบบสอบถาม
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Container
        component="main"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        maxWidth={false}
      >
        <Box sx={{ ...boxStyle, padding: "16px", gap: "16px" }}>
          <Box width={1}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{
                "& .MuiStepConnector-line": {
                  borderTopWidth: "4px",
                },
                ".MuiStepLabel-iconContainer.Mui-completed .MuiSvgIcon-fontSizeMedium":
                  {
                    color: "#1DA64A",
                  },
                "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
                  borderColor: "#1DA64A",
                },
                "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line":
                  {
                    borderColor: "#1DA64A",
                  },
              }}
            ></Stepper>

            {!started && (
              <Box display="flex" flexDirection="column" gap="16px">
                <Typography
                  dangerouslySetInnerHTML={{ __html: questionaire?.detail }}
                />

                <Box display="flex" justifyContent="center">
                  <Button
                    color="green"
                    variant="contained"
                    onClick={handleStarted}
                  >
                    <Typography typography="subtitle2" color="white">
                      เริ่มทำเเบบสอบถาม
                    </Typography>
                  </Button>
                </Box>
              </Box>
            )}
            {started && getContentForStep()}
          </Box>
        </Box>
      </Container>

      <Modal keepMounted open={openSuccess}>
        <Box sx={modalStyle} width="340px">
          <SuccessModal
            message={`ส่งแบบสอบถามสำเร็จ`}
            handleSuccessClose={handleSuccessClose}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle} width="340px">
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default Questionaire;
