import { Avatar, Box, Button, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../features/userStore/hook";
import {
  getAvatar,
  getProfile,
} from "../../../features/userStore/libs/profileSlice";

const CertificateModal = ({ handleClose, onDownload }: any) => {
  const profile = useAppSelector(getProfile);
  const avatar = useAppSelector(getAvatar);

  const [fullnameIsLarge, setfullnameIsLarge] = useState<boolean>(false);

  useEffect(() => {
    if (profile.created_date) {
      const length = profile.first_name.length + profile.last_name.length;
      if (length > 18) {
        setfullnameIsLarge(true);
      }
    }
  }, [profile]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      padding="16px"
      gap="8px"
    >
      <Box display="flex" width={1} onClick={handleClose} justifyContent="end">
        <CancelOutlinedIcon
          sx={{ cursor: "pointer", color: "lightGrey.main" }}
        />
      </Box>

      <Typography align="center" typography="h4" color="black.main">
        e-Certificate
      </Typography>

      <Box display="flex" justifyContent="center">
        <Box
          id="certificateDom"
          display="flex"
          flexDirection="column"
          sx={{
            backgroundImage: "url('/achievement/cer_ss6.png')",
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
          }}
          width={1}
          height={{ xs: 200, sm: 400 }}
          position="relative"
        >
          <Box position="absolute" top="35%" left="10%">
            <Box
              sx={{
                width: { xs: 50, sm: 90 },
                height: { xs: 50, sm: 90 },
              }}
            >
              <Box sx={{ width: "100%", height: "100%" }}>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    padding: "4px",
                    boxShadow: "0 3px 6px rgb(0 0 0 / 16%)",
                    marginBottom: 0,
                    backgroundColor: "#fff",
                    borderRadius: "100%",
                  }}
                >
                  <Avatar
                    alt={profile.first_name}
                    src={avatar ? avatar : "/images/avatar-default.png"}
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box position="absolute" top="49%" width={{ xs: 310, sm: 575 }}>
            {fullnameIsLarge && (
              <Typography
                align="center"
                typography={{ xs: "body2", sm: "h5" }}
                color="common.black"
              >
                {profile.first_name} {profile.last_name}
              </Typography>
            )}
            {!fullnameIsLarge && (
              <Typography
                align="center"
                typography={{ xs: "body2", sm: "h3" }}
                color="common.black"
              >
                {profile.first_name} {profile.last_name}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center">
        <Button
          color="green"
          fullWidth
          variant="contained"
          type="button"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            width: "295px",
          }}
          startIcon={<DownloadIcon sx={{ color: "white" }} />}
          onClick={onDownload}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 600, textTransform: "none" }}
            color="white"
          >
            ดาวน์โหลด e-Certificate
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default CertificateModal;
