import { Box, Button, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const Error1Modal = ({ title, subtitle, handleErrorClose }: any) => {
  return (
    <Box display="flex" flexDirection="column" padding="24px 16px" gap="8px">
      <Typography align="center">
        <HighlightOffIcon
          sx={{ fontSize: 60, color: "#EB698F" }}
          color="error"
        />
      </Typography>

      <Typography align="center" color="black.main" typography="h4">
        {title}
      </Typography>

      <Typography align="center" color="lightGrey.main" typography="body2">
        {subtitle}
      </Typography>

      <Button
        fullWidth
        color="silver"
        variant="contained"
        sx={{ boxShadow: "none" }}
        onClick={handleErrorClose}
      >
        <Typography typography="subtitle2" color="cgrey.main" fontWeight={600}>
          ปิด
        </Typography>
      </Button>
    </Box>
  );
};

export default Error1Modal;
