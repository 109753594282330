import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { TableVirtuoso, TableComponents } from "react-virtuoso";
import { activityExerciseHistoryService } from "../../services/exercise.service";
import { exerciseActivityTypeFiitService } from "../../services/master-data.service";
import { Link as RouterLink } from "react-router-dom";

interface Data {
  activity_date: string;
  sum_calories: number;
  sum_distances: number;
  sum_minutes: number;
}

interface ColumnData {
  dataKey: keyof Data;
  label: string;
  numeric?: boolean;
  width: number;
}

const columns: ColumnData[] = [
  {
    width: 100,
    label: "วันที่",
    dataKey: "activity_date",
  },
  {
    width: 100,
    label: "กิโลเมตร",
    dataKey: "sum_distances",
    numeric: true,
  },
  {
    width: 100,
    label: "นาที",
    dataKey: "sum_minutes",
    numeric: true,
  },
  {
    width: 100,
    label: "แคลอรี่",
    dataKey: "sum_calories",
    numeric: true,
  },
];

const HistoryExercise = () => {
  const [year, setYear] = useState<string>(`${dayjs().year()}`);
  const [month, setMonth] = useState<string>(`${dayjs().month() + 1}`);
  const [activityTypeId, setActivityTypeId] = useState<string>("0");
  const [date, setDate] = useState<string>(`${dayjs().year()}`);
  const [years, setYears] = useState<any>([]);
  const [months, setMonths] = useState<any>([]);
  const [exerciseHistory, setExerciseHistory] = useState<any>({});
  const [dtExerciseHistory, setDtExerciseHistory] = useState<any>([]);
  const [exerciseActivityTypes, setExerciseActivityTypes] = useState([]);

  // form
  const { register, watch, getValues } = useForm({
    defaultValues: {
      year: year,
      month: month,
      activity_type_id: activityTypeId,
    },
  });

  const watchYear = watch("year");
  const watchMonth = watch("month");
  const watchActivityTypeId = watch("activity_type_id");
  // end form

  // func
  const getActivityExerciseHistory = async () => {
    const response = await activityExerciseHistoryService(getValues());
    const { activities } = response.data;
    setExerciseHistory(response.data);
    setDtExerciseHistory([...activities].reverse());
  };

  const getExerciseActivityType = async () => {
    const response = await exerciseActivityTypeFiitService();
    setExerciseActivityTypes(response.data);
  };

  const getYears = () => {
    let currentYear = new Date().getFullYear(),
      years = [];
    let startYear = 2017;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    setYears(years);
  };
  // end func

  // func
  useEffect(() => {
    getYears();
    setMonths(dayjs.months());
    getActivityExerciseHistory();
    getExerciseActivityType();
  }, []);

  useEffect(() => {
    setYear(watchYear);
    setMonth(watchMonth);
    setActivityTypeId(watchActivityTypeId);
    setDate(`${watchYear}-${watchMonth}-01`);
    getActivityExerciseHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchYear, watchMonth, watchActivityTypeId]);
  // end func

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "32px",
        py: "50px",
      }}
    >
      <Typography
        zIndex="1"
        align="center"
        typography={{ xs: "h3", sm: "h1" }}
        color="ktgGreen.main"
      >
        ผลการออกกำลังกาย
      </Typography>

      <Container
        component="main"
        sx={{
          maxWidth: "940px",
          gap: "15px",
          display: "flex",
          flexDirection: "column",
          zIndex: 1,
        }}
        maxWidth={false}
      >
        <Box
          boxShadow="0px 0px 6px rgba(0, 0, 0, 0.15)"
          display="flex"
          flexDirection="column"
          py="24px"
          px={{ xs: "16px", md: "72px" }}
          gap="32px"
          borderRadius="10px"
          sx={{ background: "#FFFFFF" }}
        >
          <Typography
            align="center"
            typography={{ xs: "h5", sm: "h4" }}
            color="common.black"
          >
            ประวัติการออกกำลังของ ก้าว ท้าใจ
          </Typography>

          <Box display="flex" justifyContent="space-around" gap="20px">
            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  เดือน
                </Typography>
              </FormLabel>
              <Select
                value={month}
                sx={{
                  "& .MuiSelect-select .notranslate::after": "กรุณาเลือกกีฬา"
                    ? {
                        content: `"กรุณาเลือกกีฬา"`,
                        opacity: 0.42,
                      }
                    : {},
                }}
                {...register("month")}
              >
                {months.map((item: any, index: number) => (
                  <MenuItem value={(index + 1).toString()} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  {/* <FormattedMessage id="register.province" /> */}
                  ปี
                </Typography>
              </FormLabel>

              <Select value={year} {...register("year")}>
                {years.map((item: any, index: number) => (
                  <MenuItem value={item} key={index}>
                    {item + 543}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box display="flex">
            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  {/* <FormattedMessage id="register.province" /> */}
                  ประเภทกีฬา
                </Typography>
              </FormLabel>
              <Select
                value={activityTypeId}
                sx={{
                  "& .MuiSelect-select .notranslate::after": "กรุณาเลือกกีฬา"
                    ? {
                        content: `"กรุณาเลือกกีฬา"`,
                        opacity: 0.42,
                      }
                    : {},
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      height: 250,
                    },
                  },
                }}
                {...register("activity_type_id")}
              >
                <MenuItem value="0" key={0}>
                  รวมทั้งหมด
                </MenuItem>
                {exerciseActivityTypes.map((item: any, index) => (
                  <MenuItem value={item.id.toString()} key={index + 1}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box display="flex" flexDirection="column" gap="16px">
            <Typography
              align="center"
              typography={{ xs: "h5", sm: "h4" }}
              color="common.black"
            >
              จำนวนแคลอรี่ {dayjs(date).locale("th").format("MMMM BBBB")}
            </Typography>

            <Box display="flex" justifyContent="center" gap="8px">
              <Box
                component="img"
                src="/icons/fire_all.png"
                width="22px"
                height="22px"
                alignSelf="center"
              />

              <Typography align="center" variant="h2" color="secPink.main">
                {exerciseHistory?.total_calories?.toLocaleString()}
              </Typography>

              <Typography
                align="center"
                typography={{ xs: "body2", sm: "body1" }}
                color="lightGrey.main"
                alignSelf="center"
              >
                <FormattedMessage id="exercise.calories" />
              </Typography>
            </Box>

            <Box display="flex" justifyContent="center">
              <Box
                display="flex"
                justifyContent="space-between"
                maxWidth="550px"
                width={1}
              >
                <Box display="flex" gap="8px">
                  <Box
                    component="img"
                    src="/icons/sneaker_all.png"
                    width="22px"
                    height="22px"
                    alignSelf="center"
                  />
                  <Box display="flex" flexDirection="column" gap="8px">
                    <Typography variant="body2" color="softBlack.main">
                      <FormattedMessage id="exercise.distanceUsed" />
                    </Typography>

                    <Box display="flex" flexDirection="row" gap="8px">
                      <Typography
                        variant="h5"
                        color="common.black"
                        display="flex"
                        flexDirection="column"
                        justifyContent="end"
                      >
                        {exerciseHistory?.total_distances?.toLocaleString()}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="lightGrey.main"
                        display="flex"
                        flexDirection="column"
                        justifyContent="end"
                      >
                        <FormattedMessage id="exercise.distanceUnit" />
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" gap="8px">
                  <Box
                    component="img"
                    src="/icons/clock_all.png"
                    width="22px"
                    height="22px"
                    alignSelf="center"
                  />
                  <Box display="flex" flexDirection="column" gap="8px">
                    <Typography variant="body2" color="softBlack.main">
                      <FormattedMessage id="exercise.timeUsed" />
                    </Typography>

                    <Box display="flex" flexDirection="row" gap="8px">
                      <Typography
                        variant="h5"
                        color="common.black"
                        display="flex"
                        flexDirection="column"
                        justifyContent="end"
                      >
                        {exerciseHistory?.total_minutes?.toLocaleString()}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="lightGrey.main"
                        display="flex"
                        flexDirection="column"
                        justifyContent="end"
                      >
                        <FormattedMessage id="exercise.timeUnit" />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <ResponsiveContainer width={"99%"} height={320}>
            <BarChart
              width={500}
              height={300}
              data={exerciseHistory?.activities}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="activity_date"
                interval={5}
                tick={<CustomizedAxisTick />}
              />
              <YAxis tick={<CustomizedYAxisTick />} />
              <Tooltip />
              <Bar dataKey="sum_calories" fill="#EB698F" />
            </BarChart>
          </ResponsiveContainer>

          <Box style={{ height: 280, width: "100%" }}>
            <TableVirtuoso
              data={dtExerciseHistory}
              components={VirtuosoTableComponents}
              fixedHeaderContent={fixedHeaderContent}
              itemContent={rowContent}
            />
          </Box>

          <Box display="flex" justifyContent="center">
            <Button
              color="green"
              variant="outlined"
              sx={{ width: 295 }}
              component={RouterLink}
              to="/home"
            >
              <Typography variant="subtitle2">
                <FormattedMessage id="common.back" />
              </Typography>
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HistoryExercise;

const CustomizedAxisTick = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
        {dayjs(payload.value).format("D")}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
        {payload.value.toLocaleString()}
      </text>
    </g>
  );
};

const fixedHeaderContent = () => {
  return (
    <TableRow>
      {columns.map((column: any) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? "right" : "left"}
          style={{ width: column.width }}
          sx={{
            backgroundColor: "#2CADF2",
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 600 }} color="white">
            {column.label}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  );
};

const rowContent = (_index: number, row: Data) => {
  return (
    <React.Fragment>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          align={column.numeric || false ? "right" : "left"}
          sx={{ borderBottom: "none" }}
        >
          {column.dataKey === "activity_date" &&
            dayjs(row[column.dataKey]).locale("th").format("D MMM")}
          {column.dataKey !== "activity_date" &&
            row[column.dataKey]?.toLocaleString()}
        </TableCell>
      ))}
    </React.Fragment>
  );
};

const VirtuosoTableComponents: TableComponents<Data> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer
      sx={{ boxShadow: "none", borderRadius: 0 }}
      {...props}
      ref={ref}
    />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};
