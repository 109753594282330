import axiosService from "../api/axios";

export interface Filters {
  page: any;
  size: any;
}

export interface RewardBody {
  reward_id: string;
}

export interface RedeemBody {
  redemption_id: string;
}

export const rewardPublishService = (params: Filters) => {
  return axiosService.get(
    "/reward/publish-reward?" + new URLSearchParams({ ...params })
  );
};

export const rewardPublishByIdService = (id: any) => {
  return axiosService.get("/reward/publish-reward-by-id/" + id);
};

export const historyPointService = (params: Filters) => {
  return axiosService.get(
    "/reward/gain-point-activity?" + new URLSearchParams({ ...params })
  );
};

export const reserveRewardService = (body: RewardBody) => {
  return axiosService.post("/reward/reserve", body);
};

export const redemptionHistoryService = (params: Filters) => {
  return axiosService.get(
    "/reward/redemption-history?" + new URLSearchParams({ ...params })
  );
};

export const redeemService = (body: RedeemBody) => {
  return axiosService.post("/reward/redeem", body);
};
