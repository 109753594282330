import {
  Box,
  Container,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Link as RouterLink } from "react-router-dom";

import {
  getCourseProgressService,
  getPublishCourseService,
} from "../../../services/course.service";
import { modalStyle } from "../../../consts/styles";
import CourseDescriptionModal from "../../modals/course/CourseDescriptionModal";
import { useNavigate } from "react-router-dom";
import { width } from "@mui/system";

const CourseDpac = () => {
  const navigate = useNavigate();

  const [, setPublishCourse] = useState<any[]>([]);
  const [dpacCourseId, setDpacCourseId] = useState<any>(null);
  const [openCourse, setCourseOpen] = useState<boolean>(false);

  const handleCourseOpen = () => {
    getCourseProgressService(dpacCourseId).then((resp) => {
      if (resp.data.registered_date) {
        navigate("/course/description", {
          state: {
            courseId: dpacCourseId,
          },
          replace: true,
        });
      } else {
        setCourseOpen(true);
      }
    });
  };
  const handleCourseClose = () => setCourseOpen(false);

  const getpublishCourse = () => {
    getPublishCourseService().then((resp) => {
      setPublishCourse(resp.data);
      setDpacCourseId(resp.data[0].course_id);
    });
  };

  useEffect(getpublishCourse, []);

  return (
    <Box>
      <Container
        component="main"
        maxWidth={false}
        sx={{
          zIndex: 1,
          maxWidth: "1120px",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: "40px", sm: "32px" },
          background:
            "radial-gradient(192.36% 244.61% at 136.15% -2.96%, #04CD85 0%, #1CB9F3 100%)",
          borderRadius: "10px",
          padding: { xs: "42px 29px", sm: "27px 72px" },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="16px"
        >
          <Typography
            align="center"
            color="white"
            fontSize={{ xs: "24px", sm: "32px" }}
            fontWeight={600}
          >
            หลักสูตรการปรับเปลี่ยน พฤติกรรมสุขภาพ
          </Typography>

          <Typography
            align="center"
            color="white"
            typography={{ xs: "body1", sm: "subtitle2" }}
          >
            หลักสูตรพื้นฐานที่จะช่วยให้เข้าใจในประเด็นการปรับเปลี่ยนพฤติกรรมสุขภาพ
            บริโภคอาหารอย่างไรจะช่วยควบคุมน้ำหนัก ลดปัจจัยเสี่ยง
            ต่อการเกิดโรคไม่ติดต่อเรื้อรัง รวมถึงการทดสอบสมรรถภาพทางกาย
            และการออกโปรแกรมการออกกำลังกายอย่างง่าย
          </Typography>

          <Button
            sx={{ width: "280px" }}
            color="cwhite"
            variant="contained"
            onClick={handleCourseOpen}
          >
            <Typography
              variant="subtitle2"
              fontWeight={600}
              sx={{ color: "#0875F7" }}
            >
              เข้าร่วมหลักสูตร
            </Typography>
          </Button>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          position="relative"
          height={{ xs: "190px", md: "300px" }}
        >
          <Box
            sx={{
              width: { xs: "300px", md: "400px" },
              background: "rgba(43, 43, 43, 0.25)",
              borderRadius: "100px",
              height: "fit-content",
            }}
          >
            <Typography
              align="center"
              color="white"
              fontSize={{ xs: "56px", md: "100px" }}
              fontWeight={600}
            >
              DPAC
            </Typography>
          </Box>

          <Box position="absolute" bottom="-60px">
            <Box
              width={{ xs: "285px", md: "400px" }}
              component="img"
              src="/images/course/dpac_home.png"
            />
          </Box>
        </Box>
      </Container>

      <Dialog
        keepMounted
        open={openCourse}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            display="flex"
            width={1}
            onClick={handleCourseClose}
            justifyContent="end"
          >
            <CancelOutlinedIcon
              sx={{ cursor: "pointer", color: "lightGrey.main" }}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          {openCourse && (
            <CourseDescriptionModal
              courseId={dpacCourseId}
              handleClose={handleCourseClose}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Box display="flex" gap="16px" width={1}>
            <Button
              fullWidth
              color="secPink"
              variant="contained"
              onClick={handleCourseClose}
            >
              <Typography variant="subtitle2" color="white">
                หน้าหลัก
              </Typography>
            </Button>

            <Button
              fullWidth
              color="green"
              variant="contained"
              component={RouterLink}
              to="/course/select-status"
              state={{ courseId: dpacCourseId }}
            >
              <Typography variant="subtitle2" color="white">
                เข้าร่วมสมัคร
              </Typography>
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CourseDpac;
