import { Box, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { achievementCaptions } from "../../../consts/app";

const AchievementBadgeModal = ({ achievementBadge, handleClose }: any) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding="16px"
      gap="8px"
    >
      <Box display="flex" width={1} onClick={handleClose} justifyContent="end">
        <CancelOutlinedIcon
          sx={{ cursor: "pointer", color: "lightGrey.main" }}
        />
      </Box>

      <Box
        component="img"
        src={`/achievement/asset${achievementBadge.achievement_type}/asset${achievementBadge.achievement_type}-${achievementBadge.level}@2x.png`}
        width={150}
        height={150}
      />

      <Typography align="center" typography="h4" color="black.main">
        {
          achievementCaptions[achievementBadge.achievement_type - 1].level[
            achievementBadge.level - 1
          ]
        }
      </Typography>
    </Box>
  );
};

export default AchievementBadgeModal;
