import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { TableComponents, TableVirtuoso } from "react-virtuoso";

interface Data {
  description: string;
  total: number;
}

interface ColumnData {
  dataKey: keyof Data;
  numeric?: boolean;
  width: number;
}

const columns: ColumnData[] = [
  {
    width: 100,
    dataKey: "description",
  },
  {
    width: 100,
    dataKey: "total",
  },
];

const MemberProvince = ({ provinceSummaries }: any) => {
  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
        background: "#FFFFFF",
        p: "16px",
        borderRadius: "10px",
      }}
    >
      <TableVirtuoso
        data={provinceSummaries}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
      />
    </Box>
  );
};

const fixedHeaderContent = () => {
  return (
    <TableRow>
      <TableCell
        colSpan={2}
        variant="head"
        align="center"
        sx={{
          backgroundColor: "#EB698F",
          borderTopLeftRadius: "10px",
        }}
      >
        <Typography variant="subtitle1" color="white">
          จำนวนผู้สมัครรายจังหวัด (คน)
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const rowContent = (_index: number, row: Data) => {
  return (
    <React.Fragment>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          align="center"
          sx={{
            borderBottom: "none",
            background: _index % 2 !== 0 ? "#F5F7FA" : "",
          }}
        >
          {column.dataKey === "description" && (
            <Typography variant="subtitle2" color="black.main">
              {row[column.dataKey]}
            </Typography>
          )}
          {column.dataKey === "total" && (
            <Typography
              align="right"
              variant="subtitle2"
              fontWeight={600}
              color="secPink.main"
            >
              {row[column.dataKey]?.toLocaleString()}
            </Typography>
          )}
        </TableCell>
      ))}
    </React.Fragment>
  );
};

const VirtuosoTableComponents: TableComponents<Data> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer
      sx={{ boxShadow: "none", borderRadius: 0 }}
      {...props}
      ref={ref}
    />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

export default MemberProvince;
