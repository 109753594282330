import { Box, Typography, Container, Button } from "@mui/material";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { boxStyle, containerStyle, pageStyle } from "../../../consts/styles";
import { getNewsByIdService } from "../../../services/news.service";
import dayjs from "dayjs";

const NewsDescription = () => {
  const { id } = useParams();

  const [news, setNews] = useState<any>(null);

  const getNewsById = () => {
    getNewsByIdService(id).then((resp) => {
      setNews(resp.data);
    });
  };

  useEffect(getNewsById, [id]);

  return (
    <Box sx={pageStyle}>
      <Typography
        align="center"
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="ktgGreen.main"
        zIndex={100}
      >
        ข่าวสารกรมอนามัย
      </Typography>

      <Container
        component="main"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        maxWidth={false}
      >
        <Box sx={{ ...boxStyle, padding: "8px", gap: "8px" }}>
          <Box
            component="img"
            width={1}
            borderRadius="10px"
            src={news?.image_url}
          />

          <Typography typography="body2" color="primary">
            {dayjs(news?.news_date).locale("th").format("D MMM BBBB")}
          </Typography>

          <Typography
            typography="subtitle2"
            fontWeight="600 !important"
            color="black.main"
          >
            {news?.title}
          </Typography>

          <Typography typography="body1" color="black.main">
            {news?.detail}
          </Typography>

          <Typography typography="body1" color="black.main" marginTop="32px">
            อ้างอิง:
          </Typography>

          <Typography typography="body1" color="black.main">
            {news?.reference}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center">
          <Button
            sx={{ width: 300 }}
            variant="contained"
            color="blue"
            size="large"
            component={RouterLink}
            to="/news"
          >
            <Typography color="white">กลับหน้าข่าวสาร</Typography>
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default NewsDescription;
