import { Box, Typography, Button } from "@mui/material";

const AssessmentSuccessModal = ({ handleClose }: any) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="32px"
      alignItems="center"
      gap="16px"
    >
      <Typography align="center" typography="h4" color="blue.main">
        คุณผ่านการประเมิน
      </Typography>

      <Box component="img" src="/course/passed.png" width={150} height={150} />

      <Box>
        <Typography
          align="center"
          typography="subtitle2"
          fontWeight={600}
          color="black.main"
        >
          สามารถดาวน์โหลด
        </Typography>

        <Typography
          align="center"
          typography="subtitle2"
          fontWeight={600}
          color="black.main"
        >
          ใบประกาศได้เลยค่ะ
        </Typography>
      </Box>

      <Button fullWidth color="blue" variant="contained" onClick={handleClose}>
        <Typography typography="subtitle2" color="white">
          ตกลง
        </Typography>
      </Button>
    </Box>
  );
};

export default AssessmentSuccessModal;
