export const configEnv = Object.freeze({
  modal_close: process.env.REACT_APP_MODAL_CLOSE
    ? parseInt(process.env.REACT_APP_MODAL_CLOSE)
    : 3000,
  admin_url: process.env.REACT_APP_ADMIN_URL,
  content_security_policy: process.env.REACT_APP_CONTENT_SECURITY_POLICY,
  app_domain: process.env.REACT_APP_DOMAIN || "",
  interval_count_notification: process.env.INTERVAL_COUNT_NOTIFICATION
    ? parseInt(process.env.INTERVAL_COUNT_NOTIFICATION)
    : 3600000,
  season_hp_cond: process.env.SEASON_HP_COND
    ? parseInt(process.env.SEASON_HP_COND)
    : 100,
  firebaseConfig: {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  },
  thaid_url: process.env.REACT_APP_THAID_URL,
  thaid_client_id: process.env.REACT_APP_THAID_CLIENT_ID,
  thaid_callback_url: process.env.REACT_APP_THAID_CALLBACK_URL,
});
