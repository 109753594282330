import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Modal,
  Pagination,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from "@mui/icons-material/Close";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../features/userStore/hook";
import {
  getHealthPoint,
  getProfile,
  setHealthPoint,
} from "../../../features/userStore/libs/profileSlice";
import {
  reserveRewardService,
  rewardPublishService,
} from "../../../services/reward.service";
import { configEnv } from "../../../configs/config-env";
import VerifiedRedeemModal from "../../../components/modals/profile/VerifiedRedeemModal";
import { modalStyle } from "../../../consts/styles";
import SuccessModal from "../../../components/modals/SuccessModal";

const Reward = () => {
  const dispatch = useAppDispatch();
  const healthPoint = useAppSelector(getHealthPoint);
  const navigate = useNavigate();
  const profile = useAppSelector(getProfile);

  const [openSuccess, setSuccesOpen] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [openVerified, setVerifiedOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [size] = useState(4);
  const [rewards, setRewards] = useState<any>([]);
  const [reward, setReward] = useState<any>();
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleSuccessOpen = () => setSuccesOpen(true);
  const handleSuccessClose = () => {
    setSuccessMessage("");
    setSuccesOpen(false);
  };
  const handleVerifiedOpen = () => setVerifiedOpen(true);
  const handleVerifiedClose = (updated = false) => {
    if (updated) {
      setSuccessMessage("ยืนยันตัวตนสำเร็จแล้ว");
      setTimeout(() => {
        handleSuccessClose();
      }, configEnv.modal_close);
    }
    setVerifiedOpen(false);
  };
  const handleAlertOpen = (reward: any) => {
    if (!profile.self_verification) {
      handleVerifiedOpen();
    } else {
      setReward(reward);
      setOpenAlert(true);
    }
  };
  const handleAlertClose = () => {
    setReward(null);
    setOpenAlert(false);
  };

  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };

  const getRewardPublish = async () => {
    const response = await rewardPublishService({ page, size });
    setRewards(response.data.items);
    setCount(response.data.total_page);
  };

  const postReserveReward = async () => {
    try {
      setLoading(true);
      const response = await reserveRewardService({
        reward_id: reward.reward_id,
      });
      dispatch(setHealthPoint(response.data.remain_health_point));
      handleAlertClose();
      setSuccessMessage("ยืนยันแลกสิทธิ์ของรางวัล สำเร็จ");
      setTimeout(() => {
        handleSuccessClose();
      }, configEnv.modal_close);
      getRewardPublish();
      setLoading(false);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      handleAlertClose();
      setErrorMessage(resMessage);
      setLoading(false);
    }
  };

  const navigateToRewardDescription = (reward: any) => {
    navigate("/reward/description", {
      state: {
        reward,
      },
      replace: true,
    });
  };

  useEffect(() => {
    getRewardPublish();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) handleSuccessOpen();
  }, [successMessage]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      gap="24px"
      pt="50px"
      pb="100px"
    >
      <Typography
        align="center"
        typography={{ xs: "h3", sm: "h1" }}
        color="ktgGreen.main"
        zIndex={1}
      >
        แลกลุ้นรางวัล
      </Typography>

      <Container
        component="main"
        sx={{
          zIndex: 1,
          maxWidth: 600,
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
        maxWidth={false}
      >
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="10px"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background: "#FFFFFF",
            textDecoration: "none",
          }}
          padding="16px"
          gap="8px"
          component={RouterLink}
          to="/reward-point"
        >
          <Typography
            align="center"
            typography={{ xs: "subtitle2", sm: "subtitle1" }}
            color="red"
          >
            คุณมี
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="8px"
          >
            <Box
              component="img"
              sx={{
                width: { xs: 50, md: 60 },
                height: { xs: 50, md: 60 },
              }}
              alt="hp coin"
              src="/icons/hp_coin.png"
            ></Box>

            <Typography variant="h1" color="red">
              {healthPoint}
            </Typography>

            <Typography alignSelf="center" variant="body1" color="red">
              แต้มสุขภาพ
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          borderRadius="10px"
          alignItems="center"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.855) 0%, rgba(255, 255, 255, 0.76) 100%)",
          }}
          padding={{ xs: "16px 8px", sm: "24px" }}
          gap="16px"
        >
          <Typography
            alignSelf="center"
            typography={{ xs: "h4", sm: "h3" }}
            color="black"
          >
            แลกสิทธิ์ลุ้นของรางวัล
          </Typography>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {rewards.map((reward: any, index: number) => (
              <Grid key={index} item xs={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  sx={{
                    background: "#FFFFFF",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                    borderRadius: "10px",
                  }}
                  padding={{ xs: "12px 8px", sm: "20px 16px" }}
                  gap={{ xs: "4px", sm: "8px" }}
                  height={1}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={{ xs: "4px", sm: "8px" }}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigateToRewardDescription(reward)}
                  >
                    <Box component="img" src={reward.image_url} width={1}></Box>

                    <Box
                      display="flex"
                      sx={{
                        background: "#F5F7FA",
                        borderRadius: "10px",
                      }}
                      padding={{ xs: "4px", sm: "8px" }}
                      justifyContent="space-around"
                      flexDirection="column"
                      gap="4px"
                    >
                      <Box
                        display="flex"
                        gap={{ xs: "2px", sm: "8px" }}
                        alignSelf="center"
                      >
                        <Box
                          component="img"
                          sx={{
                            width: { xs: 16, sm: 24 },
                            height: { xs: 16, sm: 24 },
                          }}
                          alt="star icon"
                          src="/icons/hp_coin.png"
                          alignSelf="center"
                        />

                        <Typography
                          alignSelf="center"
                          sx={{
                            fontSize: { xs: "14px", sm: "20px" },
                            fontWeight: "600",
                          }}
                          color="red"
                        >
                          {reward.used_point.toLocaleString()}
                        </Typography>
                      </Box>

                      <Box
                        display="flex"
                        gap={{ xs: "4px", sm: "8px" }}
                        justifyContent="center"
                      >
                        <Box display="flex" gap="4px">
                          <Typography
                            alignSelf="center"
                            sx={{ fontSize: { xs: "12px", sm: "20px" } }}
                            color="red"
                          >
                            {reward.remain_qty.toLocaleString()} /
                          </Typography>

                          <Typography
                            alignSelf="center"
                            sx={{ fontSize: { xs: "10px", sm: "18px" } }}
                            color="red"
                          >
                            {reward.quantity.toLocaleString()}
                          </Typography>

                          <Typography
                            alignSelf="center"
                            sx={{ fontSize: { xs: "10px", sm: "18px" } }}
                            color="primary"
                          >
                            สิทธิ์
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Typography
                      typography={{ sx: "body1", sm: "h4" }}
                      fontWeight={600}
                      color="common.black"
                    >
                      {reward.title}
                    </Typography>
                  </Box>

                  <Button
                    color="green"
                    variant="contained"
                    disabled={
                      healthPoint < reward.used_point ||
                      reward.remain_qty <= 0 ||
                      reward.is_full_reserved
                    }
                    onClick={() => handleAlertOpen(reward)}
                  >
                    <Typography
                      typography={{ sx: "body2", sm: "subtitle2" }}
                      color="white"
                    >
                      แลกรางวัล
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Pagination
            count={count}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            color="blue"
          />
        </Box>

        <Button
          color="blue"
          variant="contained"
          component={RouterLink}
          to="/redemption-history"
        >
          <Typography variant="subtitle2" color="white">
            ประวัติการแลกของรางวัล
          </Typography>
        </Button>
      </Container>

      <Modal keepMounted open={openAlert} onClose={handleAlertClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340,
            bgcolor: "background.paper",
            borderRadius: "10px",
          }}
          display="flex"
          flexDirection="column"
          gap="8px"
        >
          <Box sx={{ textAlign: "end", p: 1 }}>
            <Typography
              onClick={handleAlertClose}
              component="span"
              sx={{ cursor: "pointer" }}
              color="cgrey.main"
            >
              <CloseIcon sx={{ fontSize: 20 }} />
            </Typography>
          </Box>

          <Box
            sx={{ px: 4, pb: 4 }}
            display="flex"
            flexDirection="column"
            gap="8px"
          >
            <Typography align="center" variant="h4" color="green.main">
              {`ยืนยันแลกสิทธิ์ของรางวัล ${reward?.title} โดยใช้แต้ม ${reward?.used_point} ?`}
            </Typography>

            <Button
              color="green"
              variant="contained"
              type="submit"
              fullWidth
              onClick={postReserveReward}
              disabled={loading}
            >
              <Typography variant="subtitle2" color="white">
                ยืนยัน
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal keepMounted open={openSuccess} onClose={handleSuccessClose}>
        <Box sx={modalStyle} width={340}>
          <SuccessModal
            message={successMessage}
            handleSuccessClose={handleSuccessClose}
            showButton={false}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340,
            bgcolor: "background.paper",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ textAlign: "end", p: 1 }}>
            <Typography
              onClick={handleErrorClose}
              component="span"
              sx={{ cursor: "pointer" }}
              color="cgrey.main"
            >
              <CloseIcon sx={{ fontSize: 20 }} />
            </Typography>
          </Box>

          <Box sx={{ px: 4, pb: 4 }}>
            <Typography align="center">
              <HighlightOffIcon sx={{ fontSize: 60, color: "#EB698F" }} />
            </Typography>
            <Typography align="center" variant="h4" color="#EB698F" mt="15px">
              {errorMessage}
            </Typography>
          </Box>
        </Box>
      </Modal>

      <Dialog
        keepMounted
        open={openVerified}
        onClose={() => handleVerifiedClose(false)}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        scroll="body"
      >
        <Box
          display="flex"
          width={1}
          onClick={() => handleVerifiedClose(false)}
          justifyContent="end"
          padding="8px 8px 0 8px"
        >
          <CancelOutlinedIcon
            sx={{ cursor: "pointer", color: "lightGrey.main" }}
          />
        </Box>
        <DialogContent sx={{ width: 300 }}>
          {openVerified && (
            <VerifiedRedeemModal handleClose={handleVerifiedClose} />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Reward;
