import { Box, Button, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage } from "react-intl";

const SuccessModal = ({ message, handleSuccessClose, showButton }: any) => {
  return (
    <Box display="flex" flexDirection="column" padding="16px" gap="4px">
      <Box
        component="a"
        alignSelf="end"
        sx={{ cursor: "pointer" }}
        onClick={handleSuccessClose}
      >
        <CloseIcon sx={{ fontSize: 20 }} />
      </Box>

      <Box display="flex" flexDirection="column">
        <Typography align="center">
          <CheckCircleOutlineIcon sx={{ fontSize: 60, color: "#1DA64A" }} />
        </Typography>

        <Typography align="center" variant="h5" color="primary">
          {message}
        </Typography>
      </Box>

      {showButton && (
        <Button
          color="green"
          variant="contained"
          type="button"
          onClick={handleSuccessClose}
        >
          <Typography variant="subtitle2" color="white">
            <FormattedMessage id="common.ok" />
          </Typography>
        </Button>
      )}
    </Box>
  );
};

export default SuccessModal;
