import { Button, Divider, Modal, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouterLink } from "react-router-dom";
import {
  getProfile,
  getExperienceLevel,
} from "../../../features/userStore/libs/profileSlice";
import { useAppSelector } from "../../../features/userStore/hook";
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import QrcodeInviteModal from "../../modals/community/QrcodeInviteModal";
import { modalStyle } from "../../../consts/styles";

const StatusInfo = () => {
  // states
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const [bmi, setBmi] = useState<any>(0);
  const [status, setStatus] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  // end states

  // handle
  const handleInviteModalOpen = () => setOpenInviteModal(true);
  const handleInviteModalClose = () => setOpenInviteModal(false);
  // end handle

  const profile = useAppSelector(getProfile);
  const experienceLevel = useAppSelector(getExperienceLevel);

  useEffect(() => {
    if (profile?.weight)
      setBmi(
        (
          (profile?.weight / (profile?.height * profile?.height)) *
          10000
        ).toFixed(2)
      );

    if (bmi) {
      if (bmi < 18.5) {
        setStatus("ผอม");
      } else if (bmi >= 18.5 && bmi < 23.0) {
        setStatus("ปกติ");
      } else if (bmi >= 23.0 && bmi < 25.0) {
        setStatus("ท้วม");
      } else if (bmi >= 25.0) {
        setStatus("อ้วน");
      }
      setLoading(false);
    }
  }, [bmi, profile]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container
        component="main"
        maxWidth={false}
        sx={{ zIndex: 1000, maxWidth: "1120px" }}
      >
        <Box
          sx={{
            display: "flex",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
            padding: { xs: "15px", sm: "36px 30px", lg: "36px 72px" },
            borderRadius: "10px",
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.855) 0%, rgba(255, 255, 255, 0.76) 100%)",
            justifyContent: { md: "space-between" },
            gap: { xs: "25px", md: 0 },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {!loading && (
            <Box
              sx={{
                display: "flex",
                gap: { xs: "10px", sm: "30px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textDecoration: "none",
                  gap: "8px",
                }}
                component={RouterLink}
                to="/experience"
              >
                <Box
                  component="img"
                  sx={{
                    width: { xs: 90, sm: 100 },
                    height: { xs: 90, sm: 100 },
                  }}
                  alt="level"
                  src={`/levels/Artboard – ${experienceLevel}.png`}
                />
                <Typography align="center" variant="h5" color="common.black">
                  <FormattedMessage id="exercise.level" />{" "}
                  <Box component="text" color="secGreen.main">
                    {experienceLevel}
                  </Box>
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{ typography: { xs: "h4", sm: "h2" } }}
                  color="common.black"
                >
                  {profile?.first_name} {profile?.last_name}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    gap: { xs: "4px", sm: "8px" },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "16px" }}
                    variant="h5"
                    color="common.black"
                  >
                    <FormattedMessage id="statusInfo.ebib" />:
                  </Typography>

                  <Typography
                    sx={{ fontSize: "16px" }}
                    variant="h5"
                    fontWeight={400}
                    color="common.black"
                  >
                    {profile?.member_id}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      textDecoration: "none",
                    }}
                    component={RouterLink}
                    to="/rank"
                  >
                    <Typography
                      sx={{ typography: { xs: "body2", sm: "body1" } }}
                      color="lightGrey.main"
                    >
                      <FormattedMessage id="statusInfo.rank" />
                    </Typography>

                    <Typography
                      sx={{ typography: { xs: "h5", sm: "h4" } }}
                      color="ktjBlue.main"
                    >
                      {profile?.rank}
                    </Typography>
                  </Box>

                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ color: "#717171", borderRightWidth: 1 }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      textDecoration: "none",
                    }}
                    component={RouterLink}
                    to="/bmi"
                  >
                    <Typography
                      sx={{ typography: { xs: "body2", sm: "body1" } }}
                      color="lightGrey.main"
                    >
                      <FormattedMessage id="statusInfo.bmi" />
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{ typography: { xs: "h5", sm: "h4" } }}
                        color="secPink.main"
                      >
                        {bmi}
                      </Typography>

                      <Typography
                        alignSelf="center"
                        variant="body1"
                        color="lightGrey.main"
                      >
                        = {status}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <Box sx={{ display: "flex", gap: "15px", alignSelf: "center" }}>
            <Button
              color="blue"
              variant="contained"
              sx={{ width: { xs: "147px", sm: "180px", lg: "200px" } }}
              startIcon={<PeopleAltOutlinedIcon />}
              component={RouterLink}
              to="/community"
            >
              <Typography variant="subtitle2" color="white">
                <FormattedMessage id="statusInfo.group" />
              </Typography>
            </Button>

            <Button
              color="green"
              variant="contained"
              sx={{ width: { xs: "147px", sm: "180px", lg: "200px" } }}
              startIcon={<PersonAddAltOutlinedIcon sx={{ color: "white" }} />}
              onClick={handleInviteModalOpen}
            >
              <Typography variant="subtitle2" color="white">
                <FormattedMessage id="statusInfo.invite" />
              </Typography>
            </Button>
          </Box>
        </Box>
      </Container>

      <Modal
        keepMounted
        open={openInviteModal}
        onClose={handleInviteModalClose}
      >
        <Box sx={modalStyle} width="340px">
          <QrcodeInviteModal />
        </Box>
      </Modal>
    </Box>
  );
};

export default StatusInfo;
