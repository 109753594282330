import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Divider,
  Grid,
  Dialog,
  Button,
  Modal,
  Tooltip,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { FormattedMessage } from "react-intl";
import dayjs from "dayjs";
import {
  boxStyle,
  containerStyle,
  modalStyle,
  pageStyle,
} from "../../../consts/styles";
import { communityTypes } from "../../../consts/app";
import {
  deleteCommunityMemberService,
  getCommunityByIdService,
} from "../../../services/community.service";
import CreateEventModal from "../../../components/modals/community/CreateEventModal";
import EditEventModal from "../../../components/modals/community/EditEventModal";
import CreateNewsModal from "../../../components/modals/community/CreateNewsModal";
import EditNewsModal from "../../../components/modals/community/EditNewsModal";
import CopyToClipboard from "react-copy-to-clipboard";
import { useAppSelector } from "../../../features/userStore/hook";
import { getProfile } from "../../../features/userStore/libs/profileSlice";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import SuccessModal from "../../../components/modals/SuccessModal";
import ErrorModal from "../../../components/modals/ErrorModal";
import EventModal from "../../../components/modals/community/EventModal";

const CommunityView = () => {
  const profile = useAppSelector(getProfile);

  const [communityId, setCommunityId] = useState<number | null>(null);
  const [event, setEvent] = useState<any | null>(null);
  const [isAdminGroup, setIsAdminGroup] = useState<boolean>(false);
  const [community, setCommunity] = useState<any | null>(null);
  const [eventData, setEventData] = useState<any | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  // state modal
  const [openCreateEvent, setCreateEventOpen] = useState<boolean>(false);
  const [openEditEvent, setEditEventOpen] = useState<boolean>(false);
  const [openCreateNews, setCreateNewsOpen] = useState<boolean>(false);
  const [openEditNews, setEditNewsOpen] = useState<boolean>(false);
  const [openEventView, setEventViewOpen] = useState<boolean>(false);
  const [openWarning, setWarningOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [openSuccess, setSuccessOpen] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);

  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => {
    setSuccessOpen(false);
    navigate("/community");
  };
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => setErrorOpen(false);
  const handleCreateNewsOpen = () => setCreateNewsOpen(true);
  const handleCreateNewsClose = () => setCreateNewsOpen(false);
  const handleCreatedNewsClose = () => {
    getCommunity();
    handleCreateNewsClose();
  };
  const handleEditNewsOpen = () => setEditNewsOpen(true);
  const handleEditNewsClose = () => setEditNewsOpen(false);
  const handleEditedNewsClose = () => {
    getCommunity();
    handleEditNewsClose();
  };
  const handleCreateEventOpen = () => setCreateEventOpen(true);
  const handleCreateEventClose = () => setCreateEventOpen(false);
  const handleCreatedEventClose = () => {
    getCommunity();
    handleCreateEventClose();
  };
  const handleEditEventOpen = (event: any) => {
    setEventData(event);
    setEditEventOpen(true);
  };
  const handleEditEventClose = () => {
    setEventData(null);
    setEditEventOpen(false);
  };
  const handleEditedEventClose = () => {
    getCommunity();
    handleEditEventClose();
  };
  const handleWarningOpen = () => setWarningOpen(true);
  const handleWarningClose = () => setWarningOpen(false);
  const handleEventViewOpen = (event: any) => {
    setEvent(event);
    setEventViewOpen(true);
  };
  const handleEventViewClose = () => {
    setEvent(null);
    setEventViewOpen(false);
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  const getCommunity = () => {
    if (communityId && profile.member_id)
      getCommunityByIdService(communityId).then((resp) => {
        setCommunity(resp.data);
        setIsAdminGroup(profile.member_id === resp.data.member_id);
      });
  };

  const navigateToMemberRankPage = () => {
    navigate("/community/member-rank", {
      state: { communityId },
      replace: true,
    });
  };

  const navigateToInviteMemberPage = () => {
    navigate("/community/member-invite", {
      state: { communityId },
      replace: true,
    });
  };

  const onLeaveGroup = async () => {
    try {
      handleLoading();
      handleWarningClose();
      await deleteCommunityMemberService(
        community.community_id,
        profile.member_id
      );
      handleEditedNewsClose();
      handleSuccessOpen();
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  useEffect(() => {
    if (!location.state) {
      navigate("/community");
      return;
    }
    setCommunityId(location.state.id);
  }, [location, navigate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getCommunity, [communityId, profile.member_id]);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  return (
    <Box sx={pageStyle}>
      <Typography
        align="center"
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="ktgGreen.main"
        zIndex={100}
      >
        {communityTypes[community?.type_id - 1]}
      </Typography>

      <Container
        component="main"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        maxWidth={false}
      >
        <Box sx={{ ...boxStyle, padding: "24px" }}>
          <Box
            display="flex"
            gap="24px"
            justifyContent="center"
            alignItems="center"
          >
            <Box display="flex" flexDirection="column" gap="8px">
              <Box
                component="img"
                borderRadius={100}
                boxShadow="0px 0px 10px rgba(0, 0, 0, 0.15)"
                width={100}
                height={100}
                src={community?.image_url || "/images/community-default.png"}
              />

              {isAdminGroup && (
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{ cursor: "pointer", textDecoration: "none" }}
                  gap="8px"
                  component={RouterLink}
                  to="/community/edit"
                  state={{ communityId, isAdminGroup }}
                >
                  <Box
                    component="img"
                    width={16}
                    height={16}
                    src="/icons/edit-1.png"
                  />

                  <Typography
                    align="center"
                    typography="body2"
                    color="blue.main"
                  >
                    <FormattedMessage id="community.edit" />
                  </Typography>
                </Box>
              )}
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              gap="12px"
              paddingY="16px"
              width={80}
              sx={{
                background: "#F5F7FA",
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                borderRadius: "100px",
              }}
            >
              <Typography align="center" typography="body2" color="cgrey.main">
                <FormattedMessage id="community.member" />
              </Typography>

              <Box
                alignSelf="center"
                component="img"
                width={16}
                height={21}
                src="/icons/member-in-group.png"
              />

              <Typography align="center" typography="body2" color="cgrey.main">
                <FormattedMessage id="community.rank" />
              </Typography>

              <Typography align="center" typography="h5" color="secYellow.main">
                {community?.member_rank}
              </Typography>
            </Box>

            <Tooltip title="อัปเดตอันดับทุกเที่ยงคืน">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                gap="12px"
                paddingY="16px"
                width={80}
                sx={{
                  background: "#EB698F",
                  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                  borderRadius: "100px",
                }}
              >
                <Typography align="center" typography="body2" color="white">
                  <FormattedMessage id="community.rankExp" />
                </Typography>

                <Box
                  alignSelf="center"
                  component="img"
                  width={16}
                  height={21}
                  src="/icons/rank.png"
                />

                <Typography align="center" typography="body2" color="white">
                  <FormattedMessage id="community.rank" />
                </Typography>

                <Typography align="center" typography="h5" color="white">
                  {community?.experience_point_rank}
                </Typography>
              </Box>
            </Tooltip>
          </Box>

          <Divider />

          <Box display="flex" flexDirection="column" gap="16px">
            <Grid container spacing={2}>
              <Grid item xs={4} sm={2}>
                <Typography
                  typography="body1"
                  color="black.main"
                  fontWeight={600}
                >
                  ชื่อ:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={10} sx={{ overflowWrap: "anywhere" }}>
                <Typography typography="body1" color="cgrey.main">
                  {community?.community_name}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4} sm={2}>
                <Typography
                  typography="body1"
                  color="black.main"
                  fontWeight={600}
                >
                  รหัสกลุ่ม:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={10} sx={{ overflowWrap: "anywhere" }}>
                <Box display="flex" justifyContent="space-between">
                  <Typography typography="body1" color="cgrey.main">
                    {community?.community_code}
                  </Typography>

                  <CopyToClipboard text={community?.community_code}>
                    <Box
                      component="img"
                      width={24}
                      height={24}
                      src="/icons/clipboard.png"
                      sx={{ cursor: "pointer" }}
                    />
                  </CopyToClipboard>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4} sm={2}>
                <Typography
                  typography="body1"
                  color="black.main"
                  fontWeight={600}
                >
                  ผู้ดูแล:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={10} sx={{ overflowWrap: "anywhere" }}>
                <Typography typography="body1" color="cgrey.main">
                  {community?.member_first_name} {community?.member_last_name}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4} sm={2}>
                <Typography
                  typography="body1"
                  color="black.main"
                  fontWeight={600}
                >
                  สมาชิก:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={10} sx={{ overflowWrap: "anywhere" }}>
                <Typography typography="body1" color="cgrey.main">
                  {community?.total} คน
                </Typography>
              </Grid>
            </Grid>

            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs={4} sm={2}>
                <Typography
                  typography="body1"
                  color="black.main"
                  fontWeight={600}
                >
                  ติดต่อ:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={10} sx={{ overflowWrap: "anywhere" }}>
                <Typography typography="body1" color="cgrey.main">
                  {community?.contact_info}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4} sm={2}>
                <Typography
                  typography="body1"
                  color="black.main"
                  fontWeight={600}
                >
                  คิดอธิบาย:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={10} sx={{ overflowWrap: "anywhere" }}>
                <Typography typography="body1" color="cgrey.main">
                  {community?.community_desc}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box sx={{ ...boxStyle, padding: "24px" }} alignItems="center">
          <Typography typography="h5" color="common.black">
            ข้อมูลการออกกำลังกาย
          </Typography>
          <Box display="flex" flexDirection="column" gap="16px">
            <Box display="flex" gap="16px">
              <Box
                alignSelf="center"
                component="img"
                width={30}
                height={30}
                src="/icons/vector_4.png"
              />

              <Box display="flex" flexDirection="column" gap="8px">
                <Typography typography="body2" color="softBlack.main">
                  ระยะทาง
                </Typography>

                <Box display="flex" gap="8px">
                  <Typography align="center" typography="h2" color="green.main">
                    {community?.distances?.toLocaleString()}
                  </Typography>

                  <Typography
                    alignSelf="center"
                    typography="body2"
                    color="lightGrey.main"
                  >
                    <FormattedMessage id="exercise.kilometer" />
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box display="flex" gap="16px">
              <Box
                alignSelf="center"
                component="img"
                width={24}
                height={28}
                src="/icons/vector_5.png"
              />

              <Box display="flex" flexDirection="column" gap="8px">
                <Typography typography="body2" color="softBlack.main">
                  ระยะเวลา
                </Typography>

                <Box display="flex" gap="8px">
                  <Typography align="center" typography="h2" color="blue.main">
                    {community?.minutes?.toLocaleString()}
                  </Typography>

                  <Typography
                    alignSelf="center"
                    typography="body2"
                    color="lightGrey.main"
                  >
                    <FormattedMessage id="exercise.minutes" />
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box display="flex" gap="16px">
              <Box
                alignSelf="center"
                component="img"
                width={24}
                height={29}
                src="/icons/vector_6.png"
              />

              <Box display="flex" flexDirection="column" gap="8px">
                <Typography typography="body2" color="softBlack.main">
                  การเผาผลาญพลังงาน
                </Typography>

                <Box display="flex" gap="8px">
                  <Typography
                    align="center"
                    typography="h2"
                    color="secPink.main"
                  >
                    {community?.calories?.toLocaleString()}
                  </Typography>

                  <Typography
                    alignSelf="center"
                    typography="body2"
                    color="lightGrey.main"
                  >
                    <FormattedMessage id="exercise.calories" />
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box display="flex" gap="16px">
              <Box
                alignSelf="center"
                component="img"
                width={27}
                height={25}
                src="/icons/vector_7.png"
              />

              <Box display="flex" flexDirection="column" gap="8px">
                <Typography typography="body2" color="softBlack.main">
                  ค่าประสบการณ์รวม
                </Typography>

                <Box display="flex" gap="8px">
                  <Typography
                    align="center"
                    typography="h2"
                    color="secYellow.main"
                  >
                    {community?.experience_point?.toLocaleString()}
                  </Typography>

                  <Typography
                    alignSelf="center"
                    typography="body2"
                    color="lightGrey.main"
                  >
                    <FormattedMessage id="exercise.exp" />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ ...boxStyle, padding: "24px" }}>
          <Box display="flex" justifyContent="space-between">
            <Typography typography="h5" color="black.main">
              ข่าวสาร
            </Typography>

            {!community?.announcement_info && isAdminGroup && (
              <Box component="a" onClick={handleCreateNewsOpen}>
                <AddCircleIcon
                  sx={{
                    fontSize: 26,
                    color: "secYellow.main",
                    cursor: "pointer",
                  }}
                />
              </Box>
            )}
          </Box>

          <Divider />

          {community?.announcement_info && (
            <Box display="flex" justifyContent="space-between" gap="8px">
              <Box display="flex" gap="8px">
                <Box width="auto">
                  <Box
                    display="flex"
                    width={40}
                    height={40}
                    sx={{ background: "#EB698F", borderRadius: "100px" }}
                    justifyContent="center"
                  >
                    <Box
                      alignSelf="center"
                      component="img"
                      width={20}
                      height={16}
                      src="/icons/vector_9.png"
                    />
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column">
                  <Typography typography="body1" color="lightGrey.main">
                    {dayjs(community.announcement_date)
                      .locale("th")
                      .format("D MMM BBBB")}
                  </Typography>

                  <Typography
                    typography="subtitle2"
                    fontWeight={600}
                    color="black.main"
                    sx={{ wordBreak: "break-word" }}
                  >
                    {community.announcement_info}
                  </Typography>
                </Box>
              </Box>
              {isAdminGroup && (
                <Box
                  alignSelf="center"
                  component="img"
                  width={20}
                  height={20}
                  sx={{ cursor: "pointer" }}
                  src="/icons/vector_8.png"
                  onClick={handleEditNewsOpen}
                />
              )}
            </Box>
          )}

          {!community?.announcement_info && (
            <Typography
              align="center"
              typography="body2"
              color="lightGrey.main"
            >
              ไม่มีข่าวสาร
            </Typography>
          )}
        </Box>

        <Box sx={{ ...boxStyle, padding: "24px" }}>
          <Box display="flex" justifyContent="space-between">
            <Typography typography="h5" color="black.main">
              กิจกรรม
            </Typography>

            {isAdminGroup && (
              <Box component="a" onClick={handleCreateEventOpen}>
                <AddCircleIcon
                  sx={{
                    fontSize: 26,
                    color: "secYellow.main",
                    cursor: "pointer",
                  }}
                />
              </Box>
            )}
          </Box>

          <Divider />

          {community?.events.map((event: any, index: any) => (
            <Box
              key={index}
              display="flex"
              justifyContent="space-between"
              gap="8px"
            >
              <Box display="flex" gap="8px">
                <Box width="auto">
                  <Box
                    display="flex"
                    width={40}
                    height={40}
                    sx={{ background: "#0892F5", borderRadius: "100px" }}
                    justifyContent="center"
                  >
                    <Box
                      alignSelf="center"
                      component="img"
                      width={20}
                      height={20}
                      src="/icons/vector_10.png"
                    />
                  </Box>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleEventViewOpen(event)}
                >
                  <Typography typography="body1" color="lightGrey.main">
                    {dayjs(event.start_date).locale("th").format("D MMM")} -{" "}
                    {dayjs(event.end_date).locale("th").format("D MMM BBBB")}
                  </Typography>

                  <Typography
                    typography="subtitle2"
                    fontWeight={600}
                    color="black.main"
                    sx={{ wordBreak: "break-word" }}
                  >
                    {event.event}
                  </Typography>
                </Box>
              </Box>

              {isAdminGroup && (
                <Box
                  alignSelf="center"
                  component="img"
                  width={20}
                  height={20}
                  sx={{ cursor: "pointer" }}
                  src="/icons/vector_8.png"
                  onClick={() => handleEditEventOpen(event)}
                />
              )}
            </Box>
          ))}

          {!community?.events.length && (
            <Typography
              align="center"
              typography="body2"
              color="lightGrey.main"
            >
              ไม่มีกิจกรรม
            </Typography>
          )}
        </Box>

        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          gap={{ xs: "16px", sm: "32px" }}
        >
          <Button
            fullWidth
            color="blue"
            variant="contained"
            onClick={navigateToMemberRankPage}
          >
            <Typography typography="subtitle2" color="white">
              อันดับสมาชิก
            </Typography>
          </Button>

          {isAdminGroup && (
            <Button
              fullWidth
              color="green"
              variant="contained"
              onClick={navigateToInviteMemberPage}
            >
              <Typography typography="subtitle2" color="white">
                เชิญเพื่อนเข้ากลุ่ม
              </Typography>
            </Button>
          )}

          {!isAdminGroup && (
            <Button
              fullWidth
              color="cwhite"
              variant="contained"
              disabled={loading}
              onClick={handleWarningOpen}
            >
              <Typography typography="subtitle2" color="black.main">
                ออกจากกลุ่ม
              </Typography>
            </Button>
          )}
        </Box>

        <Button
          fullWidth
          color="cwhite"
          variant="contained"
          component={RouterLink}
          sx={{ boxShadow: "none" }}
          to="/community"
        >
          <Typography typography="subtitle2" color="black.main">
            กลับ
          </Typography>
        </Button>
      </Container>

      <Dialog
        keepMounted
        open={openCreateEvent}
        onClose={handleCreateEventClose}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        scroll="body"
      >
        {openCreateEvent && (
          <CreateEventModal
            communityId={community?.community_id}
            handleCreateEventClose={handleCreateEventClose}
            handleCreatedEventClose={handleCreatedEventClose}
          />
        )}
      </Dialog>

      <Dialog
        keepMounted
        open={openEditEvent}
        onClose={handleCreateEventClose}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        scroll="body"
      >
        {openEditEvent && (
          <EditEventModal
            eventData={eventData}
            handleEditEventClose={handleEditEventClose}
            handleEditedEventClose={handleEditedEventClose}
          />
        )}
      </Dialog>

      <Dialog
        keepMounted
        open={openCreateNews}
        onClose={handleCreateNewsClose}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        scroll="body"
      >
        {openCreateNews && (
          <CreateNewsModal
            communityId={communityId}
            handleCreateNewsClose={handleCreateNewsClose}
            handleCreatedNewsClose={handleCreatedNewsClose}
          />
        )}
      </Dialog>

      <Dialog
        keepMounted
        open={openEditNews}
        onClose={handleEditNewsClose}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        scroll="body"
      >
        {openEditNews && (
          <EditNewsModal
            community={community}
            handleEditeNewsClose={handleEditNewsClose}
            handleEditedNewsClose={handleEditedNewsClose}
          />
        )}
      </Dialog>

      <Modal keepMounted open={openSuccess}>
        <Box sx={modalStyle} width="340px">
          <SuccessModal
            message={"แก้ไขข้อมูลกลุ่มสำเร็จ"}
            handleSuccessClose={handleSuccessClose}
            showButton={true}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle} width="340px">
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
        <Box sx={modalStyle} width="340px">
          <ConfirmModal
            message={`<Typography align='center' color='black.main' typography='subtitle2'>คุณแน่ใจหรือไม่ ว่าจะออกจากกลุ่ม</Typography> <Typography align='center' color='black.main' typography='subtitle2'>“${community?.community_name}”</Typography>`}
            handleConfirm={onLeaveGroup}
            handleClose={handleWarningClose}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openEventView} onClose={handleEventViewClose}>
        <Box sx={modalStyle} width="340px">
          {event && (
            <EventModal event={event} handleClose={handleEventViewClose} />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default CommunityView;
