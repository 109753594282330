import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getLessonProgressService } from "../../../services/course.service";
import {
  boxStyle,
  containerStyle,
  modalStyle,
  pageStyle,
} from "../../../consts/styles";
import WarningModal from "../../../components/modals/WarningModal";
import {
  Box,
  Typography,
  Container,
  Button,
  Skeleton,
  Modal,
} from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import SuccessModal from "../../../components/modals/SuccessModal";
import { configEnv } from "../../../configs/config-env";
dayjs.extend(duration);

const LessonOverviewPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [courseId, setCourseId] = useState<number | null>(null);
  const [lessons, setLessons] = useState<any>([]);
  const [openSuccess, setSuccessOpen] = useState<boolean>(false);
  const [openWarnning, setWarnningOpen] = useState<boolean>(false);
  const [messageWarnning, setMessageWarnning] = useState<string>("");

  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => setSuccessOpen(false);

  const handleWarnningOpen = () => setWarnningOpen(true);
  const handleWarnningClose = () => {
    setMessageWarnning("");
    setWarnningOpen(false);
  };

  const getLessonProgress = () => {
    if (courseId)
      setTimeout(() => {
        getLessonProgressService(courseId).then((resp) => {
          setLessons(resp.data);
        });
      }, 500);
  };

  const navigateToLesson = (lesson: any) => {
    const date1 = dayjs();
    const date2 = dayjs(lesson.tested_date).add(lesson.gap_time, "hour");

    if (!lesson.is_passed && lesson.tested_date && date1.isBefore(date2)) {
      const duration = dayjs.duration(date2.diff(date1));

      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.asMinutes() % 60);

      setMessageWarnning(
        `เหลืออีก ${hours}:${minutes} ช.ม. จึงจะสามารถทำแบบทดสอบได้อีกครั้ง`
      );
      return;
    }

    if (lesson.is_passed) {
      handleSuccessOpen();
      setTimeout(() => {
        handleSuccessClose();
      }, configEnv.modal_close);
      return;
    }

    navigate("/course/lesson-quiz", {
      state: { courseId, lessonId: lesson.lesson_id, lesson },
    });
  };

  useEffect(() => {
    if (!location.state) {
      navigate("/home");
      return;
    }
    setCourseId(location.state.courseId);
  }, [location, navigate]);

  useEffect(getLessonProgress, [courseId]);

  useEffect(() => {
    if (messageWarnning) handleWarnningOpen();
  }, [messageWarnning]);

  return (
    <Box sx={pageStyle}>
      <Typography
        align="center"
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="ktgGreen.main"
        zIndex={1}
      >
        บทเรียน
      </Typography>

      <Container
        component="main"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        maxWidth={false}
      >
        {!lessons.length && (
          <>
            <Skeleton variant="rounded" width={"100%"} height={220} />

            <Skeleton variant="rounded" width={"100%"} height={220} />

            <Skeleton variant="rounded" width={"100%"} height={220} />
          </>
        )}
        {lessons.map((lesson: any, index: number) => (
          <Box key={index} sx={{ ...boxStyle, padding: "16px", gap: "16px" }}>
            <Typography align="center" typography="h4" color="black.main">
              {lesson.lesson_order === 0
                ? "บทนำ"
                : `บทที่ ${lesson.lesson_order}`}
            </Typography>

            <Typography
              align="center"
              typography="subtitle1"
              color="softBlack.main"
            >
              {lesson.title}
            </Typography>

            <Box display="flex" gap="8px">
              <Button
                fullWidth
                color="green"
                variant="contained"
                sx={{ boxShadow: "none" }}
                component={RouterLink}
                to="/course/lesson"
                state={{ lesson, courseId }}
              >
                <Typography typography="subtitle2" color="white">
                  เริ่มเรียน
                </Typography>
              </Button>
              {lesson?.passed_criteria > 0 && (
                <Button
                  fullWidth
                  color="blue"
                  variant="contained"
                  sx={{ boxShadow: "none" }}
                  disabled={
                    (lesson.learned_time || 0) < lesson.minimum_learning_time
                  }
                  onClick={() => navigateToLesson(lesson)}
                >
                  <Typography typography="subtitle2" color="white">
                    แบบทดสอบ
                  </Typography>
                </Button>
              )}
            </Box>

            <Box display="flex" gap="8px">
              <CheckCircleIcon
                color={
                  (lesson.learned_time || 0) >= lesson.minimum_learning_time
                    ? "green"
                    : "primary"
                }
              />

              <Typography typography="subtitle2" color="softBlack.main">
                <>
                  เวลาของบทเรียน {Math.floor(lesson.learning_time / 60)}:
                  {lesson.learning_time % 60 < 10
                    ? `0${lesson.learning_time % 60}`
                    : lesson.learning_time % 60}{" "}
                  นาที | เรียนอย่างน้อย{" "}
                  {Math.floor(lesson.minimum_learning_time / 60)}:
                  {lesson.minimum_learning_time % 60 < 10
                    ? `0${lesson.minimum_learning_time % 60}`
                    : lesson.minimum_learning_time % 60}{" "}
                  นาที
                </>
              </Typography>
            </Box>

            {lesson.passed_criteria > 0 && (
              <Box display="flex" gap="8px">
                <CheckCircleIcon
                  color={lesson.is_passed ? "green" : "primary"}
                />

                <Typography typography="subtitle2" color="softBlack.main">
                  ผ่านแบบทดสอบบทที่ {lesson.lesson_order}
                </Typography>
              </Box>
            )}
          </Box>
        ))}

        <Button
          fullWidth
          color="blue"
          variant="contained"
          sx={{ boxShadow: "none" }}
          component={RouterLink}
          to="/course/description"
          state={{ courseId }}
        >
          <Typography typography="subtitle2" color="white">
            กลับ
          </Typography>
        </Button>
      </Container>

      <Modal keepMounted open={openSuccess} onClose={handleSuccessClose}>
        <Box sx={modalStyle} width="340px">
          <SuccessModal
            message={`คุณผ่านบททดสอบแล้ว`}
            handleSuccessClose={handleSuccessClose}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openWarnning} onClose={handleWarnningClose}>
        <Box sx={modalStyle} width="340px">
          <WarningModal
            message={messageWarnning}
            handleClose={handleWarnningClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default LessonOverviewPage;
