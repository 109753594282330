import { Box, Button, Typography, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import dayjs from "dayjs";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useMediaQuery from "@mui/material/useMediaQuery";
import { boxStyle } from "../../../consts/styles";

const MemberCountChart = ({ weeklySummarie }: any) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const renderLabel = (props: any) => {
    return matches
      ? `${props.toLocaleString()} คน`
      : `${(props / 1000).toFixed(1)}k`;
  };

  return (
    <Box sx={boxStyle}>
      <Box display="flex" justifyContent="space-between">
        <Typography typography={{ xs: "h4", sm: "h3" }} color="black.main">
          จำนวนผู้สมัคร (คน)
        </Typography>

        <Button
          color="green"
          variant="contained"
          component={RouterLink}
          to="/member-by-season"
        >
          <Typography variant="subtitle2" color="white">
            เพิ่มเติม
          </Typography>
        </Button>
      </Box>

      <Box display="flex" justifyContent="center" height={440}>
        <ResponsiveContainer width={"100%"} height={"100%"}>
          <BarChart
            width={500}
            height={340}
            data={weeklySummarie}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="code"
              interval={0}
              angle={matches ? 0 : -70}
              height={matches ? 50 : 150}
              dy={matches ? 0 : 50}
              tickFormatter={(tickItem) => {
                return `${dayjs(tickItem)
                  .locale("th")
                  .format("DD MMM")} - ${dayjs(tickItem)
                  .add(6, "day")
                  .locale("th")
                  .format("DD MMM BB")}`;
              }}
              fontSize={12}
            />
            <YAxis tick={<CustomizedYAxisTick />} domain={[0, `dataMax`]} />
            <Tooltip />
            <Bar dataKey="total" fill="#2FD25D" barSize={120}>
              <LabelList
                dataKey="total"
                position="top"
                formatter={renderLabel}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default MemberCountChart;

const CustomizedYAxisTick = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
        {payload.value.toLocaleString()}
      </text>
    </g>
  );
};
