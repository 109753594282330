import { Box, Typography, Button } from "@mui/material";

const WarningModal = ({
  needLearnAgain,
  lesson,
  handleGoBack,
  handleGoToLesson,
}: any) => {
  return (
    <Box display="flex" flexDirection="column" padding="32px">
      <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
        <Typography align="center" typography="h4" color="blue.main">
          ผลการทดสอบ
        </Typography>

        <Box
          component="img"
          src="/course/not-pass.png"
          width={150}
          height={150}
        />

        <Box display="flex" flexDirection="column" gap="8px">
          <Typography align="center" typography="h5" color="black.main">
            คุณยังไม่ผ่าน
          </Typography>
          <Typography align="center" typography="h5" color="black.main">
            แบบทดสอบ บทที่ {lesson.lesson_order}
          </Typography>

          <Typography align="center" typography="body1" color="cgrey.main">
            {needLearnAgain
              ? "*จำเป็นต้องเรียนบทที่ 1 ใหม่อีกครั้งค่ะ"
              : "*คุณสามารถทำแบบทดสอบได้อีกครั้ง เมื่อผ่านไป 24 ชั่วโมง"}
          </Typography>
        </Box>

        <Box display="flex" gap="8px" width={1}>
          <Button
            fullWidth
            color="blue"
            variant="outlined"
            onClick={handleGoBack}
          >
            <Typography typography="subtitle2" color="blue.main">
              กลับ
            </Typography>
          </Button>
          <Button
            fullWidth
            color="blue"
            variant="contained"
            onClick={handleGoToLesson}
          >
            <Typography typography="subtitle2" color="white">
              บทเรียน
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default WarningModal;
