import axiosService from "../api/axios";

export const uploadTempService = (formData: FormData) => {
  return axiosService.post("/member/temp-avatar-upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const avatarUploadService = (formData: FormData) => {
  return axiosService.post("/member/avatar-upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const imageUploadService = (formData: FormData) => {
  return axiosService.post("/member/temp-image-upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
