import { Box, Container } from "@mui/material";
import { pageStyle } from "../../consts/styles";

const PdpaPage = () => {
  return (
    <Box sx={pageStyle}>
      <Container
        component="main"
        sx={{
          zIndex: 1,
          maxWidth: 600,
        }}
        maxWidth={false}
      >
        <Box
          dangerouslySetInnerHTML={{
            __html: `
            <style>
              ol {
                list-style-type: none;
                counter-reset: item;
                margin: 0;
                padding: 0;
              }
              
              ol > li {
                display: table;
                counter-increment: item;
                margin-bottom: 0.6em;
              }
              
              ol > li:before {
                content: counters(item, ".") ". ";
                display: table-cell;
                padding-right: 0.6em;    
              }
              
              li ol > li {
                margin: 0;
              }
              
              li ol > li:before {
                content: counters(item, ".") " ";
              }
            </style>

            <h3 style="text-align: center">นโยบายคุ้มครองข้อมูลส่วนบุคคล กรมอนามัย</h3>

            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรมอนามัยในฐานะผู้ควบคุมข้อมูลส่วนบุคคล มีหน้าที่ต้องปฏิบัติ ตามพระราชบัญญัติคุ้มครองข้อมูล
              ส่วนบุคคล พ.ศ. ๒๕๖๒ เพื่อให้เป็นไปตามนโยบายและแนวปฏิบัติ การคุ้มครองข้อมูลส่วนบุคคลกรมอนามัย
              กรมอนามัยจึงได้กำหนด แนวปฏิบัติการคุ้มครองข้อมูลส่วนบุคคล ไว้ดังต่อไปนี้
            </p>

            <ol>
              <li>
                <p style="font-weight: bold">ขอบเขตการบังคับใช้นโยบายฉบับนี้</p>
                <ol>
                  <div>
                    <p>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;นโยบายฉบับนี้ มีผลบังคับใช้กับหน่วยงานภายในสังกัดกรมอนามัยทั้งส่วนกลางและส่วนภูมิภาค
                    </p>
                  </div>
                </ol>
              </li>
              <li>
                <p style="font-weight: bold">ข้อมูลส่วนบุคคลที่ได้รับการคุ้มครอง</p>
                <ol>
                  <li>
                    ข้อมูลส่วนบุคคลของบุคลากรหน่วยงานของกรมอนามัย
                    <div>
                      <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เป็นข้อมูลส่วนบุคคลของ ข้าราชการ พนักงานราชการ พนักงานกระทรวงสาธารณสุข ลูกจ้างประจำ ลูกจ้างชั่วคราว ลูกจ้างโครงการ ในสังกัดกรมอนามัย
                      </p>
                    </div>
                  </li>
                  <li>
                    ข้อมูลส่วนบุคคลของผู้มาติดต่องาน
                    <div>
                      <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เป็นข้อมูลส่วนบุคลคลของ ผู้มาติดต่องาน สมัครงาน การทำธุรกรรม เช่น การขอใบอนุญาตต่าง ๆ
                        การส่งตรวจสิ่งส่งตรวจทางห้องปฏิบัติการ เป็นต้น การทำนิติกรรม เช่น การทำสัญญาว่าจ้าง สัญญาซื้อขาย
                        รวมถึงข้อมูลส่วนบุคคลของพนักงานหรือลูกจ้างของหน่วยงานที่ทำสัญญา หรือทำงานให้กับกรมอนามัย
                      </p>
                    </div>
                  </li>
                  <li>
                    ข้อมูลส่วนบุคคลของผู้รับบริการ
                    <div>
                      <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เป็นข้อมูลส่วนบุคคลของผู้มาติดต่อเพื่อรับบริการทางการแพทย์และสาธารณสุข ที่ หน่วยบริการ
                        สุขภาพของกรมอนามัย รวมถึงข้อมูลส่วนบุคคลของผู้รับบริการกรณีที่บุคลากรของหน่วยบริการสุขภาพ
                        ของกรมอนามัยออกไปให้บริการนอกหน่วยบริการในพื้นที่ที่รับผิดชอบ และข้อมูลการใช้บริการสุขภาพทางดิจิทัล
                      </p>
                    </div>
                  </li>
                </ol>
              </li>
              <li>
                <p style="font-weight: bold">การเก็บรวบรวมข้อมูลส่วนบุคคลอย่างจำกัด</p>
                <ol>
                  <div>
                    <p>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรมอนามัยจะเก็บรวบรรมข้อมูลส่วนบุคคล "เท่าที่จำเป็น" สำหรับการให้บริการตามวัตถุประสงค์
                      ในการดำเนินงานของกรมอนามัย อย่างเคร่งครัด และกรมอนามัยจะขอความยินยอมจากเจ้าของข้อมูล
                      ส่วนบุคคลก่อนหรือขณะที่เก็บรวบรวมข้อมูลส่วนบุคคล เว้นแต่การเก็บรวบรวมข้อมูลส่วนบุคคลที่ได้รับยกเว้น
                      ไม่ต้องขอความยินยอมตามที่กฎหมายกำหนด และจะรักษาข้อมูลเหล่านั้นไว้เป็นความลับตามเกณฑ์มาตรฐาน
                      การกำหนดชั้นความลับของข้อมูลของกรมอนามัย
                    </p>
                  </div>
                </ol>
              </li>
              <li>
                <p style="font-weight: bold">วัตถุประสงค์ในการเก็บรวบรวม ใช้ เปิดเผย ข้อมูลส่วนบุคคล</p>
                <ol>
                  <li>
                    <p>
                      กรมอนามัย จะเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล เพื่อการดำเนินงานในพันธกิจต่าง ๆ ของกรมอนามัย รวมทั้งเพื่อการศึกษาวิจัยหรือ การจัดทำสถิติที่เป็นไปตามวัตถุประสงค์การดำเนินงานของกรมอนามัย หรือตามที่กฎหมายกำหนด
                    </p>
                  </li>
                  <li>
                    <p>
                      กรมอนามัย จะบันทึกวัตถุประสงค์ของการเก็บรวบรวมข้อมูลส่วนบุคคล ในขณะที่มีการรวบรวม
                      และจัดเก็บ รวมถึงการนำข้อมูลนั้นไปใช้ในภายหลัง และหากมีการเปลี่ยนแปลงวัตถุประสงค์ของการเก็บ
                      รวบรวมข้อมูล กรมอนามัย จะจัดทำบันทึกแก้ไขเพิ่มเติมไว้เป็นหลักฐาน หากมีการเปลี่ยนแปลงวัตถุประสงค์
                      ตามที่เคยได้แจ้งไว้ กรมอนามัย จะแจ้งวัตถุประสงค์ใหม่นั้นให้กับเจ้าของข้อมูลส่วนบุคคลทราบและได้รับ
                      ความยินยอมก่อนเก็บรวบรวม ใช้ หรือเปิดเผย เว้นแต่ได้รับยกเว้นไม่ต้องขอความยินยอมตามที่กฎหมายกำหนด
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p style="font-weight: bold">การกำกับดูแลการเก็บรวบรวม ใช้และการเปิดเผยข้อมูลส่วนบุคคล</p>
                <ol>
                  <li>
                    <p>
                      กรมอนามัย จะกำกับดูแลมิให้ผู้ที่ไม่มีหน้าที่หรือไม่ได้รับมอบหมายเก็บรวบรวมข้อมูลส่วนบุคคล
                      นำไปใช้ประโยชน์ เปิดเผย แสดง หรือทำให้ปรากฏในลักษณะอื่นใดแก่บุคคลอื่น นอกเหนือวัตถุประสงค์
                      ที่ได้ขอความยินยอมจากเจ้าของข้อมูลส่วนบุคคล เว้นแต่การใช้ข้อมูลส่วนบุคคลที่ได้รับยกเว้นไม่ ต้องขอความยินยอมตามที่กฎหมายกำหนด
                    </p>
                  </li>
                  <li>
                    <p>
                      กรมอนามัย จะไม่เปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูล โดยไม่มีฐานการประมวลผลข้อมูล
                      โดยชอบด้วยกฎหมาย แต่อาจเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูลภายใต้หลักเกณฑ์ที่กฎหมายกำหนด
                      เช่น การเปิดเผยต่อหน่วยงานราชการ หน่วยงานภาครัฐ หน่วยงานที่กำกับดูแล รวมถึงในกรณีที่มีการร้องขอ
                      ให้เปิดเผยข้อมูลโดยอาศัยอำนาจตามกฎหมาย
                    </p>
                  </li>
                  <li>
                    <p>
                      กรมอนามัย อาจใช้เทคโนโลยีคุกกี้ (Cookies) เพื่อเก็บรวบรวมข้อมูลพฤติกรรมของเจ้าของข้อมูล
                      ส่วนบุคคล เกี่ยวกับการเข้าถึง การใช้งาน หรือการรับบริการผ่านเว็บไซต์ และแอปพลิเคชันของกรมอนามัย
                      เพื่อประโยชน์ในการอำนวยความสะดวกแก่เจ้าของข้อมูลส่วนบุคคลในการเข้าถึง การใช้งาน หรือการรับบริการผ่านเว็บไซต์และแอปพลิเคชัน ของกรมอนามัย
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p style="font-weight: bold">ระยะเวลาในการจัดเก็บข้อมูล</p>
                <ol>
                  <div>
                    <p>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรมอนามัย จะเก็บรักษาข้อมูลส่วนบุคคลของไว้เป็นระยะเวลา ตราบเท่าที่วัตถุประสงค์
                      ของการนำข้อมูลดังกล่าวไปใช้ยังคงมีอยู่ หลังจากนั้น กรมอนามัยจะลบ ทำลายข้อมูล หรือทำให้ข้อมูล
                      ไม่สามารถระบุตัวตนได้ เว้นแต่กรณีจำเป็นต้องเก็บ รักษาข้อมูลต่อไปตามที่กฎหมายที่เกี่ยวข้องกำหนด
                      หรือเพื่อเป็นการคุ้มครองสิทธิประโยชน์ของกรมอนามัย หรือหากมีความจำเป็นเพื่อวัตถุประสงค์อื่น ๆ
                      เช่น เพื่อความปลอดภัย เพื่อการป้องกันการละเมิดหรือการประพฤติมิซอบ หรือเพื่อการเก็บบันทึกทางการเงิน
                    </p>
                  </div>
                </ol>
              </li>
              <li>
                <p style="font-weight: bold">การโอนข้อมูลไปต่างประเทศ</p>
                <ol>
                  <div>
                    <p>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรมอนามัย จะทำการเปิดเผยข้อมูลส่วนบุคคลต่อผู้รับข้อมูลในต่างประเทศ เฉพาะกรณีที่กฎหมาย
                      คุ้มครองข้อมูลส่วนบุคคลกำหนดให้ทำได้เท่านั้น ทั้งนี้หน่วยงานของกรมอนามัยอาจปฏิบัติตามหลักเกณฑ์
                      การโอนข้อมูลระหว่างประเทศ โดยเข้าทำข้อสัญญามาตรฐานหรือใช้กลไกอื่นที่พึงมีตามกฎหมายว่าด้วย
                      การคุ้มครองข้อมูลที่ใช้บังคับ และ หน่วยงานของกรมอนามั้ยอาจอาศัยสัญญาการโอนข้อมูล หรือกลไกอื่น
                      ที่ได้รับการอนุมัติ เพื่อการโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ
                    </p>
                  </div>
                </ol>
              </li>
              <li>
                <p style="font-weight: bold">การรักษาความมั่นคงปลอดภัย</p>
                <ol>
                  <div>
                    <p>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรมอนามัย จะใช้มาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล ซึ่งครอบคลุมถึงมาตรการ
                      ป้องกัน ด้านการบริหารจัดการ (Administrative Safeguard) มาตรการป้องกันด้านเทคนิค (Technical
                      Safeguard) และมาตรการป้องกันทางกายภาพ (Physical Safeguard) ในเรื่องการเข้าถึงหรือควบคุม
                      การใช้งานข้อมูลส่วนบุคคล (Access Control() เพื่อป้องกันการเข้าถึงและเปิดเผยข้อมูลโดยไม่ได้รับอนุญาต
                      และสอดคล้องกับการดำเนินงานของกรมอนามัย
                    </p>
                  </div>
                </ol>
              </li>
              <li>
                <p style="font-weight: bold">การลบหรือทำลายข้อมูลส่วนบุคคล</p>
                <ol>
                  <div>
                    <p>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรมอนามัย จะดำเนินการลบหรือทำลายข้อมูลส่วนบุคคล เมื่อพ้นกำหนดระยะเวลาการเก็บ
                      หรือหมดความจำเป็นตามวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลได้ หรือเมื่อเจ้าของข้อมูลส่วนบุคคลร้องขอหรือเจ้าของข้อมูลส่วนบุคคลได้เพิกถอนความยินยอม เว้นแต่การเก็บรักษาข้อมูลส่วนบุคคลที่ได้รับยกเว้นไม่ต้องขอความยินยอมตามที่กฎหมายกำหนด
                    </p>
                  </div>
                </ol>
              </li>
              <li>
                <p style="font-weight: bold">การมีส่วนร่วมของเจ้าของข้อมูล</p>
                <ol>
                  <div>
                    <p>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรมอนามัย จะเก็บรวบรวมข้อมูลส่วนบุคคลจากเจ้าของข้อมูลส่วนบุคคลโดยตรงเท่านั้น และต้อง
                      "ขอความยินยอมจากเจ้าของข้อมูลส่วนบุคคลก่อนหรือระหว่างเก็บรวบรวมข้อมูลส่วนบุคคล" เว้นแต่การเก็บ
                      รวบรวมข้อมูลส่วนบุคคลที่ได้รับยกเว้นไม่ต้องขอความยินยอมตามที่กฎหมายกำหนด หากหน่วยงานของ
                      กรมอนามัย จำเป็นต้อง "เก็บรวบรวมข้อมูลส่วนบุคคลจากแหล่งอื่น" ที่ไม่ใช่เก็บจากเจ้าของข้อมูลส่วนบุคคล
                      โดยตรง หน่วยงานของกรมอนามัย จะแจ้งเหตุผลความจำเป็นนั้นให้เจ้าของข้อมูลส่วนบุคคลทราบ เว้นแต่การ
                      เก็บรวบรวมข้อมูลส่วนบุคคลที่ได้รับยกเว้นไม่ต้อง ขอความยินยอมตามที่กฎหมายกำหนด
                    </p>
                  </div>
                </ol>
              </li>
              <li>
                <p style="font-weight: bold">การมีส่วนร่วมของเจ้าของข้อมูล</p>
                <ol>
                  <div>
                    <p>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เจ้าของข้อมูลส่วนบุคคลมีสิทธิในการดำเนินการ กับข้อมูลส่วนบุคคลของตนเองที่กรมอนามัยดูแล
                      ดังต่อไปนี้
                    </p>
                  </div>
                  <li>
                    <p>
                      สิทธิในการขอรับข้อมูลส่วนบุคคลของตนเอง โดยเจ้าของข้อมูลมีสิทธิที่จะขอรับสำเนาข้อมูล
                      ส่วนบุคคลของตน และมีสิทธิที่จะร้องขอให้เปิดเผยถึงการได้มาซึ่งข้อมูลของเจ้าของข้อมูล
                    </p>
                  </li>
                  <li>
                    <p>
                      สิทธิคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของตนเองด้วยเหตุบางประการตามที่กฎหมายกำหนด
                    </p>
                  </li>
                  <li>
                    <p>
                      สิทธิขอให้ลบหรือทำลายข้อมูล โดยขอให้กรมอนามัย ดำเนินการลบ หรือทำลาย หรือทำให้ข้อมูล
                      ส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลได้ด้วยเหตุบางประการได้ตามที่
                      กฎหมายกำหนด
                    </p>
                  </li>
                  <li>
                    <p>
                      สิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคล โดยขอให้กรมอนามัยระงับการใช้ข้อมูลส่วนบุคคลของ
                      ตนเองด้วยเหตุบางประการตามที่กฎหมายกำหนด
                    </p>
                  </li>
                  <li>
                    <p>
                      สิทธิขอให้แก้ไขเปลี่ยนแปลง โดยขอให้กรมอนามัย ดำเนินการให้ข้อมูลส่วนบุคคลนั้นถูกต้อง
                      เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p style="font-weight: bold">การเปิดเผยเกี่ยวกับการดำเนินการ แนวปฏิบัติและนโยบายที่เกี่ยวกับข้อมูลส่วนบุคคล</p>
                <ol>
                  <li>
                    <p>
                      กรมอนามัย มีการดำเนินการตามนโยบายการคุ้มครองข้อมูลส่วนบุคคลของ กรมอนามัย โดย
                      จะเผยแพร่ผ่านทางเว็บไซต์ https://pdpa.anamai.moph.go.th รวมทั้งหากมีการปรับปรุงแก้ไขนโยบายการคุ้มครองข้อมูลส่วนบุคคล ก็จะดำเนินการเผยแพร่ผ่านช่องทางดังกล่าว รวมทั้งผ่านสื่อที่กรมอนามัยใช้เพื่อ
                      การประชาสัมพันธ์ตามความเหมาะสมด้วย
                    </p>
                  </li>
                  <li>
                    <p>
                      การดำเนินการแนวปฏิบัติ และนโยบายที่เกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลที่ กรมอนามัย
                      ประกาศใช้นี้ จะใช้เฉพาะสำหรับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลในกิจการของ กรมอนามัยซึ่งรวมตลอดถึงการบริหารงาน การให้บริการ และการเข้าถึงเว็บไซต์ของ กรมอนามัย เท่านั้น หากผู้ใช้บริการมีการเชื่อมโยง (Link) ไปยังเว็บไซต์อื่นผ่านทางเว็บไซต์ของกรมอนามัย ผู้ใช้บริการจะต้องศึกษาและปฏิบัติตามนโยบายและแนวปฏิบัติในการคุ้มครองข้อมูลส่วนบุคคลตามที่ปรากฏในเว็บไซต์อื่นนั้นแยกต่างหากจากกรมอนามัยด้วย
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p style="font-weight: bold">ช่องทางการติดต่อ</p>
                <div>
                  <p>
                    เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (DPO : Data Protection Officer)
                  </p>
                  <p>
                    กองแผนงาน กรมอนามัย
                  </p>
                  <p>
                    อาคาร ๕ ชั้น ๔
                  </p>
                  <p>
                    ตำบลตลาดขวัญ อำเภอเมือง จังหวัดนนทบุรี ๑๑๐๐.
                  </p>
                  <p>
                    อีเมล์ dpo@anamai.mail.go.th
                  </p>
                </div>
              </li>
            </ol>
            `,
          }}
        ></Box>
      </Container>
    </Box>
  );
};

export default PdpaPage;
