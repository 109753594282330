import { Box, Typography, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const LessonNotDoneModal = ({ course }: any) => {
  return (
    <Box display="flex" flexDirection="column" padding="32px" gap="16px">
      <Box display="flex" flexDirection="column">
        <Typography align="center">
          <ErrorOutlineIcon sx={{ fontSize: 60, color: "#FFCC4C" }} />
        </Typography>

        <Typography align="center" variant="subtitle2" color="black.main">
          กรุณาเข้าสู่บทเรียนให้ครบก่อนค่ะ
        </Typography>
      </Box>

      <Box display="flex" gap="8px">
        <Button
          fullWidth
          color="blue"
          variant="contained"
          component={RouterLink}
          to="/course/lesson-overview"
          state={{ courseId: course?.course_id }}
        >
          <Typography typography="subtitle2" color="white">
            บทเรียน
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default LessonNotDoneModal;
