import { Button, Typography } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Box, Container } from "@mui/system";
import { styled } from "@mui/material/styles";
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useAppSelector } from "../../features/userStore/hook";
import { getProfile } from "../../features/userStore/libs/profileSlice";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 100,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#FFFFFF",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 100,
    backgroundColor: "#FFCC4C",
  },
}));

const ExperiencePage = () => {
  const profile = useAppSelector(getProfile);
  const [percent, setPercent] = useState<number>(0);

  useEffect(() => {
    if (profile)
      setPercent(
        (profile.experience_point * 100) / profile.next_experience_point
      );
  }, [profile]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      gap="24px"
      pt="50px"
      pb="100px"
    >
      <Typography
        align="center"
        typography={{ xs: "h3", sm: "h1" }}
        color="ktgGreen.main"
        zIndex={1}
      >
        ค่าประสบการณ์
      </Typography>

      <Container
        component="main"
        sx={{
          zIndex: 1,
          maxWidth: 600,
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
        maxWidth={false}
      >
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="10px"
          alignItems="center"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background:
              "linear-gradient(142.14deg, rgba(55, 224, 174, 0.9) 21.93%, rgba(119, 218, 255, 0.9) 78.19%)",
          }}
          padding="32px 24px"
          gap="16px"
        >
          <Box
            width={1}
            sx={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Box>
              <Box
                component="img"
                sx={{
                  width: { xs: 70, sm: 80, md: 100 },
                  height: { xs: 70, sm: 80, md: 100 },
                }}
                alt="current"
                src={`/levels/Artboard – ${profile?.experience_level}.png`}
              />
              <Typography
                align="center"
                sx={{ typography: { xs: "body2", sm: "body1" } }}
                color="white"
              >
                <FormattedMessage id="exercise.level" />{" "}
                {profile?.experience_level}
              </Typography>
            </Box>

            <Box
              sx={{
                alignSelf: "center",
                flexGrow: 0.7,
              }}
            >
              <BorderLinearProgress variant="determinate" value={percent} />
            </Box>

            <Box>
              <Box
                component="img"
                sx={{
                  width: { xs: 70, sm: 80, md: 100 },
                  height: { xs: 70, sm: 80, md: 100 },
                }}
                alt="next"
                src={`/levels/Artboard – ${profile?.next_experience_level}.png`}
              />

              <Typography
                align="center"
                sx={{ typography: { xs: "body2", sm: "body1" } }}
                color="white"
              >
                <FormattedMessage id="exercise.level" />{" "}
                {profile?.next_experience_level}
              </Typography>
            </Box>
          </Box>

          <Box
            width="300px"
            sx={{
              background: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderRadius: "10px",
              padding: "20px 20px",
              gap: "20px",
            }}
          >
            <Typography
              align="center"
              variant="subtitle2"
              sx={{ fontWeight: 600 }}
              color="common.black"
            >
              <FormattedMessage id="exercise.levelTotal" />
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Typography
                align="center"
                alignSelf="normal"
                variant="h2"
                color="green.main"
              >
                {profile?.experience_point}
              </Typography>

              <Typography
                align="center"
                alignSelf="center"
                variant="h2"
                color="cgrey.main"
              >
                / {profile?.next_experience_point}
              </Typography>
            </Box>

            <Typography align="center" variant="body1" color="cgrey.main">
              <FormattedMessage id="exercise.exp" />
            </Typography>
          </Box>

          <Button
            color="blue"
            variant="contained"
            sx={{
              boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
              width: "300px",
            }}
            component={RouterLink}
            to="/point-conditions"
          >
            <Typography variant="subtitle2" color="white">
              EXP ได้รับอย่างไร
            </Typography>
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default ExperiencePage;
