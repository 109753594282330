import {
  Box,
  Button,
  Container,
  Fab,
  Typography,
  Grid,
  Badge,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  boxShadowStyle,
  boxBlurStyle,
  boxStyle,
  containerStyle,
  pageStyle,
} from "../../../consts/styles";
import { communityTypes } from "../../../consts/app";
import {
  getMyCommunityService,
  getRejectInviteRequestService,
} from "../../../services/community.service";

const Community = () => {
  const navigate = useNavigate();

  const [communityType, setCommunityType] = useState<number>(1);
  const [communities, setCommunities] = useState<any[]>([]);
  const [inviteRequests, setInviteRequests] = useState<any[]>([]);
  const [communitiesFitered, setCommunitiesFitered] = useState<any[]>([]);

  const getMyCommunity = () => {
    getMyCommunityService().then((resp) => {
      setCommunities(resp.data);
    });
  };

  const filterCommunities = () => {
    if (communities.length) {
      const filtered = communities.filter((c) => c.type_id === communityType);
      setCommunitiesFitered(filtered);
    }
  };

  const getRejectInviteRequest = () => {
    getRejectInviteRequestService().then((resp) => {
      setInviteRequests(resp.data);
    });
  };

  const navigateToView = (id: number) => {
    navigate("/community/view", {
      state: { id },
      replace: true,
    });
  };

  useEffect(getMyCommunity, []);

  useEffect(filterCommunities, [communities, communityType]);

  useEffect(getRejectInviteRequest, []);

  return (
    <Box sx={pageStyle}>
      <Box
        display="flex"
        maxWidth={{ xs: 350, sm: 600 }}
        width={1}
        justifyContent="space-between"
      >
        <Grid container spacing={2}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Typography
              align="center"
              sx={{ typography: { xs: "h3", sm: "h1" } }}
              color="ktgGreen.main"
              zIndex={100}
            >
              <FormattedMessage id="community" />
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        gap="16px"
        maxWidth={{ xs: 350, sm: 400 }}
        width={1}
      >
        <Box display="flex" gap="8px" width="100%">
          <Button
            fullWidth
            color="secPink"
            variant="contained"
            startIcon={<SearchIcon />}
            component={RouterLink}
            to="/community/search"
          >
            <Typography variant="subtitle2" color="white">
              <FormattedMessage id="community.searchGroup" />
            </Typography>
          </Button>

          <Button
            fullWidth
            color="green"
            variant="contained"
            startIcon={<AddCircleOutlineIcon sx={{ color: "white" }} />}
            component={RouterLink}
            to="/community/create"
          >
            <Typography variant="subtitle2" color="white">
              <FormattedMessage id="community.create" />
            </Typography>
          </Button>
        </Box>

        <Box
          sx={{
            overflowY: { xs: "auto" },
            p: "4px",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box
            display="flex"
            sx={{
              width: "auto",
            }}
            justifyContent="center"
            gap="16px"
          >
            {communityTypes.map((type, index) => (
              <Button
                key={index}
                color={communityType === index + 1 ? "blue" : "cwhite"}
                variant="contained"
                sx={{
                  width: "auto",
                  boxShadow: boxShadowStyle,
                }}
                onClick={() => setCommunityType(index + 1)}
              >
                <Typography variant="subtitle2">{type}</Typography>
              </Button>
            ))}
          </Box>
        </Box>
      </Box>

      <Container component="main" sx={containerStyle} maxWidth={false}>
        <Box sx={boxBlurStyle}>
          {communitiesFitered.map((community, index) => (
            <Box
              sx={{ ...boxStyle, cursor: "pointer" }}
              onClick={() => navigateToView(community.community_id)}
            >
              <Box display="flex" gap="16px">
                <Box
                  component="img"
                  borderRadius={100}
                  boxShadow="0px 0px 10px rgba(0, 0, 0, 0.15)"
                  width={100}
                  height={100}
                  src={community.image_url || "/images/community-default.png"}
                />

                <Box display="flex" flexDirection="column" gap="16px">
                  <Typography typography="h3" color="black.main">
                    {community.community_name}
                  </Typography>

                  <Box display="flex" gap="8px">
                    <Box
                      component="img"
                      width={16}
                      height={21}
                      src="/icons/member-in-group.png"
                    />

                    <Typography typography="body1" color="secYellow.main">
                      สมาชิก {community?.total?.toLocaleString() || 0} คน
                    </Typography>
                  </Box>

                  <Box display="flex" gap="8px">
                    <Typography typography="h4" color="secPink.main">
                      {community?.calories?.toLocaleString() || 0}
                    </Typography>

                    <Typography
                      alignSelf="center"
                      typography="body1"
                      color="lightGrey.main"
                    >
                      แคลอรี
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
          <Typography align="center" typography="body2" color="softBlack.main">
            *ท่านสามารถเข้าร่วมกลุ่มได้อีก {5 - communities.length} กลุ่ม*
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Community;
