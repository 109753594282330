import { useEffect, useState } from "react";
import { getKpiService } from "../../../services/dashboard.service";
import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { boxStyle } from "../../../consts/styles";
import { districtService } from "../../../services/master-data.service";
import { FormattedMessage } from "react-intl";
import React from "react";
import { TableComponents, TableVirtuoso } from "react-virtuoso";
import { getItem } from "../../../services/localStorage.service";
import dayjs from "dayjs";

const DashboardKpi = ({ provinces }: any) => {
  const [kpiData, setKpiData] = useState<any>();

  const [districts, setDistricts] = useState([]);

  const [zone, setZone] = useState<string>("");
  const [province, setProvince] = useState<string>("");
  const [district, setDistrict] = useState<string>("");
  const [provincesFiltered, setProvincesFiltered] = useState([]);

  const getKpi = () => {
    getKpiService({
      zone,
      province_id: province,
      district_id: district,
    }).then((resp) => {
      setKpiData(resp.data);
      if (!province) {
        const filterByZone = resp.data.register_kpi.map((s: any) => s.location);
        setProvincesFiltered(
          provinces.filter((p: any) => filterByZone.includes(p.name))
        );
      }
    });
  };

  // service
  const getDistricts = async (provinceId: string) => {
    setDistrict("");
    const response = await districtService(provinceId);
    setDistricts(response.data);
  };

  // handle
  const handleZoneChange = (event: SelectChangeEvent) => {
    setZone(event.target.value);
    setProvince("");
    setDistrict("");
    setDistricts([]);
  };

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setProvince(event.target.value);
    setDistrict("");
    getDistricts(event.target.value);
  };

  const handleDistrictChange = (event: SelectChangeEvent) => {
    setDistrict(event.target.value);
  };

  useEffect(getKpi, [zone, province, district]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      gap="32px"
    >
      <Box sx={boxStyle} gap={{ xs: "8px", sm: "16px" }}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems="center"
          gap="16px"
          width={1}
        >
          <Select
            fullWidth
            sx={{
              "& .MuiSelect-select .notranslate::after": "เขต"
                ? {
                    content: `"เขต"`,
                    opacity: 0.42,
                  }
                : {},
            }}
            value={zone}
            onChange={handleZoneChange}
          >
            <MenuItem key={0} value="">
              ทั้งหมด
            </MenuItem>
            {Array.from(Array(13).keys()).map((index: any) => (
              <MenuItem key={index} value={(index + 1).toString()}>
                {"เขตสุขภาพที่ " + (index + 1)}
              </MenuItem>
            ))}
          </Select>
          <Select
            fullWidth
            sx={{
              "& .MuiSelect-select .notranslate::after": "จังหวัด"
                ? {
                    content: `"จังหวัด"`,
                    opacity: 0.42,
                  }
                : {},
            }}
            value={province}
            onChange={handleProvinceChange}
          >
            <MenuItem key={0} value="">
              ทั้งหมด
            </MenuItem>
            {provincesFiltered.map((p: { id: number; name: string }) => (
              <MenuItem key={p.id} value={p.id.toString()}>
                {p.name}
              </MenuItem>
            ))}
          </Select>
          <Select
            fullWidth
            sx={{
              "& .MuiSelect-select .notranslate::after": "อำเภอ"
                ? {
                    content: `"อำเภอ"`,
                    opacity: 0.42,
                  }
                : {},
            }}
            value={district}
            onChange={handleDistrictChange}
          >
            <MenuItem key={0} value="">
              ทั้งหมด
            </MenuItem>
            {districts.map((d: { id: number; name: string }) => (
              <MenuItem key={d.id} value={d.id.toString()}>
                {d.name}
              </MenuItem>
            ))}
          </Select>

          {/* <Box>
            <Button
              variant="contained"
              sx={{ width: "120px", backgroundColor: "#0DAC87" }}
              onClick={onSearchHandler}
            >
              <Typography variant="subtitle2" color="white">
                <FormattedMessage id="common.search" />
              </Typography>
            </Button>
          </Box> */}
        </Box>
      </Box>

      <TableKpi
        title="จํานวนประชาชนเข้าถึงดิจิทัลแพลตฟอร์มในการส่งเสริมสุขภาพ"
        subtitle="ข้อมูล จำนวนผู้ลงทะเบียนสะสมทั้งหมด"
        data={kpiData?.register_kpi}
      />

      <TableKpi
        title="ประชากรมีการปรับเปลี่ยนพฤติกรรมสุขภาพ (ยอด Active)"
        subtitle="ข้อมูล จำนวนสมาชิกมีการส่งผลออกกำลังกาย เข้ารอบรู้สุขภาพ หรือ ประเมิน BMI อย่างน้อย 1 ครั้ง นับตั้งแต่ 1 มี.ค. 2566"
        data={kpiData?.activity_kpi}
      />

      <TableKpi
        title="ประชาชนได้รับการพัฒนาศักยภาพด้านการส่งเสริม กิจกรรมทางกาย/ออกกําลังกาย และ การปรับเปลี่ยนพฤติกรรมสุขภาพ (DPAC)"
        subtitle="ข้อมูล จำนวนสมาชิกสะสม ที่ผ่านการอบรมหลักสูตรปรับเปลี่ยนพฤติกรรมสุขภาพ (DPAC)"
        data={kpiData?.dpac_kpi}
      />
    </Box>
  );
};

interface Data {
  location: string;
  first_period_goal: number;
  first_period_ratio: number;
  first_period_total: number;
  second_period_goal: number;
  second_period_ratio: number;
  second_period_total: number;
}

interface ColumnData {
  dataKey: keyof Data;
  label: string;
  numeric?: boolean;
  width: number;
  color: string;
}

var columns: ColumnData[] = [
  {
    width: 100,
    label: "พื้นที่",
    dataKey: "location",
    color: "#0DAC87",
  },
  {
    width: 100,
    label: "เป้าหมาย",
    dataKey: "first_period_goal",
    color: "#3DBD9F",
  },
  {
    width: 100,
    label: "จำนวน",
    dataKey: "first_period_total",
    color: "#3DBD9F",
  },
  {
    width: 100,
    label: "ร้อยละ",
    dataKey: "first_period_ratio",
    color: "#3DBD9F",
  },
  {
    width: 100,
    label: "เป้าหมาย",
    dataKey: "second_period_goal",
    color: "#5DC7AF",
  },
  {
    width: 100,
    label: "จำนวน",
    dataKey: "second_period_total",
    color: "#5DC7AF",
  },
  {
    width: 100,
    label: "ร้อยละ",
    dataKey: "second_period_ratio",
    color: "#5DC7AF",
  },
];

const TableKpi = ({ title, subtitle, data }: any) => {
  const [global] = useState<any>(getItem("global"));

  const fixedHeaderContent = () => {
    return (
      <>
        <TableRow>
          <TableCell
            sx={{
              borderBottom: "none",
              padding: "10px 0",
              backgroundColor: "#0DAC87",
            }}
          />

          <TableCell
            align="center"
            colSpan={3}
            variant="head"
            sx={{
              borderLeft: "1px",
              padding: "10px 0",
              backgroundColor: "#3DBD9F",
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 600 }} color="white">
              5 เดือนแรก (
              {dayjs(global?.first_period_start_date)
                .locale("th")
                .format("MMM BB")}{" "}
              -{" "}
              {dayjs(global?.second_period_start_date)
                .add(-1, "days")
                .locale("th")
                .format("MMM BB")}
              )
            </Typography>
          </TableCell>
          <TableCell
            align="center"
            colSpan={3}
            variant="head"
            sx={{
              padding: "10px 0",
              backgroundColor: "#5DC7AF",
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 600 }} color="white">
              5 เดือนหลัง (
              {dayjs(global?.second_period_start_date)
                .locale("th")
                .format("MMM BB")}{" "}
              -{" "}
              {dayjs(global?.second_period_end_date)
                .locale("th")
                .format("MMM BB")}
              )
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow>
          {columns.map((column: any) => (
            <TableCell
              key={column.dataKey}
              variant="head"
              align="center"
              style={{ width: column.width }}
              sx={{
                padding: "10px 0",
                backgroundColor: column.color,
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 600 }}
                color="white"
              >
                {column.label}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </>
    );
  };

  return (
    <Box sx={boxStyle}>
      <Typography align="center" typography="h4" color="black.main">
        {title}
      </Typography>

      <Typography align="center" typography="body1" color="black.main">
        {subtitle}
      </Typography>

      <Box
        sx={{
          height: 400,
          background: "#FFFFFF",
          borderRadius: "10px",
        }}
      >
        <TableVirtuoso
          data={data}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </Box>
    </Box>
  );
};

const rowContent = (_index: number, row: any) => {
  return (
    <React.Fragment>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          align="center"
          sx={{
            borderBottom: "none",
            background: _index % 2 !== 0 ? "#F5F7FA" : "",
          }}
        >
          <Typography align="right" variant="body1" color="black.main">
            {row[column?.dataKey] ? row[column?.dataKey].toLocaleString() : "-"}
          </Typography>
        </TableCell>
      ))}
    </React.Fragment>
  );
};

const VirtuosoTableComponents: TableComponents<any> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer
      sx={{ boxShadow: "none", borderRadius: 0 }}
      {...props}
      ref={ref}
    />
  )),
  Table: (props) => <Table {...props} />,
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

export default DashboardKpi;
