import {
  Card,
  CardMedia,
  Box,
  Container,
  Modal,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { useEffect, useState } from "react";
import { getPublishBannerService } from "../../../services/banner.service";
import { getProfile } from "../../../features/userStore/libs/profileSlice";
import { useAppSelector } from "../../../features/userStore/hook";
import {
  getFiitPdpaService,
  patchFiitPadpaService,
} from "../../../services/fiit.service";
import { modalStyle } from "../../../consts/styles";
import ConnectFiitModal from "../../modals/ConnectFiitModal";
import PdapContent from "../../PdapContent";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import ErrorModal from "../../modals/ErrorModal";

const BannerSlider = () => {
  const profile = useAppSelector(getProfile);
  const navigate = useNavigate();

  const [banner, setBanner] = useState<any[]>([]);
  const [openConnectFiitToken, setOpenConnectFiitToken] =
    useState<boolean>(false);
  const [openFiitPdpa, setFiitPdpaOpen] = useState(false);
  const [acceptFiitPdpa, setAcceptFiitPdpa] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [consent, setConsent] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState(false);

  const getPublishBanner = () => {
    getPublishBannerService().then((resp) => {
      setBanner(resp.data);
    });
  };

  const handleConnectFiitTokenOpen = () => {
    if (!profile.fiit_registered_date) {
      getFiitPdpaService().then((resp) => {
        setConsent(resp.data.pdpa);
        setOpenConnectFiitToken(true);
      });
    }
  };
  const handleConnectFiitTokenClose = () => setOpenConnectFiitToken(false);
  const handleFiitPdpaOpen = () => {
    handleConnectFiitTokenClose();
    setFiitPdpaOpen(true);
  };
  const handleFiitPdpaClose = () => setFiitPdpaOpen(false);
  const handleAcceptFiitPdpaChange = (event: any) => {
    setAcceptFiitPdpa(acceptFiitPdpa ? false : true);
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorOpen(false);
    setErrorMessage("");
  };

  const onClickBanner = (redirectUrl: string) => {
    if (redirectUrl === "fiit") {
      handleConnectFiitTokenOpen();
    } else {
      window.open(redirectUrl, "_blank");
    }
  };

  const handleAcceptFiitPdpaSubmit = async () => {
    try {
      handleLoading();
      await patchFiitPadpaService({ username: profile.username });
      setTimeout(() => {
        navigate("/connect-fiit", {
          state: {
            username: profile.username, // username
          },
          replace: true,
        });
        handleFiitPdpaClose();
      }, 1000);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  useEffect(getPublishBanner, []);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container
        component="main"
        maxWidth={false}
        sx={{ zIndex: 1000, maxWidth: "1120px" }}
      >
        <Swiper
          spaceBetween={50}
          centeredSlides={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {banner.map(({ banner_url, banner_id, redirect_url }) => (
            <SwiperSlide
              style={{ cursor: "pointer" }}
              onClick={() => {
                onClickBanner(redirect_url);
              }}
            >
              <Card key={banner_url} sx={{ background: "none" }}>
                <CardMedia
                  component="img"
                  image={banner_url}
                  title={banner_id}
                />
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>

      <Dialog keepMounted open={openFiitPdpa}>
        <DialogContent sx={{ height: "calc(100vh - 100px)" }}>
          <DialogContentText>
            <PdapContent consent={consent} />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: "32px", justifyContent: "center" }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width={1}
          >
            <FormGroup>
              <FormControlLabel
                onChange={handleAcceptFiitPdpaChange}
                control={<Checkbox color="green" value={true} />}
                label="ยอมรับข้อตกลงและเงื่อนไข"
              />
            </FormGroup>
            <Box display="flex" gap="8px">
              <Button
                color="blue"
                variant="outlined"
                type="button"
                onClick={handleFiitPdpaClose}
              >
                <Typography variant="subtitle2">
                  <FormattedMessage id="register.refuse" />
                </Typography>
              </Button>
              <Button
                color="green"
                variant="contained"
                type="button"
                onClick={handleAcceptFiitPdpaSubmit}
                disabled={!acceptFiitPdpa || loading}
              >
                <Typography variant="subtitle2" color="white">
                  <FormattedMessage id="register.accept" />
                </Typography>
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>

      <Modal
        keepMounted
        open={openConnectFiitToken}
        onClose={handleConnectFiitTokenClose}
      >
        <Box sx={modalStyle} width={340}>
          <ConnectFiitModal handleFiitPdpaOpen={handleFiitPdpaOpen} />
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle} width={340}>
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default BannerSlider;
