import { createTheme, PaletteColorOptions, Theme } from "@mui/material/styles";
const { palette } = createTheme();
const { augmentColor } = palette;

const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor } });

declare module "@mui/material/styles" {
  interface CustomPalette {
    green: PaletteColorOptions | any;
    blue: PaletteColorOptions | any;
    lightGrey: PaletteColorOptions | any;
    ktgGreen: PaletteColorOptions | any;
    secGreen: PaletteColorOptions | any;
    cgrey: PaletteColorOptions | any;
    black: PaletteColorOptions | any;
    ktjBlue: PaletteColorOptions | any;
    secPink: PaletteColorOptions | any;
    secYellow: PaletteColorOptions | any;
    cwhite: PaletteColorOptions | any;
    softBlack: PaletteColorOptions | any;
    red: string;
    tealishBlue: PaletteColorOptions | any;
    silver: PaletteColorOptions | any;
  }

  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}

  interface TypographyVariants {
    h4GdBlue: React.CSSProperties;
    rewardTitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h4GdBlue?: React.CSSProperties;
    rewardTitle?: React.CSSProperties;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    green: true;
    blue: true;
    cwhite: true;
    secPink: true;
    tealishBlue: true;
    lightGrey: true;
    silver: true;
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    lightGrey: true;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h4GdBlue: true;
    black: true;
    rewardTitle: true;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    ktgGreen: true;
    green: true;
    black: true;
    white: true;
    secYellow: true;
    lightGrey: true;
    blue: true;
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    green: true;
  }
}

declare module "@mui/material/Radio" {
  interface RadioPropsColorOverrides {
    ktgGreen: true;
  }
}

declare module "@mui/material/Pagination" {
  interface PaginationPropsColorOverrides {
    blue: true;
  }
}

let userTheme: Theme = createTheme({
  palette: {
    primary: createColor("#89939E"),
    green: createColor("#0DAC87"),
    blue: createColor("#0892F5"),
    lightGrey: createColor("#89939E"),
    ktgGreen: createColor("#1DA64A"),
    secGreen: createColor("#15E74F"),
    cgrey: createColor("#717171"),
    black: createColor("#2B2B2B"),
    common: {
      black: "#2B2B2B",
    },
    cwhite: createColor("#FFFFFF"),
    red: "#E96189",
    ktjBlue: createColor("#2CADF2"),
    secPink: createColor("#EB698F"),
    secYellow: createColor("#FFB100"),
    softBlack: createColor("#4D4D4D"),
    tealishBlue: createColor("#E0E5ED"),
    silver: createColor("#D9E2EB"),
  },
  typography: {
    fontFamily: ['"Noto Sans Thai"', "sans-serif"].join(","),
    h1: {
      fontSize: 48,
      fontWeight: 600,
    },
    h2: {
      fontSize: 36,
      fontWeight: 600,
    },
    h3: {
      fontSize: 28,
      fontWeight: 600,
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
    },
    h5: {
      fontSize: 20,
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 18,
      fontWeight: 400,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
    },
    h4GdBlue: {
      background: "linear-gradient(183.23deg, #2E9BD6 26.52%, #1887C1 83.47%)",
      fontSize: 24,
      fontWeight: 600,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    rewardTitle: {
      background:
        "radial-gradient(155.22% 197.38% at 108.84% -2.96%, #00A651 0%, #00AEEF 100%)",
      fontWeight: 600,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          height: "50px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "100px !important",
        },
      },
    },
  },
});

userTheme = createTheme(userTheme, {
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          [userTheme.breakpoints.up("md")]: {
            maxWidth: "790px",
          },
        },
      },
    },
  },
});

export { userTheme };
