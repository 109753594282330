import { Box, Container, Typography, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { getHomeSummaryService } from "../../../services/dashboard.service";

const HomeDashboard = () => {
  const [homeSummary, setHomeSummary] = useState<any>(null);

  const getHomeSummary = () => {
    getHomeSummaryService().then((resp) => {
      setHomeSummary(resp.data);
    });
  };

  useEffect(getHomeSummary, []);
  return (
    <Box
      sx={{
        backgroundImage: "url('/dashboard/bg_section5.png')",
        /* Center and scale the image nicely */
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Container
        component="main"
        maxWidth={false}
        sx={{
          zIndex: 1,
          maxWidth: "1120px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          paddingY: { xs: "32px", sm: "56px" },
        }}
      >
        <Box display="flex" gap="16px">
          <Box
            component="img"
            src="/dashboard/winning_home.png"
            width={{ xs: 51, sm: 72 }}
            height={{ xs: 51, sm: 72 }}
          />

          <Typography
            alignSelf="center"
            sx={{ color: "#028F5D" }}
            typography={{ xs: "h4", sm: "h1" }}
          >
            ก้าวท้าใจ ก้าวไปด้วยกัน
          </Typography>
        </Box>

        <Box paddingX={{ sm: "100px" }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={6}>
              <Card
                title={"สมาชิก"}
                unit={"คน"}
                quantity={homeSummary ? homeSummary?.total_members : 0}
                imageIcon={"/dashboard/member_home.png"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                title={"ก้าวรอบโลก"}
                unit={"รอบ"}
                quantity={
                  homeSummary ? homeSummary?.total_distances / 40077 : 0
                }
                imageIcon={"/dashboard/world_home.png"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                title={"เผาผลาญพลังงาน"}
                unit={"kcal"}
                quantity={homeSummary ? homeSummary?.total_calories : 0}
                imageIcon={"/dashboard/burn_home.png"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                title={"ลดน้ำหนักคนไทยแล้ว"}
                unit={"kg."}
                quantity={homeSummary ? homeSummary?.total_calories / 7000 : 0}
                imageIcon={"/dashboard/fat_home.png"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                title={"แต้มสุขภาพสะสม"}
                unit={"แต้ม"}
                quantity={homeSummary ? homeSummary?.total_experiences : 0}
                imageIcon={"/dashboard/point_home.png"}
              />
            </Grid>
            <Grid item xs={12} sm={6} component={RouterLink} to="/achievement-view">
              <Box
                width={1}
                component="img"
                src="/dashboard/reward&success_home.png"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

const Card = ({ title, unit, quantity, imageIcon }: any) => {
  return (
    <Box
      display="flex"
      gap="8px"
      sx={{
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.855) 0%, rgba(255, 255, 255, 0.76) 100%)",
        borderRadius: "10px",
        padding: "16px",
      }}
    >
      <Box
        width={{ xs: 60, sm: 80 }}
        height={{ xs: 60, sm: 80 }}
        sx={{
          background: "#FFFFFF",
          borderRadius: "100px",
          boxShadow: "0px 0px 10px rgba(55, 168, 87, 0.2)",
        }}
        display="flex"
        justifyContent="center"
      >
        <Box
          alignSelf="center"
          component="img"
          src={imageIcon}
          width={{ xs: 40, sm: 50 }}
          height={{ xs: 40, sm: 50 }}
        />
      </Box>

      <Box display="flex" flexDirection="column">
        <Typography
          color="black.main"
          typography={{ xs: "subtitle2", sm: "h4" }}
          fontWeight="400 !important"
        >
          {title}
        </Typography>

        <Box display="flex" gap="8px">
          <Typography color="secPink.main" typography={{ xs: "h3", sm: "h2" }}>
            {quantity?.toLocaleString()}
          </Typography>

          <Typography
            alignSelf="end"
            color="black.main"
            typography={{ xs: "subtitle2", sm: "h4" }}
            fontWeight="400 !important"
          >
            {unit}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeDashboard;
