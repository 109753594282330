import { Box, Typography, Grid } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const ResultExercise = () => {
  const items = [
    {
      id: 1,
      image: "/images/exercise/run_exercise.png",
      label: "เดิน - วิ่ง",
    },
    {
      id: 2,
      image: "/images/exercise/bicycle_exercise.png",
      label: "ปั่นจักรยาน",
    },
    {
      id: 3,
      image: "/images/exercise/weight_exercise.png",
      label: "เวทเทรนนิ่ง",
    },
    {
      id: 4,
      image: "/images/exercise/aerobic_exercise.png",
      label: "แอโรบิค",
    },
    {
      id: 6,
      image: "/images/exercise/breathe_exercise.png",
      label: "โยคะ",
    },
    {
      id: "",
      image: "/images/exercise/others_exercise.png",
      label: "อื่น ๆ",
    },
  ];
  
  return (
    <Box
      sx={{
        background:
          "linear-gradient(94.1deg, rgba(4, 205, 133, 0.9) -20.26%, rgba(28, 185, 243, 0.9) 149.71%)",
        minHeight: 450,
        display: "flex",
        flexDirection: "column",
        p: { xs: "15px", sm: "50px" },
        gap: "40px",
        justifyContent: "center",
      }}
    >
      <Typography
        align="center"
        sx={{ typography: { xs: "h4", sm: "h2" } }}
        color="white"
      >
        ส่งผลออกกำลังกาย
      </Typography>

      <Grid
        alignItems="center"
        justifyContent="center"
        container
        spacing={{ xs: 2, md: 3 }}
      >
        {items.map((item, index) => (
          <Grid
            item
            key={index}
            component={RouterLink}
            to="/add-result-exercise"
            state={{ activity_type_id: item.id }}
            sx={{ textDecoration: "none" }}
            xs={4}
            md={2}
          >
            <Box display="flex" justifyContent="center">
              <Box
                component="img"
                sx={{
                  width: { xs: 100, sm: 130, lg: 170 },
                  height: { xs: 100, sm: 130, lg: 170 },
                  "&:hover": {
                    opacity: "95%",
                  },
                  cursor: "pointer",
                }}
                alt=""
                src={item.image}
              />
            </Box>

            <Typography
              align="center"
              sx={{
                typography: { xs: "body1", sm: "h4" },
                fontWeight: "400 !important",
              }}
              color="white"
            >
              {item.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ResultExercise;
