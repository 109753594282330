import axiosService from "../api/axios";

export const provinceService = () => {
  return axiosService.get("/master-data/province");
};

export const districtService = (provinceId: string) => {
  return axiosService.get(
    "/master-data/district?" +
      new URLSearchParams({
        province_id: provinceId,
      })
  );
};

export const subdistrictService = (districtId: string) => {
  return axiosService.get(
    "/master-data/subdistrict?" +
      new URLSearchParams({
        district_id: districtId,
      })
  );
};

export const postcodeService = (subdistrictId: string) => {
  return axiosService.get(
    "/master-data/postcode?" +
      new URLSearchParams({
        subdistrict_id: subdistrictId,
      })
  );
};

export const exerciseActivityTypeService = () => {
  return axiosService.get("/master-data/exercise-activity-type");
};

export const memberTypeService = () => {
  return axiosService.get("/master-data/member-type");
};

export const exerciseActivityTypeFiitService = () => {
  return axiosService.get("/master-data/exercise-activity-type-fiit");
};

export const getSystemVariableService = () => {
  return axiosService.get("/master-data/system-variable");
};
