import {
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import dayjs from "dayjs";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../features/userStore/hook";
import {
  getProfile,
  setProfile,
} from "../../../features/userStore/libs/profileSlice";
import React from "react";
import { IMaskInput } from "react-imask";
import {
  postSelfVerifyService,
  profileService,
} from "../../../services/auth.service";
import { modalStyle } from "../../../consts/styles";
import Error1Modal from "../Error1Modal";
import { monthItemsForVerify } from "../../../consts/app";

interface State {
  textmask: string;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextLaserCode = React.forwardRef<HTMLElement, CustomProps>(
  function TextLaserCode(props, ref: any) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="XX0-0000000-00"
        definitions={{
          X: /[a-zA-Z]/,
        }}
        inputRef={ref}
        onAccept={(value: any) => {
          if (typeof value === "string") value = value.toLocaleUpperCase();
          onChange({ target: { name: props.name, value: value } });
        }}
        overwrite
      />
    );
  }
);

const VerifiedRedeemModal = ({ handleClose }: any) => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(getProfile);

  // states
  const [years, setYears] = useState<any[]>([]);
  const [days, setDays] = useState<number>(0);
  const [laserCode, setLaserCode] = useState<State>({
    textmask: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleLaserCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLaserCode({
      textmask: event.target.value,
    });
  };

  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => setErrorOpen(false);
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  // form
  const { register, formState, handleSubmit, getValues } = useForm({
    defaultValues: {
      first_name: profile.first_name,
      last_name: profile.last_name,
      identification_number: profile.identification_number,
      laser_code: "",
      year: profile.date_of_birth
        ? dayjs(profile.date_of_birth).format("YYYY")
        : new Date().getFullYear().toString(),
      month: profile.date_of_birth
        ? dayjs(profile.date_of_birth).format("MM")
        : "00",
      day: profile.date_of_birth
        ? dayjs(profile.date_of_birth).format("DD")
        : "00",
    },
  });

  const onError = (errors: any, e: any) => console.log(errors, e);

  const { isDirty, isValid, errors } = formState;

  const loadMoreItems = (event: any) => {
    if (event.target.scrollTop + 500 === event.target.scrollHeight) {
      //user is at the end of the list so load more items
      const yearList: any[] = [];
      Array.from(Array(10).keys()).map((index: any) => {
        yearList.push(parseInt(years[years.length - 1]) + (index + 1));
      });
      setYears([...years, ...yearList].sort());
    } else if (event.target.scrollTop === 0) {
      const yearList: any[] = [];
      Array.from(Array(10).keys()).map((index: any) => {
        yearList.push(parseInt(years[0]) - (index + 1));
      });
      setYears([...yearList, ...years].sort());
    }
  };

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();

      const body = {
        first_name: values.first_name,
        last_name: values.last_name,
        identification_number: values.identification_number,
        date_of_birth: `${values.year}-${values.month || "00"}-${
          values.day || "00"
        }`,
        laser_code: values.laser_code,
      };

      await postSelfVerifyService(body);

      const response = await profileService();
      dispatch(setProfile(response.data));
      handleClose(true);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(
        resMessage === "เลขบัตรนี้ยืนยันตัวตนไปแล้ว"
          ? resMessage
          : "ข้อมูลไม่ถูกต้อง"
      );
      handleLoaded();
    }
  };

  const onYearChanged = (event: any) => {
    const { month } = getValues();
    const daysInMonth = dayjs(
      `${event.target.value}-${month}-01`
    ).daysInMonth();
    setDays(daysInMonth + 1);
  };

  const onMonthChanged = (event: any) => {
    const { year } = getValues();
    const daysInMonth = dayjs(`${year}-${event.target.value}-01`).daysInMonth();
    setDays(daysInMonth + 1);
  };

  const setForm = () => {
    const year = profile.date_of_birth
      ? dayjs(profile.date_of_birth).format("YYYY")
      : new Date().getFullYear().toString();

    const yearList: any[] = [parseInt(year)];
    Array.from(Array(10).keys()).map((index: any) => {
      yearList.push(parseInt(year) - (index + 1));
      yearList.push(parseInt(year) + (index + 1));
    });

    const daysInMonth = profile.date_of_birth
      ? dayjs(profile.date_of_birth).daysInMonth()
      : 1;
    setDays(daysInMonth);
    setYears(yearList.sort());
  };

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  useEffect(() => {
    if (profile) setForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <Box>
        <Typography align="center" variant="h5" color="common.black">
          โปรดยืนยันตัวตน เพื่อรับรางวัล
        </Typography>

        <Typography align="center" variant="body2" color="primary.main">
          ใช้ข้อมูลเพื่อตรวจสอบตัวตนเท่านั้น
        </Typography>
      </Box>

      <FormControl fullWidth>
        <FormLabel>
          <Typography variant="body1" color="common.black">
            ชื่อ
          </Typography>
        </FormLabel>

        <TextField
          color="lightGrey"
          placeholder="ชื่อจริง"
          error={!!errors["first_name"]}
          {...register("first_name", { required: true })}
          onInput={(e: any) => {
            e.target.value = e.target.value.toString().slice(0, 100);
          }}
        />
      </FormControl>

      <FormControl fullWidth>
        <FormLabel>
          <Typography variant="body1" color="common.black">
            นามสกุล
          </Typography>
        </FormLabel>

        <TextField
          color="lightGrey"
          placeholder="นามสกุล"
          error={!!errors["last_name"]}
          {...register("last_name", { required: true })}
          onInput={(e: any) => {
            e.target.value = e.target.value.toString().slice(0, 100);
          }}
        />
      </FormControl>

      <FormControl fullWidth>
        <FormLabel>
          <Typography variant="body1" color="common.black">
            เลขบัตรประชาชน
          </Typography>
        </FormLabel>

        <TextField
          color="lightGrey"
          placeholder="เลขบัตรประชาชน 13 หลัก"
          error={!!errors["identification_number"]}
          helperText={
            errors["identification_number"]
              ? (errors["identification_number"].message as string)
              : ""
          }
          {...register("identification_number", {
            required: true,
            minLength: 13,
            maxLength: 13,
          })}
          onInput={(e: any) => {
            e.target.value = e.target.value.replace(/\D/g, "").slice(0, 13);
          }}
        />
      </FormControl>

      <FormControl fullWidth>
        <FormLabel>
          <Typography variant="body1" color="common.black">
            เลขหลังบัตรประชาชน
          </Typography>
        </FormLabel>

        <OutlinedInput
          value={laserCode.textmask}
          inputComponent={TextLaserCode as any}
          placeholder="XX0-0000000-00"
          error={!!errors["laser_code"]}
          {...register("laser_code", {
            minLength: 14,
            maxLength: 14,
            required: true,
            onChange: handleLaserCodeChange,
          })}
        />
      </FormControl>

      <FormControl fullWidth>
        <FormLabel>
          <Typography variant="body1" color="common.black">
            ปีเกิด
          </Typography>
        </FormLabel>

        <Select
          defaultValue={
            profile.date_of_birth
              ? dayjs(profile.date_of_birth).format("YYYY")
              : new Date().getFullYear().toString()
          }
          sx={{
            "& .MuiSelect-select .notranslate::after": "ปปปป"
              ? {
                  content: `"ปปปป"`,
                  opacity: 0.42,
                }
              : {},
          }}
          MenuProps={{
            PaperProps: {
              onScroll: loadMoreItems,
              style: {
                height: 500,
              },
            },
          }}
          {...register("year", { required: true })}
          onChange={onYearChanged}
        >
          {years.map((item: number, index: any) => (
            <MenuItem key={index} value={item}>
              {item + 543}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <FormLabel>
          <Typography variant="body1" color="common.black">
            เดือนเกิด
          </Typography>
        </FormLabel>

        <Select
          defaultValue={
            profile.date_of_birth
              ? dayjs(profile.date_of_birth).format("MM")
              : "00"
          }
          sx={{
            "& .MuiSelect-select .notranslate::after": "ดด"
              ? {
                  content: `"ดด"`,
                  opacity: 0.42,
                }
              : {},
          }}
          {...register("month")}
          onChange={onMonthChanged}
        >
          {monthItemsForVerify.map((month: string, index: any) => (
            <MenuItem key={index} value={index <= 9 ? `0${index}` : index}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <FormLabel>
          <Typography variant="body1" color="common.black">
            วันเกิด
          </Typography>
        </FormLabel>

        <Select
          defaultValue={
            profile.date_of_birth
              ? dayjs(profile.date_of_birth).format("DD")
              : "00"
          }
          sx={{
            "& .MuiSelect-select .notranslate::after": "วว"
              ? {
                  content: `"วว"`,
                  opacity: 0.42,
                }
              : {},
          }}
          {...register("day")}
        >
          {Array.from(Array(days).keys()).map((index: any) => (
            <MenuItem key={index} value={index <= 9 ? `0${index}` : index}>
              {index === 0 ? "ไม่ทราบวันเกิด" : index}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        fullWidth
        color="green"
        variant="contained"
        onClick={handleSubmit(onSubmitHandler, onError)}
        disabled={!isDirty || !isValid || loading}
      >
        <Typography variant="subtitle2" color="white">
          ยืนยันตัวตน
        </Typography>
      </Button>

      <Modal keepMounted open={openError}>
        <Box sx={modalStyle} width={340}>
          <Error1Modal
            title={errorMessage}
            subtitle={`กรุณาลองใหม่อีกครั้ง`}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default VerifiedRedeemModal;
