import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

const CreateBmiModal = ({ onSubmitHandler, handleClose, loading }: any) => {
  // form
  const { register, formState, getValues } = useForm({
    defaultValues: {
      weight: "",
      height: "",
      waistline: "",
    },
    mode: "all",
  });

  const { isValid, isDirty, errors } = formState;

  return (
    <Box display="flex" flexDirection="column" gap="8px" padding="24px">
      <Box display="flex" width={1} onClick={handleClose} justifyContent="end">
        <CancelOutlinedIcon
          sx={{ cursor: "pointer", color: "lightGrey.main" }}
        />
      </Box>

      <Typography align="center" typography="h5" color="black.main">
        บันทึกข้อมูล
      </Typography>

      <Typography align="center" typography="h5" color="black.main">
        เพื่อดู BMI ล่าสุดของท่าน
      </Typography>

      <FormControl fullWidth>
        <TextField
          type="number"
          color="lightGrey"
          placeholder="กรอกน้ำหนัก (กิโลกรัม)"
          error={!!errors["weight"]}
          helperText={errors["weight"] ? errors["weight"].message : ""}
          {...register("weight", {
            required: true,
            min: {
              value: 10,
              message: "น้ำหนัก จำกัดช่วง 10-200 กก.",
            },
            max: {
              value: 200,
              message: "น้ำหนัก จำกัดช่วง 10-200 กก.",
            },
          })}
        />
      </FormControl>

      <FormControl fullWidth>
        <TextField
          type="number"
          color="lightGrey"
          placeholder="กรอกส่วนสูง (เซนติเมตร)"
          error={!!errors["height"]}
          helperText={errors["height"] ? errors["height"].message : ""}
          {...register("height", {
            required: true,
            min: {
              value: 90,
              message: "ส่วนสูง จำกัด 90-250 ซม.",
            },
            max: {
              value: 250,
              message: "ส่วนสูง จำกัด 90-250 ซม.",
            },
          })}
        />
      </FormControl>

      <FormControl fullWidth>
        <TextField
          type="number"
          color="lightGrey"
          placeholder="กรอกรอบเอว (นิ้ว)"
          error={!!errors["waistline"]}
          helperText={errors["waistline"] ? errors["waistline"].message : ""}
          {...register("waistline", {
            required: true,
            min: {
              value: 0,
              message: "รอบเอว จำกัด 0-100 นิ้ว",
            },
            max: {
              value: 100,
              message: "รอบเอว จำกัด 0-100 นิ้ว",
            },
          })}
        />
      </FormControl>

      <Button
        color="green"
        variant="contained"
        fullWidth
        onClick={() => onSubmitHandler(getValues())}
        disabled={!isValid || !isDirty || loading}
      >
        <Typography variant="subtitle2" color="white">
          <FormattedMessage id="common.save" />
        </Typography>
      </Button>
    </Box>
  );
};

export default CreateBmiModal;
