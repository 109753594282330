import axiosService from "../api/axios";

export const getNewsByIdService = (id: any) => {
  return axiosService.get(`/news/${id}`);
};

export const getNewsService = (params: any) => {
  return axiosService.get(
    "/news/publish-news?" + new URLSearchParams({ ...params })
  );
};