import {
  Box,
  Button,
  Container,
  Typography,
  Modal,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from "@mui/icons-material/Close";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Link as RouterLink } from "react-router-dom";
import { boxStyle, containerStyle, pageStyle } from "../../../consts/styles";
import {
  getHealthPoint,
  setHealthPoint,
  getProfile,
} from "../../../features/userStore/libs/profileSlice";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../features/userStore/hook";
import {
  reserveRewardService,
  rewardPublishByIdService,
} from "../../../services/reward.service";
import { configEnv } from "../../../configs/config-env";
import { modalStyle } from "../../../consts/styles";
import SuccessModal from "../../../components/modals/SuccessModal";
import VerifiedRedeemModal from "../../../components/modals/profile/VerifiedRedeemModal";

const RewardDescription = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const healthPoint = useAppSelector(getHealthPoint);
  const profile = useAppSelector(getProfile);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const rewardId = searchParams.get("reward_id");

  const [reward, setReward] = useState<any>(null);
  const [openSuccess, setSuccesOpen] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [openVerified, setVerifiedOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const handleSuccessOpen = () => setSuccesOpen(true);
  const handleSuccessClose = () => {
    setSuccessMessage("");
    setSuccesOpen(false);
  };
  const handleVerifiedOpen = () => setVerifiedOpen(true);
  const handleVerifiedClose = (updated = false) => {
    if (updated) {
      setSuccessMessage("ยืนยันตัวตนสำเร็จแล้ว");
      setTimeout(() => {
        handleSuccessClose();
      }, configEnv.modal_close);
    }
    setVerifiedOpen(false);
  };
  const handleAlertOpen = (reward: any) => {
    if (!profile.self_verification) {
      handleVerifiedOpen();
    } else {
      setReward(reward);
      setOpenAlert(true);
    }
  };
  const handleAlertClose = () => {
    setOpenAlert(false);
  };
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  const rewardPublishById = () => {
    rewardPublishByIdService(rewardId).then((resp) => {
      setReward(resp.data);
    });
  };

  const postReserveReward = async () => {
    try {
      handleLoading();
      const response = await reserveRewardService({
        reward_id: reward.reward_id,
      });
      dispatch(setHealthPoint(response.data.remain_health_point));
      handleAlertClose();
      setSuccessMessage("ยืนยันแลกสิทธิ์ของรางวัล สำเร็จ");
      setTimeout(() => {
        handleSuccessClose();
        navigate("/reward");
      }, configEnv.modal_close);
      handleLoaded();
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      handleAlertClose();
      setErrorMessage(resMessage);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (rewardId) {
      rewardPublishById();
    } else {
      if (!location.state && !rewardId) {
        navigate("");
        return;
      }
      setReward(location.state.reward);
    }
  }, [location, navigate, rewardId]);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) handleSuccessOpen();
  }, [successMessage]);

  return (
    <Box sx={pageStyle}>
      <Typography
        align="center"
        typography={{ xs: "h3", sm: "h1" }}
        color="ktgGreen.main"
        zIndex={1}
      >
        รายละเอียดของรางวัล
      </Typography>

      <Container
        component="main"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        maxWidth={false}
      >
        <Box component="img" src={reward?.image_url} width={1} />

        <Box
          display="flex"
          sx={{
            background: "#F5F7FA",
            borderRadius: "10px",
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
          }}
          padding={{ xs: "4px", sm: "8px" }}
          justifyContent="center"
        >
          <Box
            display="flex"
            width={350}
            flexDirection="column"
            justifyContent="space-around"
            gap="8px"
          >
            <Box display="flex" gap="8px" justifyContent="center">
              <Box
                component="img"
                sx={{
                  width: { xs: 24, sm: 32 },
                  height: { xs: 24, sm: 32 },
                }}
                alt="star icon"
                src="/icons/hp_coin.png"
              />

              <Typography
                alignSelf="center"
                typography={{ xs: "h5", sm: "h3" }}
                color="red"
              >
                {reward?.used_point.toLocaleString()}
              </Typography>

              <Typography
                alignSelf="center"
                typography="h5"
                fontWeight="400 !important"
                color="primary"
              >
                แต้ม
              </Typography>
            </Box>

            <Box display="flex" gap="8px" justifyContent="center">
              <Box display="flex" gap="4px">
                <Typography
                  alignSelf="center"
                  typography="h5"
                  fontWeight="400 !important"
                  color="red"
                >
                  {reward?.remain_qty.toLocaleString()} /
                </Typography>

                <Typography
                  alignSelf="center"
                  typography="h5"
                  fontWeight="400 !important"
                  color="red"
                >
                  {reward?.quantity.toLocaleString()}
                </Typography>

                <Typography
                  alignSelf="center"
                  typography="h5"
                  fontWeight="400 !important"
                  color="primary"
                >
                  สิทธิ์
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ ...boxStyle, padding: "16px" }}>
          <Typography typography="h5" color="common.black">
            {reward?.title}
          </Typography>

          <Box
            dangerouslySetInnerHTML={{
              __html: reward?.detail,
            }}
          ></Box>

          <Button
            color="green"
            variant="contained"
            disabled={
              healthPoint < reward?.used_point ||
              reward?.remain_qty <= 0 ||
              reward?.is_full_reserved
            }
            onClick={() => handleAlertOpen(reward)}
          >
            <Typography
              typography={{ sx: "body2", sm: "subtitle2" }}
              color="white"
            >
              แลกรางวัล
            </Typography>
          </Button>

          <Button
            color="blue"
            variant="contained"
            component={RouterLink}
            to="/reward"
          >
            <Typography variant="subtitle2" color="white">
              กลับหน้าแลกรางวัล
            </Typography>
          </Button>
        </Box>
      </Container>

      <Modal keepMounted open={openAlert} onClose={handleAlertClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340,
            bgcolor: "background.paper",
            borderRadius: "10px",
          }}
          display="flex"
          flexDirection="column"
          gap="8px"
        >
          <Box sx={{ textAlign: "end", p: 1 }}>
            <Typography
              onClick={handleAlertClose}
              component="span"
              sx={{ cursor: "pointer" }}
              color="cgrey.main"
            >
              <CloseIcon sx={{ fontSize: 20 }} />
            </Typography>
          </Box>

          <Box
            sx={{ px: 4, pb: 4 }}
            display="flex"
            flexDirection="column"
            gap="8px"
          >
            <Typography align="center" variant="h4" color="green.main">
              {`ยืนยันแลกสิทธิ์ของรางวัล ${reward?.title} โดยใช้แต้ม ${reward?.used_point} ?`}
            </Typography>

            <Button
              color="green"
              variant="contained"
              type="submit"
              fullWidth
              onClick={postReserveReward}
              disabled={loading}
            >
              <Typography variant="subtitle2" color="white">
                ยืนยัน
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal keepMounted open={openSuccess} onClose={handleSuccessClose}>
        <Box sx={modalStyle} width={340}>
          <SuccessModal
            message={successMessage}
            handleSuccessClose={handleSuccessClose}
            showButton={false}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340,
            bgcolor: "background.paper",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ textAlign: "end", p: 1 }}>
            <Typography
              onClick={handleErrorClose}
              component="span"
              sx={{ cursor: "pointer" }}
              color="cgrey.main"
            >
              <CloseIcon sx={{ fontSize: 20 }} />
            </Typography>
          </Box>

          <Box sx={{ px: 4, pb: 4 }}>
            <Typography align="center">
              <HighlightOffIcon sx={{ fontSize: 60, color: "#EB698F" }} />
            </Typography>
            <Typography align="center" variant="h4" color="#EB698F" mt="15px">
              {errorMessage}
            </Typography>
          </Box>
        </Box>
      </Modal>

      <Dialog
        keepMounted
        open={openVerified}
        onClose={() => handleVerifiedClose(false)}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        scroll="body"
      >
        <Box
          display="flex"
          width={1}
          onClick={() => handleVerifiedClose(false)}
          justifyContent="end"
          padding="8px 8px 0 8px"
        >
          <CancelOutlinedIcon
            sx={{ cursor: "pointer", color: "lightGrey.main" }}
          />
        </Box>
        <DialogContent sx={{ width: 300 }}>
          {openVerified && (
            <VerifiedRedeemModal handleClose={handleVerifiedClose} />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default RewardDescription;
