import axiosService from "../api/axios";

export interface Filters {
  page: any;
  size: any;
}

export const getQuestionaireService = (params: Filters) => {
  return axiosService.get(
    "/questionaire/publish-questionaire?" + new URLSearchParams({ ...params })
  );
};

export const getQuestionsByIdService = (id: any) => {
  return axiosService.get(`/questionaire/${id}/question`);
};

export const postQuestionByIdService = (id: any, body: any) => {
  return axiosService.post(`/questionaire/${id}/submit`, body);
};
