import {
  Box,
  Typography,
  Container,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Modal,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  boxStyle,
  containerStyle,
  modalStyle,
  pageStyle,
} from "../../../consts/styles";
import { postInviteMemberService } from "../../../services/community.service";
import SuccessModal from "../../../components/modals/SuccessModal";
import ErrorModal from "../../../components/modals/ErrorModal";

const CommunityMemberInvite = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [communityId, setCommunityId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openSuccess, setSuccessOpen] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);

  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => {
    setSuccessOpen(false);
    navigate("/community/view", {
      state: { id: communityId },
      replace: true,
    });
  };
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  const { register, formState, handleSubmit } = useForm({
    defaultValues: {
      invite_type: 1,
      invite_to_member_id: null,
    },
  });

  const { isValid, isDirty } = formState;

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();
      await postInviteMemberService(communityId, {
        ...values,
        invite_to_member_id: parseInt(values.invite_to_member_id),
      });
      handleSuccessOpen();
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  useEffect(() => {
    if (!location.state) {
      navigate("/community");
      return;
    }
    setCommunityId(location.state.communityId);
  }, [location, navigate]);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  return (
    <Box sx={pageStyle}>
      <Typography
        align="center"
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="ktgGreen.main"
        zIndex={100}
      >
        เชิญเพื่อนเข้ากลุ่ม
      </Typography>

      <Container
        component="main"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        maxWidth={false}
      >
        <Box sx={{ ...boxStyle, padding: "24px" }}>
          <FormControl fullWidth>
            <FormLabel>
              <Typography variant="body1" color="common.black">
                เชิญเพื่อนโดย e-BIB
              </Typography>
            </FormLabel>

            <TextField
              placeholder="กรอก e-BIB ของเพื่อน"
              {...register("invite_to_member_id", { required: true })}
              onInput={(e: any) => {
                e.target.value = e.target.value.replace(/\D/g, "").slice(0, 7);
              }}
            />
          </FormControl>

          <Button
            fullWidth
            color="green"
            variant="contained"
            onClick={handleSubmit(onSubmitHandler)}
            disabled={!isValid || !isDirty || loading}
          >
            <Typography variant="subtitle2" color="white">
              เชิญ
            </Typography>
          </Button>
        </Box>

        <Button
          fullWidth
          color="cwhite"
          variant="contained"
          sx={{ boxShadow: "none" }}
          component={RouterLink}
          to="/community/view"
          state={{ id: communityId }}
        >
          <Typography typography="subtitle2" color="black.main">
            กลับ
          </Typography>
        </Button>
      </Container>

      <Modal keepMounted open={openSuccess}>
        <Box sx={modalStyle} width="340px">
          <SuccessModal
            message={"เชิญเพื่อนสำเร็จ"}
            handleSuccessClose={handleSuccessClose}
            showButton={true}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle} width="340px">
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default CommunityMemberInvite;
