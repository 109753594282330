import { Box, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const WarningModal = ({ message, handleClose, btnMessage }: any) => {
  return (
    <Box display="flex" flexDirection="column" padding="16px" gap="4px">
      <Box
        component="a"
        alignSelf="end"
        sx={{ cursor: "pointer" }}
        onClick={handleClose}
      >
        <CloseIcon sx={{ fontSize: 20 }} />
      </Box>

      <Box display="flex" flexDirection="column" gap="16px">
        <Typography align="center">
          <ErrorOutlineIcon sx={{ fontSize: 60, color: "#FFCC4C" }} />
        </Typography>

        <Typography align="center" variant="h5" color="primary">
          {message}
        </Typography>

        {btnMessage && (
          <Button
            color="green"
            variant="contained"
            sx={{ color: "white" }}
            onClick={handleClose}
          >
            <Typography variant="subtitle2">{btnMessage}</Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default WarningModal;
