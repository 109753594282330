import { Box, Button, Typography } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

const ConfirmModal = ({ message, handleConfirm, handleClose }: any) => {
  return (
    <Box display="flex" flexDirection="column" padding="16px">
      <Box display="flex" flexDirection="column" gap="16px">
        <Typography align="center">
          <HelpOutlineOutlinedIcon
            sx={{ fontSize: 50, color: "secYellow.main" }}
            color="error"
          />
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        ></Box>

        <Box display="flex" gap="8px">
          <Button
            fullWidth
            color="blue"
            variant="outlined"
            onClick={handleClose}
          >
            <Typography typography="subtitle2" color="blue.main">
              ยกเลิก
            </Typography>
          </Button>

          <Button
            fullWidth
            color="blue"
            variant="contained"
            onClick={handleConfirm}
          >
            <Typography typography="subtitle2" color="white">
              ตกลง
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmModal;
