import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Typography,
  Container,
  Toolbar,
  IconButton,
  Avatar,
  Menu,
  AppBar,
  Box,
  MenuItem,
  useTheme,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Divider,
  Modal,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Fab,
  Badge,
  DialogTitle,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { isMobile } from "react-device-detect";
import { deleteUserService, logOutService } from "../../services/auth.service";
import {
  getAvatar,
  getHealthPoint,
  getProfile,
  setFiitRegisteredDate,
  setProfile,
} from "../../features/userStore/libs/profileSlice";
import { useAppDispatch, useAppSelector } from "../../features/userStore/hook";
import { modalStyle } from "../../consts/styles";
import ConnectFiitModal from "../modals/ConnectFiitModal";
import PdapContent from "../../components/PdapContent";
import {
  patchFiitPadpaService,
  postFiitDisConnectService,
  getFiitPdpaService,
} from "../../services/fiit.service";
import ErrorModal from "../modals/ErrorModal";
import CancleConnectFiitModal from "../modals/CancleConnectFiitModal";
import ConfirmCancleConnectFiitModal from "../modals/ConfirmCancleConnectFiitModal";
import SuccessModal from "../modals/SuccessModal";
import QrcodeInviteModal from "../modals/community/QrcodeInviteModal";
import { getNotificationUnreadService } from "../../services/notification.service";
import { configEnv } from "../../configs/config-env";
import { getItem, removeItem } from "../../services/localStorage.service";

const TopBar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const avatar = useAppSelector(getAvatar);
  const profile = useAppSelector(getProfile);
  const healthPoint = useAppSelector(getHealthPoint);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  // states
  const [openSuccess, setSuccessOpen] = useState<boolean>(false);
  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const [openConnectFiitToken, setOpenConnectFiitToken] =
    useState<boolean>(false);
  const [openFiitPdpa, setFiitPdpaOpen] = useState(false);
  const [acceptFiitPdpa, setAcceptFiitPdpa] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState(false);
  const [openCancleConnectFiit, setCancleConnectFiitOpen] =
    useState<boolean>(false);
  const [openConfirmCancleConnectFiit, setConfirmCancleConnectFiitOpen] =
    useState<boolean>(false);
  const [consent, setConsent] = useState("");
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const [openConfirmCancelUser, setOpenConfirmCancelUser] =
    useState<boolean>(false);
  const [username, setUsername] = useState("");
  // end states

  // handle
  const handleConfirmCancelUserOpen = () => {
    handleCloseUserMenu();
    setOpenConfirmCancelUser(true);
  };
  const handleConfirmCancelUserClose = () => setOpenConfirmCancelUser(false);
  const handleInviteModalOpen = () => {
    handleCloseUserMenu();
    setOpenInviteModal(true);
  };
  const handleInviteModalClose = () => setOpenInviteModal(false);
  const handleWarningClose = () => setOpenWarning(false);
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);
  const handleConnectFiitTokenOpen = () => {
    getFiitPdpaService().then((resp) => {
      setConsent(resp.data.pdpa);
      setOpenConnectFiitToken(true);
    });
  };
  const handleConnectFiitTokenClose = () => setOpenConnectFiitToken(false);
  const handleCancleConnectFiitOpen = () => {
    handleCloseUserMenu();
    setCancleConnectFiitOpen(true);
  };
  const handleCancleConnectFiitClose = () => setCancleConnectFiitOpen(false);
  const handleConfirmCancleConnectFiitOpen = () => {
    setConfirmCancleConnectFiitOpen(true);
    handleCancleConnectFiitClose();
  };
  const handleConfirmCancleConnectFiitClose = () =>
    setConfirmCancleConnectFiitOpen(false);

  const handleFiitPdpaOpen = () => {
    handleConnectFiitTokenClose();
    setFiitPdpaOpen(true);
  };
  const handleFiitPdpaClose = () => setFiitPdpaOpen(false);

  const handleAcceptFiitPdpaChange = (event: any) => {
    setAcceptFiitPdpa(acceptFiitPdpa ? false : true);
  };
  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => {
    setSuccessOpen(false);
  };

  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorOpen(false);
    setErrorMessage("");
  };

  const handleAcceptFiitPdpaSubmit = async () => {
    try {
      handleLoading();
      await patchFiitPadpaService({ username: profile.username });
      setTimeout(() => {
        navigate("/connect-fiit", {
          state: {
            username: profile.username, // username
          },
          replace: true,
        });
        handleFiitPdpaClose();
      }, 1000);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  const navigateToNotifications = () => {
    navigate("/notifications");
  };

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorSideMenu, setAnchorSideMenum] = useState<boolean>(false);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setAnchorSideMenum(open);
    };

  const list = () => (
    <Box
      width={300}
      padding="30px 16px"
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box display="flex" padding="24px 0" gap="8px">
        <Avatar
          alt="avatar"
          src={avatar || "/images/avatar-default.png"}
          sx={{ width: 50, height: 50 }}
        />

        <Box display="flex" flexDirection="column">
          <Box display="flex" gap="8px">
            <Typography
              variant="subtitle2"
              alignSelf="center"
              color="common.black"
            >
              {profile?.first_name}
            </Typography>
            <Typography
              variant="subtitle2"
              alignSelf="center"
              color="common.black"
            >
              {profile?.last_name}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            color="ktgGreen.main"
            component={RouterLink}
            to="/profile"
            sx={{ textDecoration: "none" }}
          >
            แก้ไขโปรไฟล์
          </Typography>
        </Box>
      </Box>
      <Divider />
      <List>
        <ListItem key={0} disablePadding>
          <ListItemButton
            sx={{ gap: "8px" }}
            component={RouterLink}
            to="/community"
          >
            <ListItemIcon sx={{ minWidth: 24 }}>
              <PeopleAltOutlinedIcon color="ktgGreen" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  fontWeight={500}
                  color="black.main"
                >
                  กลุ่ม
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem key={0} disablePadding>
          <ListItemButton sx={{ gap: "8px" }} onClick={handleInviteModalOpen}>
            <ListItemIcon sx={{ minWidth: 24 }}>
              <Box
                component="img"
                width={24}
                height={24}
                src="/icons/invite_sm_mobile.png"
              ></Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  fontWeight={500}
                  color="black.main"
                >
                  เชิญเพื่อน
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem key={1} disablePadding>
          <ListItemButton
            sx={{ gap: "8px" }}
            component={RouterLink}
            to="/dashboard-overview"
          >
            <ListItemIcon sx={{ minWidth: 24 }}>
              <Box
                component="img"
                width={24}
                height={24}
                src="/icons/dashboard_sm_mobile.png"
              ></Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  fontWeight={500}
                  color="black.main"
                >
                  รายงาน
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem key={2} disablePadding>
          <ListItemButton
            sx={{ gap: "8px" }}
            component={RouterLink}
            to="/achievement-view"
          >
            <ListItemIcon sx={{ minWidth: 24 }}>
              <Box
                component="img"
                width={24}
                height={24}
                src="/icons/success_sm_mobile.png"
              ></Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  fontWeight={500}
                  color="black.main"
                >
                  ความสำเร็จ
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem key={3} disablePadding>
          <ListItemButton sx={{ gap: "8px" }} component={RouterLink} to="/news">
            <ListItemIcon sx={{ minWidth: 24 }}>
              <Box
                component="img"
                width={24}
                height={24}
                src="/icons/news_sm_mobile.png"
              ></Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  fontWeight={500}
                  color="black.main"
                >
                  ข่าวสารกรมอนามัย
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem key={4} disablePadding>
          <ListItemButton
            sx={{ gap: "8px" }}
            component={RouterLink}
            to="/health-literacy"
          >
            <ListItemIcon sx={{ minWidth: 24 }}>
              <Box
                component="img"
                width={24}
                height={24}
                src="/icons/knowledge_sm_mobile.png"
              ></Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  fontWeight={500}
                  color="black.main"
                >
                  รอบรู้สุขภาพ
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem key={5} disablePadding>
          <ListItemButton
            sx={{ gap: "8px" }}
            component={RouterLink}
            to="/questionaires"
          >
            <ListItemIcon sx={{ minWidth: 24 }}>
              <Box
                component="img"
                width={24}
                height={24}
                src="/icons/questionnaire_sm_mobile.png"
              ></Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  fontWeight={500}
                  color="black.main"
                >
                  แบบสอบถาม
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem key={6} disablePadding>
          <ListItemButton sx={{ gap: "8px" }} component={RouterLink} to="/faq">
            <ListItemIcon sx={{ minWidth: 24 }}>
              <Box
                component="img"
                width={24}
                height={24}
                src="/icons/FAQ_sm_mobile.png"
              ></Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  fontWeight={500}
                  color="black.main"
                >
                  FAQ
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding onClick={handleCancleConnectFiitOpen}>
          <ListItemButton sx={{ gap: "8px" }}>
            <ListItemIcon sx={{ minWidth: 16 }}>
              <Box
                component="img"
                width={16}
                height={16}
                src="/images/fiit/cancel-connect.png"
              ></Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  typography="subtitle2"
                  fontWeight={500}
                  color="black.main"
                >
                  ยกเลิกการเชื่อมต่อ FIIT
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem key={1} disablePadding onClick={handleConfirmCancelUserOpen}>
          <ListItemButton sx={{ gap: "8px" }}>
            <ListItemIcon sx={{ minWidth: 24 }}>
              <Box
                component="img"
                width={24}
                height={24}
                src="/icons/cancel_user.png"
              ></Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  fontWeight={500}
                  color="black.main"
                >
                  ยกเลิกการเป็นสมาชิก
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>

        <ListItem key={2} disablePadding onClick={logOut}>
          <ListItemButton sx={{ gap: "8px" }}>
            <ListItemIcon sx={{ minWidth: 24 }}>
              <Box
                component="img"
                width={24}
                height={24}
                src="/icons/logout_sm_mobile.png"
              ></Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  fontWeight={500}
                  color="black.main"
                >
                  ออกจากระบบ
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handlePostFiitDisConnect = async () => {
    try {
      handleLoading();
      handleConfirmCancleConnectFiitClose();
      await postFiitDisConnectService();

      dispatch(setFiitRegisteredDate(null));

      handleLoaded();
      setSuccessMessage("ยกเลิกการเชื่อมต่อสำเร็จ");
      handleSuccessOpen();
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  const logOut = async () => {
    await logOutService();
    handleCloseUserMenu();

    const state = getItem("state");
    navigate(state ? "/pin" : "/login", {
      state: { state, isVerifyPin: true, logOut: true },
    });
  };

  const handleCancelUserSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    deleteUserService({
      username: profile.username,
      member_id: profile.member_id,
    }).then(async () => {
      handleConfirmCancelUserClose();
      setSuccessMessage("ยกเลิกการเป็นสมาชิกสำเร็จ");
      handleSuccessOpen();

      await logOutService();

      removeItem("state");
      setTimeout(() => {
        navigate("/login", {
          state: { logOut: true },
        });
      }, 1000);
    });
    // Your form handling logic
  };

  const handleNavigateApp = () => {
    const url = isMobile
      ? "com.sabuydigital.fiittoken://"
      : "https://app.adjust.com/jenob24";

    // window.open(url, "_blank");
    window.location.assign(url);
  };

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  useEffect(() => {
    const fetchData = () => {
      getNotificationUnreadService({}).then((resp) => {
        dispatch(setProfile(resp.data));
      });
    };

    fetchData(); // Fetch data immediately

    const intervalId = setInterval(
      fetchData,
      configEnv.interval_count_notification
    ); // Call fetchData every 5 seconds

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, [dispatch]);

  return (
    <AppBar
      component="nav"
      sx={{
        height: "120px",
        background: "#FFF",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
        justifyContent: "center",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          px: {
            xs: "16px !important",
            md: "48px !important",
          },
        }}
      >
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center" gap="16px">
            <Box component={RouterLink} to="/home">
              <Box
                component="img"
                sx={{
                  width: { xs: 52, md: 70 },
                  height: { xs: 70, md: 80 },
                }}
                alt="Your logo."
                src="/images/logo.png"
              />
            </Box>
            {!profile.fiit_registered_date && (
              <Box onClick={handleConnectFiitTokenOpen}>
                <Box
                  component="img"
                  sx={{
                    height: { xs: 40, md: 40 },
                    cursor: "pointer",
                  }}
                  alt="fiit logo."
                  src="/images/fiit/fiit-disconnect.png"
                />
              </Box>
            )}
            {profile.fiit_registered_date && (
              <Box
                // component={RouterLink}
                // to={
                //   isMobile
                //     ? "com.sabuydigital.fiittoken://"
                //     : "https://app.adjust.com/jenob24"
                // }
                // target="_blank"
                onClick={handleNavigateApp}
              >
                <Box
                  component="img"
                  sx={{
                    height: { xs: 40, md: 40 },
                    cursor: "pointer",
                  }}
                  alt="fiit logo."
                  src="/images/fiit/logo-main.png"
                />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: { xs: "none", lg: "flex" },
              gap: "24px",
            }}
          >
            <Typography
              variant="subtitle1"
              color="common.black"
              component={RouterLink}
              to="/home"
              sx={{ textDecoration: "none" }}
            >
              หน้าแรก
            </Typography>
            <Typography
              variant="subtitle1"
              color="common.black"
              component={RouterLink}
              to="/add-result-exercise"
              sx={{ textDecoration: "none" }}
            >
              ส่งผล
            </Typography>
            <Typography
              variant="subtitle1"
              color="common.black"
              component={RouterLink}
              to="/dashboard-exercise"
              sx={{ textDecoration: "none" }}
            >
              ดูผล
            </Typography>
            <Typography
              variant="subtitle1"
              color="common.black"
              component={RouterLink}
              to="/dashboard-overview"
              sx={{ textDecoration: "none" }}
            >
              รายงาน
            </Typography>
            <Typography
              variant="subtitle1"
              color="common.black"
              component={RouterLink}
              to="/community"
              sx={{ textDecoration: "none" }}
            >
              กลุ่ม
            </Typography>
            <Typography
              variant="subtitle1"
              color="common.black"
              component={RouterLink}
              to="/reward"
              sx={{ textDecoration: "none" }}
            >
              แลกรางวัล
            </Typography>
            <Typography
              variant="subtitle1"
              color="common.black"
              component={RouterLink}
              to="/health-literacy"
              sx={{ textDecoration: "none" }}
            >
              รอบรู้สุขภาพ
            </Typography>
            <Typography
              variant="subtitle1"
              color="common.black"
              component={RouterLink}
              to="/questionaires"
              sx={{ textDecoration: "none" }}
            >
              แบบสอบถาม
            </Typography>
            <Typography
              variant="subtitle1"
              color="common.black"
              component={RouterLink}
              to="/faq"
              sx={{ textDecoration: "none" }}
            >
              FAQ
            </Typography>
          </Box>

          <Box display="flex">
            <Box sx={{ display: "flex", gap: { xs: "8px", md: "24px" } }}>
              <Box display="flex" flexDirection="column" gap="8px">
                <Box
                  display="flex"
                  gap="16px"
                  justifyContent="end"
                  alignItems="center"
                >
                  <Fab
                    sx={{
                      background: "#DDE3EA",
                      boxShadow: "none",
                      width: 40,
                      height: 40,
                    }}
                    onClick={navigateToNotifications}
                  >
                    <Badge
                      color="error"
                      variant="dot"
                      overlap="circular"
                      invisible={!profile?.notification_unread_count}
                    >
                      <NotificationsNoneIcon />
                    </Badge>
                  </Fab>
                  <React.Fragment key="right">
                    <IconButton
                      onClick={
                        matches ? handleOpenUserMenu : toggleDrawer(true)
                      }
                      sx={{ p: 0 }}
                    >
                      <Avatar
                        alt="avatar"
                        src={avatar || "/images/avatar-default.png"}
                        sx={{ width: 50, height: 50 }}
                      />
                    </IconButton>
                    <Menu
                      sx={{ mt: "45px" }}
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <Box display="flex" padding="24px" gap="8px">
                        <Avatar
                          alt="avatar"
                          src={avatar || "/images/avatar-default.png"}
                          sx={{ width: 50, height: 50 }}
                        />

                        <Box display="flex" flexDirection="column">
                          <Box display="flex" gap="8px">
                            <Typography
                              variant="subtitle2"
                              alignSelf="center"
                              color="common.black"
                            >
                              {profile?.first_name}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              alignSelf="center"
                              color="common.black"
                            >
                              {profile?.last_name}
                            </Typography>
                          </Box>
                          <Typography
                            variant="body2"
                            color="ktgGreen.main"
                            component={RouterLink}
                            to="/profile"
                            onClick={handleCloseUserMenu}
                            sx={{ textDecoration: "none" }}
                          >
                            แก้ไขโปรไฟล์
                          </Typography>
                        </Box>
                      </Box>

                      <MenuItem key="0" onClick={handleInviteModalOpen}>
                        <Box
                          component="img"
                          width={20}
                          height={20}
                          src="/icons/invite_sm_mobile.png"
                          mr="8px"
                        />
                        <Typography
                          variant="subtitle2"
                          fontWeight={500}
                          color="black.main"
                        >
                          เชิญเพื่อน
                        </Typography>
                      </MenuItem>

                      <MenuItem
                        key="1"
                        component={RouterLink}
                        to="/achievement-view"
                        onClick={handleCloseUserMenu}
                      >
                        <Box
                          component="img"
                          width={20}
                          height={20}
                          src="/icons/success_sm_mobile.png"
                          mr="8px"
                        />
                        <Typography
                          variant="subtitle2"
                          fontWeight={500}
                          color="black.main"
                        >
                          ความสำเร็จ
                        </Typography>
                      </MenuItem>

                      {profile.fiit_registered_date && (
                        <>
                          <Divider />
                          <MenuItem
                            key="2"
                            onClick={handleCancleConnectFiitOpen}
                          >
                            <Box
                              component="img"
                              width={16}
                              height={16}
                              src="/images/fiit/cancel-connect.png"
                              mr="8px"
                            />
                            <Typography variant="body1" color="common.black">
                              ยกเลิกการเชื่อมต่อ FIIT
                            </Typography>
                          </MenuItem>
                        </>
                      )}

                      <Divider />

                      <MenuItem key="3" onClick={handleConfirmCancelUserOpen}>
                        <Box
                          component="img"
                          width={20}
                          height={20}
                          src="/icons/cancel_user.png"
                          mr="8px"
                        />
                        <Typography variant="body1" color="common.black">
                          ยกเลิกการเป็นสมาชิก
                        </Typography>
                      </MenuItem>

                      <MenuItem key="4" onClick={logOut}>
                        <Box
                          component="img"
                          width={20}
                          height={20}
                          src="/icons/logout.png"
                          mr="8px"
                        />
                        <Typography variant="body1" color="common.black">
                          ออกจากระบบ
                        </Typography>
                      </MenuItem>
                    </Menu>

                    <SwipeableDrawer
                      anchor="right"
                      open={anchorSideMenu}
                      onClose={toggleDrawer(false)}
                      onOpen={toggleDrawer(true)}
                    >
                      {list()}
                    </SwipeableDrawer>
                  </React.Fragment>
                </Box>

                <Box
                  display="flex"
                  gap="8px"
                  sx={{ textDecoration: "none" }}
                  component={RouterLink}
                  to="/reward-point"
                >
                  <Box
                    component="img"
                    sx={{
                      width: { xs: 24, md: 24 },
                      height: { xs: 24, md: 24 },
                    }}
                    alt="hp coin"
                    src="/icons/hp_coin.png"
                  />

                  <Typography variant="body1" color="red" fontWeight={600}>
                    {healthPoint | 0}
                  </Typography>
                  <Typography
                    variant="body2"
                    alignSelf="center"
                    color="red"
                    fontWeight={300}
                  >
                    แต้มสุขภาพ
                  </Typography>
                </Box>
              </Box>

              {/* <Box
                display="flex"
                gap={{ xs: "8px", md: "24px" }}
                sx={{ textDecoration: "none" }}
                component={RouterLink}
                to="/reward-point"
              >
                <Box
                  component="img"
                  sx={{
                    width: { xs: 50, md: 60 },
                    height: { xs: 50, md: 60 },
                  }}
                  alt="hp coin"
                  src="/icons/hp_coin.png"
                />

                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography variant="h4" color="red">
                    {healthPoint | 0}
                  </Typography>

                  <Typography
                    variant="body1"
                    color="red"
                    sx={{ fontWeight: 300 }}
                  >
                    แต้มสุขภาพ
                  </Typography>
                </Box>
              </Box> */}
            </Box>
          </Box>
        </Toolbar>
      </Container>

      <Modal keepMounted open={openSuccess} onClose={handleSuccessClose}>
        <Box sx={modalStyle} width="340px">
          <SuccessModal
            message="ยกเลิกการเชื่อมต่อสำเร็จ"
            handleSuccessClose={handleSuccessClose}
          />
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openConnectFiitToken}
        onClose={handleConnectFiitTokenClose}
      >
        <Box sx={modalStyle} width="340px">
          <ConnectFiitModal handleFiitPdpaOpen={handleFiitPdpaOpen} />
        </Box>
      </Modal>
      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle} width={340}>
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>

      <Dialog
        open={openConfirmCancelUser}
        onClose={handleConfirmCancelUserClose}
      >
        <form onSubmit={handleCancelUserSubmit}>
          <DialogTitle typography="h4">ยืนยันยกเลิกการเป็นสมาชิก</DialogTitle>
          <DialogContent>
            <DialogContentText color="error">
              ระบุ Username เพื่อยืนยันการยกเลิก
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="username"
              name="username"
              label="Username"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => setUsername(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmCancelUserClose}>ปิด</Button>
            <Button
              color="success"
              type="submit"
              disabled={profile.username !== username}
            >
              ยืนยัน
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog keepMounted open={openFiitPdpa}>
        <DialogContent sx={{ height: "calc(100vh - 100px)" }}>
          <DialogContentText>
            <PdapContent consent={consent} />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: "32px", justifyContent: "center" }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width={1}
          >
            <FormGroup>
              <FormControlLabel
                onChange={handleAcceptFiitPdpaChange}
                control={<Checkbox color="green" value={true} />}
                label="ยอมรับข้อตกลงและเงื่อนไข"
              />
            </FormGroup>
            <Box display="flex" gap="8px">
              <Button
                color="blue"
                variant="outlined"
                type="button"
                onClick={handleFiitPdpaClose}
              >
                <Typography variant="subtitle2">
                  <FormattedMessage id="register.refuse" />
                </Typography>
              </Button>
              <Button
                color="green"
                variant="contained"
                type="button"
                onClick={handleAcceptFiitPdpaSubmit}
                disabled={!acceptFiitPdpa || loading}
              >
                <Typography variant="subtitle2" color="white">
                  <FormattedMessage id="register.accept" />
                </Typography>
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 420,
            bgcolor: "background.paper",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ textAlign: "end", p: 1 }}>
            <Typography
              onClick={handleWarningClose}
              component="span"
              sx={{ cursor: "pointer" }}
              color="cgrey.main"
            >
              <CloseIcon sx={{ fontSize: 20 }} />
            </Typography>
          </Box>

          <Typography align="center">
            <Box
              component="img"
              src="/icons/develop.png"
              width="50px"
              height="50px"
            ></Box>
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            gap="16px"
            sx={{ px: "28px", pb: "28px" }}
          >
            <Typography
              align="center"
              variant="h4"
              mt="15px"
              color="cgrey.main"
            >
              <FormattedMessage id="common.waitForDevelop" />
            </Typography>

            <Button
              color="green"
              variant="contained"
              onClick={handleWarningClose}
            >
              <Typography variant="h4" color="white">
                ตกลง
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={openCancleConnectFiit}
        onClose={handleCancleConnectFiitClose}
      >
        <Box sx={modalStyle} width={340}>
          <CancleConnectFiitModal
            handleConfirmCancleConnectFiitOpen={
              handleConfirmCancleConnectFiitOpen
            }
            handleCancleConnectFiitClose={handleCancleConnectFiitClose}
          />
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={openConfirmCancleConnectFiit}
        onClose={handleConfirmCancleConnectFiitClose}
      >
        <Box sx={modalStyle} width={340}>
          <ConfirmCancleConnectFiitModal
            handlePostFiitDisConnect={handlePostFiitDisConnect}
            handleConfirmCancleConnectFiitClose={
              handleConfirmCancleConnectFiitClose
            }
          />
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={openInviteModal}
        onClose={handleInviteModalClose}
      >
        <Box sx={modalStyle} width="340px">
          <QrcodeInviteModal />
        </Box>
      </Modal>
    </AppBar>
  );
};

export default TopBar;
