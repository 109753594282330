import axiosService from "../api/axios";

export const getFiitPdpaService = () => {
  return axiosService.get("/fiit/pdpa");
};

export const patchFiitPadpaService = (body: { username: string }) => {
  return axiosService.patch("/fiit/pdpa", body);
};

export const postRequestOtpService = (body: {
  username: string;
  phone_no: string;
}) => {
  return axiosService.post("/fiit/kaotajai-request-otp", body);
};

export const postFiitConnectService = (body: {
  username: string;
  phone_no: string;
  otp: string;
  ref_code: string;
}) => {
  return axiosService.post("/fiit/kaotajai-connect", body);
};

export const postFiitDisConnectService = () => {
  return axiosService.post("/fiit/kaotajai-disconnect");
};
