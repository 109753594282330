import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  Button,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { Box, Container } from "@mui/system";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";
import { useAppSelector } from "../../features/userStore/hook";
import { getProfile } from "../../features/userStore/libs/profileSlice";
import {
  getTopRankService,
  getTopProvinceRankService,
} from "../../services/dashboard.service";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.ktjBlue.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const RankPage = () => {
  const [filterType, setFilterType] = useState<any>(0);
  const [topRanks, setTopRanks] = useState<any>([]);
  const profile = useAppSelector(getProfile);

  const handleFilterTypeChange = (event: SelectChangeEvent) => {
    setFilterType(event.target.value);
  };

  const getTopRank = () => {
    if (!filterType) {
      getTopRankService().then((resp) => {
        setTopRanks(resp.data);
      });
    } else {
      getTopProvinceRankService().then((resp) => {
        setTopRanks(resp.data);
      });
    }
  };

  useEffect(getTopRank, [filterType]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      gap="24px"
      pt="50px"
      pb="100px"
    >
      <Typography
        align="center"
        typography={{ xs: "h3", sm: "h1" }}
        color="ktgGreen.main"
        zIndex={1}
      >
        จัดอันดับ Rank
      </Typography>

      <Container
        component="main"
        sx={{
          zIndex: 1,
          maxWidth: 600,
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
        maxWidth={false}
      >
        <Box
          display="flex"
          borderRadius="10px"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.855) 0%, rgba(255, 255, 255, 0.76) 100%)",
          }}
          padding="16px"
          gap="16px"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              textDecoration: "none",
              gap: "8px",
            }}
          >
            <Box
              component="img"
              sx={{
                width: 100,
                height: 100,
              }}
              alt="level"
              src={`/levels/Artboard – ${profile?.experience_level}.png`}
            />

            <Typography align="center" variant="h5" color="common.black">
              <FormattedMessage id="exercise.level" />{" "}
              <Box component="text" color="secGreen.main">
                {profile?.experience_level}
              </Box>
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column" gap="16px">
            <Typography
              sx={{ typography: { xs: "h4", sm: "h2" } }}
              color="common.black"
            >
              {profile?.first_name} {profile?.last_name}
            </Typography>

            <Box
              sx={{
                display: "flex",
                gap: { xs: "4px", sm: "8px" },
              }}
            >
              <Typography
                sx={{ fontSize: "16px" }}
                variant="h5"
                color="common.black"
              >
                <FormattedMessage id="statusInfo.ebib" />:
              </Typography>

              <Typography
                sx={{ fontSize: "16px" }}
                variant="h5"
                fontWeight={400}
                color="common.black"
              >
                {profile?.member_id}
              </Typography>
            </Box>

            <Box display="flex" gap={{ xs: "4px", sm: "8px" }}>
              <Typography
                sx={{ typography: { xs: "h4", sm: "h2" } }}
                color="secPink.main"
              >
                {profile?.experience_point}
              </Typography>

              <Typography
                alignSelf="end"
                typography="body1"
                color="lightGrey.main"
              >
                แต้ม
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          borderRadius="10px"
          alignItems="center"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background:
              "linear-gradient(94.1deg, rgba(4, 205, 133, 0.9) -20.26%, rgba(28, 185, 243, 0.9) 149.71%)",
          }}
          padding="32px 24px"
          gap="16px"
        >
          <Typography typography="h5" color="white">
            อันดับของคุณ
          </Typography>

          <Box display="flex" gap="16px" width={1}>
            <Box
              width={1}
              sx={{
                background: "#FFFFFF",
              }}
              padding="12px 16px"
              borderRadius="10px"
            >
              <Typography align="center" typography="h2" color="green.main">
                {profile?.rank}
              </Typography>

              <Typography
                align="center"
                typography="body1"
                sx={{ fontWeight: 500 }}
                color="primary"
              >
                ประเทศ
              </Typography>
            </Box>

            <Box
              width={1}
              sx={{
                background: "#FFFFFF",
              }}
              padding="12px 16px"
              borderRadius="10px"
            >
              <Typography align="center" typography="h2" color="green.main">
                {profile?.province_rank}
              </Typography>

              <Typography
                align="center"
                typography="body1"
                sx={{ fontWeight: 500 }}
                color="primary"
              >
                จังหวัด
              </Typography>
            </Box>
          </Box>

          <Typography typography="body1" color="white">
            *อัปเดตอันดับทุกหนึ่งชั่วโมง
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          borderRadius="10px"
          alignItems="center"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background: "#FFFFFF",
          }}
          padding="32px 24px"
          gap="16px"
        >
          <Select
            fullWidth
            value={filterType}
            onChange={handleFilterTypeChange}
          >
            <MenuItem key={0} value={0}>
              ทั้งประเทศ
            </MenuItem>
            <MenuItem key={1} value={1}>
              จังหวัด
            </MenuItem>
          </Select>

          <TableContainer
            component={Paper}
            sx={{ boxShadow: "none", borderRadius: 0 }}
          >
            <Table sx={{ borderRadius: "10px" }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>ลำดับ</StyledTableCell>
                  <StyledTableCell>Lv.</StyledTableCell>
                  <StyledTableCell>ชื่อ - สกุล</StyledTableCell>
                  <StyledTableCell>Ebib</StyledTableCell>
                  <StyledTableCell>EXP</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topRanks.map((row: any, index: number) => (
                  <StyledTableRow
                    key={index}
                    sx={{
                      background:
                        profile?.member_id === row.member_id
                          ? "#96E8AD !important"
                          : "",
                    }}
                  >
                    <StyledTableCell component="th" scope="row">
                      {row.seq_no}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box
                        component="img"
                        sx={{
                          width: 80,
                          height: 80,
                        }}
                        alt="level"
                        src={`/levels/Artboard – ${row.experience_level}.png`}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.first_name} {row.last_name}
                    </StyledTableCell>
                    <StyledTableCell>{row.member_id}</StyledTableCell>
                    <StyledTableCell>
                      {row.experience_point.toLocaleString()}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Button
          color="blue"
          variant="contained"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            width: "300px",
            alignSelf: "center",
          }}
          component={RouterLink}
          to="/home"
        >
          <Typography variant="subtitle2" color="white">
            <FormattedMessage id="home" />
          </Typography>
        </Button>
      </Container>
    </Box>
  );
};

export default RankPage;
