export const boxStyle = {
  p: "24px 16px",
  background: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
  gap: "16px",
};

export const boxBlurStyle = {
  p: "24px 16px",
  background:
    "linear-gradient(180deg, rgba(255, 255, 255, 0.855) 0%, rgba(255, 255, 255, 0.76) 100%)",
  display: "flex",
  flexDirection: "column",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
  gap: "16px",
};

export const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
};

export const pageStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  paddingBottom: "100px",
  paddingTop: "56px",
  gap: "24px",
};

export const containerStyle = { zIndex: 1, maxWidth: 850 };

export const boxShadowStyle = {
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
};
