import axiosService from "../api/axios";

interface Bmi {
  height: number;
  weight: number;
  waistline: number;
}

export const getBmiHistoryService = () => {
  return axiosService.get("/activity/bmi-history");
};

export const postBmiService = (body: Bmi) => {
  return axiosService.post("/activity/bmi", body);
};
