import { Box, Typography, Button, Modal, Container } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {
  postFiitConnectService,
  postRequestOtpService,
} from "../../../services/fiit.service";
import ErrorModal from "../../../components/modals/ErrorModal";
import {
  containerStyle,
  boxStyle,
  modalStyle,
  pageStyle,
} from "../../../consts/styles";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../features/userStore/hook";
import {
  getProfile,
  setFiitRegisteredDate,
} from "../../../features/userStore/libs/profileSlice";

const ConfirmOtpFiit = () => {
  const profile = useAppSelector(getProfile);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  const handleRequestOtp = async () => {
    try {
      handleLoading();
      const { username, phone_no } = getValues();
      const resp = await postRequestOtpService({ username, phone_no });
      setValue("otp", "");
      setValue("ref_code", resp.data.ref_code);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  // form
  const { control, formState, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      username: "",
      phone_no: "",
      otp: "",
      ref_code: "",
    },
  });

  const { isDirty, isValid } = formState;

  const onError = (errors: any, e: any) => console.log(errors, e);

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();
      await postFiitConnectService(values);

      dispatch(setFiitRegisteredDate(new Date().toISOString()));

      setTimeout(() => {
        navigate("/connect-fiit-success");
        handleLoaded();
      }, 1000);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };
  // end

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  useEffect(() => {
    if (!location.state) {
      navigate("");
      return;
    }
    const { username, phone_no, ref_code } = location.state;
    setData(location.state);
    setValue("username", username);
    setValue("phone_no", phone_no);
    setValue("ref_code", ref_code);
  }, [location, navigate, setValue]);

  return (
    <Box sx={pageStyle}>
      <Box
        component="img"
        src="/images/logo.png"
        alt="logo"
        width={75}
        height={100}
      />

      <Container
        component="main"
        sx={{ ...containerStyle, maxWidth: "400px" }}
        maxWidth={false}
      >
        <Box sx={{ ...boxStyle, alignItems: "center", padding: "40px 32px" }}>
          <Box
            component="img"
            src="/images/fiit/logo.png"
            width={110}
            height={50}
          />

          <Box
            component="img"
            src="/images/fiit/otp-sent.png"
            width={160}
            height={160}
          />

          <Typography align="center" variant="h4" color="black.main">
            ส่งหมายเลข OTP แล้ว!
          </Typography>

          <Box>
            <Typography align="center" variant="body1" color="cgrey.main">
              กรอกหมายเลขที่ส่งให้เบอร์ {data?.phone_no}
            </Typography>

            <Typography align="center" variant="body1" color="cgrey.main">
              รหัสอ้างอิง: {data?.ref_code}
            </Typography>
          </Box>

          <Controller
            name="otp"
            control={control}
            rules={{ validate: (value) => value.length === 6 }}
            render={({ field, fieldState }) => (
              <Box>
                <MuiOtpInput
                  sx={{
                    gap: 1,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "4px !important",
                    },
                  }}
                  TextFieldsProps={{
                    placeholder: "-",
                  }}
                  onInput={(e: any) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  }}
                  {...field}
                  length={6}
                />
              </Box>
            )}
          />

          <Button
            fullWidth
            color="green"
            variant="contained"
            onClick={handleSubmit(onSubmitHandler, onError)}
            disabled={!isDirty || !isValid || loading}
          >
            <Typography variant="subtitle2" color="white">
              ยืนยัน
            </Typography>
          </Button>

          <Typography
            variant="body1"
            color="black.main"
            sx={{ cursor: "pointer" }}
            onClick={handleRequestOtp}
          >
            ขอรหัส OTP อีกครั้ง
          </Typography>

          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component={RouterLink}
            to={profile ? "/home" : "/login"}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
        </Box>
      </Container>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle} width={340}>
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default ConfirmOtpFiit;
