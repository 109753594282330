import axiosService from "../api/axios";

export const getPublishCourseService = () => {
  return axiosService.get("/course/publish-course");
};

export const getCourseProgressService = (courseId: any) => {
  return axiosService.get(`/course/${courseId}/progress`);
};

export const postApplyCourseService = (courseId: any, body: any) => {
  return axiosService.post(`/course/${courseId}/apply`, body);
};

export const getLessonProgressService = (courseId: any) => {
  return axiosService.get(`/course/${courseId}/lesson-progress`);
};

export const postLearningTimeService = (
  courseId: any,
  lessonId: any,
  body: any
) => {
  return axiosService.post(
    `/course/${courseId}/lesson/${lessonId}/learn`,
    body
  );
};

export const getLessonQuizService = (courseId: any, lessonId: any) => {
  return axiosService.get(`/course/${courseId}/lesson/${lessonId}/quiz`);
};

export const postLessonQuizService = (
  courseId: any,
  lessonId: any,
  body: any
) => {
  return axiosService.post(
    `/course/${courseId}/lesson/${lessonId}/submit-quiz`,
    body
  );
};

export const postCourseEvaluate = (courseId: any, body: any) => {
  return axiosService.post(`/course/${courseId}/submit-evaluate `, body);
};
