import { Box, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { getCourseProgressService } from "../../../services/course.service";

const CourseDescriptionModal = ({ courseId }: any) => {
  const [course, setCourse] = useState<any>(null);

  const getCourseProgress = () => {
    getCourseProgressService(courseId).then((resp) => {
      setCourse(resp.data);
    });
  };

  useEffect(getCourseProgress, [courseId]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="8px"
      padding="16px"
    >
      <Box
        component="img"
        src="/images/logo.png"
        width="110px"
        height="150px"
      />

      <Typography align="center" variant="h4" color="ktgGreen.main">
        {course?.title}
      </Typography>

      <Typography
        align="center"
        variant="body1"
        color="black.main"
        dangerouslySetInnerHTML={{
          __html: course?.description,
        }}
      ></Typography>
    </Box>
  );
};

export default CourseDescriptionModal;
