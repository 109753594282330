import axiosService from "../api/axios";

interface Community {
  community_name: string;
  community_desc: string;
  type_id: number;
  is_limit_member: boolean;
  max_member: number;
  contact_info: string;
  address: string;
  subdistrict_id: number;
  district_id: number;
  province_id: number;
}

interface CommunityEvent {
  event: string;
  activity_type_id: number;
  start_date: string;
  end_date: string;
  result_count_type: number;
}

interface InviteMember {
  invite_type: number;
  invite_to_member_id?: number;
}

export const getMyCommunityService = () => {
  return axiosService.get("/community/my-community");
};

export const postCommunityService = (body: Community) => {
  return axiosService.post("/community", body);
};

export const patchCommunityService = (id: any, body: any) => {
  return axiosService.patch(`/community/${id}`, body);
};

export const postUploadImageService = (
  communityId: any,
  formData: FormData
) => {
  return axiosService.post(
    `/community/${communityId}/community-upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const getCommunityService = (params: any) => {
  return axiosService.get("/community?" + new URLSearchParams({ ...params }));
};

export const getEventByIdService = (eventId: any, params: any) => {
  return axiosService.get(
    `/community/event/${eventId}/member?` + new URLSearchParams({ ...params })
  );
};

export const getCommunityByIdService = (id: any) => {
  return axiosService.get(`/community/${id}`);
};

export const postCommunityEventService = (id: any, body: CommunityEvent) => {
  return axiosService.post(`/community/${id}/event`, body);
};

export const patchCommunityEventService = (id: any, body: CommunityEvent) => {
  return axiosService.patch(`/community/event/${id}`, body);
};

export const deleteCommunityEventService = (id: any) => {
  return axiosService.delete(`/community/event/${id}`);
};

export const getCommunityMemberService = (id: any, params: any) => {
  return axiosService.get(
    `/community/${id}/member?` + new URLSearchParams({ ...params })
  );
};

export const deleteCommunityMembersService = (id: any, params: any) => {
  return axiosService.delete(
    `/community/${id}/member?` + new URLSearchParams({ ...params })
  );
};

export const postInviteMemberService = (id: any, body: InviteMember) => {
  return axiosService.post(`/community/${id}/invite`, body);
};

export const getCommunityJoinRequestService = (id: any) => {
  return axiosService.get(`/community/${id}/join-request`);
};

export const postAcceptInviteService = (id: any) => {
  return axiosService.post(`/community/invite/${id}/accept`);
};

export const postRejectInviteService = (id: any) => {
  return axiosService.post(`/community/invite/${id}/reject`);
};

export const getRejectInviteRequestService = () => {
  return axiosService.get("/community/invite/request");
};

export const deleteRejectInviteRequestService = (
  communityId: any,
  memberId: any
) => {
  return axiosService.delete(`/community/${communityId}/member/${memberId}`);
};

export const deleteCommunityMemberService = (
  communityId: any,
  memberId: any
) => {
  return axiosService.delete(`/community/${communityId}/member/${memberId}`);
};

export const deleteCommunityService = (communityId: any) => {
  return axiosService.delete(`/community/${communityId}`);
};
