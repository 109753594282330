import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { getFiitPdpaService } from "../../services/fiit.service";

const PdpaModal = () => {
  const [pdpa, setPdpa] = useState<string>("");

  const getFiitPadpa = () => {
    getFiitPdpaService().then((resp) => {
      setPdpa(resp.data.pdpa);
    });
  };

  useEffect(getFiitPadpa, []);

  return (
    <Box
      dangerouslySetInnerHTML={{
        __html: pdpa,
      }}
    ></Box>
  );
};

export default PdpaModal;
