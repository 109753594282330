import axiosService from "../api/axios";

export interface Filters {
  page: any;
  size: any;
}

export const getNotificationsService = (params: Filters) => {
  return axiosService.get(
    "/notification?" + new URLSearchParams({ ...params })
  );
};

export const getNotificationUnreadService = (body: any) => {
  return axiosService.get("/notification/unread", { data: body });
};

export const postNotificationAcceptService = (id: any) => {
  return axiosService.post(`/notification/${id}/accept`);
};

export const postNotificationRejectService = (id: any) => {
  return axiosService.post(`/notification/${id}/reject`);
};
