import { Box, Typography, Button, Container } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { boxStyle, containerStyle, pageStyle } from "../../../consts/styles";
import { useAppSelector } from "../../../features/userStore/hook";
import { getProfile } from "../../../features/userStore/libs/profileSlice";

const ConnectFiitSuccess = () => {
  const profile = useAppSelector(getProfile);

  return (
    <Box sx={pageStyle}>
      <Box
        component="img"
        src="/images/logo.png"
        alt="logo"
        width={75}
        height={100}
      />

      <Container
        component="main"
        sx={{ ...containerStyle, maxWidth: "400px" }}
        maxWidth={false}
      >
        <Box sx={{ ...boxStyle, alignItems: "center", padding: "40px 32px" }}>
          <Typography align="center" variant="h4" color="green.main">
            เชื่อมข้อมูลสำเร็จ
          </Typography>

          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            width={1}
          >
            <Box
              component="img"
              src="/images/logo.png"
              alt="logo"
              width={75}
              height={100}
            />

            <Box
              component="img"
              src="/images/fiit/x.png"
              alt="logo"
              width={15}
              height={15}
            />

            <Box
              component="img"
              src="/images/fiit/logo-main.png"
              alt="logo"
              width={80}
              height={80}
            />
          </Box>

          <Box>
            <Typography
              align="center"
              variant="h4"
              typography="subtitle2"
              color="black.main"
              fontWeight={600}
            >
              ฟิตโทเคน
            </Typography>

            <Typography
              align="center"
              typography="subtitle2"
              color="black.main"
              fontWeight={600}
            >
              ฟิตเนตเคลื่อนที่
            </Typography>

            <Typography
              align="center"
              typography="subtitle2"
              color="black.main"
              fontWeight={600}
            >
              เปลี่ยนมือถือเป็นฟิตเนต
            </Typography>
          </Box>

          <CopyToClipboard text={"www.fiittoken.io"}>
            <Box
              sx={{
                display: "flex",
                background: "#E0E5ED",
                padding: "8px 16px",
                borderRadius: "100px",
                gap: "8px",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src="/icons/ph_link-simple-bold.png"
                width={20}
                height={20}
              />
              <Typography variant="body1" color="black.main">
                www.fiittoken.io
              </Typography>
            </Box>
          </CopyToClipboard>

          <Button
            color="green"
            variant="contained"
            component={RouterLink}
            to={profile ? "/home" : "/login"}
            fullWidth
          >
            <Typography variant="subtitle2" color="white">
              <FormattedMessage id={profile ? "home" : "signin"} />
            </Typography>
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default ConnectFiitSuccess;
