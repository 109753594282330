import {
  Box,
  Container,
  Grid,
  Modal,
  Pagination,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";

import { getQuestionaireService } from "../../../services/questionaire.service";
import { containerStyle, modalStyle, pageStyle } from "../../../consts/styles";
import dayjs from "dayjs";
import WarningModal from "../../../components/modals/WarningModal";

const Questionaires = () => {
  const navigate = useNavigate();

  const [openWarnning, setWarnningOpen] = useState(false);
  const [questionaires, setQuestionaires] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [size] = useState(4);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleWarnningOpen = () => setWarnningOpen(true);
  const handleWarnningClose = () => setWarnningOpen(false);

  const getQuestionaire = () => {
    getQuestionaireService({ page, size }).then((resp) => {
      setQuestionaires(resp.data.items);
      setCount(resp.data.total_page);
    });
  };

  const navigateToQuestionaire = (question: any) => {
    if (!question.questionaire_date) {
      navigate("/questionaire", {
        state: {
          question,
        },
        replace: true,
      });
    } else {
      handleWarnningOpen();
    }
  };

  useEffect(getQuestionaire, [page, size]);

  return (
    <Box sx={pageStyle}>
      <Typography
        align="center"
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="ktgGreen.main"
        zIndex={100}
      >
        แบบสอบถาม
      </Typography>

      <Container
        component="main"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        maxWidth={false}
      >
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="10px"
          alignItems="center"
          sx={{
            cursor: "pointer",
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.855) 0%, rgba(255, 255, 255, 0.76) 100%)",
          }}
          padding={{ xs: "24px 8px", sm: "24px" }}
          gap="16px"
        >
          {questionaires.map((question: any, index: number) => (
            <Box
              key={index}
              width={1}
              display="flex"
              flexDirection="column"
              gap="16px"
              sx={{
                background: question.questionaire_date
                  ? "rgba(47, 210, 93, 0.05)"
                  : "#FFFFFF",
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                borderRadius: "10px",
                border: question.questionaire_date
                  ? "2px solid #2FD25D"
                  : "none",
                textDecoration: "none",
              }}
              padding={{ xs: "8px", sm: "16px" }}
              onClick={() => navigateToQuestionaire(question)}
            >
              <Box display="flex" gap="8px">
                <CheckCircleOutlineIcon
                  color="primary"
                  sx={{ display: question.questionaire_date ? "none" : "flex" }}
                />

                <CheckCircleIcon
                  color="green"
                  sx={{
                    display: !question.questionaire_date ? "none" : "flex",
                  }}
                />

                <Typography
                  fontWeight={600}
                  fontSize={{ xs: "16px", sm: "18px" }}
                  color="common.black"
                >
                  {question.title}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="column" gap="8px">
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography typography="body1" color="cgrey.main">
                      วันเผยแพร่:
                    </Typography>
                  </Grid>
                  <Grid item xs={9} paddingLeft="0px !important">
                    <Typography typography="body1" color="cgrey.main">
                      {dayjs(question.publish_date).format("D MMM BB")}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} padding="0">
                  <Grid item xs={3}>
                    <Typography typography="body1" color="cgrey.main">
                      วันที่ตอบ:
                    </Typography>
                  </Grid>
                  <Grid item xs={9} paddingLeft="0px !important">
                    <Typography typography="body1" color="cgrey.main">
                      {question.questionaire_date
                        ? dayjs(question.questionaire_date).format("D MMM BB")
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ))}
          <Pagination
            count={count}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            color="blue"
          />
        </Box>
      </Container>

      <Modal keepMounted open={openWarnning}>
        <Box sx={modalStyle} width={340}>
          <WarningModal
            message={`ได้ตอบแบบสอบถาม หัวข้อนี้ แล้ว ขอบคุณ ที่ร่วมตอบแบบสอบถามค่ะ`}
            handleClose={handleWarnningClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default Questionaires;
