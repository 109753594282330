import {
  Box,
  Container,
  Divider,
  Modal,
  Rating,
  Typography,
} from "@mui/material";
import Keyboard, { KeyboardReactInterface } from "react-simple-keyboard";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import "react-simple-keyboard/build/css/index.css";
import { useEffect, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import {
  postAuthPinLogin,
  postThaidInit,
  postThaidSetPin,
  postThaidSignup,
} from "../../services/auth.service";
import { FormattedMessage } from "react-intl";
import { configEnv } from "../../configs/config-env";
import WarningModal from "../../components/modals/WarningModal";
import { modalStyle, pageStyle } from "../../consts/styles";
import { removeItem, setItem } from "../../services/localStorage.service";

const PinPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const [step, setStep] = useState<number>(1);
  const [pin, setPin] = useState<string>("");
  const [input, setInput] = useState<string>("");
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [btnName, setBtnName] = useState<string>("");
  const [errorStatus, setErrorStatus] = useState<number>(0);
  const [openSuccess, setSuccessOpen] = useState(false);

  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => setSuccessOpen(false);
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    if (errorStatus === 400) {
      removeItem("state");
      navigate("/login");
    } else {
      setErrorMessage("");
      setErrorOpen(false);
    }
  };

  const onChangeHandler = (e: any) => {
    if (input.length <= 6) setInput(e);

    if (location.state.isSetPin || location.state.isRegister) {
      if (e.length === 6) {
        if (step === 1) {
          setPin(e);
          setStep(2);
          setTimeout(() => {
            resetKeyboard();
          }, 100);
        } else if (step === 2) {
          if (pin === e) {
            onSubmitHandler(e);
          } else {
            setStep(1);
            setPin("");
            setErrorMessage("รหัส PIN ไม่ตรงกัน");
            handleErrorOpen();
            setTimeout(() => {
              resetKeyboard();
            }, 100);
          }
        }
      }
    } else if (location.state.isVerifyPin) {
      if (e.length === 6) onSubmitHandler(e);
    }
  };
  const onKeyPressHandler = (btn: any) => {
    if (btn === "{bksp}") setInput(input.slice(0, -1));
  };

  const resetKeyboard = () => {
    setInput("");
    keyboardRef.current?.setInput("");
  };

  const onSubmitHandler = (e: string) => {
    if (location.state.isSetPin) {
      postThaidSetPin({ state: location.state.state, pin: e })
        .then((_) => {
          resetKeyboard();
          setPin("");
          navigate("/pin", {
            state: { state: location.state.state, isVerifyPin: true },
            replace: false,
          });
        })
        .catch((error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(resMessage);
          handleErrorOpen();
        });
    } else if (location.state.isRegister) {
      postThaidSignup({ ...location.state, pin: e })
        .then((_) => {
          resetKeyboard();
          setPin("");
          navigate("/pin", {
            state: { state: location.state.state, isVerifyPin: true },
            replace: false,
          });
        })
        .catch((error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(resMessage);
          handleErrorOpen();
        });
    } else if (location.state.isVerifyPin) {
      postAuthPinLogin({ state: location.state.state, pin: e })
        .then((resp) => {
          const {
            access_token,
            refresh_token,
            season_start_date,
            season_end_date,
            first_period_start_date,
            second_period_start_date,
            second_period_end_date,
          } = resp.data;

          const session = { access_token, refresh_token };
          const global = {
            season_start_date,
            season_end_date,
            first_period_start_date,
            second_period_start_date,
            second_period_end_date,
          };

          setItem("session", JSON.stringify(session));
          setItem("global", JSON.stringify(global));
          handleSuccessOpen();

          setTimeout(() => {
            handleSuccessClose();
            navigate("/home");
          }, 1000);
        })
        .catch((error) => {
          setErrorStatus(error.response.status);
          setBtnName(error.response.status === 400 ? "ปิด" : "ลองอีกครั้ง");
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(resMessage);
          handleErrorOpen();
        })
        .finally(() => resetKeyboard());
    }
  };

  const navigateToThaiD = () => {
    postThaidInit().then((resp) => {
      setItem("state", JSON.stringify(resp.data.state));
      const url =
        (configEnv.thaid_url as string) +
        "/api/v2/oauth2/auth/?" +
        new URLSearchParams({
          response_type: "code",
          client_id: configEnv.thaid_client_id as string,
          redirect_uri: configEnv.thaid_callback_url as string,
          scope: "pid given_name family_name birthdate gender address",
          state: resp.data.state,
        });

      window.location.href = url;
    });
  };

  useEffect(() => {
    if (location?.state?.logOut) {
      setTimeout(() => {
        removeItem("session");
      }, 1000);

      return;
    }
  }, [navigate]);

  return (
    <Box sx={pageStyle}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "17px",
        }}
      >
        <Box
          component="img"
          src="/images/logo.png"
          alt="logo"
          width={75}
          height={100}
        />
      </Box>

      <Container
        component="main"
        sx={{
          zIndex: 1,
          width: 400,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="10px"
          alignItems="center"
          sx={{
            cursor: "pointer",
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background: "#FFFFFF",
          }}
          padding={{ xs: "48px", sm: "40px" }}
          gap="16px"
        >
          <Typography align="center" typography="h4" color="ktgGreen.main">
            {location.state?.isVerifyPin && "กรอก Pin"}
            {(location.state?.isRegister || location.state?.isSetPin) &&
              (step === 1 ? "ตั้งค่า Pin" : "ยืนยัน Pin ")}
          </Typography>

          {(location.state?.isRegister || location.state?.isSetPin) && (
            <Typography align="center" color="common.black">
              เพื่อเข้าใช้แอปก้าวท้าใจอย่างปลอดภัย
            </Typography>
          )}

          <Rating
            sx={{ gap: "8px" }}
            name="pin"
            value={input.length}
            readOnly
            max={6}
            icon={
              <CircleIcon
                sx={{ opacity: 0.55 }}
                fontSize="inherit"
                color="success"
              />
            }
            emptyIcon={
              <PanoramaFishEyeIcon
                sx={{ opacity: 0.55 }}
                fontSize="inherit"
                color="success"
              />
            }
          />

          <Keyboard
            keyboardRef={(r) => (keyboardRef.current = r)}
            layoutName="default"
            theme={"hg-theme-default pinthaid"}
            layout={{
              default: ["1 2 3", "4 5 6", "7 8 9", "_ 0 {bksp}"],
            }}
            mergeDisplay
            display={{
              "{bksp}": "⌫",
            }}
            onChange={onChangeHandler}
            onKeyPress={onKeyPressHandler}
          />

          {location.state?.isVerifyPin && (
            <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
              <Typography
                variant="body1"
                color="cgrey.main"
                component="a"
                onClick={navigateToThaiD}
                sx={{ textDecoration: "none" }}
              >
                ลืม Pin
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderColor: "#717171", borderRightWidth: 2 }}
              />
              <Typography
                variant="body1"
                color="cgrey.main"
                component="a"
                href={`${configEnv.admin_url}`}
                target="_blank"
                sx={{ textDecoration: "none" }}
              >
                <FormattedMessage id="foradmin" />
              </Typography>
            </Box>
          )}
        </Box>
      </Container>

      <Modal keepMounted open={openSuccess} onClose={handleSuccessClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340,
            bgcolor: "background.paper",
            borderRadius: "10px",
            p: 4,
          }}
        >
          <Typography align="center">
            <CheckCircleOutlineIcon sx={{ fontSize: 60 }} color="ktgGreen" />
          </Typography>

          <Typography
            align="center"
            variant="h4"
            color="ktgGreen.main"
            marginTop="15px"
          >
            <FormattedMessage id="login.member.success" />
          </Typography>
        </Box>
      </Modal>

      <Modal keepMounted open={openError}>
        <Box sx={modalStyle} width={340}>
          <WarningModal
            message={errorMessage}
            handleClose={handleErrorClose}
            btnMessage={btnName}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default PinPage;
