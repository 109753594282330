import { Box, Button, Container, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { pageStyle } from "../../consts/styles";

const ThaidVerifySuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box sx={pageStyle}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "17px",
        }}
      >
        <Box
          component="img"
          src="/images/logo.png"
          alt="logo"
          width={75}
          height={100}
        />
      </Box>
      <Container
        component="main"
        sx={{
          zIndex: 1,
          width: 400,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="10px"
          alignItems="center"
          sx={{
            cursor: "pointer",
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background: "#FFFFFF",
          }}
          padding="36px"
          gap="32px"
        >
          <Typography align="center" typography="h4" color="green.main">
            ยืนยันตัวตนสำเร็จ
          </Typography>

          <Box
            component="img"
            src="/images/verified-thaid-successfully.png"
            width={200}
            height={200}
          />

          <Box
            display="flex"
            flexDirection="column"
            gap="16px"
            width={1}
            sx={{ justifyContent: "center" }}
          >
            {!location.state?.member_id && (
              <Button
                color="green"
                variant="contained"
                sx={{ color: "white" }}
                onClick={() =>
                  navigate("/register", {
                    state: location.state,
                  })
                }
              >
                <Typography variant="subtitle2">ดำเนินการต่อ</Typography>
              </Button>
            )}

            {location.state?.member_id && (
              <Button
                color="green"
                variant="contained"
                sx={{ color: "white" }}
                onClick={() =>
                  navigate("/pin", {
                    state: { ...location.state, isSetPin: true },
                  })
                }
              >
                <Typography variant="subtitle2">
                  {location.state?.has_pin ? "ตั้งค่า PIN ใหม่" : "ตั้งค่า PIN"}
                </Typography>
              </Button>
            )}

            {location.state?.has_pin && (
              <Button
                color="blue"
                variant="contained"
                onClick={() =>
                  navigate("/pin", {
                    state: { ...location.state, isVerifyPin: true },
                  })
                }
              >
                <Typography variant="subtitle2">
                  ใช้ PIN เดิมที่เคยตั้งไว้
                </Typography>
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ThaidVerifySuccess;
