import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Divider,
  SelectChangeEvent,
  // FormHelperText,
  Button,
  Modal,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useState, ChangeEvent, useEffect, useMemo, useCallback } from "react";
import Resizer from "react-image-file-resizer";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import th from "dayjs/locale/th";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { zodResolver } from "@hookform/resolvers/zod";
import { TypeOf } from "zod";
import { useForm, SubmitHandler } from "react-hook-form";
import debounce from "lodash.debounce";
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";

import {
  provinceService,
  districtService,
  subdistrictService,
  postcodeService,
  memberTypeService,
} from "../../services/master-data.service";
import { avatarUploadService } from "../../services/upload.service";
import { profileSchema } from "../../services/form.service";
import {
  updateProfileService,
  Profile,
  verifyIdentificationNumber,
} from "../../services/auth.service";
import {
  setAvatarUrl,
  getProfile,
  setProfile,
} from "../../features/userStore/libs/profileSlice";
import { useAppDispatch, useAppSelector } from "../../features/userStore/hook";
import { getCroppedImg } from "../../utils/cropper.utils";
import VerifiedRedeemModal from "../../components/modals/profile/VerifiedRedeemModal";
import { modalStyle } from "../../consts/styles";
import SuccessModal from "../../components/modals/SuccessModal";
import { configEnv } from "../../configs/config-env";

class OverwriteAdapterDayjs extends AdapterDayjs {
  format = (date: any, formatKey: any) => {
    switch (formatKey) {
      case "dayOfMonth":
        return date.$D;
      case "monthAndYear":
        return dayjs(date).locale("th").format("DD MMMM BBBB");
      case "year":
        return dayjs(date).format("BBBB");
      case "monthShort":
        return dayjs(date).format("MMM");
      default:
        return "";
    }
  };
}

type ProfileInput = TypeOf<typeof profileSchema>;

const ProfilePage = () => {
  // gobal states
  const dispatch = useAppDispatch();
  const profile = useAppSelector(getProfile);
  // end gobal states

  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  // form
  const { register, formState, setValue, setError, clearErrors, handleSubmit } =
    useForm<ProfileInput>({
      resolver: zodResolver(profileSchema),
    });

  const onError = (errors: any, e: any) => console.log(errors, e);

  const { isValid, errors } = formState;
  // end form

  // states
  const [file, setFile] = useState<any>();
  const [image, setImage] = useState<any>();
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [identificationNumber, setIdentificationNumber] = useState("");
  const [moo, setMoo] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [districs, setDistrics] = useState([]);
  const [subdistrict, setSubdistricts] = useState([]);
  const [postcodes, setPostcodes] = useState([]);

  const [gender, setGender] = useState<string>("");
  const [avatar, setAvatar] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [memberType, setMemberType] = useState([]);
  // error
  const [duplicatedIdentificationNumber, setDuplicatedIdentificationNumber] = useState(false);
  const [openSuccess, setSuccesOpen] = useState(false);
  const [openError, setErrorOpen] = useState(false);
  const [openWarning, setWarningOpen] = useState(false);
  const [openVerified, setVerifiedOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [warningMessage, setWarningMessage] = useState<string>("");

  // cropper
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [, setCroppedImage] = useState<any>(null);
  const [openCropDialog, setCropDialogOpen] = useState(false);
  // end states

  // handles
  const handleCropDialogOpen = () => setCropDialogOpen(true);
  const handleCropDialogClose = () => setCropDialogOpen(false);
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);
  const handleVerifiedOpen = () => {
    if (profile.self_verification || duplicatedIdentificationNumber) return;
    setVerifiedOpen(true);
  };
  const handleVerifiedClose = (updated = false) => {
    if (updated) {
      setSuccessMessage("ยืนยันตัวตนสำเร็จแล้ว");
      setTimeout(() => {
        handleSuccessClose();
      }, configEnv.modal_close);
    }
    setVerifiedOpen(false);
  };

  const handleGenderChange = (event: any) => {
    const result = event.target.value;
    setGender(result);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    // set zoom defualt crop
    setZoom(1);

    if (file) {
      setImage(URL.createObjectURL(file));
      handleCropDialogOpen();
    }
  };

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setValue("current_district_id", "");
    setValue("current_subdistrict_id", "");
    setValue("current_postcode", "");

    setDistrics([]);
    setSubdistricts([]);
    setPostcodes([]);
    getDistricts(event.target.value);
  };

  const handleDistrictChange = (event: SelectChangeEvent) => {
    setValue("current_subdistrict_id", "");
    setValue("current_postcode", "");

    setSubdistricts([]);
    setPostcodes([]);
    getSubdistricts(event.target.value);
  };

  const handleSubdistrictsChange = (event: SelectChangeEvent) => {
    setValue("current_postcode", "");

    setPostcodes([]);
    getPostcodes(event.target.value);
  };

  const handleMooChange = (event: any) => {
    const result = event.target.value.replace(/\D/g, "");
    setMoo(result);
  };

  const onCropComplete = useCallback(
    async (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const onSubmitHandler: SubmitHandler<ProfileInput> = async (values) => {
    try {
      handleLoading();

      if (file) {
        const formdata = new FormData();
        formdata.append("file", file);
        const resp = await uploadAvatar(formdata);
        dispatch(setAvatarUrl(`${resp.file_url}?v=${Date.now()}`));
      }

      let body: Profile = {
        ...values,
        current_subdistrict_id: parseInt(values.current_subdistrict_id),
        current_district_id: parseInt(values.current_district_id),
        current_province_id: parseInt(values.current_province_id),
        date_of_birth: dayjs(dateOfBirth).format("YYYY-MM-DD"),
        type_id: parseInt(values.type_id),
        gender: gender,
      };

      await updateProfileService(body);

      dispatch(setProfile(body));
      setSuccessMessage("แก้ไขโปรไฟล์สำเร็จ");
      setTimeout(() => {
        handleSuccessClose();
        navigate("/home", { replace: true });
      }, 1000);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  const handleSuccessOpen = () => setSuccesOpen(true);
  const handleSuccessClose = () => {
    setSuccessMessage("");
    setSuccesOpen(false);
  };
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => setErrorOpen(false);
  const handleWarningOpen = () => setWarningOpen(true);
  const handleWarningClose = () => setWarningOpen(false);
  // end handles

  // style
  const FormStyled = {
    display: "flex",
    flexDirection: matches ? "row" : "column",
    justifyContent: "space-between",
    gap: 3,
  };

  const FormIStyled = {
    display: "flex",
    flexDirection: matches ? "row" : "column",
    justifyContent: matches ? "" : "space-between",
    gap: 3,
  };
  // end style

  // services
  const getProvinces = async () => {
    const response = await provinceService();
    setProvinces(response.data);
  };

  const getDistricts = async (provinceId: string) => {
    const response = await districtService(provinceId);
    setDistrics(response.data);
  };

  const getSubdistricts = async (districtId: any) => {
    const response = await subdistrictService(districtId);
    setSubdistricts(response.data);
  };

  const getPostcodes = async (subdistrictId: any) => {
    const response = await postcodeService(subdistrictId);
    setPostcodes(response.data);
  };

  const uploadAvatar = async (formdata: FormData) => {
    const response = await avatarUploadService(formdata);
    return response.data;
  };

  const getMemberType = async () => {
    const response = await memberTypeService();
    setMemberType(response.data);
  };

  const setProfileForm = () => {
    setValue("first_name", profile.first_name);
    setValue("last_name", profile.last_name);
    setValue("identification_number", profile.identification_number);
    setValue("phone_no", profile.phone_no);
    setValue("current_address", profile.current_address);
    setValue(
      "type_id",
      profile.type_id ? profile.type_id.toString() : profile.type_id
    );

    setAvatar(profile.avatar_url);
    getDistricts(profile.current_province_id);
    getSubdistricts(profile.current_district_id);
    getPostcodes(profile.current_subdistrict_id);

    setIdentificationNumber(profile.identification_number);
    setMoo(profile.current_moo);
    setHeight(profile.height);
    setWeight(profile.weight);
    setDateOfBirth(profile.date_of_birth);
    setGender(profile.gender);
  };

  const validateCitizenId = async (event: any) => {
    const value = event.target.value;
    if (value.substring(0, 1) === 0 || value.length !== 13) {
      setError("identification_number", {
        type: "custom",
        message: "เลขบัตรผิด",
      });
      return;
    } else {
      let sum = 0;
      for (var i = 0; i < value.length - 1; i++) {
        sum += parseFloat(value.charAt(i)) * (13 - i);
      }

      if ((11 - (sum % 11)) % 10 !== parseFloat(value.charAt(12))) {
        setError("identification_number", {
          type: "custom",
          message: "เลขบัตรผิด",
        });
        return;
      }
    }
    const response = await verifyIdentificationNumber({
      identification_number: value,
      member_id: profile.member_id,
    });

    if (!response.data.is_available) {
      setError("identification_number", {
        type: "custom",
        message: "เลขบัตรนี้ มีอยู่ในระบบแล้ว",
      });
      return;
    }

    clearErrors("identification_number");
  };

  const handlePhoneNoChange = (event: any) => {
    const value = event.target.value.replace(/\D/g, "");
    if (value.length !== 10) {
      setError("phone_no", {
        type: "custom",
        message: "เบอร์โทรศัพท์ต้องมีความยาว 10 ตัวอักษร",
      });
    } else {
      clearErrors("phone_no");
    }
    setValue("phone_no", value);
  };

  const debouncedIdNumberHandler = useMemo(
    () => debounce(validateCitizenId, 300),
    [profile]
  );

  const acceptCropAvatar = async () => {
    const { url, blob }: any = await getCroppedImg(image, croppedAreaPixels);
    setCroppedImage(url);

    const file = new File([blob], "name");
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      80,
      0,
      (file: any) => {
        setFile(file);
      },
      "file"
    );
    setAvatar(url);
    handleCropDialogClose();
  };

  const getVerifyIdentificationNumber = async () => {
    const response = await verifyIdentificationNumber({
      identification_number: profile.identification_number,
      member_id: profile.member_id,
    });

    if (!response.data.is_available) {
      setError("identification_number", {
        type: "custom",
        message: "เลขบัตรนี้ มีอยู่ในระบบแล้ว",
      });

      setWarningMessage("เลขบัตรประชาชน มีอยู่ในระบบแล้ว");
      setDuplicatedIdentificationNumber(true);
      return;
    }
  };
  // end services

  // core func
  useEffect(() => {
    if (profile.member_id) {
      getProvinces();
      getMemberType();
      setProfileForm();
      getVerifyIdentificationNumber();
      setInitLoading(false);
    }
  }, [profile]);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  useEffect(() => {
    if (warningMessage) handleWarningOpen();
  }, [warningMessage]);

  useEffect(() => {
    if (successMessage) handleSuccessOpen();
  }, [successMessage]);
  // end core func

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        py: "100px",
      }}
    >
      <Container
        component="main"
        maxWidth={matches ? "md" : "sm"}
        sx={{ zIndex: 1000 }}
      >
        <Box
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            display: "flex",
            padding: "40px 30px",
            borderRadius: "10px",
            background: "#FFFFFF",
            gap: matches ? 0 : 3,
            flexDirection: matches ? "row" : "column",
            justifyContent: matches ? "space-around" : "",
          }}
        >
          {!initLoading && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                width: 1,
              }}
            >
              <Typography align="center" variant="h3" color="ktgGreen.main">
                ข้อมูลส่วนตัว
              </Typography>

              {/*  upload image */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: 120,
                    height: 120,
                    position: "relative",
                  }}
                >
                  <Box sx={{ width: "100%", height: "100%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        padding: "4px",
                        boxShadow: "0 3px 6px rgb(0 0 0 / 16%)",
                        marginBottom: 0,
                        backgroundColor: "#fff",
                        borderRadius: "100%",
                      }}
                    >
                      <img
                        src={avatar ? avatar : "/images/avatar-default.png"}
                        alt="logo"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "100%",
                        }}
                      />
                    </Box>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      sx={{
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        border: "4px solid #fff",
                        width: "35px",
                        height: "35px",
                        backgroundColor: "#D9E2EB",
                        "&:hover": {
                          backgroundColor: "#1688C4",
                        },
                        boxShadow:
                          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
                      }}
                    >
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={handleFileChange}
                      />
                      <PhotoCameraOutlinedIcon
                        color="black"
                        sx={{ width: "18px", height: "18px" }}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                gap="8px"
                sx={{ cursor: "pointer" }}
                onClick={handleVerifiedOpen}
              >
                <Box
                  component="img"
                  width={24}
                  height={24}
                  src={
                    profile.self_verification
                      ? "/icons/vector_13.png"
                      : "/icons/vector_12.png"
                  }
                />

                <Typography
                  align="center"
                  variant="body1"
                  color={profile.self_verification ? "green.main" : "red"}
                >
                  {profile.self_verification
                    ? "ยืนยันตัวตนแล้ว"
                    : duplicatedIdentificationNumber ? "เลขบัตรประชาชนนี้ มีสมาชิกท่านอื่น ใช้ยืนยันตนไปแล้ว" : "กรุณายืนยันตัวตน"}
                </Typography>
              </Box>

              {/* profile form */}
              <Box
                sx={{
                  gap: 4,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={FormIStyled}>
                  <TextField
                    defaultValue={profile.username}
                    color="lightGrey"
                    fullWidth
                    label="ชื่อผู้ใช้"
                    disabled
                  />
                  <TextField
                    defaultValue={profile.identification_number}
                    color="lightGrey"
                    fullWidth
                    label="เลขบัตรประชาชน 13 หลัก"
                    error={!!errors["identification_number"]}
                    helperText={
                      errors["identification_number"]
                        ? errors["identification_number"].message
                        : ""
                    }
                    sx={{ maxWidth: { sm: "auto", md: "248px" } }}
                    {...register("identification_number", {
                      value: identificationNumber,
                      onChange: debouncedIdNumberHandler,
                    })}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toString().slice(0, 13);
                    }}
                    disabled={profile.self_verification}
                  />
                  <TextField
                    defaultValue={profile.phone_no}
                    color="lightGrey"
                    fullWidth
                    label="เบอร์โทรศัพท์"
                    error={!!errors["phone_no"]}
                    helperText={
                      errors["phone_no"] ? errors["phone_no"].message : ""
                    }
                    sx={{ maxWidth: { sm: "auto", md: "248px" } }}
                    {...register("phone_no", {
                      onChange: handlePhoneNoChange,
                    })}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toString().slice(0, 10);
                    }}
                  />
                </Box>

                <Box sx={FormIStyled}>
                  <FormControl fullWidth>
                    <FormLabel>
                      <Typography variant="body1" color="common.black">
                        <FormattedMessage id="register.firstname" />
                      </Typography>
                    </FormLabel>
                    <TextField
                      defaultValue={profile.first_name}
                      color="lightGrey"
                      placeholder="ชื่อจริง"
                      error={!!errors["first_name"]}
                      {...register("first_name")}
                      onInput={(e: any) => {
                        e.target.value = e.target.value
                          .toString()
                          .slice(0, 100);
                      }}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <FormLabel>
                      <Typography variant="body1" color="common.black">
                        <FormattedMessage id="register.lastname" />
                      </Typography>
                    </FormLabel>
                    <TextField
                      defaultValue={profile.last_name}
                      color="lightGrey"
                      placeholder="นามสกุล"
                      error={!!errors["last_name"]}
                      {...register("last_name")}
                      onInput={(e: any) => {
                        e.target.value = e.target.value
                          .toString()
                          .slice(0, 100);
                      }}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <FormLabel>
                      <Typography variant="body1" color="common.black">
                        <FormattedMessage id="register.date_of_birth" />
                      </Typography>
                    </FormLabel>

                    <LocalizationProvider
                      dateAdapter={OverwriteAdapterDayjs}
                      adapterLocale={th}
                    >
                      <DatePicker
                        disableFuture={true}
                        views={["year", "month", "day"]}
                        inputFormat="DD/MMM/BBBB"
                        value={dateOfBirth}
                        onChange={(newValue: any) => {
                          const date = dayjs(newValue).format("YYYY-MM-DD");
                          setDateOfBirth(date);
                          // setValue("date_of_birth", date);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "วว/ดด/ปปปป",
                              onChange: () => {},
                            }}
                            error={!!errors["date_of_birth"]}
                            {...register("date_of_birth")}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Box>

                <Box sx={FormStyled}>
                  <FormControl fullWidth>
                    <FormLabel id="demo-radio-buttons-group-label">
                      <Typography variant="body1" color="common.black">
                        <FormattedMessage id="register.gender" />
                      </Typography>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={profile.gender}
                      name="radio-buttons-group"
                      sx={{
                        "&$checked": {
                          color: "#2FD25D",
                        },
                      }}
                      onChange={handleGenderChange}
                    >
                      <FormControlLabel
                        value="M"
                        control={<Radio color="ktgGreen" />}
                        label="ชาย"
                      />
                      <FormControlLabel
                        value="F"
                        control={<Radio color="ktgGreen" />}
                        label="หญิง"
                      />
                    </RadioGroup>
                  </FormControl>

                  <FormControl fullWidth>
                    <FormLabel>
                      <Typography variant="body1" color="common.black">
                        <FormattedMessage id="register.marriage_status" />
                      </Typography>
                    </FormLabel>
                    <Select
                      defaultValue={profile.type_id}
                      error={!!errors["type_id"]}
                      {...register("type_id")}
                      sx={{
                        "& .MuiSelect-select .notranslate::after":
                          "กรุณาเลือกสถานะ"
                            ? {
                                content: `"กรุณาเลือกสถานะ"`,
                                opacity: 0.42,
                              }
                            : {},
                      }}
                    >
                      {memberType.map((p: { id: number; name: string }) => (
                        <MenuItem key={p.id} value={p.id.toString()}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <FormControl fullWidth>
                        <FormLabel>
                          <Typography variant="body1" color="common.black">
                            <FormattedMessage id="register.weight" />
                          </Typography>
                        </FormLabel>

                        <TextField
                          type="text"
                          color="lightGrey"
                          placeholder="กก."
                          value={weight}
                          disabled
                        />
                      </FormControl>

                      <FormControl fullWidth>
                        <FormLabel>
                          <Typography variant="body1" color="common.black">
                            <FormattedMessage id="register.height" />
                          </Typography>
                        </FormLabel>
                        <TextField
                          type="text"
                          color="lightGrey"
                          placeholder="ซม."
                          value={height}
                          disabled
                        />
                      </FormControl>
                    </Box>
                  </FormControl>
                </Box>

                <Box>
                  <Divider sx={{ color: "#717171", borderWidth: 2 }}>
                    <Typography variant="h5" color="ktgGreen.main">
                      <FormattedMessage id="register.address" />
                    </Typography>
                  </Divider>
                </Box>

                <Box sx={FormStyled}>
                  <FormControl fullWidth>
                    <FormLabel>
                      <Typography variant="body1" color="common.black">
                        <FormattedMessage id="register.current_address1" />
                      </Typography>
                    </FormLabel>
                    <TextField
                      defaultValue={profile.current_address}
                      color="lightGrey"
                      error={!!errors["current_address"]}
                      {...register("current_address")}
                      onInput={(e: any) => {
                        e.target.value = e.target.value
                          .toString()
                          .slice(0, 200);
                      }}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <FormLabel>
                      <Typography variant="body1" color="common.black">
                        <FormattedMessage id="register.current_moo" />
                      </Typography>
                    </FormLabel>
                    <TextField
                      color="lightGrey"
                      value={moo}
                      error={!!errors["current_moo"]}
                      {...register("current_moo", {
                        onChange: handleMooChange,
                      })}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.toString().slice(0, 50);
                      }}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <FormLabel>
                      <Typography variant="body1" color="common.black">
                        <FormattedMessage id="register.province" />
                      </Typography>
                    </FormLabel>
                    <Select
                      defaultValue={profile.current_province_id}
                      error={!!errors["current_province_id"]}
                      {...register("current_province_id")}
                      sx={{
                        "& .MuiSelect-select .notranslate::after":
                          "กรุณาเลือกจังหวัด"
                            ? {
                                content: `"กรุณาเลือกจังหวัด"`,
                                opacity: 0.42,
                              }
                            : {},
                      }}
                      onChange={handleProvinceChange}
                    >
                      {provinces.map((p: { id: number; name: string }) => (
                        <MenuItem key={p.id} value={p.id.toString()}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={FormStyled}>
                  <FormControl fullWidth>
                    <FormLabel>
                      <Typography variant="body1" color="common.black">
                        <FormattedMessage id="register.district" />
                      </Typography>
                    </FormLabel>
                    <Select
                      defaultValue={profile.current_district_id}
                      sx={{
                        "& .MuiSelect-select .notranslate::after":
                          "กรุณาเลือกอำเภอ"
                            ? {
                                content: `"กรุณาเลือกอำเภอ"`,
                                opacity: 0.42,
                              }
                            : {},
                      }}
                      error={!!errors["current_district_id"]}
                      {...register("current_district_id")}
                      onChange={handleDistrictChange}
                    >
                      {districs.map((d: { id: number; name: string }) => (
                        <MenuItem key={d.id} value={d.id.toString()}>
                          {d.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <FormLabel>
                      <Typography variant="body1" color="common.black">
                        <FormattedMessage id="register.subdistrict" />
                      </Typography>
                    </FormLabel>
                    <Select
                      defaultValue={profile.current_subdistrict_id}
                      error={!!errors["current_subdistrict_id"]}
                      {...register("current_subdistrict_id")}
                      sx={{
                        "& .MuiSelect-select .notranslate::after":
                          "กรุณาเลือกตำบล"
                            ? {
                                content: `"กรุณาเลือกตำบล"`,
                                opacity: 0.42,
                              }
                            : {},
                      }}
                      onChange={handleSubdistrictsChange}
                    >
                      {subdistrict.map((d: { id: number; name: string }) => (
                        <MenuItem key={d.id} value={d.id.toString()}>
                          {d.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <FormLabel>
                      <Typography variant="body1" color="common.black">
                        <FormattedMessage id="register.postcode" />
                      </Typography>
                    </FormLabel>
                    <Select
                      defaultValue={profile.current_postcode}
                      error={!!errors["current_postcode"]}
                      {...register("current_postcode")}
                      sx={{
                        "& .MuiSelect-select .notranslate::after":
                          "กรุณาเลือกรหัสไปรษณีย์"
                            ? {
                                content: `"กรุณาเลือกรหัสไปรษณีย์"`,
                                opacity: 0.42,
                              }
                            : {},
                      }}
                    >
                      {postcodes.map((d: { id: number; name: string }) => (
                        <MenuItem key={d.id} value={d.name}>
                          {d.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center", gap: 3 }}>
                  <Button
                    color="blue"
                    variant="outlined"
                    type="button"
                    component={RouterLink}
                    to="/home"
                    sx={{ maxWidth: "230px", width: 1 }}
                  >
                    <Typography variant="subtitle2">
                      <FormattedMessage id="common.cancel" />
                    </Typography>
                  </Button>

                  <Button
                    color="green"
                    variant="contained"
                    type="button"
                    onClick={handleSubmit(onSubmitHandler, onError)}
                    disabled={
                      !isValid || Object.values(errors).length !== 0 || loading
                    }
                    sx={{ maxWidth: "230px", width: 1 }}
                  >
                    <Typography variant="subtitle2" color="white">
                      <FormattedMessage id="common.save" />
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Container>

      <Modal keepMounted open={openSuccess} onClose={handleSuccessClose}>
        <Box sx={modalStyle} width={340}>
          <SuccessModal
            message={successMessage}
            handleSuccessClose={handleSuccessClose}
            showButton={false}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 343,
            bgcolor: "background.paper",
            borderRadius: "10px",
            p: 4,
          }}
        >
          <Typography align="center">
            <HighlightOffIcon sx={{ fontSize: 60, color: "#EB698F" }} />
          </Typography>

          <Typography
            align="center"
            variant="h4"
            color="#EB698F"
            marginTop="15px"
          >
            {errorMessage}
          </Typography>
        </Box>
      </Modal>

      <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 420,
            bgcolor: "background.paper",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ textAlign: "end", p: 1 }}>
            <Typography
              onClick={handleWarningClose}
              component="span"
              sx={{ cursor: "pointer" }}
              color="cgrey.main"
            >
              <CloseIcon sx={{ fontSize: 20 }} />
            </Typography>
          </Box>

          <Typography align="center">
            <ErrorOutlineIcon sx={{ fontSize: 60, color: "#FFCC4C" }} />
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            gap="16px"
            sx={{ px: "28px", pb: "28px" }}
          >
            <Typography
              align="center"
              variant="h4"
              color="cgrey.main"
              marginTop="15px"
            >
              {warningMessage}
            </Typography>

            <Button
              color="green"
              variant="contained"
              onClick={handleWarningClose}
            >
              <Typography variant="h4" color="white">
                ตกลง
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>

      <Dialog keepMounted disableEscapeKeyDown open={openCropDialog}>
        <DialogContent sx={{ height: "350px", width: "500px" }}>
          <Box
            position="absolute"
            sx={{ top: 0, left: 0, right: 0, bottom: "80px" }}
          >
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, justifyContent: "center" }}>
          <Button
            color="blue"
            variant="outlined"
            type="button"
            onClick={handleCropDialogClose}
          >
            <Typography variant="subtitle2">
              <FormattedMessage id="common.cancel" />
            </Typography>
          </Button>
          <Button
            color="green"
            variant="contained"
            type="button"
            onClick={acceptCropAvatar}
          >
            <Typography variant="subtitle2" color="white">
              <FormattedMessage id="register.accept" />
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        keepMounted
        open={openVerified}
        onClose={() => handleVerifiedClose(false)}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        scroll="body"
      >
        <Box
          display="flex"
          width={1}
          onClick={() => handleVerifiedClose(false)}
          justifyContent="end"
          padding="8px 8px 0 8px"
        >
          <CancelOutlinedIcon
            sx={{ cursor: "pointer", color: "lightGrey.main" }}
          />
        </Box>
        <DialogContent sx={{ width: 300 }}>
          {openVerified && (
            <VerifiedRedeemModal handleClose={handleVerifiedClose} />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ProfilePage;
