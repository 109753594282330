import { useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { pdapService } from "../services/auth.service";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  Box,
  useTheme,
  Modal,
} from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import useMediaQuery from "@mui/material/useMediaQuery";

import PdapContent from "../components/PdapContent";
import TopBar from "../components/user/TopBar";
import Footer from "../components/user/Footer";
import { consentPdpaService } from "../services/auth.service";
import { getItem } from "../services/localStorage.service";
import { profileService } from "../services/auth.service";
import { setProfile } from "../features/userStore/libs/profileSlice";
import { useAppDispatch } from "../features/userStore/hook";

import Menu from "../components/user/Menu";
import { modalStyle } from "../consts/styles";
import { postBmiService } from "../services/bmi.service";
import CreateBmiModal from "../components/modals/CreateBmiModal";
import SuccessModal from "../components/modals/SuccessModal";
import ErrorModal from "../components/modals/ErrorModal";

const UserProtectLayout = () => {
  const navigate = useNavigate();
  const prevLocation = useLocation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const lgBp = useMediaQuery(theme.breakpoints.down("lg"));

  const [openPdpa, setPdpaOpen] = useState(false);
  const [, setReaded] = useState<Boolean>(false);
  const [openCreateBmi, setCreateBmiOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openSuccess, setSuccessOpen] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [consent, setConsent] = useState("");

  const handlePdpaClose = () => setPdpaOpen(false);
  const consentPdpa = async () => {
    await consentPdpaService();
    dispatch(setProfile({ pdpa_accept_required: false }));
    handlePdpaClose();
  };
  const handleCreateBmiOpen = () => setCreateBmiOpen(true);
  const handleCreateBmiClose = () => setCreateBmiOpen(false);
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);
  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => setSuccessOpen(false);
  const handleErrorOpen = () => {
    if (errorMessage) setErrorOpen(true);
  };
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };
  const handleScroll = (e: any) => {
    const bottom =
      Math.ceil(e.target.scrollHeight - e.target.scrollTop) <=
      e.target.clientHeight;

    if (bottom) setReaded(bottom);
  };

  const initialProfileState = async () => {
    const response = await profileService();
    const { pdpa_accept_required } = response.data;
    if (pdpa_accept_required) {
      pdapService().then((resp: any) => {
        const { pdpa } = resp.data;
        setConsent(pdpa);
        setPdpaOpen(true);
      });
    }
    if (response.data.bmi_send_required) handleCreateBmiOpen();

    dispatch(setProfile(response.data));
  };

  const onSubmitCreateBmiHandler = async (values: any) => {
    try {
      handleLoading();
      await postBmiService({
        height: parseFloat(values.height),
        waistline: parseInt(values.waistline),
        weight: parseFloat(values.weight),
      });
      handleCreateBmiClose();
      handleSuccessOpen();
      dispatch(setProfile({ bmi_send_required: false }));
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  useEffect(() => {
    const session = getItem("session");
    // potected routes
    if (!session) navigate(`/login?redirectTo=${prevLocation.pathname}`);
  }, [navigate, prevLocation.pathname]);

  useEffect(() => {
    initialProfileState();
  }, []);

  useEffect(handleErrorOpen, [errorMessage]);

  return (
    <>
      <TopBar />
      <Box component="main" mt={"120px"} mb={{ xs: "56px", lg: 0 }}>
        <Outlet />
        <Footer />
      </Box>

      {lgBp && <Menu />}

      {/* pdpa modal */}
      <Dialog keepMounted disableEscapeKeyDown open={openPdpa}>
        <DialogContent
          sx={{ height: "calc(100vh - 100px)" }}
          onScroll={handleScroll}
        >
          <DialogContentText>
            <PdapContent consent={consent} />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2, justifyContent: "center" }}>
          <Button
            color="blue"
            variant="outlined"
            type="button"
            onClick={handlePdpaClose}
          >
            <Typography variant="subtitle2">
              <FormattedMessage id="register.refuse" />
            </Typography>
          </Button>
          <Button
            color="green"
            variant="contained"
            type="button"
            onClick={consentPdpa}
          >
            <Typography variant="subtitle2" color="white">
              <FormattedMessage id="register.accept" />
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <Modal keepMounted open={openCreateBmi} onClose={handleCreateBmiClose}>
        <Box sx={modalStyle} width={340}>
          <CreateBmiModal
            onSubmitHandler={onSubmitCreateBmiHandler}
            handleClose={handleCreateBmiClose}
            loading={loading}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openSuccess}>
        <Box sx={modalStyle} width={340}>
          <SuccessModal
            message={"บันทึกข้อมูลสำเร็จ"}
            handleSuccessClose={handleSuccessClose}
            showButton={true}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle} width={340}>
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>
    </>
  );
};

export default UserProtectLayout;
