import {
  Box,
  Container,
  MenuItem,
  Paper,
  Select,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  Checkbox,
  Modal,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { tableCellClasses } from "@mui/material/TableCell";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  boxStyle,
  containerStyle,
  modalStyle,
  pageStyle,
} from "../../../consts/styles";
import {
  getCommunityMemberService,
  deleteCommunityMembersService,
  getCommunityByIdService,
} from "../../../services/community.service";
import SuccessModal from "../../../components/modals/SuccessModal";
import ErrorModal from "../../../components/modals/ErrorModal";
import { useAppSelector } from "../../../features/userStore/hook";
import { getProfile } from "../../../features/userStore/libs/profileSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CommunityMemberRank = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const profile = useAppSelector(getProfile);

  const [communityId, setCommunityId] = useState<number | null>(null);
  const [isAdminGroup, setIsAdminGroup] = useState<boolean>(false);
  const [, setCommunity] = useState<any | null>(null);
  const [members, setMembers] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);
  const [deleteList, setDeleteList] = useState<any>([]);

  const [openSuccess, setSuccessOpen] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => setSuccessOpen(false);
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => setErrorOpen(false);

  const { register, watch } = useForm({
    defaultValues: {
      order_by: "experience_point",
    },
  });

  const orderByWatch = watch("order_by");

  const handleIsConfirmDelete = () => setIsConfirmDelete(true);
  const handleNotConfirmDelete = () => setIsConfirmDelete(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleConfirmDeleteMember = async () => {
    try {
      if (deleteList.length) {
        await deleteCommunityMembersService(communityId, {
          member_ids: deleteList.join(),
        });
        handleSuccessOpen();
      }
      handleNotConfirmDelete();
      setDeleteList([]);
      getCommunityMember();
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
    }
  };

  const handleChangeCheckBox = (id: any) => {
    const memberId = deleteList.includes(id);
    if (!memberId) {
      setDeleteList([...deleteList, id]);
    } else {
      setDeleteList(deleteList.filter((t: any) => t !== id));
    }
  };

  const getCommunity = () => {
    if (communityId && profile)
      getCommunityByIdService(communityId).then((resp) => {
        setCommunity(resp.data);
        setIsAdminGroup(profile.member_id === resp.data.member_id);
      });
  };

  const getCommunityMember = () => {
    if (communityId)
      getCommunityMemberService(communityId, {
        page: page + 1,
        size: rowsPerPage,
        order_by: orderByWatch,
      }).then((resp) => {
        setMembers(resp.data.items);
        setCount(resp.data.total_row);
      });
  };

  useEffect(() => {
    if (!location.state) {
      navigate("/community");
      return;
    }
    setCommunityId(location.state.communityId);
  }, [location, navigate]);

  useEffect(getCommunityMember, [communityId, orderByWatch, page, rowsPerPage]);

  useEffect(getCommunity, [communityId, profile.member_id]);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  return (
    <Box sx={pageStyle}>
      <Typography
        align="center"
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="ktgGreen.main"
        zIndex={100}
      >
        อันดับสมาชิก
      </Typography>

      <Container
        component="main"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        maxWidth={false}
      >
        <Box sx={{ ...boxStyle, padding: "24px" }}>
          <Box display="flex" gap="24px" flexDirection="column">
            <Box display="flex" width={1} justifyContent="space-between">
              <Box width="25%"></Box>

              <Typography
                width="50%"
                align="center"
                typography="h4"
                color="black.main"
              >
                รายชื่อสมาชิก
              </Typography>

              <Box width="25%" display="flex" justifyContent="end">
                <Select
                  defaultValue={"experience_point"}
                  {...register("order_by")}
                  sx={{ alignSelf: "end", height: "32px" }}
                >
                  <MenuItem key={0} value={"member_id"}>
                    e-BIB
                  </MenuItem>
                  <MenuItem key={1} value={"experience_point"}>
                    EXP
                  </MenuItem>
                </Select>
              </Box>
            </Box>

            <Box>
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none",
                  borderRadius: 0,
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                }}
              >
                <Table
                  sx={{ minWidth: 450 }}
                  aria-labelledby="tableTitle"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      {isConfirmDelete && <Box></Box>}
                      <StyledTableCell
                        sx={{
                          typography: "body1",
                          fontWeight: 600,
                          borderTopLeftRadius: "10px",
                        }}
                      >
                        e-BIB
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          typography: "body1",
                          fontWeight: 600,
                        }}
                      >
                        ชื่อ - สกุล
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          typography: "body1",
                          fontWeight: 600,
                        }}
                      >
                        EXP
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          typography: "body1",
                          fontWeight: 600,
                        }}
                      >
                        แคลอรี
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {members.map((row: any, index: number) => (
                      <StyledTableRow key={index}>
                        {isConfirmDelete && (
                          <Checkbox
                            onChange={() => handleChangeCheckBox(row.member_id)}
                            disabled={profile.member_id === row.member_id}
                          />
                        )}
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{ textAlign: "right" }}
                        >
                          {row.member_id}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.first_name} {row.last_name}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{ textAlign: "right" }}
                        >
                          {row.experience_point.toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{ textAlign: "right" }}
                        >
                          {row.calories.toLocaleString()}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                labelRowsPerPage={""}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>

            {isAdminGroup && (
              <Box display="flex" gap="8px">
                {!isConfirmDelete && (
                  <Button
                    fullWidth
                    color="secPink"
                    variant="contained"
                    startIcon={<RemoveCircleOutlineIcon />}
                    onClick={handleIsConfirmDelete}
                  >
                    <Typography variant="subtitle2">ลบสมาชิก</Typography>
                  </Button>
                )}

                {isConfirmDelete && (
                  <Button
                    fullWidth
                    color="secPink"
                    variant="contained"
                    startIcon={<CheckCircleOutlineIcon />}
                    onClick={handleConfirmDeleteMember}
                  >
                    <Typography variant="subtitle2">ยืนยันการลบ</Typography>
                  </Button>
                )}

                <Button
                  fullWidth
                  color="green"
                  variant="contained"
                  startIcon={
                    <PersonAddAltOutlinedIcon sx={{ color: "white" }} />
                  }
                  component={RouterLink}
                  to="/community/member-invite"
                  state={{ communityId: communityId }}
                >
                  <Typography variant="subtitle2" color="white">
                    เชิญสมาชิก
                  </Typography>
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        <Button
          fullWidth
          color="cwhite"
          variant="contained"
          sx={{ boxShadow: "none" }}
          component={RouterLink}
          to="/community/view"
          state={{ id: communityId }}
        >
          <Typography typography="subtitle2" color="black.main">
            กลับ
          </Typography>
        </Button>
      </Container>

      <Modal keepMounted open={openSuccess}>
        <Box sx={modalStyle} width="340px">
          <SuccessModal
            message={"ลบสมาชิกสำเร็จ"}
            handleSuccessClose={handleSuccessClose}
            showButton={true}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle} width="340px">
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default CommunityMemberRank;
