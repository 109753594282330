import {
  Box,
  Typography,
  Button,
  Container,
  Modal,
  Avatar,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import * as htmlToImage from "html-to-image";
import {
  boxStyle,
  containerStyle,
  modalStyle,
  pageStyle,
} from "../../../consts/styles";
import { getBadgeProgressService } from "../../../services/activity.service";
import {
  getAvatar,
  getProfile,
} from "../../../features/userStore/libs/profileSlice";
import { useAppSelector } from "../../../features/userStore/hook";
import CertificateModal from "../../../components/modals/achievement/CertificateModal";
import AchievementBadgeModal from "../../../components/modals/achievement/AchievementBadgeModal";
import WarningModal from "../../../components/modals/WarningModal";
import { configEnv } from "../../../configs/config-env";

const AchievementPage = () => {
  const profile = useAppSelector(getProfile);
  const avatar = useAppSelector(getAvatar);
  const boxRef = useRef<HTMLElement | undefined>();

  const [achievement, setAchievement] = useState<any>(null);
  const [achievement1, setAchievement1] = useState(1);
  const [achievement2, setAchievement2] = useState(1);
  const [achievement3, setAchievement3] = useState(1);
  const [achievement4, setAchievement4] = useState(1);
  const [achievement5, setAchievement5] = useState(1);
  const [achievement6, setAchievement6] = useState(1);
  const [achievement7, setAchievement7] = useState(1);
  const [achievementBadge, setAchievementBadge] = useState<any>(null);
  const [openCertificate, setOpenCertificate] = useState<boolean>(false);
  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const [openAchievementBadge, setOpenAchievementBadge] =
    useState<boolean>(false);
  const [fullnameIsLarge, setfullnameIsLarge] = useState<boolean>(false);
  const [download, setDownload] = useState<boolean>(false);

  // handles
  const handleCertificateOpen = () => {
    if (achievement.season_hp >= configEnv.season_hp_cond) {
      setOpenCertificate(true);
    } else {
      handleWarningOpen();
    }
  };
  const handleCertificateClose = () => setOpenCertificate(false);
  const handleAchievementBadgeOpen = (badge: any) => {
    setAchievementBadge(badge);
    setOpenAchievementBadge(true);
  };
  const handleAchievementBadgeClose = () => setOpenAchievementBadge(false);
  const handleWarningOpen = () => setOpenWarning(true);
  const handleWarningClose = () => setOpenWarning(false);

  const handleDownload = () => setDownload(true);
  const handleDownloaded = () => setDownload(false);

  const getBadgeProgress = () => {
    getBadgeProgressService().then((resp) => {
      setAchievement(resp.data);
    });
  };

  const setBadgeProgress = () => {
    if (achievement && profile) {
      // walk_run_distances
      if (achievement.walk_run_distances < 1) {
        setAchievement1(1);
      } else if (
        achievement.walk_run_distances >= 1 &&
        achievement.walk_run_distances < 50
      ) {
        setAchievement1(2);
      } else if (
        achievement.walk_run_distances >= 50 &&
        achievement.walk_run_distances < 100
      ) {
        setAchievement1(3);
      } else if (achievement.walk_run_distances >= 100) {
        setAchievement1(4);
      }
      // end walk_run_distances

      // count_submit_bmi
      if (achievement.count_submit_bmi < 1) {
        setAchievement2(1);
      } else if (achievement.count_submit_bmi === 1) {
        setAchievement2(2);
      } else if (
        achievement.count_submit_bmi >= 2 &&
        achievement.count_submit_bmi < 5
      ) {
        setAchievement2(3);
      } else if (achievement.count_submit_bmi >= 5) {
        setAchievement2(4);
      }
      // end count_submit_bmi

      // total_calories
      if (achievement.total_calories < 1000) {
        setAchievement3(1);
      } else if (
        achievement.total_calories > 1000 &&
        achievement.total_calories < 5000
      ) {
        setAchievement3(2);
      } else if (
        achievement.total_calories >= 5000 &&
        achievement.total_calories < 10000
      ) {
        setAchievement3(3);
      } else if (achievement.total_calories >= 10000) {
        setAchievement3(4);
      }
      // end total_calories

      // total_minutes
      if (achievement.total_minutes < 150) {
        setAchievement4(1);
      } else if (
        achievement.total_minutes >= 150 &&
        achievement.total_minutes < 1000
      ) {
        setAchievement4(2);
      } else if (
        achievement.total_minutes >= 1000 &&
        achievement.total_minutes < 2000
      ) {
        setAchievement4(3);
      } else if (achievement.total_minutes >= 2000) {
        setAchievement4(4);
      }
      // end total_minutes

      // experience_level
      if (profile.experience_level < 3) {
        setAchievement5(1);
      } else if (
        profile.experience_level >= 3 &&
        profile.experience_level < 8
      ) {
        setAchievement5(2);
      } else if (
        profile.experience_level >= 8 &&
        profile.experience_level < 20
      ) {
        setAchievement5(3);
      } else if (profile.experience_level >= 20) {
        setAchievement5(4);
      }
      // end experience_level

      // count_health_topic
      if (achievement.count_health_topic < 1) {
        setAchievement6(1);
      } else if (
        achievement.count_health_topic >= 1 &&
        achievement.count_health_topic < 5
      ) {
        setAchievement6(2);
      } else if (
        achievement.count_health_topic >= 5 &&
        achievement.count_health_topic < 10
      ) {
        setAchievement6(3);
      } else if (achievement.count_health_topic >= 10) {
        setAchievement6(4);
      }
      // end count_health_topic

      // count_exercise_days
      if (achievement.count_exercise_days < 3) {
        setAchievement7(1);
      } else if (
        achievement.count_exercise_days >= 3 &&
        achievement.count_exercise_days < 7
      ) {
        setAchievement7(2);
      } else if (
        achievement.count_exercise_days >= 7 &&
        achievement.count_exercise_days < 15
      ) {
        setAchievement7(3);
      } else if (achievement.count_exercise_days >= 15) {
        setAchievement7(4);
      }
      // end count_exercise_days
    }
  };

  const onDownload = async () => {
    handleDownload();

    const filename = `certificate-${dayjs().format("YYYYMMDD-HHMM")}.png`;

    let dataUrl = await buildPng();
    handleDownloaded();

    var img = new Image();
    img.src = dataUrl;

    img.onload = function () {
      // Create a canvas element
      var canvas = document.createElement("canvas");
      var ctx: any = canvas.getContext("2d");

      // Set canvas dimensions to match the image
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image onto the canvas
      ctx.drawImage(img, 0, 0);

      // Convert canvas content to a data URL
      var dataURL = canvas.toDataURL("image/png");

      // Create a temporary anchor element
      var a = document.createElement("a");
      a.href = dataURL;

      // Set the filename for the download
      a.download = filename;

      // Programmatically click the anchor to trigger the download
      a.click();
    };
  };

  const buildPng = async () => {
    const element = document.getElementById("image-node") as HTMLElement;

    let dataUrl = "";
    const minDataLength = 2000000;
    let i = 0;
    const maxAttempts = 10;

    while (dataUrl.length < minDataLength && i < maxAttempts) {
      dataUrl = await htmlToImage.toJpeg(element, {
        quality: 0.95,
        cacheBust: true,
      });
      i += 1;
    }

    return dataUrl;
  };

  useEffect(() => {
    if (profile.created_date) {
      const length = profile.first_name.length + profile.last_name.length;
      if (length > 18) {
        setfullnameIsLarge(true);
      }
    }
  }, [profile]);

  useEffect(getBadgeProgress, []);

  useEffect(setBadgeProgress, [achievement, profile]);

  return (
    <Box sx={pageStyle}>
      <Typography
        align="center"
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="ktgGreen.main"
        zIndex={1}
      >
        ความสำเร็จ
      </Typography>

      <Box display="flex" gap="16px" maxWidth={{ xs: 350, sm: 400 }} width={1}>
        <Button
          color="green"
          fullWidth
          variant="contained"
          startIcon={
            <Box
              component="img"
              src="/icons/vector_14.png"
              width={24}
              height={24}
            />
          }
          onClick={handleCertificateOpen}
        >
          <Typography
            variant="subtitle2"
            color="white"
            sx={{ textTransform: "none" }}
          >
            e-Certificate
          </Typography>
        </Button>

        <Button
          fullWidth
          color="secPink"
          variant="contained"
          startIcon={
            <Box
              component="img"
              src="/icons/vector_15.png"
              width={24}
              height={24}
            />
          }
          component={RouterLink}
          to="/reward"
        >
          <Typography variant="subtitle2" color="white">
            แลกรางวัล
          </Typography>
        </Button>
      </Box>

      <Container component="main" sx={containerStyle} maxWidth={false}>
        <Box sx={boxStyle}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" gap="16px">
              <Box
                component="img"
                src={`/achievement/asset1/asset1-${achievement1}@2x.png`}
                sx={{ cursor: "pointer" }}
                width={{
                  xs: achievement1 > 2 ? 105 : 85,
                  md: achievement1 > 2 ? 150 : 130,
                }}
                height={{ xs: 95, md: 145 }}
                onClick={() => {
                  handleAchievementBadgeOpen({
                    level: achievement1,
                    achievement_type: 1,
                    achievement: achievement,
                  });
                }}
              />
              <Box
                component="img"
                src={`/achievement/asset2/asset2-${achievement2}@2x.png`}
                sx={{ cursor: "pointer" }}
                width={{ xs: 85, md: 130 }}
                height={{ xs: 95, md: 145 }}
                onClick={() => {
                  handleAchievementBadgeOpen({
                    level: achievement2,
                    achievement_type: 2,
                    achievement: achievement,
                  });
                }}
              />
            </Box>
            <Box display="flex" gap="16px">
              <Box
                component="img"
                src={`/achievement/asset3/asset3-${achievement3}@2x.png`}
                sx={{ cursor: "pointer" }}
                width={{ xs: 85, md: 130 }}
                height={{ xs: 95, md: 145 }}
                onClick={() => {
                  handleAchievementBadgeOpen({
                    level: achievement3,
                    achievement_type: 3,
                    achievement: achievement,
                  });
                }}
              />
              <Box
                component="img"
                src={`/achievement/asset4/asset4-${achievement4}@2x.png`}
                sx={{ cursor: "pointer" }}
                width={{ xs: 85, md: 130 }}
                height={{ xs: 95, md: 145 }}
                onClick={() => {
                  handleAchievementBadgeOpen({
                    level: achievement4,
                    achievement_type: 4,
                    achievement: achievement,
                  });
                }}
              />
              <Box
                component="img"
                src={`/achievement/asset5/asset5-${achievement5}@2x.png`}
                sx={{ cursor: "pointer" }}
                width={{ xs: 85, md: 130 }}
                height={{ xs: 95, md: 145 }}
                onClick={() => {
                  handleAchievementBadgeOpen({
                    level: achievement5,
                    achievement_type: 5,
                    achievement: achievement,
                  });
                }}
              />
            </Box>
            <Box display="flex" gap="16px">
              <Box
                component="img"
                src={`/achievement/asset6/asset6-${achievement6}@2x.png`}
                sx={{ cursor: "pointer" }}
                width={{ xs: 85, md: 130 }}
                height={{ xs: 95, md: 145 }}
                onClick={() => {
                  handleAchievementBadgeOpen({
                    level: achievement6,
                    achievement_type: 6,
                    achievement: achievement,
                  });
                }}
              />
              <Box
                component="img"
                src={`/achievement/asset7/asset7-${achievement7}@2x.png`}
                sx={{ cursor: "pointer" }}
                width={{ xs: 85, md: 130 }}
                height={{ xs: 95, md: 145 }}
                onClick={() => {
                  handleAchievementBadgeOpen({
                    level: achievement7,
                    achievement_type: 7,
                    achievement: achievement,
                  });
                }}
              />
            </Box>
          </Box>
        </Box>
      </Container>

      <Box id="image-node" display="flex" justifyContent="center" ref={boxRef}>
        <Box
          id="certificate"
          display="flex"
          flexDirection="column"
          sx={{
            backgroundImage: "url('/achievement/cer_ss6.png')",
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            display: download ? "block" : "none",
          }}
          width={1122}
          height={794}
          position="relative"
        >
          <Box position="absolute" top="35.5%" left="10.3%">
            <Box
              sx={{
                width: 175,
                height: 175,
              }}
            >
              <Box sx={{ width: "100%", height: "100%" }}>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    padding: "4px",
                    boxShadow: "0 3px 6px rgb(0 0 0 / 16%)",
                    marginBottom: 0,
                    backgroundColor: "#fff",
                    borderRadius: "100%",
                  }}
                >
                  <Avatar
                    alt={profile.first_name}
                    src={avatar ? avatar : "/images/avatar-default.png"}
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box position="absolute" top="51%" width={1}>
            {fullnameIsLarge && (
              <Typography align="center" typography="h2" color="common.black">
                {profile.first_name} {profile.last_name}
              </Typography>
            )}
            {!fullnameIsLarge && (
              <Typography align="center" typography="h2" color="common.black">
                {profile.first_name} {profile.last_name}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Modal
        keepMounted
        open={openCertificate}
        onClose={handleCertificateClose}
      >
        <Box sx={modalStyle} width={{ xs: 340, sm: 600 }}>
          <CertificateModal
            handleClose={handleCertificateClose}
            onDownload={onDownload}
          />
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openAchievementBadge}
        onClose={handleAchievementBadgeClose}
      >
        <Box sx={modalStyle} width={{ xs: 340 }}>
          {achievementBadge && (
            <AchievementBadgeModal
              achievementBadge={achievementBadge}
              handleClose={handleAchievementBadgeClose}
            />
          )}
        </Box>
      </Modal>

      <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
        <Box sx={modalStyle} width={{ xs: 340 }}>
          <WarningModal
            message={`คุณต้องทำอีก ${
              configEnv.season_hp_cond - achievement?.season_hp
            } แต้มสุขภาพ จึงจะได้รับ ใบประกาศนียบัตร`}
            handleClose={handleWarningClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default AchievementPage;
