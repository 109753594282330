import {
  Box,
  Typography,
  styled,
  TableCell,
  TableRow,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { communityTypes } from "../../../consts/app";

import { boxStyle } from "../../../consts/styles";

const CommunityDescription = ({
  typeSummaries,
  memberRanks,
  experiencePointRanks,
}: any) => {
  return (
    <Box sx={{ ...boxStyle, gap: "28px" }}>
      <Typography
        align="center"
        typography={{ xs: "h4", sm: "h3" }}
        color="black.main"
      >
        รายละเอียดกลุ่ม
      </Typography>

      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        gap="16px"
      >
        <Box
          display="flex"
          boxShadow="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15)"
          borderRadius="10px"
          sx={{ background: "#2CADF2" }}
          gap="16px"
          justifyContent="center"
          width={1}
          paddingY="16px"
          maxWidth={{ sm: "300px" }}
        >
          <Box
            alignSelf="center"
            component="img"
            src="/dashboard/group_dashboard.png"
            sx={{
              height: { xs: "40px", sm: "50px" },
              width: { xs: "40px", sm: "50px" },
            }}
          ></Box>

          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography
              typography={{ xs: "body1", sm: "subtitle2" }}
              color="white"
            >
              จำนวนกลุ่มทั้งหมด
            </Typography>

            <Box display="flex" gap="8px">
              <Typography typography={{ xs: "h4", sm: "h2" }} color="white">
                {typeSummaries?.total.toLocaleString()}
              </Typography>

              <Typography variant="body1" color="white" alignSelf="end">
                กลุ่ม
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          gap="16px"
          flexDirection={{ xs: "column", lg: "row" }}
          width={1}
        >
          <Box display="flex" gap="16px" width={1}>
            <CommunityType
              textLabel="ชุมชน"
              value={typeSummaries?.total_type_1?.toLocaleString()}
            />
            <CommunityType
              textLabel="โรงเรียน"
              value={typeSummaries?.total_type_2.toLocaleString()}
            />
          </Box>

          <Box display="flex" gap="16px" width={1}>
            <CommunityType
              textLabel="องค์กร"
              value={typeSummaries?.total_type_3.toLocaleString()}
            />
            <CommunityType
              textLabel="สาธารณสุข"
              value={typeSummaries?.total_type_4.toLocaleString()}
            />
          </Box>
        </Box>
      </Box>

      <Typography
        align="center"
        typography={{ xs: "h4", sm: "h3" }}
        color="black.main"
      >
        อันดับกลุ่มที่สมาชิกสูงสุด 20 อันดับ
      </Typography>

      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", borderRadius: 0, maxHeight: "300px" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Typography fontWeight={600} variant="body1">
                  ลำดับ
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontWeight={600} variant="body1">
                  ชื่อกลุ่ม
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontWeight={600} variant="body1">
                  ประเภทกลุ่ม
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontWeight={600} variant="body1">
                  จังหวัด
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontWeight={600} variant="body1">
                  จำนวนสมาชิกทั้งหมด
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontWeight={600} variant="body1">
                  แคลอรี่
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {memberRanks?.map((row: any, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  <Typography variant="body1">{row.seq_no}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1">{row.community_name}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  {communityTypes[row.type_id - 1]}
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1">{row.province_name}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1" align="right">
                    {row.total.toLocaleString()}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1" align="right">
                    {row.total_calories.toLocaleString()}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography
        align="center"
        typography={{ xs: "h4", sm: "h3" }}
        color="black.main"
      >
        อันดับกลุ่มที่มี EXP สูงสุด 20 อันดับ
      </Typography>

      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", borderRadius: 0, maxHeight: "300px" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTable1Cell>
                <Typography fontWeight={600} variant="body1">
                  ลำดับ
                </Typography>
              </StyledTable1Cell>
              <StyledTable1Cell>
                <Typography fontWeight={600} variant="body1">
                  ชื่อกลุ่ม
                </Typography>
              </StyledTable1Cell>
              <StyledTable1Cell>
                <Typography fontWeight={600} variant="body1">
                  ประเภทกลุ่ม
                </Typography>
              </StyledTable1Cell>
              <StyledTable1Cell>
                <Typography fontWeight={600} variant="body1">
                  จังหวัด
                </Typography>
              </StyledTable1Cell>
              <StyledTable1Cell>
                <Typography fontWeight={600} variant="body1">
                  จำนวนสมาชิกทั้งหมด
                </Typography>
              </StyledTable1Cell>
              <StyledTable1Cell>
                <Typography fontWeight={600} variant="body1">
                  EXP
                </Typography>
              </StyledTable1Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {experiencePointRanks?.map((row: any, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  <Typography variant="body1">{row.seq_no}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1">{row.community_name}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  {communityTypes[row.type_id - 1]}
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1">{row.province_name}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1" align="right">
                    {row.total.toLocaleString()}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1" align="right">
                    {row.experience_point.toLocaleString()}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const CommunityType = ({ textLabel, value }: any) => {
  return (
    <Box
      sx={{
        ...boxStyle,
        gap: "8px",
        width: 1,
        padding: "16px",
      }}
    >
      <Typography
        align="center"
        typography={{ xs: "body1", sm: "subtitle2" }}
        color="black.main"
      >
        {textLabel}
      </Typography>

      <Typography align="center" typography="h4" color="blue.main">
        {value}
      </Typography>

      <Typography
        align="center"
        typography={{ xs: "body2", sm: "body1" }}
        color="primary"
      >
        กลุ่ม
      </Typography>
    </Box>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.common.white,
  },
}));

const StyledTable1Cell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.ktjBlue.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default CommunityDescription;
