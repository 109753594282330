import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link as RouterLink } from "react-router-dom";

import { getNewsService } from "../../../services/news.service";

const NewsSlider = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const [page] = useState(1);
  const [size] = useState(100);
  const [news, setNews] = useState<any[]>([]);

  const getNews = () => {
    getNewsService({ page, size }).then((resp) => {
      setNews(resp.data.items);
    });
  };

  useEffect(getNews, [page, size]);

  return (
    <Box display="flex" flexDirection="column">
      <Container
        component="main"
        maxWidth={false}
        sx={{
          zIndex: 1,
          maxWidth: "1120px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography
          align="center"
          typography="rewardTitle"
          fontSize={{ xs: "24px", sm: "48px" }}
        >
          ข่าวสารกรมอนามัย
        </Typography>

        <Box>
          <Swiper
            className="swiper-container"
            spaceBetween={24}
            slidesPerView={!sm ? 2 : 3}
            modules={[Autoplay, Pagination, Navigation]}
            style={{ display: "grid", paddingBottom: 40 }}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
          >
            <Box>
              {news.map((item: any, index: number) => (
                <SwiperSlide key={index}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height={1}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={{ xs: "8px", sm: "24px" }}
                      justifyContent="space-between"
                    >
                      <Box
                        src={item.image_url}
                        height={{ xs: 100, sm: 200 }}
                        sx={{ borderRadius: "10px" }}
                        component="img"
                      />

                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={{ xs: "4px", sm: "8px" }}
                      >
                        <Typography
                          typography={{ xs: "body1", sm: "h4" }}
                          fontWeight="600 !important"
                          color="black.main"
                        >
                          {item.title.substring(0, sm ? 60 : 30)}
                          {item.title.length > (sm ? 60 : 30) && "..."}
                        </Typography>

                        <Typography
                          typography={{ xs: "body2", sm: "subtitle2" }}
                          color="cgrey.main"
                        >
                          {item.sub_title.substring(0, sm ? 100 : 50)}
                          {item.sub_title.length > (sm ? 100 : 50) && "..."}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="end"
                      component={RouterLink}
                      to={`/news/${item.news_id}/description`}
                      sx={{ textDecoration: "none" }}
                    >
                      <Typography
                        typography="body2"
                        fontWeight="600 !important"
                        color="blue.main"
                      >
                        อ่านต่อ
                      </Typography>

                      <ArrowForwardIosIcon
                        color="blue"
                        sx={{ alignSelf: "center", fontSize: 12 }}
                      />
                    </Box>
                  </Box>
                </SwiperSlide>
              ))}
            </Box>
          </Swiper>
        </Box>

        <Box alignSelf="center">
          <Button
            color="blue"
            variant="contained"
            component={RouterLink}
            to="/news"
          >
            <Typography variant="subtitle2">ดูเพิ่มเติม</Typography>
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default NewsSlider;
