import {
  Route,
  Navigate,
  Routes,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Fragment, useEffect } from "react";
import UserLayout from "./layouts/UserLayout";
import LoginPage from "./pages/user/LoginPage";
import RegisterPage from "./pages/user/RegisterPage";

import HomePage from "./pages/user/HomePage";
import UserProtectLayout from "./layouts/UserProtectLayout";
import ProfilePage from "./pages/user/ProfilePage";
import AddResultExercise from "./pages/user/AddResultExercise";
import ResultExercise from "./pages/user/ResultExercise";
import DashboardExercise from "./pages/user/DashboardExercise";
import HistoryExercise from "./pages/user/HistoryExercise";
import ScrollToTop from "./helpers/ScrollToTop";
import ForgotPassword from "./pages/user/ForgotPassword";
import ResetPassword from "./pages/user/ResetPassword";
import Dashboard from "./pages/user/Dashboard";
import MemberBySeason from "./pages/user/MemberBySeason";
import Reward from "./pages/user/reward/Reward";
import RewardPoint from "./pages/user/reward/RewardPoint";
import PointConditions from "./pages/user/reward/PointConditions";
import RedemptionHistory from "./pages/user/reward/RedemptionHistory";
import ExperiencePage from "./pages/user/ExperiencePage";
import RankPage from "./pages/user/RankPage";
import BmiPage from "./pages/user/BmiPage";
import Community from "./pages/user/community/Community";
import CommunityCreate from "./pages/user/community/CommunityCreate";
import ConnectFiitToken from "./pages/user/fiit/ConnectFiitToken";
import ConfirmOtpFiit from "./pages/user/fiit/ConfirmOtpFiit";
import ConnectFiitSuccess from "./pages/user/fiit/ConnectFiitSuccess";
import CommunitySearch from "./pages/user/community/CommunitySearch";
import CommunityView from "./pages/user/community/CommunityView";
import CommunityMemberRank from "./pages/user/community/CommunityMemberRank";
import CommunityMemberInvite from "./pages/user/community/CommunityMemberInvite";
import RewardDescription from "./pages/user/reward/RewardDescription";
import CommunityEdit from "./pages/user/community/CommunityEdit";
import News from "./pages/user/news/News";
import NewsDescription from "./pages/user/news/NewsDescription";
import Faq from "./pages/user/faq/Faq";
import CourseDescriptionPage from "./pages/user/course/CourseDescriptionPage";
import SelectStatusPage from "./pages/user/course/SelectStatusPage";
import AchievementPage from "./pages/user/activity/AchievementPage";
import LessonOverviewPage from "./pages/user/course/LessonOverviewPage";
import LessonPage from "./pages/user/course/LessonPage";
import HealthLiteracy from "./pages/user/healthLiteracy/HealthLiteracy";
import QuizPage from "./pages/user/course/QuizPage";
import AssessmentPage from "./pages/user/course/AssessmentPage";
import Questionaires from "./pages/user/questionaire/Questionaires";
import Questionaire from "./pages/user/questionaire/Questionaire";
import CertificatePage from "./pages/user/course/CertificatePage";
import Notifications from "./pages/user/notification/Notifications";
import HealthPlaces from "./pages/user/healthPlace/HealthPlaces";
import PdpaPage from "./pages/user/PdpaPage";
import TermsAndConditions from "./pages/user/TermsAndConditions";
import ThaiDCallback from "./pages/user/ThaiDCallback";
import PinPage from "./pages/user/PinPage";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { configEnv } from "./configs/config-env";
import ThaidVerifySuccess from "./pages/user/ThaidVerifySuccess";
import { getItem } from "./services/localStorage.service";

const app = initializeApp(configEnv.firebaseConfig);
getAnalytics(app);

export const App = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const session = getItem("session");
    const state = getItem("state");

    if (!session && state && !searchParams.get("state"))
      navigate("/pin", {
        state: { state, isVerifyPin: true },
      });
  }, []);

  return (
    <Fragment>
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />

        <Route path="/terms-of-use" element={<TermsAndConditions />} />
        <Route path="/callback" element={<ThaiDCallback />} />

        <Route element={<UserLayout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/connect-fiit" element={<ConnectFiitToken />} />
          <Route path="/confirm-otp-fiit" element={<ConfirmOtpFiit />} />
          <Route
            path="/connect-fiit-success"
            element={<ConnectFiitSuccess />}
          />
          <Route path="/pin" element={<PinPage />} />
          <Route path="/thaid-verify-sccess" element={<ThaidVerifySuccess />} />

          {/* faq */}
          <Route path="/anonymous-faq" element={<Faq />} />
          {/* pdpa */}
          <Route path="/pdpa" element={<PdpaPage />} />

          <Route element={<UserProtectLayout />}>
            <Route path="/home" element={<HomePage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route
              path="/add-result-exercise"
              element={<AddResultExercise />}
            />
            <Route path="/dashboard-exercise" element={<DashboardExercise />} />
            <Route path="/experience" element={<ExperiencePage />} />
            <Route path="/rank" element={<RankPage />} />
            <Route path="/result-exercise" element={<ResultExercise />} />
            <Route path="/history-exercise" element={<HistoryExercise />} />
            <Route path="/dashboard-overview" element={<Dashboard />} />
            <Route path="/member-by-season" element={<MemberBySeason />} />

            <Route path="/reward" element={<Reward />} />
            <Route path="/reward/description" element={<RewardDescription />} />

            <Route path="/reward-point" element={<RewardPoint />} />
            <Route path="/redemption-history" element={<RedemptionHistory />} />
            <Route path="/point-conditions" element={<PointConditions />} />

            <Route path="/bmi" element={<BmiPage />} />

            {/* community */}
            <Route path="/community" element={<Community />} />
            <Route path="/community/create" element={<CommunityCreate />} />
            <Route path="/community/edit" element={<CommunityEdit />} />
            <Route path="/community/search" element={<CommunitySearch />} />
            <Route path="/community/view" element={<CommunityView />} />
            <Route
              path="/community/member-rank"
              element={<CommunityMemberRank />}
            />
            {/* <Route
              path="/community/member-request"
              element={<CommunityMemberRequest />}
            /> */}
            <Route
              path="/community/member-invite"
              element={<CommunityMemberInvite />}
            />
            {/* <Route
              path="/community/notification"
              element={<CommunityNotification />}
            /> */}

            {/* news */}
            <Route path="/news" element={<News />} />
            <Route path="/news/:id/description" element={<NewsDescription />} />

            {/* faq */}
            <Route path="/faq" element={<Faq />} />

            {/* course */}
            <Route
              path="/course/description"
              element={<CourseDescriptionPage />}
            />
            <Route
              path="/course/select-status"
              element={<SelectStatusPage />}
            />
            <Route
              path="/course/lesson-overview"
              element={<LessonOverviewPage />}
            />
            <Route path="/course/lesson" element={<LessonPage />} />
            <Route path="/course/lesson-quiz" element={<QuizPage />} />
            <Route path="/course/assessment" element={<AssessmentPage />} />
            <Route path="/course/certificate" element={<CertificatePage />} />

            {/* achievement */}
            <Route path="/achievement-view" element={<AchievementPage />} />

            {/* knowledge health  */}
            <Route path="/health-literacy" element={<HealthLiteracy />} />

            {/* questionaire */}
            <Route path="/questionaires" element={<Questionaires />} />
            <Route path="/questionaire" element={<Questionaire />} />

            {/* notifications */}
            <Route path="/notifications" element={<Notifications />} />

            {/* Health place list */}
            <Route path="/health-place-list" element={<HealthPlaces />} />
          </Route>
        </Route>
      </Routes>
    </Fragment>
  );
};
