import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link as RouterLink } from "react-router-dom";
import {
  gethealthLiteraciesService,
  postReadhealthLiteracyService,
} from "../../../services/healthLiteracy.service";
import { useAppDispatch } from "../../../features/userStore/hook";
import { setProfile } from "../../../features/userStore/libs/profileSlice";

const HealthLiteracy = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const [page] = useState(1);
  const [size] = useState(100);
  const [healthLiteracy, setHealthLiteracy] = useState<any[]>([]);

  const gethealthLiteracies = () => {
    gethealthLiteraciesService({ page, size }).then((resp) => {
      setHealthLiteracy(resp.data.items);
    });
  };

  function onClickReadMore(healthId: any) {
    postReadhealthLiteracyService(healthId).then((resp) => {
      dispatch(setProfile(resp.data));
    });
  }

  useEffect(gethealthLiteracies, [page, size]);

  return (
    <Box display="flex" flexDirection="column">
      <Container
        component="main"
        maxWidth={false}
        sx={{
          zIndex: 1,
          maxWidth: "1120px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography
          align="center"
          typography="rewardTitle"
          fontSize={{ xs: "24px", sm: "48px" }}
        >
          รอบรู้สุขภาพ
        </Typography>

        <Box>
          <Swiper
            className="swiper-container"
            spaceBetween={24}
            slidesPerView={sm ? 3 : 2}
            modules={[Autoplay, Pagination, Navigation]}
            style={{ display: "grid", paddingBottom: 40 }}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
          >
            <Box>
              {healthLiteracy.map((item: any, index: number) => (
                <SwiperSlide key={index}>
                  <Box
                    component="a"
                    display="flex"
                    flexDirection="column"
                    height={1}
                    padding="20px 16px"
                    borderRadius="10px"
                    sx={{ background: "white", textDecoration: "none" }}
                    onTouchStart={function () {
                      onClickReadMore(item.health_id);
                    }}
                    href={item.reference}
                    target="_blank"
                    gap="8px"
                  >
                    <Box
                      src={item.image_url}
                      sx={{ borderRadius: "10px", height: "auto", width: 1 }}
                      component="img"
                    />

                    <Typography
                      typography={{ xs: "body1", sm: "h4" }}
                      fontWeight="600 !important"
                      color="black.main"
                    >
                      {item.title}
                    </Typography>
                  </Box>
                </SwiperSlide>
              ))}
            </Box>
          </Swiper>
        </Box>

        <Box alignSelf="center">
          <Button
            color="blue"
            variant="contained"
            component={RouterLink}
            to="/health-literacy"
          >
            <Typography variant="subtitle2">ดูเพิ่มเติม</Typography>
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default HealthLiteracy;
