import { Box, Typography } from "@mui/material";
import { boxStyle } from "../../../consts/styles";

const ReportExercise = ({ allSummaries }: any) => {
  return (
    <Box sx={boxStyle}>
      <Typography
        align="center"
        typography={{ xs: "h4", sm: "h3" }}
        color="black.main"
      >
        รายงานการออกกำลังกาย
      </Typography>

      <Box
        display="flex"
        gap="16px"
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Box
          display="flex"
          borderRadius="10px"
          padding="16px 0"
          width={1}
          sx={{ border: "2px solid #EB698F" }}
          gap="16px"
          justifyContent="center"
        >
          <Box
            alignSelf="center"
            component="img"
            src="/icons/vector_1.png"
            width="45px"
            height="55px"
          ></Box>

          <Box display="flex" flexDirection="column">
            <Typography
              typography={{ xs: "body1", sm: "subtitle2" }}
              color="black.main"
            >
              จำนวนสะสมพลังงานทั้งหมด
            </Typography>

            <Typography
              typography={{ xs: "body1", sm: "h4" }}
              color="secPink.main"
            >
              {allSummaries?.total_calories.toLocaleString()}
            </Typography>

            <Typography
              typography={{ xs: "body2", sm: "body1" }}
              color="primary"
            >
              กิโลแคลอรี่
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          borderRadius="10px"
          padding="16px 0"
          width={1}
          sx={{ border: "2px solid #2FD25D" }}
          gap="16px"
          justifyContent="center"
        >
          <Box
            alignSelf="center"
            component="img"
            src="/icons/vector_3.png"
            width="45px"
            height="55px"
          ></Box>

          <Box display="flex" flexDirection="column">
            <Typography
              typography={{ xs: "body1", sm: "subtitle2" }}
              color="black.main"
            >
              จำนวนระยะทางทั้งหมด
            </Typography>

            <Typography
              typography={{ xs: "body1", sm: "h4" }}
              color="secGreen.main"
            >
              {allSummaries?.total_distances.toLocaleString()}
            </Typography>

            <Typography
              typography={{ xs: "body2", sm: "body1" }}
              color="primary"
            >
              กิโลเมตร
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          borderRadius="10px"
          padding="16px 0"
          width={1}
          sx={{ border: "2px solid #2CADF2" }}
          gap="16px"
          justifyContent="center"
        >
          <Box
            alignSelf="center"
            component="img"
            src="/icons/vector_2.png"
            width="45px"
            height="55px"
          ></Box>

          <Box display="flex" flexDirection="column">
            <Typography
              typography={{ xs: "body1", sm: "subtitle2" }}
              color="black.main"
            >
              จำนวนเวลาทั้งหมด
            </Typography>

            <Typography
              typography={{ xs: "body1", sm: "h4" }}
              color="ktjBlue.main"
            >
              {allSummaries?.total_minutes.toLocaleString()}
            </Typography>

            <Typography
              typography={{ xs: "body2", sm: "body1" }}
              color="primary"
            >
              นาที
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReportExercise;
