import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const ErrorModal = ({ message, handleErrorClose }: any) => {
  return (
    <Box display="flex" flexDirection="column" padding="16px">
      <Box
        component="a"
        alignSelf="end"
        sx={{ cursor: "pointer" }}
        onClick={handleErrorClose}
      >
        <CloseIcon sx={{ fontSize: 20 }} />
      </Box>

      <Box display="flex" flexDirection="column">
        <Typography align="center">
          <HighlightOffIcon
            sx={{ fontSize: 60, color: "#EB698F" }}
            color="error"
          />
        </Typography>

        <Typography align="center" color="#EB698F" variant="h5">
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default ErrorModal;
