import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { TableComponents, TableVirtuoso } from "react-virtuoso";

interface Data {
  conditions: string;
  point: string;
  exp: string;
}

interface ColumnData {
  dataKey: keyof Data;
  label: string;
  numeric?: boolean;
  width: number;
}

const columns: ColumnData[] = [
  {
    width: 150,
    label: "เงื่อนไข",
    dataKey: "conditions",
  },
  {
    width: 100,
    label: "แต้มสุขภาพ",
    dataKey: "point",
  },
  {
    width: 100,
    label: "EXP",
    dataKey: "exp",
  },
];

const data = [
  { conditions: "ลงทะเบียนใหม่", point: "5 แต้ม", exp: "5 EXP" },
  {
    conditions: "เชิญชวนเพื่อนลงทะเบียน",
    point: "3 แต้ม / คน",
    exp: "3 EXP / คน",
  },
  { conditions: "ประเมิน BMI", point: "5 แต้ม / ครั้ง", exp: "5 EXP / คน" },
  {
    conditions: "การเผาผลาญพลังงานทุก ๆ 100 kcal",
    point: "1 แต้ม",
    exp: "1 EXP",
  },
  {
    conditions: "ผ่านความสำเร็จ 1 ครั้ง",
    point: "5 แต้ม / ครั้ง",
    exp: "5 EXP / ครั้ง",
  },
  {
    conditions: "รอบรู้สุขภาพ",
    point: "3 แต้ม / เรื่อง",
    exp: "3 EXP / เรื่อง",
  },
  { conditions: "ผ่านการอบรมหลักสูตรฯ", point: "20 แต้ม", exp: "20 EXP" },
  {
    conditions: "เข้าร่วมกลุ่ม",
    point: "5 แต้ม / กลุ่ม",
    exp: "5 EXP / กลุ่ม",
  },
];

const PointConditions = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      gap="24px"
      pt="50px"
      pb="100px"
    >
      <Typography
        align="center"
        typography={{ xs: "h3", sm: "h1" }}
        color="ktgGreen.main"
        zIndex={1}
      >
        เงื่อนไขการรับแต้มต่าง ๆ
      </Typography>

      <Container
        component="main"
        sx={{
          zIndex: 1,
          maxWidth: 600,
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
        maxWidth={false}
      >
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="10px"
          alignItems="center"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background: "#FFFFFF",
          }}
          padding="16px"
          gap="16px"
        >
          <Typography
            align="center"
            typography={{ xs: "h4", sm: "h3" }}
            color="common.black"
          >
            รายละเอียดเงื่อนไข
          </Typography>

          <Box
            sx={{
              height: 530,
              width: 1,
              background: "#FFFFFF",
              borderRadius: "10px",
            }}
          >
            <TableVirtuoso
              data={data}
              components={VirtuosoTableComponents}
              fixedHeaderContent={fixedHeaderContent}
              itemContent={rowContent}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const fixedHeaderContent = () => {
  return (
    <TableRow>
      {columns.map((column: any) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align="center"
          style={{ width: column.width }}
          sx={{
            padding: "10px 0",
            backgroundColor: "#2CADF2",
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 600 }} color="white">
            {column.label}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  );
};

const rowContent = (_index: number, row: any) => {
  return (
    <React.Fragment>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          align="center"
          sx={{
            borderBottom: "none",
            background: _index % 2 !== 0 ? "#F5F7FA" : "",
          }}
        >
          <Typography variant="body1" color="black.main">
            {row[column.dataKey]}
          </Typography>
        </TableCell>
      ))}
    </React.Fragment>
  );
};

const VirtuosoTableComponents: TableComponents<any> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer
      sx={{ boxShadow: "none", borderRadius: 0 }}
      {...props}
      ref={ref}
    />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

export default PointConditions;
