import { Box, Typography } from "@mui/material";

const MemberTypeSection = ({ summary }: any) => {
  return (
    <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap="16px">
      <Box
        sx={{ background: "#FFFFFF" }}
        boxShadow="0px 0px 10px rgba(0, 0, 0, 0.15)"
        borderRadius="10px"
        padding="16px 24px"
        display="flex"
        flexDirection="column"
        gap="16px"
        width={1}
      >
        <Box
          display="flex"
          justifyContent={{ xs: "space-between", sm: "center" }}
          flexDirection={{ xs: "row-reverse", sm: "column" }}
          gap="16px"
        >
          <Box
            component="img"
            src="/dashboard/general.png "
            width="50px"
            height="50px"
            sx={{ alignSelf: "center" }}
          ></Box>

          <Box display="flex" flexDirection="column" gap="4px">
            <Typography
              typography={{ xs: "body1", sm: "subtitle2" }}
              sx={{ alignSelf: { sm: "center" } }}
              color="black.main"
            >
              ประชาชนทั่วไป
            </Typography>

            <Box
              display="flex"
              gap="8px"
              justifyContent={{ xs: "start", sm: "center" }}
            >
              <Typography variant="h5" color="blue.main">
                {summary?.all_summaries[0].total_type_1.toLocaleString()}
              </Typography>

              <Typography alignSelf="end" variant="body1" color="cgrey.main">
                คน
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            background: "#F5F7FA",
            borderRadius: "5px",
            padding: "8px 10px",
          }}
        >
          <Typography variant="body1" color="secPink.main" fontWeight={600}>
            วันนี้เพิ่มขึ้น +
            {summary?.today_summaries[0].total_type_1.toLocaleString()} คน
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{ background: "#FFFFFF" }}
        boxShadow="0px 0px 10px rgba(0, 0, 0, 0.15)"
        borderRadius="10px"
        padding="16px 24px"
        display="flex"
        flexDirection="column"
        gap="16px"
        width={1}
      >
        <Box
          display="flex"
          justifyContent={{ xs: "space-between", sm: "center" }}
          flexDirection={{ xs: "row-reverse", sm: "column" }}
          gap="16px"
        >
          <Box
            component="img"
            src="/dashboard/student.png"
            width="50px"
            height="50px"
            sx={{ alignSelf: "center" }}
          ></Box>

          <Box display="flex" flexDirection="column" gap="4px">
            <Typography
              typography={{ xs: "body1", sm: "subtitle2" }}
              sx={{ alignSelf: { sm: "center" } }}
              color="black.main"
            >
              นักเรียน / นักศึกษา
            </Typography>

            <Box
              display="flex"
              gap="8px"
              justifyContent={{ xs: "start", sm: "center" }}
            >
              <Typography variant="h5" color="blue.main">
                {summary?.all_summaries[0].total_type_2.toLocaleString()}
              </Typography>

              <Typography alignSelf="end" variant="body1" color="cgrey.main">
                คน
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            background: "#F5F7FA",
            borderRadius: "5px",
            padding: "8px 10px",
          }}
        >
          <Typography variant="body1" color="secPink.main" fontWeight={600}>
            วันนี้เพิ่มขึ้น +
            {summary?.today_summaries[0].total_type_2.toLocaleString()} คน
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{ background: "#FFFFFF" }}
        boxShadow="0px 0px 10px rgba(0, 0, 0, 0.15)"
        borderRadius="10px"
        padding="16px 24px"
        display="flex"
        flexDirection="column"
        gap="16px"
        width={1}
      >
        <Box
          display="flex"
          justifyContent={{ xs: "space-between", sm: "center" }}
          flexDirection={{ xs: "row-reverse", sm: "column" }}
          gap="16px"
        >
          <Box
            component="img"
            src="/dashboard/vhv.png"
            width="50px"
            height="50px"
            sx={{ alignSelf: "center" }}
          ></Box>

          <Box display="flex" flexDirection="column" gap="4px">
            <Typography
              typography={{ xs: "body1", sm: "subtitle2" }}
              sx={{ alignSelf: { sm: "center" } }}
              color="black.main"
            >
              อสม.
            </Typography>

            <Box
              display="flex"
              gap="8px"
              justifyContent={{ xs: "start", sm: "center" }}
            >
              <Typography variant="h5" color="blue.main">
                {summary?.all_summaries[0].total_type_3.toLocaleString()}
              </Typography>

              <Typography alignSelf="end" variant="body1" color="cgrey.main">
                คน
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            background: "#F5F7FA",
            borderRadius: "5px",
            padding: "8px 10px",
          }}
        >
          <Typography variant="body1" color="secPink.main" fontWeight={600}>
            วันนี้เพิ่มขึ้น +
            {summary?.today_summaries[0].total_type_3.toLocaleString()} คน
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{ background: "#FFFFFF" }}
        boxShadow="0px 0px 10px rgba(0, 0, 0, 0.15)"
        borderRadius="10px"
        padding="16px 24px"
        display="flex"
        flexDirection="column"
        gap="16px"
        width={1}
      >
        <Box
          display="flex"
          justifyContent={{ xs: "space-between", sm: "center" }}
          flexDirection={{ xs: "row-reverse", sm: "column" }}
          gap="16px"
        >
          <Box
            component="img"
            src="/dashboard/indivadual.png"
            width="50px"
            height="50px"
            sx={{ alignSelf: "center" }}
          ></Box>

          <Box display="flex" flexDirection="column" gap="4px">
            <Typography
              typography={{ xs: "body1", sm: "subtitle2" }}
              sx={{ alignSelf: { sm: "center" } }}
              color="black.main"
            >
              บุคลากรภาคเอกชน
            </Typography>

            <Box
              display="flex"
              gap="8px"
              justifyContent={{ xs: "start", sm: "center" }}
            >
              <Typography variant="h5" color="blue.main">
                {summary?.all_summaries[0].total_type_4.toLocaleString()}
              </Typography>

              <Typography alignSelf="end" variant="body1" color="cgrey.main">
                คน
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            background: "#F5F7FA",
            borderRadius: "5px",
            padding: "8px 10px",
          }}
        >
          <Typography variant="body1" color="secPink.main" fontWeight={600}>
            วันนี้เพิ่มขึ้น +
            {summary?.today_summaries[0].total_type_4.toLocaleString()} คน
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{ background: "#FFFFFF" }}
        boxShadow="0px 0px 10px rgba(0, 0, 0, 0.15)"
        borderRadius="10px"
        padding="16px 24px"
        display="flex"
        flexDirection="column"
        gap="16px"
        width={1}
      >
        <Box
          display="flex"
          justifyContent={{ xs: "space-between", sm: "center" }}
          flexDirection={{ xs: "row-reverse", sm: "column" }}
          gap="16px"
        >
          <Box
            component="img"
            src="/dashboard/gover.png"
            width="50px"
            height="50px"
            sx={{ alignSelf: "center" }}
          ></Box>

          <Box display="flex" flexDirection="column" gap="4px">
            <Typography
              typography={{ xs: "body1", sm: "subtitle2" }}
              sx={{ alignSelf: { sm: "center" } }}
              color="black.main"
            >
              บุคลากรภาครัฐฯ
            </Typography>

            <Box
              display="flex"
              gap="8px"
              justifyContent={{ xs: "start", sm: "center" }}
            >
              <Typography variant="h5" color="blue.main">
                {summary?.all_summaries[0].total_type_5.toLocaleString()}
              </Typography>

              <Typography alignSelf="end" variant="body1" color="cgrey.main">
                คน
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            background: "#F5F7FA",
            borderRadius: "5px",
            padding: "8px 10px",
          }}
        >
          <Typography variant="body1" color="secPink.main" fontWeight={600}>
            วันนี้เพิ่มขึ้น +
            {summary?.today_summaries[0].total_type_5.toLocaleString()} คน
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MemberTypeSection;
