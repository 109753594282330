import axiosService from "../api/axios";

export const gethealthLiteraciesService = (params: any) => {
  return axiosService.get(
    "/health-literacy/publish-health-literacy?" +
      new URLSearchParams({ ...params })
  );
};

export const postReadhealthLiteracyService = (id: any) => {
  return axiosService.post(`/health-literacy/${id}/read`);
};
