import {
  Box,
  Typography,
  Container,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  Modal,
} from "@mui/material";
import {
  boxStyle,
  containerStyle,
  modalStyle,
  pageStyle,
} from "../../../consts/styles";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { postApplyCourseService } from "../../../services/course.service";
import ErrorModal from "../../../components/modals/ErrorModal";

const SelectStatusPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [courseId, setCourseId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };

  const {
    control,
    watch,
    register,
    unregister,
    setValue,
    formState,
    handleSubmit,
  } = useForm({
    defaultValues: {
      member_type_id: null,
      affiliation: "",
    },
  });

  const { isDirty, isValid } = formState;

  const memberTypeId = watch("member_type_id");

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();
      await postApplyCourseService(courseId, values);

      navigate("/course/description", {
        state: {
          courseId,
        },
        replace: true,
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  useEffect(() => {
    if (!location.state) {
      navigate("/home");
      return;
    }
    setCourseId(location.state.courseId);
  }, [location, navigate]);

  useEffect(() => {
    unregister("affiliation");
    setValue("affiliation", "");
  }, [memberTypeId, setValue, unregister]);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  return (
    <Box sx={pageStyle}>
      <Typography
        align="center"
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="ktgGreen.main"
        zIndex={100}
      >
        โปรดเลือกสถานะ
      </Typography>

      <Container
        component="main"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        maxWidth={false}
      >
        <Box sx={{ ...boxStyle, padding: "24px" }}>
          <FormControl>
            <Controller
              rules={{ required: true }}
              control={control}
              name="member_type_id"
              render={({ field }: any) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="ประชาชนทั่วไป"
                  />

                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="อสม. / อสค."
                  />

                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label="นักเรียน / นักศึกษา"
                  />

                  <FormControlLabel
                    value={4}
                    control={<Radio />}
                    label="เจ้าหน้าที่สังกัดกระทรวงสาธารณสุข"
                  />
                  {memberTypeId === "4" && (
                    <TextField
                      placeholder="กรอกชื่อหน่วยงานของท่าน"
                      {...register("affiliation", { required: true })}
                    />
                  )}

                  <FormControlLabel
                    value={5}
                    control={<Radio />}
                    label="เจ้าหน้าที่ในสถานประกอบการ"
                  />
                  {memberTypeId === "5" && (
                    <TextField
                      placeholder="กรอกชื่อหน่วยงานของท่าน"
                      {...register("affiliation", { required: true })}
                    />
                  )}

                  <FormControlLabel
                    value={6}
                    control={<Radio />}
                    label="พนักงานองค์กรปกครองส่วนท้องถิ่น"
                  />
                  {memberTypeId === "6" && (
                    <TextField
                      placeholder="กรอกชื่อหน่วยงานของท่าน"
                      {...register("affiliation", { required: true })}
                    />
                  )}

                  <FormControlLabel
                    value={7}
                    control={<Radio />}
                    label="บุคลากรในสถานศึกษา"
                  />
                  {memberTypeId === "7" && (
                    <TextField
                      placeholder="กรอกชื่อหน่วยงานของท่าน"
                      {...register("affiliation", { required: true })}
                    />
                  )}
                </RadioGroup>
              )}
            />
          </FormControl>

          <Box display="flex" width={1} gap="16px">
            <Button
              fullWidth
              color="blue"
              variant="outlined"
              component={RouterLink}
              to="/home"
            >
              <Typography variant="subtitle2">ยกเลิก</Typography>
            </Button>

            <Button
              fullWidth
              color="green"
              variant="contained"
              onClick={handleSubmit(onSubmitHandler)}
              disabled={!isValid || !isDirty || loading}
            >
              <Typography variant="subtitle2" color="white">
                บันทึก
              </Typography>
            </Button>
          </Box>
        </Box>
      </Container>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle} width="340px">
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default SelectStatusPage;
