import {
  Box,
  FormControl,
  Typography,
  FormLabel,
  TextField,
  Button,
  Select,
  MenuItem,
  Modal,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { FormattedMessage } from "react-intl";
import { SubmitHandler, useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import th from "dayjs/locale/th";
import { OverwriteAdapterDayjs } from "../../../utils/common.util";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { exerciseActivityTypeService } from "../../../services/master-data.service";
import { modalStyle } from "../../../consts/styles";
import SuccessModal from "../SuccessModal";
import ErrorModal from "../ErrorModal";
import { postCommunityEventService } from "../../../services/community.service";

const minDate = new Date(new Date().getTime() + 86400000);

const CreateEventModal = ({
  communityId,
  handleCreateEventClose,
  handleCreatedEventClose,
}: any) => {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openSuccess, setSuccessOpen] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [exerciseActivityTypes, setExerciseActivityTypes] = useState([]);
  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => {
    setSuccessOpen(false);
    handleCreatedEventClose();
  };
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  const { register, formState, setValue, handleSubmit, trigger } = useForm({
    defaultValues: {
      event: "",
      activity_type_id: null,
      start_date: "",
      end_date: "",
      result_count_type: null,
    },
  });

  const { isDirty, isValid } = formState;

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();
      await postCommunityEventService(communityId, {
        ...values,
        activity_type_id:
          values.activity_type_id === -1 ? null : values.activity_type_id,
        start_date: startDate,
        end_date: endDate,
      });
      handleSuccessOpen();
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  const getExerciseActivityType = () => {
    exerciseActivityTypeService().then((resp) => {
      setExerciseActivityTypes(resp.data);
    });
  };

  useEffect(getExerciseActivityType, []);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  return (
    <>
      <DialogContent sx={{ width: { xs: 300, sm: 600 } }}>
        <Box display="flex" flexDirection="column">
          <Box component="a" onClick={handleCreateEventClose} alignSelf="end">
            <CancelOutlinedIcon
              sx={{ cursor: "pointer", color: "lightGrey.main" }}
            />
          </Box>

          <Typography align="center" typography="h3" color="green.main">
            จัดกิจกรรม
          </Typography>

          <Box display="flex" flexDirection="column" gap="16px">
            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  ชื่อกิจกรรม
                </Typography>
              </FormLabel>

              <TextField
                placeholder="กรอกชื่อกิจกรรม"
                {...register("event", { required: true, maxLength: 100 })}
              />
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  วันเริ่ม
                </Typography>
              </FormLabel>

              <LocalizationProvider
                dateAdapter={OverwriteAdapterDayjs}
                adapterLocale={th}
              >
                <DatePicker
                  minDate={minDate}
                  views={["year", "month", "day"]}
                  inputFormat="DD/MMM/BBBB"
                  value={startDate}
                  onChange={async (newValue: any) => {
                    const date = dayjs(newValue).format("YYYY-MM-DD");
                    await trigger("start_date", { shouldFocus: true });
                    setStartDate(date);
                    setValue("start_date", date);
                    setEndDate(null);
                    setValue("end_date", "");
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "เลือกวันเริ่ม",
                        readOnly: true,
                      }}
                      {...register("start_date", { required: true })}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  วันสิ้นสุด
                </Typography>
              </FormLabel>

              <LocalizationProvider
                dateAdapter={OverwriteAdapterDayjs}
                adapterLocale={th}
              >
                <DatePicker
                  minDate={dayjs(startDate).add(1, "day")}
                  disabled={startDate ? false : true}
                  views={["year", "month", "day"]}
                  inputFormat="DD/MMM/BBBB"
                  value={endDate}
                  onChange={async (newValue: any) => {
                    const date = dayjs(newValue).format("YYYY-MM-DD");
                    await trigger("end_date", { shouldFocus: true });
                    setEndDate(date);
                    setValue("end_date", date);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "เลือกวันสิ้นสุด",
                        readOnly: true,
                      }}
                      {...register("end_date", { required: true })}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  ประเภทกีฬา
                </Typography>
              </FormLabel>

              <Select
                defaultValue={null}
                {...register("activity_type_id")}
                sx={{
                  "& .MuiSelect-select .notranslate::after": "เลือกประเภทกีฬา"
                    ? {
                        content: `"เลือกประเภทกีฬา"`,
                        opacity: 0.42,
                      }
                    : {},
                }}
              >
                <MenuItem value={-1}>ทุกประเภทกีฬา</MenuItem>
                {exerciseActivityTypes.map((item: any, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  การนับผล
                </Typography>
              </FormLabel>

              <Select
                {...register("result_count_type", { required: true })}
                sx={{
                  "& .MuiSelect-select .notranslate::after": "เลือกการนับผล "
                    ? {
                        content: `"เลือกการนับผล"`,
                        opacity: 0.42,
                      }
                    : {},
                }}
              >
                <MenuItem value={1} key={0}>
                  ระยะทาง
                </MenuItem>

                <MenuItem value={2} key={1}>
                  ระยะเวลา
                </MenuItem>

                <MenuItem value={3} key={2}>
                  แคลอรี
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "0px 20px 24px",
        }}
      >
        <Box display="flex" gap="8px" width={1}>
          <Button
            fullWidth
            color="blue"
            variant="outlined"
            onClick={handleCreateEventClose}
          >
            <Typography variant="subtitle2">ยกเลิก</Typography>
          </Button>

          <Button
            fullWidth
            color="green"
            variant="contained"
            onClick={handleSubmit(onSubmitHandler)}
            disabled={!isValid || loading}
          >
            <Typography variant="subtitle2" color="white">
              <FormattedMessage id="common.confirm" />
            </Typography>
          </Button>
        </Box>
      </DialogActions>

      <Modal keepMounted open={openSuccess}>
        <Box sx={modalStyle} width="340px">
          <SuccessModal
            message={"สร้างกิจกรรมสำเร็จ"}
            handleSuccessClose={handleSuccessClose}
            showButton={true}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openError}>
        <Box sx={modalStyle} width="340px">
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>
    </>
  );
};

export default CreateEventModal;
