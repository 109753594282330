import { Avatar, Box, Button, Modal, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useAppSelector } from "../../../features/userStore/hook";
import {
  getProfile,
  getCurrentSeasonExperiencePoint,
  getAvatar,
} from "../../../features/userStore/libs/profileSlice";
import { getItem } from "../../../services/localStorage.service";
import * as htmlToImage from "html-to-image";
import { getSystemVariableService } from "../../../services/master-data.service";
import { modalStyle } from "../../../consts/styles";
import WarningModal from "../../modals/WarningModal";
import { configEnv } from "../../../configs/config-env";

const DashboardExerciseSeason = () => {
  const [season] = useState<any>(getItem("gobal"));
  const avatar = useAppSelector(getAvatar);
  const [fullnameIsLarge, setfullnameIsLarge] = useState<boolean>(false);
  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const [systemVariable, setSystemVariable] = useState<any>(null);
  const profile = useAppSelector(getProfile);
  const currentSeasonExperiencePoint = useAppSelector(
    getCurrentSeasonExperiencePoint
  );

  const onDownload = async () => {
    if (currentSeasonExperiencePoint >= configEnv.season_hp_cond) {
      const filename = `kaotajao-${dayjs().format("YYYYMMDD-HHMM")}.png`;
      let dataUrl = await buildPng();

      var img = new Image();

      img.src = dataUrl;

      img.onload = function () {
        // Create a canvas element
        var canvas = document.createElement("canvas");
        var ctx: any = canvas.getContext("2d");

        // Set canvas dimensions to match the image
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Convert canvas content to a data URL
        var dataURL = canvas.toDataURL("image/png");

        // Create a temporary anchor element
        var a = document.createElement("a");
        a.href = dataURL;

        // Set the filename for the download
        a.download = filename;

        // Programmatically click the anchor to trigger the download
        a.click();
      };
    } else {
      handleWarningOpen();
    }
  };

  const buildPng = async () => {
    const element = document.getElementById("domEl") as HTMLElement;

    let dataUrl = "";
    const minDataLength = 2000000;
    let i = 0;
    const maxAttempts = 10;

    while (dataUrl.length < minDataLength && i < maxAttempts) {
      dataUrl = await htmlToImage.toJpeg(element, {
        quality: 0.95,
        cacheBust: true,
      });
      i += 1;
    }

    return dataUrl;
  };

  const handleWarningOpen = () => setOpenWarning(true);
  const handleWarningClose = () => setOpenWarning(false);

  const getSystemVariable = () => {
    getSystemVariableService().then((resp) => {
      setSystemVariable(resp.data);
    });
  };

  useEffect(() => {
    if (profile.created_date) {
      const length = profile.first_name.length + profile.last_name.length;
      if (length > 18) {
        setfullnameIsLarge(true);
      }
    }
  }, [profile]);

  useEffect(getSystemVariable, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      py={{ xs: "32px", sm: "24px" }}
      px="24px"
      gap="16px"
    >
      <Typography
        align="center"
        typography={{ xs: "h5", sm: "h4" }}
        color="common.black"
      >
        ผลออกกำลังกายในแคมเปญ
      </Typography>

      <Typography
        align="center"
        typography={{ xs: "subtitle2", sm: "h5" }}
        color="secGreen.main"
      >
        "เริ่ม{" "}
        {dayjs(systemVariable?.campaign_start_date)
          .locale("th")
          .format("D MMM")}{" "}
        -{" "}
        {dayjs(systemVariable?.campaign_end_date)
          .locale("th")
          .format("D MMM BBBB")}
        "
      </Typography>

      <Box display="flex" justifyContent="center">
        <Box
          id="domEl"
          display="flex"
          flexDirection="column"
          sx={{
            backgroundImage: "url('/season/ss6.png')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
          }}
          width={{ xs: 300, sm: 500, md: 845 }}
          height={{ xs: 300, sm: 500, md: 845 }}
          position="relative"
        >
          <Box
            position="absolute"
            top={{ xs: "28.5%", sm: "29.5%" }}
            left="21%"
          >
            <Typography
              typography={{ xs: "body2", sm: "h5", md: "h2" }}
              color="common.black"
            >
              {profile?.member_id}
            </Typography>
          </Box>

          <Box
            position="absolute"
            top={{ xs: "28.5%", md: "29.5%" }}
            left="65%"
          >
            <Typography
              fontSize={{ xs: "12px" }}
              typography={{ sm: "h5", md: "h3" }}
              color="#4360ac"
            >
              {profile?.experience_level}
            </Typography>
          </Box>

          <Box
            position="absolute"
            top="40%"
            left="0%"
            width={{ xs: 300, sm: 500, md: 845 }}
          >
            <Typography
              typography={{ xs: "body2", sm: "h5", md: "h2" }}
              color="common.black"
              align={fullnameIsLarge ? "left" : "center"}
              marginLeft={fullnameIsLarge ? "10%" : ""}
            >
              {profile.first_name} {profile.last_name}
            </Typography>
          </Box>

          <Box position="absolute" top="23.5%" left="70%">
            <Box
              sx={{
                width: { xs: 77, sm: 125, md: 215 },
                height: { xs: 77, sm: 125, md: 215 },
              }}
            >
              <Box sx={{ width: "100%", height: "100%" }}>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    padding: "4px",
                    boxShadow: "0 3px 6px rgb(0 0 0 / 16%)",
                    marginBottom: 0,
                    backgroundColor: "#fff",
                    borderRadius: "100%",
                  }}
                >
                  <Avatar
                    alt={profile.first_name}
                    src={avatar ? avatar : "/images/avatar-default.png"}
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            position="absolute"
            top="57%"
            left="0"
            width={{ xs: 300, sm: 500, md: 845 }}
            display="flex"
            gap="8px"
            justifyContent="center"
          >
            <Typography
              alignSelf="end"
              typography={{ xs: "h5", sm: "h3", md: "h1" }}
              color="ktgGreen.main"
              align="center"
            >
              {currentSeasonExperiencePoint}
            </Typography>
            <Typography
              alignSelf="end"
              typography="h5"
              color="common.black"
              align="center"
            >
              แต้ม
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center">
        <Button
          color="secPink"
          fullWidth
          variant="contained"
          type="button"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            width: "295px",
          }}
          component={RouterLink}
          to="/point-conditions"
        >
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 600 }}
            color="white"
          >
            เงื่อนไขการรับแต้ม
          </Typography>
        </Button>
      </Box>

      <Box display="flex" justifyContent="center">
        <Button
          color="green"
          fullWidth
          variant="contained"
          type="button"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            width: "295px",
          }}
          onClick={onDownload}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 600 }}
            color="white"
          >
            บันทึกรูปภาพ
          </Typography>
        </Button>
      </Box>

      <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
        <Box sx={modalStyle} width={{ xs: 340 }}>
          <WarningModal
            message={`คุณต้องทำอีก ${
              configEnv.season_hp_cond - currentSeasonExperiencePoint
            }`}
            handleClose={handleWarningClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default DashboardExerciseSeason;
