import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { TableComponents, TableVirtuoso } from "react-virtuoso";
import { boxStyle } from "../../../consts/styles";

interface Data {
  description: string;
  total: number;
  total_calories: number;
  total_distances: number;
  total_minutes: number;
}

interface ColumnData {
  dataKey: keyof Data;
  label: string;
  numeric?: boolean;
  width: number;
}

const columns: ColumnData[] = [
  {
    width: 100,
    label: "ประเภทกีฬา",
    dataKey: "description",
  },
  {
    width: 100,
    label: "จำนวนครั้งที่ส่งผล",
    dataKey: "total",
  },
  {
    width: 100,
    label: "ระยะเวลา (นาที)",
    dataKey: "total_minutes",
  },
  {
    width: 100,
    label: "ระยะทาง (กิโลเมตร)",
    dataKey: "total_distances",
  },
  {
    width: 100,
    label: "แคลอรี่",
    dataKey: "total_calories",
  },
];

const ResultExercise = ({ activityTypeSummaries }: any) => {
  return (
    <Box sx={boxStyle}>
      <Typography
        align="center"
        typography={{ xs: "h4", sm: "h3" }}
        color="black.main"
      >
        ผลออกกำลังกาย
      </Typography>

      <Box
        sx={{
          height: 400,
          background: "#FFFFFF",
          borderRadius: "10px",
        }}
      >
        <TableVirtuoso
          data={activityTypeSummaries}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </Box>
    </Box>
  );
};

const fixedHeaderContent = () => {
  return (
    <TableRow>
      {columns.map((column: any) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align="center"
          style={{ width: column.width }}
          sx={{
            padding: "10px 0",
            backgroundColor: "#EB698F",
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 600 }} color="white">
            {column.label}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  );
};

const rowContent = (_index: number, row: Data) => {
  return (
    <React.Fragment>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          align="center"
          sx={{
            borderBottom: "none",
            background: _index % 2 !== 0 ? "#F5F7FA" : "",
          }}
        >
          {column.dataKey === "description" && (
            <Typography variant="subtitle2" color="black.main">
              {row[column.dataKey]}
            </Typography>
          )}
          {column.dataKey !== "description" && (
            <Typography
              align="right"
              variant="subtitle2"
              fontWeight={
                column.dataKey === "total" || column.dataKey === "total_minutes"
                  ? 600
                  : 400
              }
              color={
                column.dataKey === "total"
                  ? "secGreen.main"
                  : column.dataKey === "total_minutes"
                  ? "ktjBlue.main"
                  : "black.main"
              }
            >
              {row[column.dataKey]?.toLocaleString()}
            </Typography>
          )}
        </TableCell>
      ))}
    </React.Fragment>
  );
};

const VirtuosoTableComponents: TableComponents<Data> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer
      sx={{ boxShadow: "none", borderRadius: 0 }}
      {...props}
      ref={ref}
    />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

export default ResultExercise;
