import {
  Box,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
  Pagination,
} from "@mui/material";
import { useEffect, useState, ChangeEvent } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  gethealthLiteraciesService,
  postReadhealthLiteracyService,
} from "../../../services/healthLiteracy.service";
import { boxStyle, containerStyle, pageStyle } from "../../../consts/styles";
import { useAppDispatch } from "../../../features/userStore/hook";
import { setProfile } from "../../../features/userStore/libs/profileSlice";

const HealthLiteracy = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const [knowledges, setKnowledges] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [size] = useState(4);

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const getKnowledges = () => {
    gethealthLiteraciesService({ page, size }).then((resp) => {
      setKnowledges(resp.data.items);
      setCount(resp.data.total_page);
    });
  };

  function onClickReadMore(healthId: any) {
    postReadhealthLiteracyService(healthId).then((resp) => {
      dispatch(setProfile(resp.data));
    });
  }

  useEffect(getKnowledges, [page, size]);

  return (
    <Box sx={pageStyle}>
      <Typography
        align="center"
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="ktgGreen.main"
        zIndex={100}
      >
        รอบรู้สุขภาพ
      </Typography>

      <Container
        component="main"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        maxWidth={false}
      >
        <Box sx={{ ...boxStyle, padding: "8px" }}>
          {knowledges.map((item: any, index: number) => (
            <Box key={index} sx={{ ...boxStyle, padding: "4px" }}>
              <Box display="flex" gap="8px">
                <Box
                  component="img"
                  width={170}
                  borderRadius="10px"
                  src={item.image_url}
                />

                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  width={1}
                >
                  <Typography
                    typography="body1"
                    fontWeight="600 !important"
                    color="black.main"
                  >
                    {sm ? item.title : item.title.substring(0, 10) + "..."}
                  </Typography>

                  <Typography typography="body2" color="cgrey.main" width={1}>
                    {sm
                      ? item.sub_title
                      : item.sub_title.substring(0, 50) + "..."}
                  </Typography>

                  <Box
                    component="a"
                    display="flex"
                    justifyContent="end"
                    sx={{ textDecoration: "none" }}
                    onTouchStart={function () {
                      onClickReadMore(item.health_id);
                    }}
                    href={item.reference}
                    target="_blank"
                  >
                    <Typography
                      typography="body2"
                      fontWeight="600 !important"
                      color="blue.main"
                    >
                      อ่านต่อ
                    </Typography>

                    <ArrowForwardIosIcon
                      color="blue"
                      sx={{ alignSelf: "center", fontSize: 12 }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}

          <Box display="flex" justifyContent="center">
            <Pagination
              count={count}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
              color="blue"
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HealthLiteracy;
