import {
  Box,
  Typography,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import { boxStyle, containerStyle, pageStyle } from "../../../consts/styles";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link as RouterLink } from "react-router-dom";
import { getFaqService } from "../../../services/faq.service";
import { getProfile } from "../../../features/userStore/libs/profileSlice";
import { useAppSelector } from "../../../features/userStore/hook";

const Faq = () => {
  const profile = useAppSelector(getProfile);

  const [faqs, setFaqs] = useState<any[]>([]);

  const getFaq = () => {
    getFaqService().then((resp: any) => {
      setFaqs(resp.data);
    });
  };

  useEffect(getFaq, []);

  return (
    <Box sx={pageStyle}>
      <Typography
        align="center"
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="ktgGreen.main"
        zIndex={100}
      >
        คำถามที่พบบ่อย
      </Typography>

      <Container
        component="main"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          maxWidth: 800,
        }}
        maxWidth={false}
      >
        <Box sx={{ ...boxStyle, padding: "32px", gap: "8px" }}>
          {faqs.map((item: any, index: number) => (
            <Accordion
              key={index}
              sx={{
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                borderRadius: "10px !important",
                "&::before": {
                  backgroundColor: "white",
                },
              }}
            >
              <AccordionSummary
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography typography="body1" color="softBlack.main">
                  {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" gap="8px">
                  <Box
                    component="img"
                    src="/icons/vector_11.png"
                    width={24}
                    height={24}
                  />
                  <Typography
                    typography="body1"
                    color="softBlack.main"
                    dangerouslySetInnerHTML={{
                      __html: item.answer,
                    }}
                  ></Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        {!profile.member_id && (
          <Button
            color="blue"
            variant="contained"
            component={RouterLink}
            to="/login"
            fullWidth
          >
            <Typography variant="subtitle2">กลับ</Typography>
          </Button>
        )}
      </Container>
    </Box>
  );
};

export default Faq;
