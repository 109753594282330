import { Box } from "@mui/material";

const PdapContent = ({ consent = `` }) => {
  return (
    <Box
      dangerouslySetInnerHTML={{
        __html: consent,
      }}
    ></Box>
  );
};

export default PdapContent;
