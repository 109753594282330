import { Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useAppSelector } from "../../features/userStore/hook";
import { getProfile } from "../../features/userStore/libs/profileSlice";
import { getBmiHistoryService } from "../../services/bmi.service";
import { pageStyle } from "../../consts/styles";

const BmiPage = () => {
  const profile = useAppSelector(getProfile);

  const [bmi, setBmi] = useState<any>(0);
  const [bmiHistory, setBmiHistory] = useState<any>([]);
  const [status, setStatus] = useState<string>("");
  const [displayGraph, setDisplayGraph] = useState<boolean>(false);

  const getBmiHistory = () => {
    getBmiHistoryService().then((resp) => {
      setBmiHistory(resp.data);
    });
  };

  const getBmi = (weight: number, height: number) => {
    return ((weight / (height * height)) * 10000).toFixed(2);
  };

  const getStatusBmi = (weight: number, height: number) => {
    const bmiData = (weight / (height * height)) * 10000;
    let status = "";
    if (bmiData < 18.5) {
      status = "ผอม";
    } else if (bmiData >= 18.5 && bmiData < 23.0) {
      status = "ปกติ";
    } else if (bmiData >= 23.0 && bmiData < 25.0) {
      status = "ท้วม";
    } else if (bmiData >= 25.0) {
      status = "อ้วน";
    }
    return status;
  };

  const setBmiStatus = () => {
    if (profile?.weight) {
      setBmi(
        (
          (profile?.weight / (profile?.height * profile?.height)) *
          10000
        ).toFixed(2)
      );
      const status = getStatusBmi(profile?.weight, profile?.height);

      // Birthdate
      const birthdate = dayjs(profile.date_of_birth);

      // Calculate age
      const currentYear = dayjs().year();
      const birthYear = birthdate.year();
      const age = currentYear - birthYear;

      setDisplayGraph(age >= 6 && age < 20);
      setStatus(status);
    }
  };

  useEffect(setBmiStatus, [bmi, profile]);
  useEffect(getBmiHistory, []);

  return (
    <Box sx={pageStyle}>
      <Typography
        align="center"
        typography={{ xs: "h3", sm: "h1" }}
        color="ktgGreen.main"
        zIndex={1}
      >
        ดัชนีมวลกาย (BMI)
      </Typography>

      <Container
        component="main"
        sx={{
          zIndex: 1,
          maxWidth: 600,
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
        maxWidth={false}
      >
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="10px"
          alignItems="center"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background: "#FFFFFF",
          }}
          padding="16px 8px"
          gap="16px"
        >
          <Box display="flex" flexDirection="column">
            <Typography
              align="center"
              typography="body2"
              color="cgrey.main"
              paddingX="10px"
            >
              คือ ตัวชี้วัดมาตรฐานเพื่อประเมินสภาวะของร่างกาย
            </Typography>

            <Typography
              align="center"
              typography="body2"
              color="cgrey.main"
              paddingX="10px"
            >
              ว่ามีความสมดุลของน้ำหนักตัวต่อส่วนสูงอยู่ในเกณฑ์ เหมาะสมหรือไม่
            </Typography>
          </Box>

          <Typography
            align="center"
            sx={{
              fontWeight: "600 !important",
            }}
            typography={{ xs: "body1", sm: "h5" }}
            color="common.black"
            paddingX="10px"
          >
            ดัชนีมวลกาย (BMI)
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: "8px",
            }}
          >
            <Typography
              sx={{ typography: { xs: "h3", sm: "h2" } }}
              color="secPink.main"
            >
              {bmi}
            </Typography>

            <Typography
              alignSelf="center"
              variant="body1"
              color="lightGrey.main"
            >
              = {status}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column">
            <Typography
              align="center"
              typography="body2"
              color="cgrey.main"
              paddingX="10px"
            >
              น้ำหนักที่เหมาะสมสำหรับคนไทยคือค่า BMI ระหว่าง 18.5-22.9
              จัดอยู่ในเกณฑ์ปกติ
            </Typography>

            <Typography
              align="center"
              typography="body2"
              color="cgrey.main"
              paddingX="10px"
            >
              ห่างไกลโรคที่เกิดจาก ความอ้วน และมีความเสี่ยงต่อการเกิดโรคต่าง ๆ
              น้อยที่สุด
            </Typography>

            <Typography
              align="center"
              typography="body2"
              color="cgrey.main"
              paddingX="10px"
            >
              ควรพยายามรักษาระดับค่า BMI ให้อยู่ในระดับนี้ให้นานที่สุด
            </Typography>
          </Box>
        </Box>

        {bmiHistory.length && (
          <Box
            display="flex"
            flexDirection="column"
            borderRadius="10px"
            alignItems="center"
            sx={{
              boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
              background: "#FFFFFF",
            }}
            padding="16px"
            gap="8px"
          >
            <Typography
              align="center"
              typography={{ xs: "body1", sm: "subtitle2" }}
              color="softBlack.main"
              paddingX="10px"
            >
              บันทึกข้อมูล เพื่อดู BMI ล่าสุดของท่าน
            </Typography>

            <Typography
              align="center"
              typography={{ xs: "body2", sm: "body1" }}
              color="cgrey.main"
              paddingX="10px"
            >
              บันทึกข้อมูลล่าสุด{" "}
              {dayjs(bmiHistory[0].activity_date)
                .locale("th")
                .format("D MMM BBBB")}
            </Typography>

            <Typography
              align="center"
              typography={{ xs: "body2", sm: "body1" }}
              color="cgrey.main"
              paddingX="10px"
            >
              สามารถบันทึกข้อมูลได้อีกครั้ง{" "}
              {dayjs(bmiHistory[0].activity_date)
                .add(31, "day")
                .locale("th")
                .format("D MMM BBBB")}
            </Typography>
          </Box>
        )}

        {displayGraph && (
          <Box
            display="flex"
            flexDirection="column"
            borderRadius="10px"
            alignItems="center"
            sx={{
              boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
              background: "#FFFFFF",
            }}
            padding="16px"
            gap="8px"
          >
            <Typography
              align="center"
              fontWeight="600 !important"
              typography={{ xs: "body2", sm: "body1" }}
              color="softBlack.main"
              paddingX="10px"
            >
              กราฟแสดงเกณฑ์อ้างอิงการเจริญเติบโต อายุ 6-19 ปี
            </Typography>

            <Box
              component="img"
              src={
                profile?.gender === "M"
                  ? "/images/bmi/male.png"
                  : "/images/bmi/female.png"
              }
              sx={{
                objectFit: "contain",
                width: 1,
              }}
            />
          </Box>
        )}

        <Box
          display="flex"
          borderRadius="10px"
          justifyContent="center"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background:
              "linear-gradient(94.1deg, rgba(4, 205, 133, 0.9) -20.26%, rgba(28, 185, 243, 0.9) 149.71%)",
          }}
          padding="16px"
          gap="8px"
        >
          <Typography
            align="center"
            alignSelf="center"
            typography={{ xs: "body1", sm: "subtitle2" }}
            color="white"
            paddingX="10px"
          >
            รอบเอวของคุณไม่ควรเกิน
          </Typography>

          <Box
            borderRadius="10px"
            sx={{
              background: "#FFFFFF",
            }}
            padding="4px 16px"
          >
            <Typography
              align="center"
              fontWeight="600 !important"
              typography={{ xs: "body1", sm: "h5" }}
              color="secPink.main"
              paddingX="10px"
            >
              85
            </Typography>
          </Box>

          <Typography
            align="center"
            alignSelf="center"
            typography={{ xs: "body1", sm: "subtitle2" }}
            color="white"
            paddingX="10px"
          >
            ซม.
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          borderRadius="10px"
          alignItems="center"
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background: "#FFFFFF",
          }}
          padding="16px"
          gap="8px"
        >
          <Typography
            align="center"
            typography={{ xs: "h5", sm: "h4" }}
            color="softBlack.main"
          >
            ประวัติการบันทึก BMI
          </Typography>

          {bmiHistory.map((item: any, index: number) => (
            <Box
              display="flex"
              flexDirection="column"
              gap="8px"
              sx={{
                background: "#F5F7FA",
                borderRadius: "10px",
              }}
              width={1}
              padding="8px 16px"
              key={index}
            >
              <Typography
                fontWeight="600 !important"
                typography={{ xs: "body1", sm: "subtitle2" }}
                color="black.main"
              >
                {dayjs(item.activity_date).format("DD/MM/BB")}
              </Typography>

              <Box display="flex" justifyContent="space-between">
                <Box display="flex" flexDirection="column" gap="8px">
                  <Typography
                    align="center"
                    fontWeight={500}
                    fontSize={{ xs: "12px", sm: "14px" }}
                    color="black.main"
                  >
                    น้ำหนัก
                  </Typography>
                  <Typography
                    align="center"
                    typography="h4"
                    color="secPink.main"
                  >
                    {item.weight}
                  </Typography>
                  <Typography
                    align="center"
                    fontWeight={500}
                    fontSize={{ xs: "12px", sm: "14px" }}
                    color="black.main"
                  >
                    กิโลกรัม
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap="8px">
                  <Typography
                    align="center"
                    fontWeight={500}
                    fontSize={{ xs: "12px", sm: "14px" }}
                    color="black.main"
                  >
                    ส่วนสูง
                  </Typography>
                  <Typography
                    align="center"
                    typography="h4"
                    color="secPink.main"
                  >
                    {item.height}
                  </Typography>
                  <Typography
                    align="center"
                    fontWeight={500}
                    fontSize={{ xs: "12px", sm: "14px" }}
                    color="black.main"
                  >
                    เซนติเมตร
                  </Typography>
                </Box>{" "}
                <Box display="flex" flexDirection="column" gap="8px">
                  <Typography
                    align="center"
                    fontWeight={500}
                    fontSize={{ xs: "12px", sm: "14px" }}
                    color="black.main"
                  >
                    BMI
                  </Typography>
                  <Typography
                    align="center"
                    typography="h4"
                    color="secPink.main"
                  >
                    {getBmi(item.weight, item.height)}
                  </Typography>
                  <Typography
                    align="center"
                    fontWeight={500}
                    fontSize={{ xs: "12px", sm: "14px" }}
                    color="black.main"
                  >
                    {getStatusBmi(item.weight, item.height)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default BmiPage;
