import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { boxStyle, containerStyle } from "../../../consts/styles";
import {
  districtService,
  provinceService,
  subdistrictService,
} from "../../../services/master-data.service";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import { getHealthPlacesService } from "../../../services/healthPlace.service";
import { FormattedMessage } from "react-intl";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { Link as RouterLink } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const HealthPlaces = () => {
  const [provinces, setProvinces] = useState([]);
  const [districs, setDistrics] = useState([]);
  const [subdistrict, setSubdistricts] = useState([]);

  const [healthPlaces, setHealthPlaces] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [size] = useState(5);

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
    onSearchHandler();
  };

  const { register, setValue, getValues } = useForm({
    defaultValues: {
      province_id: "",
      district_id: "",
      subdistrict_id: "",
      health_place_type: "",
      health_place_name: "",
    },
  });

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setValue("district_id", "");
    setValue("subdistrict_id", "");

    setDistrics([]);
    setSubdistricts([]);
    getDistricts(event.target.value);
  };

  const handleDistrictChange = (event: SelectChangeEvent) => {
    setValue("subdistrict_id", "");

    setSubdistricts([]);
    getSubdistricts(event.target.value);
  };

  const getProvinces = () => {
    provinceService().then((resp) => {
      setProvinces(resp.data);
    });
  };

  const getDistricts = (provinceId: string) => {
    districtService(provinceId).then((resp) => {
      setDistrics(resp.data);
    });
  };

  const getSubdistricts = (districtId: any) => {
    subdistrictService(districtId).then((resp) => {
      setSubdistricts(resp.data);
    });
  };

  const onSearchHandler = () => {
    getHealthPlacesService({ ...getValues(), page, size }).then((resp) => {
      setHealthPlaces(resp.data.items);
      setCount(resp.data.total_page);
    });
  };

  useEffect(onSearchHandler, [page]);

  useEffect(getProvinces, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      gap="24px"
      pt="50px"
      pb="100px"
    >
      <Typography
        align="center"
        typography={{ xs: "h3", sm: "h1" }}
        color="ktgGreen.main"
        zIndex={1}
      >
        Health Place
      </Typography>

      <Container
        component="main"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        maxWidth={false}
      >
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="10px"
          alignItems="center"
          sx={{
            cursor: "pointer",
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            background: "#FFFFFF",
          }}
          padding={{ xs: "24px 8px", sm: "24px" }}
          gap="16px"
        >
          <Typography typography="body1" align="center">
            ค้นหาสถานที่ที่คุณต้องการ
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Select
                  {...register("health_place_type")}
                  sx={{
                    "& .MuiSelect-select .notranslate::after": "เลือกประเภท"
                      ? {
                          content: `"เลือกประเภท"`,
                          opacity: 0.42,
                        }
                      : {},
                  }}
                >
                  <MenuItem value={""}>ทั้งหมด</MenuItem>
                  <MenuItem value={1}>สวนสุขภาพ</MenuItem>
                  <MenuItem value={2}>สนามกีฬา</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Select
                  {...register("province_id")}
                  sx={{
                    "& .MuiSelect-select .notranslate::after": "เลือกจังหวัด"
                      ? {
                          content: `"เลือกจังหวัด"`,
                          opacity: 0.42,
                        }
                      : {},
                  }}
                  onChange={handleProvinceChange}
                >
                  <MenuItem value={""}>ทั้งหมด</MenuItem>
                  {provinces.map((p: { id: number; name: string }) => (
                    <MenuItem key={p.id} value={p.id}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Select
                  {...register("district_id")}
                  sx={{
                    "& .MuiSelect-select .notranslate::after": "เลือกอำเภอ"
                      ? {
                          content: `"เลือกอำเภอ"`,
                          opacity: 0.42,
                        }
                      : {},
                  }}
                  onChange={handleDistrictChange}
                >
                  <MenuItem value={""}>ทั้งหมด</MenuItem>
                  {districs.map((p: { id: number; name: string }) => (
                    <MenuItem key={p.id} value={p.id}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Select
                  {...register("subdistrict_id")}
                  sx={{
                    "& .MuiSelect-select .notranslate::after": "เลือกตำบล"
                      ? {
                          content: `"เลือกตำบล"`,
                          opacity: 0.42,
                        }
                      : {},
                  }}
                >
                  <MenuItem value={""}>ทั้งหมด</MenuItem>
                  {subdistrict.map((p: { id: number; name: string }) => (
                    <MenuItem key={p.id} value={p.id}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <FormControl fullWidth>
                <TextField
                  placeholder="ค้นหาด้วยชื่อสถานที่"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  {...register("health_place_name")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                color="green"
                variant="contained"
                onClick={onSearchHandler}
              >
                <Typography variant="subtitle2" color="white">
                  <FormattedMessage id="common.search" />
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box display="flex" flexDirection="column" gap="16px" marginTop="16px">
          <Typography
            typography={{ xs: "subtitle2", md: "h3" }}
            fontWeight="700 !important"
          >
            สถานที่แนะนำ
          </Typography>

          {healthPlaces.map((row, index) => (
            <Box key={index} sx={boxStyle}>
              <Box display="flex" gap="16px">
                <Avatar
                  src={row.image_url}
                  sx={{
                    width: { xs: 32, md: 150 },
                    height: { xs: 32, md: 150 },
                  }}
                >
                  H
                </Avatar>

                <Box display="flex" flexDirection="column" gap="8px" width={1}>
                  <Typography
                    typography={{ xs: "subtitle2", md: "subtitle1" }}
                    fontWeight="700 !important"
                  >
                    {row.name}
                  </Typography>

                  <Box display="flex" gap="8px">
                    <Typography typography={{ xs: "body2", md: "body1" }}>
                      เปิด{" "}
                      {row.open_time_hh < 10
                        ? `0${row.open_time_hh}`
                        : row.open_time_hh}
                      :
                      {row.open_time_mm < 10
                        ? `0${row.open_time_mm}`
                        : row.open_time_mm}{" "}
                      -{" "}
                      {row.close_time_hh < 10
                        ? `0${row.close_time_hh}`
                        : row.close_time_hh}
                      :
                      {row.close_time_mm < 10
                        ? `0${row.close_time_mm}`
                        : row.close_time_mm}{" "}
                      น.
                    </Typography>
                    {row.service_option.facilities
                      .filter((f: any) => f.is_available)
                      .map((f: any) => (
                        <Box
                          component="img"
                          width={20}
                          height={20}
                          src={f.image_url}
                        />
                      ))}
                  </Box>

                  <Box display="flex" gap="8px">
                    <Typography
                      typography={{ xs: "body2", md: "body1" }}
                      color="green.main"
                    >
                      ประเภทกีฬา
                    </Typography>

                    {row.service_option.sport_types.map((s: any) => (
                      <Box
                        component="img"
                        width={20}
                        height={20}
                        src={s.image_url}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box display="flex" gap="16px">
                <Button
                  fullWidth
                  color="blue"
                  variant="contained"
                  component={RouterLink}
                  to={row?.google_map_url}
                  target="_blank"
                >
                  เดินทางไปที่นี่{" "}
                  <FmdGoodIcon sx={{ fontSize: 20, marginLeft: "8px" }} />
                </Button>
                <Button
                  fullWidth
                  color="green"
                  variant="outlined"
                  onClick={() => {
                    expanded[index] = !expanded[index];
                    setExpanded([...expanded]);
                  }}
                >
                  รายละเอียด{" "}
                  {!expanded[index] ? (
                    <ArrowDropDownIcon
                      sx={{ fontSize: 20, marginLeft: "8px" }}
                    />
                  ) : (
                    <ArrowDropUpIcon sx={{ fontSize: 20, marginLeft: "8px" }} />
                  )}
                </Button>
              </Box>

              {expanded[index] && (
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="8px"
                  sx={{
                    padding: "24px",
                    backgroundColor: "#E7F7F3",
                    borderRadius: "8px",
                    marginTop: "8px",
                  }}
                >
                  <Typography
                    typography={{ xs: "body1", md: "h5" }}
                    fontWeight="700 !important"
                  >
                    รายละเอียด
                  </Typography>

                  {row.service_option.sport_types.find(
                    (t: any) => t.id === 1
                  ) && (
                    <Box display="flex" gap="8px">
                      <Typography
                        typography={{ xs: "body2", md: "body1" }}
                        fontWeight="700 !important"
                      >
                        ระยะทางเดินวิ่ง:
                      </Typography>

                      <Typography typography={{ xs: "body2", md: "body1" }}>
                        {
                          row.service_option.sport_types.find(
                            (t: any) => t.id === 1
                          ).kilometre
                        }{" "}
                        กม.
                      </Typography>

                      <Typography typography={{ xs: "body2", md: "body1" }}>
                        {
                          row.service_option.sport_types.find(
                            (t: any) => t.id === 1
                          ).metre
                        }{" "}
                        ม.
                      </Typography>
                    </Box>
                  )}

                  {row.service_option.sport_types.find(
                    (t: any) => t.id === 2
                  ) && (
                    <Box display="flex" gap="8px">
                      <Typography
                        typography={{ xs: "body2", md: "body1" }}
                        fontWeight="700 !important"
                      >
                        ระยะปั่นจักรยาน:
                      </Typography>

                      <Typography typography={{ xs: "body2", md: "body1" }}>
                        {
                          row.service_option.sport_types.find(
                            (t: any) => t.id === 2
                          ).kilometre
                        }{" "}
                        กม.
                      </Typography>

                      <Typography typography={{ xs: "body2", md: "body1" }}>
                        {
                          row.service_option.sport_types.find(
                            (t: any) => t.id === 2
                          ).metre
                        }{" "}
                        ม.
                      </Typography>
                    </Box>
                  )}

                  <Box display="flex" gap="8px">
                    <Typography
                      typography={{ xs: "body2", md: "body1" }}
                      fontWeight="700 !important"
                    >
                      ประเภทกีฬา:
                    </Typography>

                    <Typography typography={{ xs: "body2", md: "body1" }}>
                      {row.service_option.sport_types
                        .map((t: any) => t.name)
                        .join(",")}
                    </Typography>
                  </Box>

                  <Box display="flex" gap="8px">
                    <Typography
                      typography={{ xs: "body2", md: "body1" }}
                      fontWeight="700 !important"
                    >
                      สิ่งอำนวยความสะดวก:
                    </Typography>

                    <Typography typography={{ xs: "body2", md: "body1" }}>
                      {row.service_option.facilities
                        .filter((f: any) => f.is_available)
                        .map((t: any) => t.name)
                        .join(",")}
                    </Typography>
                  </Box>

                  <Box display="flex" gap="8px">
                    <Typography
                      typography={{ xs: "body2", md: "body1" }}
                      fontWeight="700 !important"
                    >
                      ข้อมูลเพิ่มเติม:
                    </Typography>

                    <Typography typography={{ xs: "body2", md: "body1" }}>
                      {row.remark || "-"}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          ))}

          <Pagination
            sx={{ alignSelf: "center" }}
            count={count}
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            color="blue"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default HealthPlaces;
