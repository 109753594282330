import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TableComponents, TableVirtuoso } from "react-virtuoso";
import { boxStyle } from "../../../consts/styles";
import { exerciseAreaSummary } from "../../../services/dashboard.service";
import { districtService } from "../../../services/master-data.service";

interface Data {
  id: number;
  description?: number;
  total_calories: number;
  total_distances: number;
  total_minutes: number;
  distinct_member: number;
}

interface ColumnData {
  dataKey: keyof Data;
  label: string;
  numeric?: boolean;
  width: number;
}

var columns: ColumnData[] = [
  {
    width: 100,
    label: "เขต",
    dataKey: "id",
  },
  {
    width: 150,
    label: "ใช้พลังงาน (kcal)",
    dataKey: "total_calories",
  },
  {
    width: 100,
    label: "ระยะเวลา (นาที)",
    dataKey: "total_minutes",
  },
  {
    width: 100,
    label: "ระยะทาง (กิโลเมตร)",
    dataKey: "total_distances",
  },
  {
    width: 100,
    label: "จ.น. สมาชิกที่ส่งผล",
    dataKey: "distinct_member",
  },
];

const ResultDescription = ({ areaSummaries, provinces, queryType }: any) => {
  const fixedHeaderContent = () => {
    return (
      <TableRow>
        {columns.map((column: any) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align="center"
            style={{ width: column.width }}
            sx={{
              padding: "10px 0",
              backgroundColor: "#2FD25D",
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 600 }} color="white">
              {column.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const rowContent = (_index: number, row: any) => {
    return (
      <React.Fragment>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            align="center"
            sx={{
              borderBottom: "none",
              background: _index % 2 !== 0 ? "#F5F7FA" : "",
            }}
          >
            {column.dataKey === "id" && (
              <Typography variant="subtitle2" color="black.main">
                {row[column.dataKey]?.toLocaleString()}
              </Typography>
            )}
            {column.dataKey === "description" && (
              <Typography variant="subtitle2" color="black.main">
                {row[column.dataKey]}
              </Typography>
            )}
            {column.dataKey !== "description" && column.dataKey !== "id" && (
              <Typography
                align="right"
                variant="subtitle2"
                fontWeight={
                  column.dataKey === "total_calories" ||
                  column.dataKey === "total_minutes"
                    ? 600
                    : 400
                }
                color={
                  column.dataKey === "total_calories"
                    ? "secPink.main"
                    : column.dataKey === "total_minutes"
                    ? "ktjBlue.main"
                    : "black.main"
                }
              >
                {row[column.dataKey]?.toLocaleString()}
              </Typography>
            )}
          </TableCell>
        ))}
      </React.Fragment>
    );
  };

  const VirtuosoTableComponents: TableComponents<any> = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer
        sx={{ boxShadow: "none", borderRadius: 0 }}
        {...props}
        ref={ref}
      />
    )),
    Table: (props) => (
      <Table
        {...props}
        sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
      />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };

  // states
  const [districts, setDistricts] = useState([]);
  const [summaries, setSummaries] = useState([]);
  const [provincesFiltered, setProvincesFiltered] = useState([]);

  // filters
  const [zone, setZone] = useState<string>("");
  const [province, setProvince] = useState<string>("");
  const [district, setDistrict] = useState<string>("");

  //service
  const getDistricts = async (provinceId: string) => {
    setDistrict("");
    const response = await districtService(provinceId);
    setDistricts(response.data);
  };

  const getExerciseAreaSummary = () => {
    let label = "";
    if (district) {
      label = "ตำบล";
    } else if (province) {
      label = "อำเภอ";
    } else if (zone) {
      label = "จังหวัด";
    } else {
      label = "เขต";
    }

    columns[0] = {
      width: 100,
      label,
      dataKey: zone || province || district ? "description" : "id",
    };

    exerciseAreaSummary(
      { zone, province_id: province, district_id: district },
      queryType
    ).then((resp) => {
      setSummaries(resp.data);
      if (!province) {
        const filterByZone = resp.data.map((s: any) => s.description);
        setProvincesFiltered(
          provinces.filter((p: any) => filterByZone.includes(p.name))
        );
      }
    });
  };

  // handle
  const handleZoneChange = (event: SelectChangeEvent) => {
    setZone(event.target.value);
    setProvince("");
    setDistrict("");
    setDistricts([]);
  };

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setProvince(event.target.value);
    setDistrict("");
    getDistricts(event.target.value);
  };

  const handleDistrictChange = (event: SelectChangeEvent) => {
    setDistrict(event.target.value);
  };

  useEffect(() => {
    setSummaries(areaSummaries);
  }, [areaSummaries]);

  useEffect(getExerciseAreaSummary, [zone, province, district, queryType]);

  return (
    <Box sx={boxStyle}>
      <Typography
        align="center"
        typography={{ xs: "h4", sm: "h3" }}
        color="black.main"
      >
        ข้อมูลผลออกกำลังกาย
      </Typography>

      <Box display="flex" gap={{ xs: "8px", sm: "16px" }}>
        <Box
          display="flex"
          gap="6px"
          sx={{ width: { xs: "auto", sm: "100px" } }}
        >
          <Box
            alignSelf="center"
            component="img"
            src="/icons/filter_icon.png"
            width="15px"
            height="10px"
          ></Box>
          <Typography
            alignSelf="center"
            align="center"
            variant="body1"
            color="black.main"
            display={{ xs: "none", sm: "block" }}
          >
            โปรดเลือก
          </Typography>
        </Box>
        <Box display="flex" gap={{ xs: "8px", sm: "16px" }} width={1}>
          <Select
            fullWidth
            sx={{
              "& .MuiSelect-select .notranslate::after": "เขต"
                ? {
                    content: `"เขต"`,
                    opacity: 0.42,
                  }
                : {},
            }}
            value={zone}
            onChange={handleZoneChange}
          >
            <MenuItem key={0} value="">
              ทั้งหมด
            </MenuItem>
            {Array.from(Array(13).keys()).map((index: any) => (
              <MenuItem key={index} value={(index + 1).toString()}>
                {"เขตสุขภาพที่ " + (index + 1)}
              </MenuItem>
            ))}
          </Select>
          <Select
            fullWidth
            sx={{
              "& .MuiSelect-select .notranslate::after": "จังหวัด"
                ? {
                    content: `"จังหวัด"`,
                    opacity: 0.42,
                  }
                : {},
            }}
            value={province}
            onChange={handleProvinceChange}
          >
            <MenuItem key={0} value="">
              ทั้งหมด
            </MenuItem>
            {provincesFiltered?.map((p: { id: number; name: string }) => (
              <MenuItem key={p.id} value={p.id.toString()}>
                {p.name}
              </MenuItem>
            ))}
          </Select>
          <Select
            fullWidth
            sx={{
              "& .MuiSelect-select .notranslate::after": "อำเภอ"
                ? {
                    content: `"อำเภอ"`,
                    opacity: 0.42,
                  }
                : {},
            }}
            value={district}
            onChange={handleDistrictChange}
          >
            <MenuItem key={0} value="">
              ทั้งหมด
            </MenuItem>
            {districts.map((d: { id: number; name: string }) => (
              <MenuItem key={d.id} value={d.id.toString()}>
                {d.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      <Box
        sx={{
          height: 400,
          background: "#FFFFFF",
          borderRadius: "10px",
        }}
      >
        <TableVirtuoso
          data={summaries}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </Box>
    </Box>
  );
};

export default ResultDescription;
