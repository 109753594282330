import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Cropper, { Area, Point } from "react-easy-crop";
import Resizer from "react-image-file-resizer";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

import {
  boxStyle,
  containerStyle,
  modalStyle,
  pageStyle,
} from "../../../consts/styles";
import { communityTypes } from "../../../consts/app";
import {
  provinceService,
  districtService,
  subdistrictService,
} from "../../../services/master-data.service";
import {
  deleteCommunityService,
  getCommunityByIdService,
  patchCommunityService,
  postUploadImageService,
} from "../../../services/community.service";
import SuccessModal from "../../../components/modals/SuccessModal";
import ErrorModal from "../../../components/modals/ErrorModal";

import { getCroppedImg } from "../../../utils/cropper.utils";
import ConfirmModal from "../../../components/modals/ConfirmModal";

const CommunityEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [communityId, setCommunityId] = useState<number | null>(null);
  const [community, setCommunity] = useState<any | null>(null);
  const [provinces, setProvinces] = useState([]);
  const [districs, setDistrics] = useState([]);
  const [subdistrict, setSubdistricts] = useState([]);
  const [notLimitMember, setNotLimitMember] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [openSuccess, setSuccessOpen] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [openWarning, setWarningOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [, setCroppedImage] = useState<any>(null);
  const [openCropDialog, setCropDialogOpen] = useState<boolean>(false);
  const [image, setImage] = useState<string>("");
  const [avatar, setAvatar] = useState<string>("");
  const [file, setFile] = useState<any>();

  const { register, formState, setValue, handleSubmit, trigger } = useForm({
    defaultValues: {
      community_name: "",
      community_desc: "",
      type_id: null,
      max_member: 0,
      contact_info: "",
      address: "",
      province_id: null,
      district_id: null,
      subdistrict_id: null,
      is_limit_member: false,
    },
  });

  const { isValid } = formState;

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    // set zoom defualt crop
    setZoom(1);

    if (file) {
      setImage(URL.createObjectURL(file));
      handleCropDialogOpen();
    }
  };

  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => {
    setSuccessOpen(false);
    navigate("/community");
  };
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };
  const handleWarningOpen = () => setWarningOpen(true);
  const handleWarningClose = () => setWarningOpen(false);
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);
  const handleCropDialogOpen = () => setCropDialogOpen(true);
  const handleCropDialogClose = () => setCropDialogOpen(false);

  const getCommunity = () => {
    if (communityId)
      getCommunityByIdService(communityId).then((resp) => {
        setCommunity(resp.data);
        setForm(resp.data);
      });
  };

  const setForm = async (data: any) => {
    await getDistricts(data.province_id);
    await getSubdistricts(data.district_id);

    setValue("community_name", data.community_name);
    setValue("community_desc", data.community_desc);
    setValue("type_id", data.type_id);
    setValue("max_member", data.max_member);
    setValue("contact_info", data.contact_info);
    setValue("address", data.address);
    setValue("province_id", data.province_id);
    setValue("district_id", data.district_id);
    setValue("subdistrict_id", data.subdistrict_id);
    setValue("is_limit_member", data.is_limit_member);
    setNotLimitMember(data.is_limit_member);
    setAvatar(data.image_url);
  };

  const handleNotLimitMemberChange = async (event: any) => {
    setNotLimitMember(!notLimitMember);
    await trigger("is_limit_member", { shouldFocus: true });
    setValue("is_limit_member", !notLimitMember);
  };

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setValue("district_id", null);
    setValue("subdistrict_id", null);

    setDistrics([]);
    setSubdistricts([]);
    getDistricts(event.target.value);
  };

  const handleDistrictChange = (event: SelectChangeEvent) => {
    setValue("subdistrict_id", null);

    setSubdistricts([]);
    getSubdistricts(event.target.value);
  };

  const getProvinces = () => {
    provinceService().then((resp) => {
      setProvinces(resp.data);
    });
  };

  const getDistricts = async (provinceId: string) => {
    const response = await districtService(provinceId);
    setDistrics(response.data);
  };

  const getSubdistricts = async (districtId: any) => {
    const response = await subdistrictService(districtId);
    setSubdistricts(response.data);
  };

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();
      await patchCommunityService(communityId, {
        ...values,
        is_limit_member: notLimitMember,
      });

      if (file) {
        const formdata = new FormData();
        formdata.append("file", file);
        await postUploadImageService(communityId, formdata);
      }
      setSuccessMessage("แก้ไขข้อมูลกลุ่มสำเร็จ");
      handleSuccessOpen();
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  const onDeleteGroup = async () => {
    try {
      handleLoading();
      handleWarningClose();
      await deleteCommunityService(communityId);
      setSuccessMessage("ลบกลุ่มสำเร็จ");
      handleSuccessOpen();
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  const onCropComplete = useCallback(
    async (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const acceptCropAvatar = async () => {
    const { url, blob }: any = await getCroppedImg(image, croppedAreaPixels);
    setCroppedImage(url);

    const file = new File([blob], "name");
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      80,
      0,
      (file: any) => {
        setFile(file);
      },
      "file"
    );
    setAvatar(url);
    await trigger("community_name", { shouldFocus: true });
    handleCropDialogClose();
  };

  useEffect(getProvinces, []);

  useEffect(() => {
    if (!location.state || !location.state.isAdminGroup) {
      navigate("/community");
      return;
    }
    setCommunityId(location.state.communityId);
  }, [location, navigate]);

  useEffect(getCommunity, [communityId]);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  return (
    <Box sx={pageStyle}>
      <Typography
        align="center"
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="ktgGreen.main"
        zIndex={100}
      >
        <FormattedMessage id="community" />
      </Typography>
      {community && (
        <Container component="main" sx={containerStyle} maxWidth={false}>
          <Box sx={{ ...boxStyle, padding: "24px" }}>
            <Typography align="center" typography="h5" color="common.black">
              <FormattedMessage id="community.create" />
            </Typography>

            <Box display="flex" alignItems="center" justifyContent="center">
              <Box width={120} height={120} position="relative">
                <Box width={1} height={1}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      padding: "4px",
                      boxShadow: "0 3px 6px rgb(0 0 0 / 16%)",
                      marginBottom: 0,
                      backgroundColor: "#fff",
                      borderRadius: "100%",
                    }}
                  >
                    <Box
                      component="img"
                      alt="avatar"
                      src={avatar ? avatar : "/images/community-default.png"}
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "100%",
                      }}
                    />
                  </Box>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    sx={{
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      border: "4px solid #fff",
                      width: "35px",
                      height: "35px",
                      backgroundColor: "#D9E2EB",
                      "&:hover": {
                        backgroundColor: "#1688C4",
                      },
                      boxShadow:
                        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
                    }}
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <PhotoCameraOutlinedIcon
                      color="black"
                      sx={{ width: "18px", height: "18px" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>

            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  <FormattedMessage id="community.types" />
                </Typography>
              </FormLabel>
              <Select
                defaultValue={community.type_id}
                sx={{
                  "& .MuiSelect-select .notranslate::after":
                    "กรุณาเลือกประเภทกลุ่ม"
                      ? {
                          content: `"กรุณาเลือกประเภทกลุ่ม"`,
                          opacity: 0.42,
                        }
                      : {},
                }}
                {...register("type_id", { required: true })}
              >
                {communityTypes.map((str: string, index: number) => (
                  <MenuItem key={index} value={index + 1}>
                    {str}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  <FormattedMessage id="community.name" />
                </Typography>
              </FormLabel>

              <TextField
                placeholder="กรอก ..."
                {...register("community_name", { required: true })}
              />
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  <FormattedMessage id="community.contractAdmin" />
                </Typography>
              </FormLabel>
              <TextField
                placeholder="กรอก ..."
                multiline
                rows={4}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px !important",
                  },
                }}
                {...register("contact_info", { required: true })}
              />
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>
                <Typography variant="body1" color="common.black">
                  <FormattedMessage id="community.groupDescription" />
                </Typography>
              </FormLabel>
              <TextField
                placeholder="กรอก ..."
                multiline
                rows={4}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px !important",
                  },
                }}
                {...register("community_desc", { required: true })}
              />
            </FormControl>

            <Box display="flex" alignItems="end" gap="16px">
              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="body1" color="common.black">
                    <FormattedMessage id="community.memberCount" />
                  </Typography>
                </FormLabel>
                <TextField
                  type="number"
                  {...register("max_member", { required: true })}
                />
              </FormControl>

              <FormControl fullWidth>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={notLimitMember}
                        color="green"
                        onChange={handleNotLimitMemberChange}
                      />
                    }
                    label="ไม่จำกัด"
                  />
                </FormGroup>
              </FormControl>
            </Box>

            <Box>
              <Divider sx={{ color: "#717171", borderWidth: 2 }}>
                <Typography variant="h5" color="ktgGreen.main">
                  <FormattedMessage id="community.groupAddress" />
                </Typography>
              </Divider>
            </Box>

            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap="16px"
            >
              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="body1" color="common.black">
                    <FormattedMessage id="community.address" />
                  </Typography>
                </FormLabel>
                <TextField {...register("address", { required: true })} />
              </FormControl>

              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="body1" color="common.black">
                    <FormattedMessage id="community.province" />
                  </Typography>
                </FormLabel>
                <Select
                  defaultValue={community.province_id}
                  {...register("province_id", { required: true })}
                  sx={{
                    "& .MuiSelect-select .notranslate::after":
                      "กรุณาเลือกจังหวัด"
                        ? {
                            content: `"กรุณาเลือกจังหวัด"`,
                            opacity: 0.42,
                          }
                        : {},
                  }}
                  onChange={handleProvinceChange}
                >
                  {provinces.map((p: { id: number; name: string }) => (
                    <MenuItem key={p.id} value={p.id}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap="16px"
            >
              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="body1" color="common.black">
                    <FormattedMessage id="community.district" />
                  </Typography>
                </FormLabel>
                <Select
                  defaultValue={community.district_id}
                  {...register("district_id", { required: true })}
                  sx={{
                    "& .MuiSelect-select .notranslate::after": "กรุณาเลือกอำเภอ"
                      ? {
                          content: `"กรุณาเลือกอำเภอ"`,
                          opacity: 0.42,
                        }
                      : {},
                  }}
                  onChange={handleDistrictChange}
                >
                  {districs.map((p: { id: number; name: string }) => (
                    <MenuItem key={p.id} value={p.id}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="body1" color="common.black">
                    <FormattedMessage id="community.subdistrict" />
                  </Typography>
                </FormLabel>
                <Select
                  defaultValue={community.subdistrict_id}
                  {...register("subdistrict_id", { required: true })}
                  sx={{
                    "& .MuiSelect-select .notranslate::after": "กรุณาเลือกตำบล"
                      ? {
                          content: `"กรุณาเลือกตำบล"`,
                          opacity: 0.42,
                        }
                      : {},
                  }}
                >
                  {subdistrict.map((p: { id: number; name: string }) => (
                    <MenuItem key={p.id} value={p.id}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box display="flex" gap="16px">
              <Button
                fullWidth
                color="silver"
                variant="contained"
                startIcon={<DeleteForeverOutlinedIcon />}
                sx={{ boxShadow: "none" }}
                onClick={handleWarningOpen}
              >
                <Typography typography="subtitle2" fontWeight={600}>
                  ลบกลุ่ม
                </Typography>
              </Button>
            </Box>

            <Box display="flex" gap="16px">
              <Button
                fullWidth
                color="blue"
                variant="outlined"
                component={RouterLink}
                to="/community"
              >
                <Typography variant="subtitle2">ยกเลิก</Typography>
              </Button>

              <Button
                fullWidth
                color="green"
                variant="contained"
                onClick={handleSubmit(onSubmitHandler)}
                disabled={!isValid || loading}
              >
                <Typography variant="subtitle2" color="white">
                  <FormattedMessage id="common.save" />
                </Typography>
              </Button>
            </Box>
          </Box>
        </Container>
      )}

      <Modal keepMounted open={openSuccess}>
        <Box sx={modalStyle} width="340px">
          <SuccessModal
            message={successMessage}
            handleSuccessClose={handleSuccessClose}
            showButton={true}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle} width="340px">
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
        <Box sx={modalStyle} width="340px">
          <ConfirmModal
            message={"คุณแน่ใจหรือไม่ ว่าจะลบกลุ่มนี้"}
            handleConfirm={onDeleteGroup}
            handleClose={handleWarningClose}
          />
        </Box>
      </Modal>

      <Dialog keepMounted disableEscapeKeyDown open={openCropDialog}>
        <DialogContent sx={{ height: "350px", width: "500px" }}>
          <Box
            position="absolute"
            sx={{ top: 0, left: 0, right: 0, bottom: "80px" }}
          >
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, justifyContent: "center" }}>
          <Button
            color="blue"
            variant="outlined"
            type="button"
            onClick={handleCropDialogClose}
          >
            <Typography variant="subtitle2">
              <FormattedMessage id="common.cancel" />
            </Typography>
          </Button>
          <Button
            color="green"
            variant="contained"
            type="button"
            onClick={acceptCropAvatar}
          >
            <Typography variant="subtitle2" color="white">
              <FormattedMessage id="register.accept" />
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CommunityEdit;
