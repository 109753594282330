import {
  Box,
  Button,
  Container,
  Fab,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import {
  boxStyle,
  containerStyle,
  modalStyle,
  pageStyle,
} from "../../../consts/styles";
import SendAssessmentSuccessModal from "../../../components/modals/course/SendAssessmentSuccessModal";
import { postCourseEvaluate } from "../../../services/course.service";
import ErrorModal from "../../../components/modals/ErrorModal";

const AssessmentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [course, setCourse] = useState<any | null>(null);
  const [openSuccess, setSuccessOpen] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => {
    navigate("/course/description", { state: { courseId: course?.course_id } });
    setSuccessOpen(false);
  };
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  const { handleSubmit, register, formState } = useForm({
    defaultValues: {
      answer: "",
    },
  });

  const { isValid, isDirty } = formState;

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();

      await postCourseEvaluate(course?.course_id, values);

      handleSuccessOpen();
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  useEffect(() => {
    if (!location.state) {
      navigate("/home");
      return;
    }
    setCourse(location.state.course);
  }, [location, navigate]);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  return (
    <Box sx={pageStyle}>
      <Box maxWidth={{ xs: 450, sm: 600 }} width={1} zIndex={1}>
        <Grid container spacing={2} padding="8px">
          <Grid item xs={1}>
            <Fab
              sx={{
                background: "#0892F5",
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                color: "white",
                "&:hover": { background: "#0892F5" },
              }}
              component={RouterLink}
              to="/course/description"
              state={{ courseId: course?.course_id }}
            >
              <ArrowBackIcon />
            </Fab>
          </Grid>
          <Grid item xs={10} alignSelf="center">
            <Typography
              align="center"
              sx={{ typography: { xs: "h3", sm: "h1" } }}
              color="ktgGreen.main"
              zIndex={1}
            >
              สอบประเมินหลักสูตร
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Container
        component="main"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        maxWidth={false}
      >
        <Box sx={{ ...boxStyle, padding: "16px", gap: "16px" }}>
          <Typography
            variant="body1"
            color="black.main"
            dangerouslySetInnerHTML={{
              __html: course?.question,
            }}
          />

          <TextField
            multiline
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: "10px !important",
              },
            }}
            rows={8}
            label="กรอกคำตอบของท่านที่นี่..."
            variant="outlined"
            {...register("answer", { required: true })}
          />

          <Box display="flex" gap="8px">
            <Button
              fullWidth
              color="blue"
              variant="outlined"
              component={RouterLink}
              to="/course/description"
              state={{ courseId: course?.course_id }}
            >
              <Typography typography="subtitle2" color="blue.main">
                กลับ
              </Typography>
            </Button>
            <Button
              fullWidth
              color="green"
              variant="contained"
              disabled={!isValid || !isDirty || loading}
              onClick={handleSubmit(onSubmitHandler)}
            >
              <Typography typography="subtitle2" color="white">
                ส่งคำตอบ
              </Typography>
            </Button>
          </Box>
        </Box>
      </Container>

      <Modal keepMounted open={openSuccess}>
        <Box sx={modalStyle} width="340px">
          <SendAssessmentSuccessModal handleClose={handleSuccessClose} />
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle} width="340px">
          <ErrorModal message={errorMessage} handleErrorClose={handleErrorClose} />
        </Box>
      </Modal>
    </Box>
  );
};

export default AssessmentPage;
