export const communityTypes = ["ชุมชน", "โรงเรียน", "องค์กร", "สาธารณสุข"];

export const monthItems = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

export const monthItemsForVerify = [
  "ไม่ทราบเดือนเกิด",
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

export const achievementCaptions = [
  {
    level: [
      "สะสมระยะทาง เดิน-วิ่งได้ อย่างน้อย 1 กิโลเมตร",
      "สะสมระยะทาง เดิน-วิ่งได้ 1 กิโลเมตร",
      "สะสมระยะทาง เดิน-วิ่งได้ 50 กิโลเมตร",
      "สะสมระยะทาง เดิน-วิ่งได้ 100 กิโลเมตร",
    ],
  },
  {
    level: [
      "ประเมิน BMI อย่างน้อย 1 ครั้ง",
      "ประเมิน BMI 1 ครั้ง",
      "ประเมิน BMI 2 ครั้ง",
      "ประเมิน BMI 5 ครั้ง",
    ],
  },
  {
    level: [
      "เผาผลาญพลังงานอย่างน้อย 1,000 kcal",
      "เผาผลาญพลังงานได้ 1,000 kcal",
      "เผาผลาญพลังงานได้ 5,000 kcal",
      "เผาผลาญพลังงานได้ 10,000 kcal",
    ],
  },
  {
    level: [
      "สะสมระยะเวลาออกกำลังกายอย่างน้อย 150 นาที",
      "สะสมระยะเวลาออกกำลังกายได้ 150 นาที",
      "สะสมระยะเวลาออกกำลังกายได้ 1,000 นาที",
      "สะสมระยะเวลาออกกำลังกายได้ 2,000 นาที",
    ],
  },
  {
    level: [
      "สะสมให้ได้ Level 3",
      "คุณผ่าน Level 3",
      "คุณผ่าน Level 8",
      "คุณผ่าน Level 20",
    ],
  },
  {
    level: [
      "ดูรอบรู้สุขภาพอย่างน้อย 1 เรื่อง",
      "ดูรอบรู้สุขภาพ 1 เรื่อง",
      "ดูรอบรู้สุขภาพ 5 เรื่อง",
      "ดูรอบรู้สุขภาพ 10 เรื่อง",
    ],
  },
  {
    level: [
      "ส่งผลออกกำลังกายอย่างน้อย 5 วัน",
      "ส่งผลออกกำลังกายรวม 5 วัน",
      "ส่งผลออกกำลังกายรวม 10 วัน",
      "ส่งผลออกกำลังกายรวม 20 วัน",
    ],
  },
];
