import { Box, Button, Container, Typography } from "@mui/material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { Link as RouterLink } from "react-router-dom";

const HealthPlace = () => {
  return (
    <Container
      component="main"
      maxWidth={false}
      sx={{ zIndex: 1000, maxWidth: "1120px" }}
    >
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        gap="16px"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={{ xs: "16px", md: "24px" }}
          width={{ xs: 1, md: 0.5 }}
        >
          <Box display="flex" gap="8px">
            <FmdGoodIcon
              sx={{
                fontSize: { xs: "20px", md: "40px" },
                alignSelf: "center",
                color: "#00A3FE",
              }}
            />
            <Typography typography={{ xs: "h4", md: "h1" }} color="green.main">
              Health Place
            </Typography>
          </Box>

          <Typography typography={{ xs: "body1", md: "subtitle2" }}>
            ค้นหาสถานที่สำหรับออกกำลังกายใกล้ ๆ
            คุณเพื่อเสริมสร้างสุขภาพที่แข็งแรง สามารถเลือกที่ที่ต้องการไปเพื่อ
            ดูวิธีการเดินทาง สิ่งอำนวยความสะดวก และอื่น ๆ
            เพื่อหาสถานที่ที่เหมาะกับการออกกำลังกายในแบบของคุณ
          </Typography>

          <Button
            color="blue"
            variant="contained"
            sx={{ width: { xs: 1, md: "fit-content" } }}
            component={RouterLink}
            to="/health-place-list"
          >
            <Typography variant="subtitle2" color="white">
              ค้นหาที่ออกกำลังกาย
            </Typography>
          </Button>
        </Box>

        <Box width={{ xs: 1, md: 0.5 }}>
          <Box component="img" src="/images/health-place.png" width={1}></Box>
        </Box>
      </Box>
    </Container>
  );
};

export default HealthPlace;
