import {
  Box,
  Button,
  FormControl,
  FormLabel,
  TextField,
  Typography,
  OutlinedInput,
  Modal,
  InputAdornment,
  Container,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IMaskInput } from "react-imask";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  boxStyle,
  containerStyle,
  modalStyle,
  pageStyle,
} from "../../../consts/styles";
import { postRequestOtpService } from "../../../services/fiit.service";
import ErrorModal from "../../../components/modals/ErrorModal";
import { useAppSelector } from "../../../features/userStore/hook";
import { getProfile } from "../../../features/userStore/libs/profileSlice";
import { FormattedMessage } from "react-intl";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

interface State {
  textmask: string;
  numberformat: string;
}

const TextPhone = React.forwardRef<HTMLElement, CustomProps>(function TextPhone(
  props,
  ref: any
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000-000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value: any) =>
        onChange({ target: { name: props.name, value } })
      }
      overwrite
    />
  );
});

const ConnectFiitToken = () => {
  const profile = useAppSelector(getProfile);
  const location = useLocation();
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [values, setValues] = React.useState<State>({
    textmask: "",
    numberformat: "1320",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      textmask: event.target.value,
      numberformat: "1320",
    });

    setValue("phone_no", event.target.value);
  };

  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  // form
  const { register, formState, handleSubmit, setValue } = useForm({
    defaultValues: {
      username: "",
      phone_no: "",
    },
  });

  const { isDirty, isValid } = formState;

  const onError = (errors: any, e: any) => console.log(errors, e);

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();

      const body = {
        ...values,
        phone_no: values.phone_no.replaceAll("-", ""),
      };

      const resp = await postRequestOtpService(body);

      setTimeout(() => {
        navigate("/confirm-otp-fiit", {
          state: {
            ...body,
            ref_code: resp.data.ref_code,
          },
          replace: true,
        });
        handleLoaded();
      }, 1000);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };
  // end

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  useEffect(() => {
    if (!location.state) {
      navigate("");
      return;
    }
    const { username } = location.state;
    setUsername(username);
    setValue("username", username);
  }, [location, navigate, setValue]);

  return (
    <Box sx={pageStyle}>
      <Box
        component="img"
        src="/images/logo.png"
        alt="logo"
        width={75}
        height={100}
      />

      <Container
        component="main"
        sx={{ ...containerStyle, maxWidth: "400px" }}
        maxWidth={false}
      >
        <Box sx={{ ...boxStyle, alignItems: "center", padding: "40px 32px" }}>
          <Box
            component="img"
            src="/images/fiit/logo.png"
            width={110}
            height={50}
          />

          <Typography align="center" variant="h4" color="black.main">
            เชื่อมต่อข้อมูลลก้าวท้าใจ
          </Typography>

          <FormControl fullWidth>
            <FormLabel>
              <Typography variant="body1" color="common.black">
                ชื่อผู้ใช้
              </Typography>
            </FormLabel>
            <TextField
              defaultValue={username}
              color="primary"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              {...register("username")}
              disabled
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel>
              <Typography variant="body1" color="common.black">
                ยืนยันเบอร์โทรศัพท์เพื่อรับ OTP
              </Typography>
            </FormLabel>

            <OutlinedInput
              value={values.textmask}
              inputComponent={TextPhone as any}
              {...register("phone_no", {
                required: true,
                minLength: 12,
                maxLength: 12,
                onChange: handleChange,
              })}
            />
          </FormControl>

          <Button
            fullWidth
            color="green"
            variant="contained"
            onClick={handleSubmit(onSubmitHandler, onError)}
            disabled={!isDirty || !isValid || loading}
          >
            <Typography variant="subtitle2" color="white">
              ขอรหัส OTP
            </Typography>
          </Button>

          <Button
            fullWidth
            color="primary"
            variant="outlined"
            component={RouterLink}
            to={profile ? "/home" : "/login"}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
        </Box>
      </Container>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle} width={340}>
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default ConnectFiitToken;
