import { getItem} from "./localStorage.service";

export default function authHeader() {
  const session = getItem("session");
  if (session?.access_token) {
    return { Authorization: `Bearer ${session?.access_token}` }; // for Spring Boot back-end
  } else {
    return { Authorization: "" }; // for Spring Boot back-end
  }
}
